import React from "react";
import { useIntl } from "react-intl";
import { ErrorMessage } from "@hookform/error-message";

import Buttons from "../../../../components/Forms/Buttons";
import InputFloat from "../../../../components/Forms/InputFloatLabel";

import noLogoBank from "../../../../assets/images/icon-banco-nologo.svg";
import arrowDownIcon from "../../../../assets/images/signup/arrow-down-select.svg";

import * as styled from "../../styles";

import { useBankInformation } from "./hooks";

export const BankAccountInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  const {
    Controller,
    control,
    handleSubmit,
    submitBankInformation,
    banksAndCodesState,
    banksAndCodes,
    isProcessing,
    errors,
    showBankList,
    handleOpenBankList,
    handleSelectABank,
    bankNameSelected,
    bankIconSelected,
    handleSearchBank,
  } = useBankInformation({ setProgressStep, isStoreWithCNPJ, store });

  return (
    <>
      <form onSubmit={handleSubmit(submitBankInformation)}>
        <div className="form-item">
          <label>{isStoreWithCNPJ ? "CNPJ" : "CPF"}</label>

          <Controller
            name="identificationNumber"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal-mask"
                mask={isStoreWithCNPJ ? "99.999.999/9999-99" : "999.999.999-99"}
                // disabled={true}
                handler={() => {}}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="identificationNumber"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "home.withdrawall.bank.holder.name",
            })}
          </label>

          <Controller
            name="holderName"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="holderName"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "create.account.bank.name",
            })}
          </label>

          <styled.SearchBank>
            <div className="div-search" onClick={handleOpenBankList}>
              <div className="bank__info">
                {!bankIconSelected &&
                  !bankNameSelected &&
                  intl.formatMessage({
                    id: "home.withdrawall.select.bank.account.placeholder.register",
                  })}

                {bankIconSelected && bankNameSelected && (
                  <>
                    <img src={bankIconSelected} alt="arrow down" />

                    <p>{bankNameSelected}</p>
                  </>
                )}
              </div>

              <div className="arrow">
                <img src={arrowDownIcon} alt="arrow down" />
              </div>
            </div>

            {showBankList && (
              <div className="c-bank-component">
                <input
                  type="search"
                  placeholder="Pesquise o banco"
                  className="search-bank"
                  onChange={(e) => handleSearchBank(e.target.value)}
                />

                <div className="scroll">
                  {(banksAndCodesState.length > 0
                    ? banksAndCodesState
                    : banksAndCodes
                  ).map((bank) => (
                    <label
                      key={bank.bankCode}
                      onClick={() =>
                        handleSelectABank(
                          bank.bankName,
                          bank.bankLogo ? bank.bankLogo : noLogoBank,
                          bank.bankCode
                        )
                      }
                    >
                      <input type="radio" name="bank" value={bank.bankCode} />

                      <span className="bank__name">
                        <img
                          src={bank.bankLogo ? bank.bankLogo : noLogoBank}
                          alt="bank logo"
                        />
                        {bank.bankName}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <ErrorMessage
              errors={errors}
              name="bank"
              render={({ message }) => <p className="error">{message}</p>}
            />
          </styled.SearchBank>
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "home.withdrawall.branch",
            })}
          </label>

          <Controller
            name="branch"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal"
                handler={() => {}}
                {...field}
                type="number"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="branch"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "home.withdrawall.account",
            })}
          </label>

          <Controller
            name="account"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal"
                handler={() => {}}
                {...field}
                type="number"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="account"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <styled.ButtonFooterContainer>
          <Buttons
            mode={!isProcessing ? "filled" : "filled-processing"}
            label={intl.formatMessage({
              id: "login.button.login.continue",
            })}
            type="submit"
            handler={() => {}}
          />
        </styled.ButtonFooterContainer>
      </form>
    </>
  );
};
