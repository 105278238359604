export default function formatIdentificationNumber(identificationNumber: string) {
  let finalIdentificationNumberFormatted = "";

  if (identificationNumber.length > 11) {
    finalIdentificationNumberFormatted = identificationNumber.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");

    } else {
    finalIdentificationNumberFormatted = identificationNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  }

  return finalIdentificationNumberFormatted;
}
