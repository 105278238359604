import React from "react";
import { useIntl } from "react-intl";

import * as styled from "./details-styles";

type Products = {
  id: number;
  name: string;
  quantity: number;
  unitPrice: number;
};

interface ProductData {
  products: Products[];
}

export const ProductItem = ({ products }: ProductData) => {
  const intl = useIntl();

  return (
    <styled.ProdutctsTable>
      <tr>
        <th className="label">
          {intl.formatMessage({ id: "order.details.product.name" })}
        </th>
        <th className="label">
          {intl.formatMessage({ id: "order.details.product.quantity" })}
        </th>
        <th className="label">
          {intl.formatMessage({ id: "order.details.product.price" })}
        </th>
      </tr>
      {products.map((products) => {
        return (
          <tr key={products.id} className="table-row-values">
            <td className="value">{products.name}</td>
            <td className="value">{products.quantity}</td>
            <td className="value">
              {intl.formatNumber(products.unitPrice, {
                style: "currency",
                currency: "BRL",
              })}
            </td>
          </tr>
        );
      })}
    </styled.ProdutctsTable>
  );
};
