import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import OrderFeesServices from "../../../../../../services/OrderFees";
import GetUserDataService from "../../../../../../services/GetUserData";

import ErrorMessage from "../../../FormValidations/ErrorMessage";

import Input from "../../../../../Forms/InputFloatLabel";
import Select from "../../../../../Forms/Select";

import CusomerPersonalData from "../../../CustomerPersonalData";

//Styles
import * as styled from "../../../payment-request-styles";

//Types
import { BoletoProps } from "../../types";

const BoletoManual = ({
  customer,
  submit,
  handleCustomer,
  isForeigner,
  boleto,
  handleProcessing,
  manualOrder,
  handleManualOrder,
  isRecurring,
  customerCurrency,
  formErrors,
  applySplitsAfterOrder,
  limitBoleto
}: BoletoProps) => {
  const applyFeesAfterOrder = [
    0, 0, 3.45, 5.22, 7.02, 8.85, 10.71, 12.6, 14.53, 16.49, 18.48, 20.5, 22.56,
  ];

  const [errorMessage, setErrorMessage] = useState("");

  const [isCnpj, setIsCnpj] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [customerFees, setCustomerFees] = useState(false);
  const [customizeBoleto, setCustomizeBoleto] = useState(false);
  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [valueOrder, setValueOrder] = useState("");
  let valueOrderNumber = parseFloat(valueOrder);

  const [manualDuedateError, setManualDuedateError] = useState("");

  const [dateLabel, setDateLabel] = useState("Data de Nascimento:");
  const [boletoInstallments, setBoletoInstallments] = useState(false);
  const [boletoInstallmentsAmount, setBoletoInstallmentsAmount] = useState<
    number[]
  >([]);

  const [isTrueOrderNumber, setIsTrueOrderNumber] = useState(false);
  const [informTax, setInformTax] = useState(false);
  const [generateAllBoleto, setGenerateAllBoleto] = useState(false);

  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");

  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);

  const yesNoSelect = [
    {
      value: "true",
      label: "select.yes",
    },
    {
      value: "false",
      label: "select.no",
    },
  ];

  const intl = useIntl();

  useEffect(() => {
    if (includeFeeTransfer) {
      OrderFeesServices.boletoFees({
        amount: parseFloat(manualOrder.amount),
        paymentType: "Boleto",
      }).then((response) => {
        if (response.data.length > 0) {
          let fee = response.data[0].amount - parseFloat(manualOrder.amount);

          let amountsWithFees = [];

          if (applySplitsAfterOrder) {
            for (let index = 1; index <= 12; index++) {
              let addictionalFee =
                (response.data[index - 1].amount * applyFeesAfterOrder[index]) /
                100;

              amountsWithFees.push(
                parseFloat(
                  (
                    parseFloat(manualOrder.amount) / index +
                    fee +
                    addictionalFee
                  ).toFixed(2)
                )
              );
            }
          } else {
            for (let index = 1; index <= 12; index++) {
              amountsWithFees.push(
                parseFloat(
                  (parseFloat(manualOrder.amount) / index + fee).toFixed(2)
                )
              );
            }
          }

          // for (let index = 1; index <= 12; index++) {
          //   amountsWithFees.push(parseFloat(((parseFloat(manualOrder.amount) / index) + fee).toFixed(2)));
          // }

          setBoletoInstallmentsAmount(amountsWithFees);
        }
      });
    } else {
      let amountsWithoutFees = [];
      let addictionalFee = 0;

      for (let index = 1; index <= 12; index++) {
        if (applySplitsAfterOrder) {
          addictionalFee =
            (parseFloat(manualOrder.amount) * applyFeesAfterOrder[index]) / 100;
        }

        amountsWithoutFees.push(
          parseFloat(
            ((parseFloat(manualOrder.amount) + addictionalFee) / index).toFixed(
              2
            )
          )
        );
      }

      // for (let index = 1; index <= 12; index++) {
      //   amountsWithoutFees.push(parseFloat(((parseFloat(manualOrder.amount) / index)).toFixed(2)));
      // }

      setBoletoInstallmentsAmount(amountsWithoutFees);
    }
  }, [includeFeeTransfer, boleto, manualOrder.amount]);

  useEffect(() => {
    setIncludeFeeTransfer(false);
    setBoletoInstallments(false);
  }, []);

  useEffect(() => {
    if (customerFees) {
      setIncludeFeeTransfer(true);
    } else {
      setIncludeFeeTransfer(false);
    }
  }, [customerFees]);

  function handleInstallments(event: string) {
    let selectedInstallment = parseInt(event);

    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        installments: selectedInstallment,
      };
    });
  }

  function handleChargeDaysBefore(event: string) {
    let numberOfDays = parseInt(event);

    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        chargeDaysBefore: numberOfDays,
      };
    });
  }

  useEffect(() => {
    if (customer.identificationNumber.length > 14) {
      setIsCnpj(true);
      setDateLabel("Data de Registro:");
      setCpfCnpjLabel("CNPJ:");

      handleCustomer((prevState: any) => {
        return {
          ...prevState,
          firstName: "",
          lastName: "",
        };
      });

      if (refName.current && refLastName.current) {
        refName.current.disabled = true;
        refLastName.current.disabled = true;
      }
    } else if (customer.identificationNumber.length === 0) {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF/CNPJ");
    } else {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento:");
      setCpfCnpjLabel("CPF");
    }
  }, [customer.identificationNumber]);

  function handleSubmit(event: React.SyntheticEvent) {
    handleProcessing(true);

    event.preventDefault();

    if (manualOrder.orderNumber === "") {
      GetUserDataService.getUserData()
        .then((responseUserData) => {
          submit(
            includeFeeTransfer,
            valueOrder,
            responseUserData.data,
            generateAllBoleto
          );
        })
        .catch((err) => {
          submit(includeFeeTransfer, valueOrder, generateAllBoleto);
        });
    }
  }

  function handleSetBoletoWithInstallments(event: string) {
    setBoletoInstallments(event === "true" ? true : false);
  }

  function handleManualOrderAmount(value: string) {
    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  return (
    <>
      {!isRecurring && (
        <>
          <div className="form-item">
            <h5>{intl.formatMessage({ id: "request.payment.amount" })}</h5>

            <Input
              mode="normal-fixed-symbol"
              isCurrency={true}
              fixedSymbol="R$"
              name="value-order"
              value={manualOrder.amount}
              placeholder=""
              handler={handleManualOrderAmount}
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="orderAmount"
              errorMessage={intl.formatMessage({
                id: "error.request.payment.amount",
              })}
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="amountMoreThanLimit"
              errorMessage={`${intl.formatMessage({
                id: "error.request.payment.amount.more.than.10k",
              })}${intl.formatNumber(limitBoleto ? limitBoleto : 0, { style: "currency", currency: "BRL" })}`}
            />
            
          </div>

          <div className="form-item">
            <h5>
              {intl.formatMessage({ id: "request.payment.boleto.installment" })}
            </h5>

            <Select
              data={yesNoSelect}
              mode="normal-intl"
              name="permission"
              value={boletoInstallments.toString()}
              placeholder={""}
              handler={handleSetBoletoWithInstallments}
            />
          </div>
        </>
      )}

      {boletoInstallments &&
        parseFloat(manualOrder.amount) > 0 &&
        !isRecurring && (
          <div className="form-item">
            <h5>
              {intl.formatMessage({
                id: "request.payment.boleto.installment.2",
              })}
            </h5>

            <Select
              mode="normal-child"
              name="installments"
              value={manualOrder.installments.toString()}
              placeholder={""}
              handler={handleInstallments}
            >
              {boletoInstallmentsAmount.map(
                (installment: number, index: number) => (
                  <option key={index} value={index + 1}>
                    {`${index + 1}x ${intl.formatMessage({
                      id: "request.payment.of",
                    })} ${intl.formatNumber(boletoInstallmentsAmount[index], {
                      style: "currency",
                      currency: "BRL",
                    })} ${intl.formatMessage({
                      id: includeFeeTransfer
                        ? "request.payment.with.fees"
                        : "request.payment.without.fees",
                    })}`}
                  </option>
                )
              )}
            </Select>
          </div>
        )}

      {manualOrder.installments > 1 && (
        <div className="form-item">
          <h5>
            {intl.formatMessage({
              id: "request.payment.boleto.days.before.generate.boleto",
            })}
          </h5>

          <Input
            required={false}
            mode="normal"
            name="chargeDaysBefore"
            value={manualOrder.chargeDaysBefore}
            placeholder=""
            handler={handleChargeDaysBefore}
          />
        </div>
      )}

      {/* {manualDuedateError !== '' && <styled.Error>{manualDuedateError}</styled.Error>} */}

      <CusomerPersonalData
        orderNumber={manualOrder.orderNumber}
        customer={customer}
        handleCustomer={handleCustomer}
        isForeigner={isForeigner}
        boleto={true}
        showCustomer={true}
        showAddress={true}
        setIsTrueOrderNumber={setIsTrueOrderNumber}
        customerCurrency={customerCurrency}
        formErrors={formErrors}
      />

      {errorMessage !== "" && (
        <styled.Error>
          <FormattedMessage
            id={`request.payment.zoop.error.${errorMessage}`}
            defaultMessage={intl.formatMessage({ id: "error.unknown.error" })}
          />
        </styled.Error>
      )}
    </>
  );
};

export default BoletoManual;
