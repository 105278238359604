const axios = require("axios");

interface IWithdrawal {
  bankAccountId: number;
  amount: number;
  description: string;
}

class BankAccountService {
  async withdrawal(withdrawal: IWithdrawal) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/bank_accounts/${withdrawal.bankAccountId}/balance/withdrawal`,
      { amount: withdrawal.amount, description: withdrawal.description },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async withdrawalForeign(amount: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fx/buckets`,
      {
        amount: amount,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getForeignWithdrawals(accountId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fx/foreign_accounts/${accountId}/withdrawals`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async changeBankAccount(bankAccount: any, storeId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/bank_accounts`,
      {
        holderName: bankAccount.holderName,
        bankCode: bankAccount.bankCode,
        routingNumber: bankAccount.routingNumber,
        accountNumber: bankAccount.accountNumber,
        identificationNumber: bankAccount.identificationNumber,
        type: bankAccount.type,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getBanks() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/banks`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    });
  }

  async listSaques(bankAccountId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/bank_accounts/${bankAccountId}/withdrawal/Report`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getWithdrawals(pageIndex: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/withdrawals?pageSize=100&pageIndex=${pageIndex}&sort=time-descending`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/withdrawals?pageSize=100&pageIndex=${pageIndex}&sort=time-descending`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async transferToAnotherStore(
    storeIdOrigem: number,
    storeIdDestino: number,
    amount: number,
    description: string
  ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/transfers/create/${storeIdOrigem}/to/${storeIdDestino}`,
      {
        amount: amount,
        description: description,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }
}

export default new BankAccountService();
