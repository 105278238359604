import React, { useContext, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { Provider } from "../store/AppContext";
import { ScreenWrapper } from "./RouterHelpers";
import { ToggleTemaContext } from "../providers/toggleTheme";
import { RequestPaymentProvider } from "../providers/requestPaymentContext";

import { isValid } from "../services/AdminSession";

import Home from "../screens/Home";
import Transactions from "../screens/Transactions";
import TransactionsDetails from "../screens/TransactionDetails";
import SaleCommissions from "../screens/SaleCommissions";
import SaleCommissionsDetails from "../screens/SaleCommissionDetails";
import Reports from "../screens/Reports";
import Login from "../screens/Login";
import Integrations from "../screens/Integrations";
import CreateStore from "../screens/StoreRegister";
import UserProfile from "../screens/UserProfile";
import RedefinePassword from "../screens/RedefinePassword";
import RequestPaymentScreen from "../screens/RequestPayment";
import CreatePaymentLinkScreen from "../screens/CreatePaymentLink";
import CreateProductScreen from "../screens/CreateProduct";
import CreateManualPaymentScreen from "../screens/CreateManualPayment";
import PaymentLinksScreen from "../screens/PaymentLinks";
import ProductsScreen from "../screens/Products/products.page";
import ProductDetailsScreen from "../screens/ProductDetails";
import PaymentLinkDetailsScreen from "../screens/PaymentLinkDetails";
import NewPaymentLinkDetailsScreen from "../screens/NewPaymentLinkDetails";
import SettingsScreen from "../screens/Settings";
import FinancialsScreen from "../screens/Financials/financials.page";
import CustomersScreen from "../screens/Customers/customers.page";
import CustomerDetailsScreen from "../screens/Customers/customer-details.page";
import CreateCustomerScreen from "../screens/Customers/Components/CreateCustomer";
import PlansScreen from "../screens/Plans/plans.page";
import PlanDetailsScreen from "../screens/Plans/plan-details.page";
import CreatePlanScreen from "../screens/Plans/Components/CreatePlan";
import SignupScreen from "../screens/Singup/signup.page";

import Sidebar from "../components/Sidebar/Index";
import SidebarMobile from "../components/SidebarMobile/index";
import TopBar from "../components/Layout/TopBar/Index";

import GlobalStyles from "../styles/global";

import "../assets/css/index.scss";

const AppRoutes = () => {
  const { theme } = useContext(ToggleTemaContext);

  const viewAsRef = useRef<HTMLDivElement>(null);
  const requestPaymentRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const testDataRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider>
        <Router>
          <TopBar
            viewAsRef={viewAsRef}
            requestPaymentRef={requestPaymentRef}
            settingsRef={settingsRef}
          />
          <Sidebar settingsRef={settingsRef} testDataRef={testDataRef} />
          <SidebarMobile settingsRef={settingsRef} testDataRef={testDataRef} />

          <Switch>
            <Route
              path="/login"
              component={() => ScreenWrapper(<Login />, `login`)}
            />

            <Route
              path="/signup"
              component={() => ScreenWrapper(<SignupScreen />, `signupScreen`)}
            />

            <Route
              path="/"
              exact
              component={() =>
                ScreenWrapper(
                  <Home
                    viewAsRef={viewAsRef}
                    requestPaymentRef={requestPaymentRef}
                    settingsRef={settingsRef}
                    testDataRef={testDataRef}
                  />,
                  "home"
                )
              }
            />
            <Route
              path="/new-password"
              exact={true}
              component={() =>
                ScreenWrapper(<RedefinePassword />, "redefinePassword")
              }
            />
            <Route
              path="/transactions"
              exact
              component={() => ScreenWrapper(<Transactions />, `transactions`)}
            />
            <Route
              path="/search"
              exact
              component={() => ScreenWrapper(<Transactions />, `transactions`)}
            />
            <Route
              path="/sale-commissions"
              exact
              component={() =>
                ScreenWrapper(<SaleCommissions />, `saleCommissions`)
              }
            />
            <Route
              path="/reports"
              component={() => ScreenWrapper(<Reports />, `details`)}
            />
            <Route
              path="/integrations"
              component={() => ScreenWrapper(<Integrations />, `integrations`)}
            />
            <Route
              path="/details"
              component={() =>
                ScreenWrapper(<TransactionsDetails />, `details`)
              }
            />
            <Route
              path="/transactions/:number/:id/details"
              component={() =>
                ScreenWrapper(<TransactionsDetails />, `details`)
              }
            />
            <Route
              path="/sale-commissions/:number/details"
              component={() =>
                ScreenWrapper(<SaleCommissionsDetails />, `details`)
              }
            />
            <Route
              path="/user-profile"
              exact
              component={() => ScreenWrapper(<UserProfile />, `userProfile`)}
            />
            <Route
              path="/settings"
              exact
              component={() => ScreenWrapper(<SettingsScreen />, `settings`)}
            />
            <Route
              path="/store-register"
              exact
              component={() => ScreenWrapper(<CreateStore />, `createStore`)}
            />
            <Route
              path="/request-payment"
              exact
              component={() =>
                ScreenWrapper(<RequestPaymentScreen />, `requestPayment`)
              }
            />
            <Route
              path="/payment-links"
              exact
              component={() =>
                ScreenWrapper(<PaymentLinksScreen />, `paymentLinks`)
              }
            />
            <Route
              path="/payment-links/:url"
              exact
              component={() =>
                ScreenWrapper(
                  <PaymentLinkDetailsScreen />,
                  `paymentLinkDetails`
                )
              }
            />
            <Route
              path="/payment-links/:url/new"
              exact
              component={() =>
                ScreenWrapper(
                  <NewPaymentLinkDetailsScreen />,
                  `newPaymentLinkDetails`
                )
              }
            />
            <Route
              path="/financials"
              exact
              component={() =>
                ScreenWrapper(<FinancialsScreen />, `financials`)
              }
            />
            <Route
              path="/products"
              exact
              component={() => ScreenWrapper(<ProductsScreen />, `products`)}
            />
            <Route
              path="/product/:id"
              exact
              component={() =>
                ScreenWrapper(<ProductDetailsScreen />, `productDetails`)
              }
            />
            <Route
              path="/product/:id/new"
              exact
              component={() =>
                ScreenWrapper(<ProductDetailsScreen />, `newProductDetails`)
              }
            />
            <Route
              path="/customers"
              exact
              component={() => ScreenWrapper(<CustomersScreen />, `customers`)}
            />
            <Route
              path="/customers/:storeId/:id"
              exact
              component={() =>
                ScreenWrapper(<CustomerDetailsScreen />, `customerDetails`)
              }
            />
            <Route
              path="/customers/create"
              exact
              component={() =>
                ScreenWrapper(<CreateCustomerScreen />, `createCustomer`)
              }
            />
            <Route
              path="/plans"
              exact
              component={() => ScreenWrapper(<PlansScreen />, `plans`)}
            />
            <Route
              path="/plans/:storeId/:id/edit"
              exact
              component={() =>
                ScreenWrapper(<CreatePlanScreen />, `planDetails`)
              }
            />
            <Route
              path="/plans/create"
              exact
              component={() =>
                ScreenWrapper(<CreatePlanScreen />, `createPlan`)
              }
            />

            <RequestPaymentProvider>
              <Route
                path="/request-payment/create-payment-link"
                exact
                component={() =>
                  ScreenWrapper(
                    <CreatePaymentLinkScreen />,
                    `createPaymentLink`
                  )
                }
              />
              <Route
                path="/request-payment/create-manual-payment"
                exact
                component={() =>
                  ScreenWrapper(
                    <CreateManualPaymentScreen />,
                    `createManualPayment`
                  )
                }
              />
              <Route
                path="/request-payment/create-product"
                exact
                component={() =>
                  ScreenWrapper(<CreateProductScreen />, `createProduct`)
                }
              />
            </RequestPaymentProvider>
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default AppRoutes;
