import React, {useState, useEffect} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

//Components
import {
  ContentBox,
  LayoutWrapper,
} from "components/Layout/Index";
import TopBar from "components/Layout/TopBar/Index";
import Sidebar from "components/Sidebar/Index";
import SaleCommissionService from 'services/SaleCommission';

//Services
import SessionService from 'services/Session';

//Styles
import * as indexStyled from '../styles/index-styles';
interface ISaleCommission {
  id: number,
  storeName: string,
  order: IOrder,
  amount: number,
  status: number,
  createdOn: string,
  paymentType?: string,
  number?: string,
  statusString?: string,
}

interface IAdminSaleCommission {
  storeName: string,
  amount: number,
  paymentType: string,
  number: string,
  statusString: string,
  expectedOn: string,
}

interface IOrder {
  status: number,
  number: string,
  netAmount: number,
  payment: IPayment
}

interface IPayment {
  status: number,
  type: number
}

function Transactions() {
  const [isSaleCommissionsLoaded, setIsSaleCommissionsLoaded] = useState<boolean>(false);
  const [isAdminSaleCommissionsLoaded, setIsAdminSaleCommissionsLoaded] = useState<boolean>(false);
  const [SaleCommissions, setSaleCommissions] = useState<ISaleCommission[]>([]);
  const [adminSaleCommissions, setAdminSaleCommissions] = useState<IAdminSaleCommission[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);

  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  let location = useLocation();

  useEffect(() => {
    SaleCommissionService.get({pageSize: 50})
    .then(response => {
      let saleCommissions: ISaleCommission[] = [];
      let adminSaleCommissions: IAdminSaleCommission[] = [];

      if(/admin/.test(location.pathname)) {
        response.data.forEach((value: any) => {
          adminSaleCommissions.push({amount: value.amount, storeName: value.store, statusString: value.status, 
          paymentType: value.paymentType, number: value.number, expectedOn: value.expectedOn});
        });

        setIsAdmin(true);
        setAdminSaleCommissions(adminSaleCommissions);
        setIsAdminSaleCommissionsLoaded(true);
      }else {
        response.data.results.forEach((value: any) => {
          saleCommissions.push({id: value.id, storeName: value.store.name, order: {status: value.split.order.status, 
            number: value.split.order.number, netAmount: value.split.order.netAmount, payment: {
              status: value.split.order.payment.status, type: value.split.order.payment.type}}, amount: value.amount,
            status: value.status, createdOn: value.createdOn});
        });

        setSaleCommissions(saleCommissions);
        setIsSaleCommissionsLoaded(true);
      }
    })
    .catch(err => console.log(err));
  }, [])

  return (
    <>
      <Sidebar />
      <LayoutWrapper>
        <TopBar />
        <indexStyled.RowList>
          <ContentBox cssClass="m-top-25 list-transactions">
            <indexStyled.TableList>
              <indexStyled.ContextBoxHeader>
                <div className="title">
                  <FormattedMessage id="sale.commissions.title" />
                </div>
                {/*<div className="selects">
                  <Select
                    mainLabel="Status:"
                    name="status"
                    options={[{ value: "all", label: "All" }]}
                    action={() => {}}
                  />
                  <Select
                    mainLabel="Filter:"
                    name="filter"
                    options={[{ value: "all", label: "Last 7 days" }]}
                    action={() => {}}
                  />
                  <Select
                    mainLabel="Method:"
                    name="status"
                    options={[{ value: "all", label: "All" }]}
                    action={() => {}}
                  />
                  </div>*/}
              </indexStyled.ContextBoxHeader>
              <indexStyled.TableListHeader>
                <ul>
                  <li>
                    <FormattedMessage id="home.status" />
                  </li>
                  <li>
                    <FormattedMessage id="home.order" />
                  </li>
                  <li>
                    <FormattedMessage id="sale.commissions.seller.name" />
                  </li>
                  <li>
                    <FormattedMessage id="home.method" />
                  </li>
                  { isAdmin && <li>
                    Data prevista
                  </li>}
                  <li>
                    <FormattedMessage id="sale.commissions.brl.commission.amount" />
                  </li>
                  {/*<li>
                    <FormattedMessage id="home.gbp.amount" />
                  </li>*/}
                </ul>
              </indexStyled.TableListHeader>
              <indexStyled.TableItems>
                {(isSaleCommissionsLoaded && !isAdmin) ? (
                  SaleCommissions.map((saleCommission : ISaleCommission, key: number) => {
                    let status;
                    let paymentType;

                    switch(saleCommission.status)
                    {
                      case 1:
                        status = <li className="green">
                                  <FormattedMessage id="payment.confirmed" />
                                </li>
                        break;
                      case 2:
                        status = <li className="red">
                                  <FormattedMessage id="payment.canceled" />
                                </li>
                        break;
                      default:
                        status = <li></li>
                        break;
                    }

                    switch(saleCommission.order.payment.type)
                    {
                      case 0:
                        paymentType = <FormattedMessage id="payment.credit.card" />
                        break;
                      case 1:
                        paymentType = <FormattedMessage id="payment.boleto.bancario" />
                        break;
                    }

                    return (
                      <Link to={`/sale-commissions/${saleCommission.order.number}/details`} key={key}>
                        <ul>
                          {status}
                          <li>#{saleCommission.order.number}</li>
                          <li>{saleCommission.storeName}</li>
                          <li>{paymentType}</li>
                          <li><FormattedNumber value={saleCommission.amount} style="currency" currency="BRL" /></li>
                          {/*
                          <li>
                            &#8356;
                            <FormattedNumber value={542.21} />
                          </li>
                          */}
                        </ul>
                      </Link>
                    );
                  })
                ) : (isAdminSaleCommissionsLoaded && isAdmin) ? (
                  adminSaleCommissions.map((adminSaleCommission : IAdminSaleCommission) => {
                    let status;
                    let paymentType;

                    switch(adminSaleCommission.statusString)
                    {
                      case 'succeeded':
                        status = <li className="green">
                                  <FormattedMessage id="payment.confirmed" />
                                </li>
                        break;
                      case 'canceled':
                        status = <li className="red">
                                  <FormattedMessage id="payment.canceled" />
                                </li>
                        break;
                      default:
                        status = <li></li>
                        break;
                    }

                    switch(adminSaleCommission.paymentType)
                    {
                      case 'Credit':
                        paymentType = <FormattedMessage id="payment.credit.card" />
                        break;
                      case 'Boleto':
                        paymentType = <FormattedMessage id="payment.boleto.bancario" />
                        break;
                      default:
                        paymentType = '----';

                    }

                    // let timeZoneOffsetRefund = new Date(adminSaleCommission.createdOn).getTimezoneOffset();
                    // let timeZoneOffsetPositiveRefund = new Date(adminSaleCommission.createdOn).getTimezoneOffset() * (-1);
          
                    // let formatDateRefund = timeZoneOffsetRefund > 0 ? new Date (new Date(adminSaleCommission.createdOn).getTime() - (timeZoneOffsetRefund * 60 * 1000)) : new Date (new Date(adminSaleCommission.createdOn).getTime() + (timeZoneOffsetPositiveRefund * 60 * 1000));
                    let dateFormattedTimeZoneRefund = moment(adminSaleCommission.expectedOn).format('DD/MM/YYYY');

                    return (
                      // <Link to={`/sale-commissions/${adminSaleCommission.number}/details`} key={adminSaleCommission.number}>
                        <ul>
                          {status}
                          <li>#{adminSaleCommission.number}</li>
                          <li>{adminSaleCommission.storeName}</li>
                          <li>{paymentType}</li>
                          <li>{dateFormattedTimeZoneRefund}</li>
                          <li><FormattedNumber value={adminSaleCommission.amount} style="currency" currency="BRL" /></li>
                          {/*
                          <li>
                            &#8356;
                            <FormattedNumber value={542.21} />
                          </li>
                          */}
                        </ul>
                      // </Link>
                    );
                  })
                ) : (
                  <Skeleton count={1} height={25} className="skeleton"/>
                )}
{/*
                <Link to={`/details`}>
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D5320822B2AB</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (1x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>

                <Link to="/details">
                  <ul>
                    <li className="yellow">
                      <FormattedMessage id="payment.pending" />
                    </li>
                    <li>#5D531715CEE36</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.debit.card" />
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="red">
                      <FormattedMessage id="payment.refused" />
                    </li>
                    <li>#5D53170FE9F5F</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.boleto.bancario" />
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D5316FEB5F88</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (12x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D53127B4009D</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (1x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D530FD8C8521</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (1x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D530FBAACCD1</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (1x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
                <Link to="/details">
                  <ul>
                    <li className="green">
                      <FormattedMessage id="payment.confirmed" />
                    </li>
                    <li>#5D530FB10FEF9</li>
                    <li>Philip Gutierrez</li>
                    <li>
                      <FormattedMessage id="payment.credit.card" /> (1x)
                    </li>
                    <li>
                      R$
                      <FormattedNumber value={542.21} />
                    </li>
                    <li>
                      &#8356;
                      <FormattedNumber value={542.21} />
                    </li>
                  </ul>
                </Link>
*/}
              </indexStyled.TableItems>
            </indexStyled.TableList>
            {/* <indexStyled.PaginationWrapper>
              <Pagination />
            </indexStyled.PaginationWrapper> */}
          </ContentBox>
        </indexStyled.RowList>
      </LayoutWrapper>
    </>
  );
}

export default Transactions;
