import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

//Services
import SessionService from "services/Session";

import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Buttons from "../../Forms/Buttons";
import UserProfile from "../TopBar/UserProfile";

// Store
import { useSettings } from "../../../store/settings";

//Styles
import * as styled from "./modals-styles";

const ProfileButtonModal = (props: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { environment } = useSettings((state) => state.settings);

  const [isProd, setIsProd] = useState(() => {
    if (environment === "prod") {
      return true;
    }

    return false;
  });

  const profileModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  function handleShowModal() {
    if (profileModalRef.current) {
      setIsMounted(false);
      setShowModal(true);
    } else {
      setIsMounted(true);
      setShowModal(true);
    }
  }

  useClickOutside(profileModalRef, isMounted, setIsMounted);

  function handleSignout(screen: string) {
    SessionService.clean();

    // if(!isProd) {
    //   window.location.href = `https://dashboard.premepay.com/login`;
    // }else {
    //   history.push("/login");
    // }
    history.push("/login");
  }

  function handleGoToUserProfile() {
    history.push("/user-profile");

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowModal(false);
    }, 300);
  }

  return (
    <styled.RelativeContainer>
      <UserProfile
        handleShowModal={handleShowModal}
        isAtTopbar={props.isAtTopbar}
      />

      {showModal && (
        <styled.UserProfileContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={profileModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowModal(false);
          }}
        >
          <div className="buttons-container">
            <div className="button__item">
              <Buttons
                mode="simple"
                label={intl.formatMessage({ id: "user.profile" })}
                handler={(e: any) => handleGoToUserProfile()}
              />
            </div>
            <div className="button__item">
              <Buttons
                mode="simple"
                label={intl.formatMessage({ id: "user.signout" })}
                handler={handleSignout}
              />
            </div>
          </div>
        </styled.UserProfileContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default ProfileButtonModal;
