import React, { useEffect, FC, useState } from "react";
import mapboxgl from "mapbox-gl";

//Styles
import * as styled from "./map-styles";

//Services
import GetCoordinatesService from "services/GetCoords";
interface IAddress {
  street: string;
  number: string;
  district: string;
  city: string;
  status: string;
}

const MapOrder: FC<IAddress> = ({ street, number, district, city, status }) => {
  const [styleMap, setStyleMap] = useState(() => {
    const themeStorage = localStorage.getItem("premepay-dashboard-theme");

    const { premepayDashboardTheme } = JSON.parse(
      themeStorage ? themeStorage : "{}"
    );

    return "mapbox://styles/premepay/ckc2dtjun6vej1ht9av434q08";
  });

  mapboxgl.accessToken =
    "pk.eyJ1IjoicHJlbWVwYXkiLCJhIjoiY2tjMGtnZHZlMWNybzJxbGp5MWM1bTNqeCJ9.iz9uVDl3Oz1a-VNNc2N5Eg";

  useEffect(() => {
    if (street !== "" && number !== "" && district !== "" && city !== "") {
      renderAll();
    }
  }, [status]);

  async function renderAll() {
    const addressQuery = `${city}+${district}+${street}+${number}`;

    await GetCoordinatesService.getCoordinatesFromHereAPI(addressQuery)
      .then((response) => {
        if (response.data.items.length > 0) {
          const map = new mapboxgl.Map({
            container: "#refMap",
            style: styleMap,
            center: [
              response.data.items[0].position.lng,
              response.data.items[0].position.lat,
            ],
            zoom: 15,
          });

          let containerPulseDiv = document.createElement("div");

          containerPulseDiv.setAttribute("aria-label", "Map marker");

          let pulseDiv = document.createElement("div");

          switch (status) {
            case "Pending":
              pulseDiv.className = "pending";
              break;
            case "Succeded":
            case "DisputeSucceeded":
              pulseDiv.className = "confirmed";
              break;
            case "Canceled":
            case "Expired":
            case "Failed":
            case "Blocked":
            case "ChargedBack":
              pulseDiv.className = "canceled";
              break;
            case "New":
              pulseDiv.className = "pending";
              break;
            case "PartiallySucceded":
            case "PartiallyCanceled":
              pulseDiv.className = "deactivated";
              break;
            default:
              pulseDiv.className = "pending";
              break;
          }

          containerPulseDiv.appendChild(pulseDiv);

          setTimeout(() => {
            const testeM = new mapboxgl.Marker(containerPulseDiv)
              .setLngLat([
                response.data.items[0].position.lng,
                response.data.items[0].position.lat,
              ])
              .addTo(map);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <styled.MapContainer
      id="#refMap"
      className="mapContainer"
    ></styled.MapContainer>
  );
};

export default MapOrder;
