import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

//Services
import SessionService from "services/Store";

import useClickOutside from "../../../hooks/useClickOutside";

//Helpers
import formatIdentificationNumber from "helpers/formatIdentificationNumber";

//Components
import Input from "../../Forms/InputFloatLabel";
import Buttons from "../../Forms/Buttons";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "./modals-styles";

type TProps = {
  handler?: any;
  setID?: any;
  labelWithStoreNameAndId?: boolean;
  storeId?: number;
  searchOptions?: string[];
};

type TStore = {
  id: number;
  businessId: number;
  socialName: string;
  identificationNumber: string;
  status: string;
};

const SelectTypeSearchButtonModal = ({
  handler,
  setID,
  labelWithStoreNameAndId,
  storeId,
  searchOptions,
}: TProps) => {
  const [stores, setStores] = useState<TStore[]>([]);
  const [backupStores, setBackupStores] = useState<TStore[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [showStoresModal, setShowStoresModal] = useState(false);
  const [isSelectedAStore, setIsSelectedAStore] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [wantedStore, setWantedStore] = useState("");

  const types = ["number", "identificationNumber", "name", "email"];

  const searchTypesModalRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    getSessionInfos();
  }, []);

  useEffect(() => {
    if (stores.length > 0 && storeId && storeId > 0) {
      setIsSelectedAStore(true);

      let labelEditting = stores.filter((store) => store.id === storeId);

      setSelectedLabel(
        formatIdentificationNumber(labelEditting[0].identificationNumber)
      );
    }
  }, [stores]);

  useEffect(() => {
    if (wantedStore !== "") {
      let filteredStores = backupStores.filter((store: TStore) =>
        store.socialName.toLowerCase().includes(wantedStore.toLowerCase())
      );

      setStores(filteredStores);
    }

    if (wantedStore === "") {
      setStores(backupStores);
    }
  }, [wantedStore]);

  function getSessionInfos() {
    SessionService.getStores().then((response) => {
      if (/admin/.test(location.pathname)) {
        setBackupStores(
          response.data.stores.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
        return setStores(
          response.data.stores.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
      } else {
        setBackupStores(
          response.data.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
        return setStores(
          response.data.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
      }
    });
  }

  function handleShowModal() {
    if (searchTypesModalRef.current) {
      setIsMounted(false);
      setShowStoresModal(true);
    } else {
      setIsMounted(true);
      setShowStoresModal(true);
    }
  }

  useClickOutside(searchTypesModalRef, isMounted, setIsMounted);

  function handleSelectAStore(type: string) {
    handler("type", type);
    handler("value", "");
    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowStoresModal(false);
    }, 300);
  }

  return (
    <styled.RelativeContainer>
      <styled.ArrowDowndToggle onClick={handleShowModal}>
        <img src={arrowIcon} alt="arrow" />
      </styled.ArrowDowndToggle>

      {showStoresModal && (
        <styled.SearchTypeContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={searchTypesModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowStoresModal(false);
          }}
        >
          <div className="header-container">
            <p className="view-as__title">
              {intl.formatMessage({ id: "transactions.search" })}
            </p>
          </div>

          <div className="buttons-container">
            {(searchOptions ? searchOptions : types).map((type: string) => {
              return (
                <div
                  className="button__item"
                  onClick={() => handleSelectAStore(type)}
                >
                  <Buttons
                    key={type}
                    mode="simple-limited"
                    label={intl.formatMessage({
                      id: `transactions.search.${type}`,
                    })}
                    handler={() => {}}
                  />
                </div>
              );
            })}
          </div>
        </styled.SearchTypeContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectTypeSearchButtonModal;
