import styled from 'styled-components';

export const Chart = styled.div`
  color: ${props => props.theme.contentColors.primary} !important;

  .progress, .progress-bar {
    height: 7px;
  }

  .apexcharts-gridline {
    stroke: ${props => props.theme.backgroundColors.secondary};
  }

  display: flex;
  align-items: center;
  flex-basis: 100%;

  &.full{
    .box-chart{
      width: 100%;
    }

    .report-chart_top{
      padding: 0 30px 0 0;

      @media screen and (max-width: 916px) {
        padding: 0px 0px;
      }
    }
  }

  .apexcharts-toolbar{
    display: none;
  }

  .apexcharts-xaxistooltip{
    color: ${props => props.theme.contentColors.primary} !important;
  }

  .apexcharts-tooltip.light {
    background: ${props => props.theme.backgroundColors.primary} !important;

    .apexcharts-tooltip-title {
      background: ${props => props.theme.backgroundColors.primary} !important;
    }
  }

  .apexcharts-xaxistooltip {
    background: ${props => props.theme.backgroundColors.primary} !important;
  }

  .box-chart{
    width: 100%;
    padding-right: 20px;

    @media screen and (max-width: 916px) {
      width: 100%;
      padding: 0;
      border-right: 0; 
    }
  }

  .report-chart_top{
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-family: "Brandon Text Medium" !important;
      color: ${props => props.theme.contentColors.secondary};
    }

    .right{
      display: flex;
    }

    .c-right--width-auto {
      width: auto;
    }
  }

  .report-totals{
    flex: 1;
    height: 100%;
    height: 300px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    color: ${props => props.theme.contentColors.primary};

    .label-top{
      font-size: 1.2em;
      display: flex;

      .c-filter-container {
        margin-left: auto;
      }

      span{
        margin-left: 4px;
        color: ${props => props.theme.contentColors.fourth};
      }
        }

    .list{
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .list-item{
        flex-basis: 50%;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .list-item-label{
          font-size: 1.2em;
        }

        .list-item-value{
          font-size: 2em;
          font-weight: 700;
          color: ${props => props.theme.contentColors.secondary};
          
          .skeleton-container {
            padding: 0 16px 0 0;
          }
        }
      }
    }

    .perfomance{
      margin-bottom: 2em;
      display: flex;
      flex-direction: row;
      align-items: center;

      .right{
        width: 65%;
        color: #f00;
      }

      .left{
        flex: 1;
      }

      .label{
        font-size: 1.2em;
      }

      .value{
        font-size: 2em;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 916px) {
      padding: 0px 0px;
    }

    @media screen and (max-width: 375px) {
      height: 150px;
    }
  }
`;

export const ChartContainer = styled(Chart)`
  display: block;
  align-items: center;
`;

export const RowWith2GridColumns = styled.div`
  display: grid;
  grid-template-columns: 5.2fr 2.2fr;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const Metrics = styled.div`
  margin-top: 30px;
  border-top: 1px solid #808080;
  padding-top: 30px;
  display: none;
`;

export const SelectContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
    font-size: 12px;
  }

  select {
    background-color: transparent;
    color: ${props => props.theme.backgroundColors.primary};
    border-radius: 3px;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid ${props => props.theme.backgroundColors.primary};
    font-size: 12px;
    height: 36px;
    cursor: pointer;
    font-weight: 500;
    /* box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3); */

    &:disabled {
      opacity: 0.5;
      background: ${props => props.theme.backgroundColors.primary};
      cursor: not-allowed;
    }
  }
`;

export const ChartAdmin = styled(Chart)`

  .box-chart{
    width: 100%;

    @media screen and (max-width: 916px) {
      width: 100%;
      padding: 0;
      border-right: 0; 
    }
  }

  .list-box {
    margin-top: -20px !important;
  }
`;
