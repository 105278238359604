import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import OrderFeesServices from "../../../../../../services/OrderFees";

import Input from "../../../../../Forms/InputFloatLabel";
import Select from "../../../../../Forms/Select";

import ErrorMessage from "../../../FormValidations/ErrorMessage";

import currencyAndSymbolUnique from "../../../../../../helpers/currencyAndSymbolUnique";

//Types
import { PixProps } from "../../types";

const Pix = ({
  customer,
  submit,
  handleCustomer,
  isForeigner,
  card,
  processing,
  manualOrder,
  handleManualOrder,
  creditCard,
  handleCreditCard,
  colorsTheme,
  tax,
  handleTax,
  selected,
  setSelected,
  formErrors,
}: PixProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [isCnpj, setIsCnpj] = useState(false);
  const [customerFees, setCustomerFees] = useState(false);
  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [valueOrder, setValueOrder] = useState("0");
  const [brValueOrder, setBrValueOrder] = useState("0");
  const [brlTaxAmount, setBrlTaxAmount] = useState("0");
  let valueOrderNumber = parseFloat(valueOrder);

  const [dateLabel, setDateLabel] = useState("Data de Nascimento:");
  const [cardInstallmentsAmount, setCardInstallmentsAmount] = useState<any>([]);

  const [urlExpandIcon, setUrlExpandIcon] = useState("/images/expand-icon.svg");
  const [expandInfos, setExpandInfos] = useState(false);

  const [isTrueOrderNumber, setIsTrueOrderNumber] = useState(false);

  const [chargeLater, setChargeLater] = useState(false);
  const [informTax, setInformTax] = useState(false);

  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");

  const [customerCurrency, setCustomerCurrency] = useState("BRL");
  const [customerCurrencySymbol, setCustomerCurrencySymbol] = useState("R$");

  const selectState = useRef<HTMLInputElement>(null);
  const refIdentificationNumber = useRef<HTMLInputElement>(null);

  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);

  const intl = useIntl();

  // useEffect(() => {
  // let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
  //   if (currency.currency === customerCurrency) {
  //     return currency.currency;
  //   }
  // });

  // localStorage.setItem('@PrimefyDashboard-currency', JSON.stringify({
  //   currency: customerCurrency
  // }));

  // setCustomerCurrencySymbol(filteredCountry.length > 0 ? filteredCountry[0].symbol : 'US$');

  // setValueOrder(((parseFloat(brValueOrder) + (customerCurrency !== 'BRL' ? parseFloat(brValueOrder) * 0.4960 : 0)) * conversionRates[filteredCountry.length > 0 ? customerCurrency : 'USD']).toString());

  // if (tax.mode === 'Fixed') {
  //   const taxAmount = tax.amount;
  //   handleTax('amount', (parseFloat(brlTaxAmount) * conversionRates[filteredCountry.length > 0 ? customerCurrency : 'USD']).toString());

  // }

  // if(hasExchangeRates) {
  //   let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
  //     if (currency.currency === customerCurrency) {
  //       return currency.currency;
  //     }
  //   });

  //   localStorage.setItem('@PrimefyDashboard-currency', JSON.stringify({
  //     currency: customerCurrency
  //   }));

  //   setCustomerCurrencySymbol(filteredCountry.length > 0 ? filteredCountry[0].symbol : 'US$');

  // handleManualOrder((prevState: any) => {
  //   return {
  //     ...prevState,
  //     amount: ((parseFloat(brValueOrder) + (customerCurrency !== 'BRL' ? parseFloat(brValueOrder) * 0.4960 : 0)) * conversionRates[filteredCountry.length > 0 ? customerCurrency : 'USD']).toString(),
  //   }
  // });

  // if (tax.mode === 'Fixed') {
  //   const taxAmount = tax.amount;
  //   handleTax('amount', (parseFloat(brlTaxAmount) * conversionRates[filteredCountry.length > 0 ? customerCurrency : 'USD']).toString());

  // }
  //   }else {
  //     let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
  //       if (currency.currency === 'BRL') {
  //         return currency.currency;
  //       }
  //     });

  //     localStorage.setItem('@PrimefyDashboard-currency', JSON.stringify({
  //       currency: customerCurrency
  //     }));

  //     setCustomerCurrency(filteredCountry.length > 0 ? filteredCountry[0].currency : 'USD');

  //     handleManualOrder((prevState: any) => {
  //       return {
  //         ...prevState,
  //         amount: (parseFloat(brValueOrder)).toString(),
  //       }
  //     });
  //   }

  // }, [customerCurrency, customer])

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrValueOrder(valueOrder);
    }
  }, [valueOrder]);

  useEffect(() => {
    if (customerCurrency === "BRL") {
      setBrlTaxAmount(tax.amount);
    }
  }, [tax.amount]);

  useEffect(() => {
    if (customerFees) {
      setIncludeFeeTransfer(true);
    } else {
      setIncludeFeeTransfer(false);
    }
  }, [customerFees]);

  useEffect(() => {
    if (
      customer.identificationNumber.length > 14 &&
      customerCurrency === "BRL"
    ) {
      setIsCnpj(true);
      setDateLabel("Data de Registro:");
      setCpfCnpjLabel("CNPJ:");

      handleCustomer((prevState: any) => {
        return {
          ...prevState,
          firstName: "",
          lastName: "",
        };
      });

      if (refName.current && refLastName.current) {
        refName.current.disabled = true;
        refLastName.current.disabled = true;
      }
    } else if (customer.identificationNumber.length === 0) {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF/CNPJ");
    } else {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
      setDateLabel("Data de Nascimento");
      setCpfCnpjLabel("CPF");
    }
  }, [customer.identificationNumber]);

  function handleCustomerData(name: string, value: string) {
    handleCustomer((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleManualOrderAmount(value: string) {
    handleManualOrder((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  return (
    <>
      {/* <div className="form-item">
      <h5>{intl.formatMessage({ id: "request.payment.amount" })}</h5>

      <Input required={true} mode="normal-currency" currencySymbol={customerCurrencySymbol} name="value-order" value={manualOrder.amount} placeholder={""} handler={handleManualOrderAmount} />
    </div> */}

      <h5>{intl.formatMessage({ id: "request.payment.customer.currency" })}</h5>
      {!isForeigner && (
        <>
          <div className="form-item select-and-input-container">
            <div className="m-20-width">
              <Select
                disabled={true}
                mode="clean-normal-child"
                name="currency"
                placeholder=""
                handler={setCustomerCurrency}
              >
                <option className="bold-option" value="BRL">
                  R$
                </option>
              </Select>
            </div>

            <div className="m-half-width">
              <Input
                mode="normal-currency"
                name="amount"
                value={manualOrder.amount}
                placeholder={""}
                handler={handleManualOrderAmount}
                isInputWithoutBorder={true}
              />
            </div>
          </div>
          <ErrorMessage
            errors={formErrors}
            wantedError="orderAmount"
            errorMessage={intl.formatMessage({
              id: "error.request.payment.amount",
            })}
          />
        </>
      )}

      {/* {parseFloat(manualOrder.amount) > 0 && <>
      {!isForeigner && <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.customer.currency" })}</h5>

        <Select mode="normal-child" name="currency" placeholder={intl.formatMessage({ id: "request.payment.customer.currency" })} handler={setCustomerCurrency} >
          {currencyAndSymbolUnique.map((currency) => (
            <option key={currency.id} value={currency.currency}>
              {currency.currency} ({currency.symbol})
            </option>
          ))}
        </Select>
      </div>} */}

      {customerCurrency === "BRL" && (
        <div className="form-item">
          <h5>{cpfCnpjLabel}</h5>

          <Input
            mode="named-mask"
            name="identificationNumber"
            mask={
              customer.identificationNumber.length > 14
                ? "99.999.999/9999-99"
                : "999.999.999-999"
            }
            value={customer.identificationNumber}
            placeholder={""}
            handler={handleCustomerData}
          />
          <ErrorMessage
            errors={formErrors}
            wantedError="cpf"
            errorMessage={intl.formatMessage({
              id: "personal.data.erro.identification.cpf",
            })}
          />
          <ErrorMessage
            errors={formErrors}
            wantedError="cnpj"
            errorMessage={intl.formatMessage({
              id: "personal.data.erro.identification.cnpj",
            })}
          />
        </div>
      )}

      {isCnpj && (
        <div className="form-item">
          <h5>
            {intl.formatMessage({ id: "request.payment.boleto.business.name" })}
          </h5>

          <Input
            required={false}
            mode="named"
            name="businessName"
            value={customer.businessName}
            placeholder={""}
            handler={handleCustomerData}
            pattern="[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
          />
          <ErrorMessage
            errors={formErrors}
            wantedError="businessName"
            errorMessage={intl.formatMessage({
              id: "personal.data.erro.business.name",
            })}
          />
        </div>
      )}

      {!isCnpj && (
        <>
          <div className="form-item">
            <h5>{intl.formatMessage({ id: "request.payment.boleto.name" })}</h5>

            <Input
              required={false}
              mode="named"
              name="firstName"
              value={customer.firstName}
              placeholder={""}
              handler={handleCustomerData}
              pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="customerName"
              errorMessage={intl.formatMessage({
                id: "personal.data.erro.name",
              })}
            />
          </div>

          <div className="form-item">
            <h5>
              {intl.formatMessage({ id: "request.payment.boleto.lastname" })}
            </h5>

            <Input
              required={false}
              mode="named"
              name="lastName"
              value={customer.lastName}
              placeholder={""}
              handler={handleCustomerData}
              pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            />
            <ErrorMessage
              errors={formErrors}
              wantedError="customerLastname"
              errorMessage={intl.formatMessage({
                id: "personal.data.erro.surname",
              })}
            />
          </div>
        </>
      )}

      <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.boleto.email" })}</h5>

        <Input
          required={false}
          mode="named"
          name="email"
          value={customer.email}
          placeholder={""}
          handler={handleCustomerData}
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
        />
        <ErrorMessage
          errors={formErrors}
          wantedError="customerEmail"
          errorMessage={intl.formatMessage({ id: "personal.data.erro.email" })}
        />
      </div>

      <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.boleto.phone" })}</h5>

        <Input
          required={false}
          mode="named-mask"
          name="phone"
          mask={
            customer.phone.length <= 14 ? "(99) 9999-99999" : "(99) 99999-9999"
          }
          value={customer.phone}
          placeholder={""}
          handler={handleCustomerData}
        />
        <ErrorMessage
          errors={formErrors}
          wantedError="customerPhone"
          errorMessage={intl.formatMessage({ id: "payment.data.erro.phone" })}
        />
      </div>

      {/* <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.credit.card.installments" })}</h5>

        <Select mode="normal-child" name="installments" placeholder={""} handler={handleInstallments} disabled={customerCurrency !== 'BRL'}>
          {cardInstallmentsAmount.map((installment: number, index: number) => (
            <option key={index + 1} value={index + 1}>
              {`${index + 1}x ${intl.formatMessage({ id: "request.payment.of" })} ${intl.formatNumber(cardInstallmentsAmount[index], { style: "currency", currency: customerCurrency, minimumSignificantDigits: 2 })} ${intl.formatMessage({ id: includeFeeTransfer ? "request.payment.with.fees" : "request.payment.without.fees" })}`}
            </option>
          ))}
        </Select>
      </div> */}

      {/* <div className="form-item">
        <h5>{intl.formatMessage({ id: "request.payment.card.name" })}</h5>

        <Input required={true} mode="named" name="holderName" value={creditCard.holderName} placeholder={""} handler={handleCreditCardNamed} pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$" />
      </div> */}
    </>
  );
};

export default Pix;
