import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

//Components
import { Pagination } from "components/Layout/Index";
import Buttons from "../Forms/Buttons";

//Styles
import * as indexStyled from "../../styles/index-styles";
import * as styled from "../styles";

export const ListRelatedOrders = ({ orders, ordersLoaded }: any) => {
  let intl = useIntl();
  let history = useHistory();

  const [paginationOrders, setPaginationOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getPaginatedData = () => {
    const startIndex = currentPage * 5 - 5;
    const endIndex = startIndex + 5;
    return orders.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setPaginationOrders(getPaginatedData());
  }, [orders, currentPage]);

  return (
    <styled.BoxDetails className="border-bottom">
      <div className="limitted-box">
        <styled.Header>
          <FormattedMessage id="manager.links.details.sales.title" />
        </styled.Header>

        <indexStyled.TableList>
          <indexStyled.TableListHeader>
            <ul>
              <li>
                <FormattedMessage id="home.status" />
              </li>
              <li>
                <FormattedMessage id="home.order" />
              </li>
              <li></li>
            </ul>
          </indexStyled.TableListHeader>

          <indexStyled.TableItems>
            {
              ordersLoaded &&
                paginationOrders &&
                paginationOrders.map((order: any) => {
                  let status;

                  switch (order.status) {
                    case "Pending":
                      status = (
                        <li>
                          <span className="status-pending">
                            <FormattedMessage id="payment.pending" />
                          </span>
                        </li>
                      );
                      break;
                    case "PreAuthorized":
                      status = (
                        <li>
                          <span className="status-pending">
                            <FormattedMessage id="status.pre.authorized" />
                          </span>
                        </li>
                      );
                      break;
                    case "Succeded":
                      status = (
                        <li>
                          <span className="status-succeeded">
                            <FormattedMessage id="payment.confirmed" />
                          </span>
                        </li>
                      );
                      break;
                    case "Canceled":
                    case "Expired":
                      status = (
                        <li>
                          <span className="status-failed">
                            <FormattedMessage id="payment.canceled" />
                          </span>
                        </li>
                      );
                      break;
                    case "Rejected":
                      status = (
                        <li>
                          <span className="status-failed">
                            <FormattedMessage id="payment.rejected" />
                          </span>
                        </li>
                      );
                      break;
                    case "PendingChallenge":
                      status = (
                        <li>
                          <span className="status-pending">
                            <FormattedMessage id="payment.PendingChallenge" />
                          </span>
                        </li>
                      );
                      break;
                    case "Failed":
                      status = (
                        <li>
                          <span className="status-failed">
                            <FormattedMessage id="payment.failed" />
                          </span>
                        </li>
                      );
                      break;
                    case "New":
                      status = (
                        <li>
                          <span className="status-pending">
                            <FormattedMessage id="payment.new" />
                          </span>
                        </li>
                      );
                      break;
                    case "Blocked":
                      status = (
                        <li>
                          <span className="status-failed">
                            <FormattedMessage id="payment.blocked" />
                          </span>
                        </li>
                      );
                      break;
                      case "Expiration":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.expiration" />
                            </span>
                          </li>
                        );
                        break;
                    case "Disputed":
                      status = (
                        <li>
                          <span className="status-pending">
                            <FormattedMessage id="payment.dispute" />
                          </span>
                        </li>
                      );
                      break;
                    case "ChargedBack":
                      status = (
                        <li>
                          <span className="status-failed">
                            <FormattedMessage id="payment.charged.back" />
                          </span>
                        </li>
                      );
                      break;
                    case "DisputeSucceeded":
                      status = (
                        <li>
                          <span className="status-succeeded">
                            <FormattedMessage id="payment.confirmed" />
                          </span>
                        </li>
                      );
                      break;
                    case "PartiallySucceded":
                      status = (
                        <li>
                          <span className="status-deactivated">
                            <FormattedMessage id="payment.partially.confirmed" />
                          </span>
                        </li>
                      );
                      break;
                    case "PartiallyCanceled ":
                      status = (
                        <li>
                          <span className="status-deactivated">
                            <FormattedMessage id="payment.partially.canceled" />
                          </span>
                        </li>
                      );
                      break;
                    default:
                      status = <li></li>;
                      break;
                  }

                  return (
                    <a key={order.id}>
                      <ul>
                        {status}
                        <li>#{order.number}</li>
                        {/* <li>{`${order.customer.firstName} ${order.customer.surname}`}</li> */}
                        <li>
                          <div>
                            <Buttons
                              mode="unfilled"
                              label={intl.formatMessage({
                                id: "manager.links.details.sales.view",
                              })}
                              handler={() =>
                                history.push(
                                  `/transactions/${order.number}/${order.storeId}/details`
                                )
                              }
                            />
                          </div>
                        </li>
                      </ul>
                    </a>
                  );
                })
              // ) : (
              //   <Skeleton count={1} height={25} className="skeleton" />
              // )
            }
          </indexStyled.TableItems>
        </indexStyled.TableList>
        <indexStyled.PaginationWrapper>
          <Pagination
            customClass="customer-pagination"
            pageIndex={currentPage}
            setPageIndex={setCurrentPage}
            disableNextButton={paginationOrders.length < 5 ? true : false}
            pageIndexProcessing={false}
            totalOrder={orders.length}
            maxNumberOrderPerRequest={5}
          />
        </indexStyled.PaginationWrapper>
      </div>
    </styled.BoxDetails>
  );
};
