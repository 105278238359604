import styled from "styled-components";

interface ImgProps {
  path: string;
}

export const LeftRightContainer = styled.section`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column !important;
  }
`;

export const Left = styled.div`
  width: calc(54% - (25px / 2));
  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 100%;
  @media screen and (max-width: 1800px) {
    width: 70%;
  }
  @media screen and (max-width: 1240px) {
    width: 70%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 990px) {
    width: 100%;
    padding-bottom: 8em;
  }
`;

export const BoxDetails = styled.div`
  background: ${(props) => props.theme.backgroundColors.primary};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 38px 39px 38px 60px;

  .limitted-box {
    max-width: 750px;
  }

  .details-button {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      text-align: center;
      width: 170px;
      outline: none;
    }
  }
  .details-items-input-100 {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 20px 20px 0 20px;
  }
  .details-items-edit-form {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 0px 0px 10px 0px !important;
    animation: topDown 0.3s ease-in-out forwards;
    /* opacity: 0; */
    @keyframes topDown {
      from {
        transform: translateY(-10px);
      }
      to {
        transform: translateY(0px);
        /* opacity: 1 */
      }
    }
  }
  .blue-title {
    color: ${(props) => props.theme.primaryColors.sixth};
    div {
      display: flex;
      p {
        margin-left: 8px;
      }
    }
  }
  .containar--50-max {
    max-width: 50px;
  }
  .containar--250-max {
    max-width: 250px;
  }
  .receivables {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.backgroundColors.fifth} !important;
    .value-line {
      margin-top: 10px;
      display: flex;
      max-width: 350px;
      justify-content: space-between;
      p {
        color: ${(props) => props.theme.contentColors.secondary};
        &:first-child {
          min-width: 30%;
          @media screen and (max-width: 916px) {
            min-width: 30%;
          }
        }
      }
    }
    .value-line.withAnticipation {
      max-width: 550px;
    }
    .withAnticipation {
      p {
        &:last-child {
          min-width: 30%;
          @media screen and (max-width: 916px) {
            min-width: 30%;
          }
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .receivable--last {
    border-bottom: 0px !important;
  }
  .limitted-right-container {
    max-width: 700px;
  }
  @media screen and (max-width: 425px) {
    padding: 8px 16px;
  }
`;

export const Header = styled.header`
  margin-bottom: 34px;
  display: flex;
  font-size: 18px;
  font-family: "Brandon Text Medium" !important;
  color: ${(props) => props.theme.contentColors.secondary};
  justify-content: space-between;
  .subtitle {
    margin-top: 8px;
  }
  .header-right {
    display: flex;
    align-items: center;
    widows: 100%;
    justify-content: space-between;
    .btn {
      font-size: 0.8em;
      width: auto !important;
    }
  }
  .edit-version {
    font-size: 0.66em !important;
    .btn {
      font-size: 1.2em;
      width: auto !important;
    }
  }
  a {
    color: ${(props) => props.theme.contentColors.fourth};
    &:hover {
      color: ${(props) => props.theme.contentColors.fourth};
    }
  }
  @media screen and (max-width: 1500px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
