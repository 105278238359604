import { useHistory } from "react-router-dom";

//Services
import SessionService from "../services/Session";

export const useValidateSession = () => {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    sessionStorage.setItem(
      "@Primefy:temp:origin",
      JSON.stringify(window.location.pathname)
    );

    history.push("/login");
  }
};
