import axios from 'axios';

class GetCoordinatesService {
    
  async getCoordinates(address: string) {
    return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyC51sREKAeC8QASVhxl56pdP8lOtdV6hu4`);
  }

  async getCoordinatesFromHereAPI(address: string) {
    return await axios.get(`https://geocode.search.hereapi.com/v1/geocode?apiKey=gi92tw01F4XMcP3MmWgv4HPaDIrsXvCkvBEEMTw_UQI&q=${address}`);
  }
}

export default new GetCoordinatesService();
