import styled from 'styled-components';

export const MapContainer= styled.div`
  &.mapContainer {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
  }

  .confirmed, .pending, .canceled, .deactivated {
    background: #33C344;
    border: 5px solid #C1E5C4;
    border-radius: 50%;
    height: 25px;
    width: 25px;       
    animation: pulse 1s ease-out;
    animation-iteration-count: infinite; 
  }

  .pending {
    background: #FFC400;
    border: 5px solid #F4E5B3;
  }

  .canceled {
    background: #FF0000;
    border: 5px solid #F5B5B4;
  }

  .deactivated {
    background: #44667d;
    border: 5px solid #E2E8EB;
  }

  @keyframes pulse {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
  }
`;
