import moment from "moment";

export default function formatDateWithFuso(date: string, format: string) {
  let timeZoneOffset = new Date(date).getTimezoneOffset();
  let timeZoneOffsetPositive = new Date(date).getTimezoneOffset() * -1;

  let formatDate =
    timeZoneOffset > 0
      ? new Date(new Date(date).getTime() - timeZoneOffset * 60 * 1000)
      : new Date(new Date(date).getTime() + timeZoneOffsetPositive * 60 * 1000);

  let dateFormattedTimeZone = moment(formatDate).format(format);

  return dateFormattedTimeZone;
}
