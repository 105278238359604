import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

//Components
import Buttons from "../../Forms/Buttons";

//Assets
import tourIcon from "assets/images/tour/tour.svg";

//Styles
import * as styled from "../styles";

type TMaskPosition = {
  x: string;
  y: string;
  width: string;
  height: string;
};

type TTour = {
  tipSide: "top" | "right" | "bottom" | "left";
  refElement: any[];
  stepsQuantity: number;
  intlId: string;
  handleShowTour: any;
};

const HomepageTour = ({
  tipSide,
  refElement,
  stepsQuantity,
  intlId,
  handleShowTour,
}: TTour) => {
  const intl = useIntl();
  const [positions, setPositions] = useState<TMaskPosition>({
    x: "0",
    y: "0",
    width: "0",
    height: "0",
  });

  const [tipModalPosition, setTipModalPosition] = useState({
    x: 0,
    y: 0,
    highLightWidth: 0,
    highLightHeight: 0,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(
    document.documentElement.scrollHeight
  );

  const [isMounted, setIsMounted] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [alredySkipped, setAlreadySkipped] = useState(() => {
    const { alreadySkippedTour } = JSON.parse(
      localStorage.getItem("@PremeRecurringHasAccount") || "{}"
    );
    return alreadySkippedTour;
  });

  useEffect(() => {
    if (refElement[currentStep].current) {
      setPositions({
        x: (
          refElement[currentStep].current.offsetLeft +
          ([1, 5, 8, 9].includes(currentStep) ? 0 : 220) -
          ([5].includes(currentStep) ? 15 : 0)
        ).toString(),
        y: (
          refElement[currentStep].current.offsetTop +
          ([8, 9].includes(currentStep) ? 48 : 0) +
          (currentStep === 8 ? 400 : 0) +
          (currentStep === 9 ? 48 : 0)
        ).toString(),
        width: `${(
          refElement[currentStep].current.offsetWidth +
          ([5].includes(currentStep) ? 8 : 0) +
          (currentStep === 8 ? 150 : 0)
        ).toString()}px`,
        height: `${(
          refElement[currentStep].current.offsetHeight +
          (currentStep === 8 ? 30 : 0)
        ).toString()}px`,
      });

      if (currentStep > 0) {
        setTipModalPosition({
          x:
            refElement[currentStep].current.offsetLeft +
            ([1, 5, 8, 9].includes(currentStep) ? 0 : 220),
          y:
            refElement[currentStep].current.offsetTop +
            (currentStep === 8 ? 420 : 0) +
            (currentStep === 9 ? 30 : 0),
          highLightWidth: refElement[currentStep].current.offsetWidth,
          highLightHeight: refElement[currentStep].current.offsetHeight,
        });
      }
    }
  }, [currentStep]);

  useEffect(() => {
    setDocumentHeight(document.documentElement.scrollHeight);
  }, []);

  function nextStep() {
    const step = currentStep;

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowModal(false);
    }, 100);

    setCurrentStep(step + 1);

    setTimeout(() => {
      setShowModal(true);
      setIsMounted(true);
    }, 300);
  }

  function finishTour() {
    handleShowTour(false);

    const { alreadyHasAccount } = JSON.parse(
      localStorage.getItem("@PremeRecurringHasAccount") || "{}"
    );

    localStorage.removeItem("@PremeRecurringHasAccount");
    localStorage.setItem(
      "@PremeRecurringHasAccount",
      JSON.stringify({
        alreadyHasAccount: alreadyHasAccount,
        alreadySkippedTour: true,
      })
    );
  }

  const tipPosition = [
    "",
    "bottom-left",
    "bottom",
    "right",
    "left",
    "bottom-center",
    "right",
    "bottom-left",
    "bottom-left",
    "top-left",
    "left",
  ];

  return !alredySkipped ? (
    <styled.RelativeContainer>
      <styled.MaskContainer step={currentStep} documentHeight={documentHeight}>
        <svg>
          <defs>
            <mask id="mask" x="0" y="0" width="100%" height="100%">
              <rect className="alpha" x="0" y="0" width="100%" height="100%" />
              <rect
                x={positions.x}
                y={positions.y}
                width={positions.width}
                height={positions.height}
              />
            </mask>
          </defs>

          <rect className="base" x="0" y="0" width="100%" height="100%" />
        </svg>
      </styled.MaskContainer>

      {currentStep === 0 && (
        <styled.InsideAbsoluteContainer documentHeight={documentHeight}>
          <styled.FirstModalContainer
            step={currentStep}
            topElement={tipModalPosition.y}
            leftElement={tipModalPosition.x}
            style={
              isMounted
                ? { animation: "scaleModal .2s ease forwards" }
                : { animation: "reveserScameModal .1s ease-out forwards" }
            }
            onAnimationEnd={() => {
              if (!isMounted) setShowModal(false);
            }}
          >
            <div className="intern-container">
              <div className="text-container">
                <div>
                  <h5>
                    {intl.formatMessage({ id: `tour.${intlId}.0.title` })}
                  </h5>
                  <p>{intl.formatMessage({ id: `tour.${intlId}.0` })}</p>
                </div>

                <div className="img-container">
                  <img src={tourIcon} alt="tour" />
                </div>
              </div>

              <div className="buttons-container">
                <div onClick={finishTour}>
                  <a>{intl.formatMessage({ id: "tour.finish" })}</a>
                </div>
                <div>
                  <Buttons
                    mode="filled"
                    label={intl.formatMessage({ id: "tour.start" })}
                    handler={nextStep}
                  />
                </div>
              </div>
            </div>
          </styled.FirstModalContainer>
        </styled.InsideAbsoluteContainer>
      )}

      {currentStep > 0 && (
        <styled.ModalContainer
          side={tipPosition[currentStep]}
          step={currentStep}
          topElement={tipModalPosition.y}
          leftElement={tipModalPosition.x}
          highLightWidth={tipModalPosition.highLightWidth}
          highLightHeight={tipModalPosition.highLightHeight}
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          onAnimationEnd={() => {
            if (!isMounted) setShowModal(false);
          }}
        >
          <div>
            <p>{intl.formatMessage({ id: `tour.${intlId}.${currentStep}` })}</p>
          </div>

          <div className="buttons-container">
            <div onClick={finishTour}>
              <a>
                {intl.formatMessage({
                  id:
                    currentStep === stepsQuantity - 1
                      ? "tour.finished"
                      : "tour.finish",
                })}
              </a>
            </div>
            {currentStep < stepsQuantity - 1 && (
              <div>
                <Buttons
                  mode="filled"
                  label={intl.formatMessage({ id: "tour.next" })}
                  handler={nextStep}
                />
              </div>
            )}
          </div>
        </styled.ModalContainer>
      )}
    </styled.RelativeContainer>
  ) : null;
};

export default HomepageTour;
