import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

//Hooks
import useClickOutside from '../../../hooks/useClickOutside';

//Components
import Buttons from '../../Forms/Buttons';

//Assets
import tipIcon from 'assets/images/global/tip-icon.svg';

//Styles
import * as styled from './modals-styles';

type TipProps = {
  text: string | undefined;
  children?: any;
}

const TipButtonModal = ({ text, children }: TipProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showTipModal, setShowTipModal] = useState(false);

  const tipModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  function handleShowModal() {
    if(tipModalRef.current) {
      setIsMounted(false);
      setShowTipModal(true);
    } else {
      setIsMounted(true);
      setShowTipModal(true);
    }
  }

  useClickOutside(tipModalRef, isMounted, setIsMounted);

  return (
    <styled.TipRelativeContainer>
      <img className="tip-icon" src={tipIcon} alt="tip" onClick={handleShowModal} />

      {showTipModal && <styled.TipContainer 
          style={isMounted ? { animation: 'scaleModal .2s ease forwards' } : { animation: 'reveserScameModal .1s ease-out forwards' }} 
          ref={tipModalRef}
          onAnimationEnd={() => { if (!isMounted) setShowTipModal(false) }}
      >

        <styled.NotificationItem>
          <div className="tip-intern-container">
            <span>{text}</span>
            {children}
          </div>
        </styled.NotificationItem>
      </styled.TipContainer>}
    </styled.TipRelativeContainer>
  )
} 

export default TipButtonModal;
