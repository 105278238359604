import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import mapboxgl from "mapbox-gl";

//Services
import GetCoordinatesService from "../../services/GetCoords";

//Styles
import * as styled from "./live-map-styles";

type TCustomerAddressLive = {
  city: string;
  district: string;
  number: string;
  street: string;
  status: string;
};
interface IMap {
  address: TCustomerAddressLive[];
}

const LiveMap = ({ address }: IMap) => {
  const [styleMap, setStyleMap] = useState(() => {
    const themeStorage = localStorage.getItem("premepay-dashboard-theme");
    const { premepayDashboardTheme } = JSON.parse(
      themeStorage ? themeStorage : "{}"
    );

    return "mapbox://styles/premepay/ckc2dtjun6vej1ht9av434q08";
  });

  mapboxgl.accessToken =
    "pk.eyJ1IjoicHJlbWVwYXkiLCJhIjoiY2tjMGtnZHZlMWNybzJxbGp5MWM1bTNqeCJ9.iz9uVDl3Oz1a-VNNc2N5Eg";

  useEffect(() => {
    renderAll();
  }, [styleMap, address]);

  async function renderAll() {
    const map = new mapboxgl.Map({
      container: "#refLiveMap",
      style: styleMap,
      center: [-48.3393536, -10.256384],
      zoom: 2.5,
    });

    let statusArray = [];

    const addressQuery = address.map(async (addressProps) => {
      statusArray.push(addressProps.status);
      const addressString = `${addressProps.city}+${addressProps.district}+${addressProps.street}+${addressProps.number}`;

      await GetCoordinatesService.getCoordinatesFromHereAPI(addressString).then(
        (response) => {
          if (response.data.items.length > 0) {
            // allCoordinates.push(response.data.results[0].geometry.location);

            let coordsNumber: [number, number] = [
              response.data.items[0].position.lng,
              response.data.items[0].position.lat,
            ];

            let geojson = {
              type: "FeatureCollection",
              features: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: coordsNumber,
                },
              },
            };

            let containerPulseDiv = document.createElement("div");

            containerPulseDiv.setAttribute("aria-label", "Map marker");

            let pulseDiv = document.createElement("div");

            switch (addressProps.status) {
              case "Pending":
                pulseDiv.className = "pending";
                break;
              case "Succeded":
              case "DisputeSucceeded":
                pulseDiv.className = "confirmed";
                break;
              case "Canceled":
              case "Expired":
              case "Failed":
              case "Blocked":
              case "ChargedBack":
                pulseDiv.className = "canceled";
                break;
              case "New":
                pulseDiv.className = "pending";
                break;
              case "PartiallySucceded":
              case "PartiallyCanceled":
                pulseDiv.className = "deactivated";
                break;
              default:
                pulseDiv.className = "pending";
                break;
            }

            containerPulseDiv.appendChild(pulseDiv);

            setTimeout(() => {
              const testeM = new mapboxgl.Marker(containerPulseDiv)
                .setLngLat(geojson.features.geometry.coordinates)
                .addTo(map);
            }, 2000);

            const themeStorage = localStorage.getItem(
              "premepay-dashboard-theme"
            );
            const { premepayDashboardTheme } = JSON.parse(
              themeStorage ? themeStorage : "{}"
            );

            setStyleMap("mapbox://styles/premepay/ckc2dtjun6vej1ht9av434q08");
          }
        }
      );
    });
  }

  return (
    <>
      <styled.Container>
        <div className="title">
          <FormattedMessage id="home.live.map" />
        </div>
        <styled.LiveMapContainer
          id="#refLiveMap"
          className="liveMapContainer"
        ></styled.LiveMapContainer>
      </styled.Container>
    </>
  );
};

export default LiveMap;
