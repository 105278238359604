import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import produce from "immer";
import useClickOutside from "../../../../../hooks/useClickOutside";

import CustomerServices from "../../../../../services/Customers";

import Buttons from "../../../../Forms/Buttons";
import Input from "../../../../Forms/InputFloatLabel";

import arrowIcon from "../../../../../assets/images/topbar/select-arrow.svg";

import * as styled from "../../../../../styles/modals-styles";

//Utils
import formatIdentificationNumber from "../../../../../helpers/formatIdentificationNumber";

//Types
import { Modal, Customer, CustomerResponse } from "./types";

export const SelectCustomerModal = ({ handler, createCustomerForPaymentLink, setCreateCustomerForPaymentLink }: Modal) => {

  const [isMounted, setIsMounted] = useState(false);
  const [showSelectAndButtonModal, setShowSelectAndButtonModal] =
    useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchedEvent, setSearchedEvent] = useState("");

  const [selectedCustomersNames, setSelectedCustomersNames] =
    useState<string>("");

  const [selectedCustomersIds, setSelectedCustomersIds] = useState<number>(0);
  const [customers, setCustomers] = useState<CustomerResponse>();
  const [backupCustomers, setBackupCustomers] = useState<CustomerResponse>();
  const [selectedCustomers, _] = useState<Customer[]>([]);

  const selectCustomerModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (searchedEvent !== "") {
      let filteredResult = backupCustomers?.customers.filter(
        (customer: Customer) =>
          customer.customer.toLowerCase().includes(searchedEvent.toLowerCase())
      );

      const filteredCustomers = produce(customers, (draft) => {
        draft!.customers = filteredResult ? filteredResult : [];
      });

      setCustomers(filteredCustomers);
    }

    if (searchedEvent === "") {
      setCustomers(backupCustomers);
    }
  }, [searchedEvent]);

  function getAllData() {
    CustomerServices.listAllCustomers({
      pageSize: 10000,
      pageIndex: 1,
      query: "",
    })
      .then((response) => {
        setCustomers(response.data);
        setBackupCustomers(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllData();
  }, []);

  function handleShowModal() {
    if (selectCustomerModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  function createNewCustomer() {
    setCreateCustomerForPaymentLink(!createCustomerForPaymentLink);
  }

  function handleFilter(property: any) {
    let currentLabels: string = selectedCustomersNames;
    let currentIds: number = selectedCustomersIds;

    if (currentIds === property.id) {
      const newSelectedCustomers: Customer[] = selectedCustomers?.filter(
        (customer) => customer.id !== property.id
      );

      const newSelectedCustomersIds: number = 0;

      const newSelectedCustomersLabel = "";

      setSelectedCustomersNames(newSelectedCustomersLabel);
      currentLabels = newSelectedCustomersLabel;
      currentIds = newSelectedCustomersIds;

      handler(newSelectedCustomers[0]?.identificationNumber);
    } else {
      currentLabels = property.customer;
      setSelectedCustomersNames(currentLabels);
      handler(property.identificationNumber);

      currentIds = property.id;
    }

    setSelectedLabel(currentLabels);
    setSelectedCustomersIds(currentIds);
  }

  useClickOutside(selectCustomerModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>
          {selectedLabel !== ""
            ? selectedLabel
            : intl.formatMessage({ id: "select.customers.modal" })}
        </p>

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showSelectAndButtonModal && (
        <styled.SelectProductsContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectCustomerModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowSelectAndButtonModal(false);
          }}
        >
          <div className="header-container--flex">
            <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
          </div>

          <div className="header-container--search--borderless">
            <Input
              required={false}
              mode="search"
              name="wantedStore"
              value={searchedEvent}
              placeholder={intl.formatMessage({
                id: "header.search.country",
              })}
              handler={setSearchedEvent}
            />
          </div>

          <div className="buttons-container scroll-items">
            {customers &&
              customers.customers.map((customer) => {
                return (
                  <div className="button__item--large" key={customer.id}>
                    <div className="flex space">
                      <Input
                        required={false}
                        mode="radio"
                        valueToCheck={selectedCustomersIds === customer.id}
                        name="events"
                        value={customer.id}
                        placeholder={customer.customer}
                        readOnly={true}
                        handler={() => handleFilter(customer)}
                      />

                      {customer.identificationNumber ? (
                        <div className="span-id-number">
                          {formatIdentificationNumber(
                            customer.identificationNumber
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </div>

          
          <styled.Tip>
            <div className="tip-intern-container">
              <Buttons
                mode="unfilled"
                handler={createNewCustomer}
                type="button"
                label={intl.formatMessage({
                  id: "customers.page.create.customer",
                })}
                disable={false}
              />
            </div>
          </styled.Tip>
        </styled.SelectProductsContainer>
      )}
    </styled.RelativeContainer>
  );
};
