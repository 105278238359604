import moment from "moment";
import { isBefore } from "date-fns";

type TNewPaymentLink = {
  planId?: number;
  name: string;
  frequency: number;
  title: string;
  orderNumber: string;
  discountLimit: string;
  amount: string;
  description: string;
  expiration: string;
  includeFeeTransfer: boolean;
  installments: number;
  boletoInstallments: number;
  dueDate: string;
  paymentTypes: number[];
  gracePeriod: string;
  productCategory: string;
  chargeDaysBefore: number;
  maxCharges: number;
  whenSend: string;
  sendToCustomer: string;
  warrantyDays: string;
  generateAllBoletos: boolean;
  currency: string;
  redirectUrl: string;
};

type PaymentLinkProps = {
  newPaymentLink: TNewPaymentLink;
  progressStep: number;
  handleErrors: any;
  card: boolean;
  boleto: boolean;
  pix: boolean;
  lateFee?: any;
  discount?: any;
  interest?: any;
  openAmount?: boolean;
  limitBoleto?: number;
  isForeigner?: boolean;
};

export function formValidation({
  newPaymentLink,
  progressStep,
  handleErrors,
  card,
  boleto,
  pix,
  lateFee,
  discount,
  interest,
  openAmount,
  limitBoleto
}: PaymentLinkProps) {
  let errors = [];

  if (progressStep > 1) {
    if (newPaymentLink.title.length === 0) errors.push("linkTitle");
    if (
      newPaymentLink.description.length === 0 ||
      newPaymentLink.description.length > 255
    )
      errors.push("linkDescription");
    if (
      (parseFloat(newPaymentLink.amount) <= 0 || !newPaymentLink.amount) &&
      !openAmount
    )
      errors.push("linkAmount");
      
    if(parseFloat(newPaymentLink.amount) < 3) {
      errors.push("linkAmountLess3");
    }
    
    
  }

  if (progressStep === 3) {
    const urlExpression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (
      newPaymentLink.redirectUrl &&
      newPaymentLink.redirectUrl !== "" &&
      !urlExpression.test(newPaymentLink.redirectUrl)
    )
      errors.push("linkUrlRedirect");

    if (!card && !boleto && !pix) errors.push("linkPaymentTypes");

    if (newPaymentLink.dueDate !== "") {
      let isValidDuedate = true;
      let isBeforeDuedate = false;

      isValidDuedate = moment(newPaymentLink.dueDate, "YYYY-MM-DD").isValid();

      let brFormatted = moment(newPaymentLink.dueDate, "DD/MM/YYYY").toString();
      let formattedManualDuedate = moment(brFormatted).format("YYYY-MM-DD");
      isBeforeDuedate = moment(formattedManualDuedate).isBefore(moment());

      if (!isValidDuedate || isBeforeDuedate) {
        errors.push("linkBoletoDuedateInvalid");
      }
    }

    if (discount) {
      if (discount.mode === "Fixed") {
        if (
          parseFloat(discount.amount) > parseFloat(newPaymentLink.amount) ||
          parseFloat(discount.amount) < 0
        ) {
          errors.push("discountAmount");
        }
      } else {
        if (
          parseFloat(discount.amount) > 100 ||
          parseFloat(discount.amount) < 0
        ) {
          errors.push("discountAmount");
        }
      }
    }

    if (lateFee) {
      if (lateFee.mode === "Fixed") {
        let twoPercentLimit = parseFloat(newPaymentLink.amount) * 0.02;

        if (
          parseFloat(lateFee.amount) < 1 ||
          parseFloat(lateFee.amount) < 0 ||
          parseFloat(lateFee.amount) > twoPercentLimit
        ) {
          errors.push("lateFeeAmount");
        }
      } else {
        if (parseFloat(lateFee.amount) > 2) {
          errors.push("lateFeeAmount");
        }
      }
    }

    if (interest) {
      if (interest.mode === "Daily_Amount") {
        let percentLimit = parseFloat(newPaymentLink.amount) * 0.00033;

        if (
          parseFloat(interest.amount) < 1 ||
          parseFloat(interest.amount) < 0 ||
          parseFloat(interest.amount) > percentLimit
        ) {
          errors.push("interestAmountDailyA");
        }
      } else if (interest.mode === "Daily_Percentage") {
        if (parseFloat(interest.amount) > 0.033) {
          errors.push("interestAmountDailyP");
        }
      } else if (interest.mode === "Monthly_Percentage") {
        if (parseFloat(interest.amount) > 1) {
          errors.push("interestAmountMontlyP");
        }
      }
    }
    
    console.log(boleto)
    if(boleto && limitBoleto && parseFloat(newPaymentLink.amount) > limitBoleto) {
      errors.push("amountMoreThanLimit");
    }
  }

  handleErrors(errors);

  return errors.length === 0;
}
