import { useState, useEffect } from "react";
import { Controller, useForm, FieldValues } from "react-hook-form";
import moment from "moment";
import { isDate, isValid } from "date-fns";
import { object, string, date } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl } from "react-intl";

// Store
import { useSettings } from "../../../../../store/settings";

import { StoreRegisterServices } from "../../../../../services/StoreRegister";

export const usePersonalInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  function parseDateString(value: any, originalValue: any) {
    const dateAsArray = originalValue.split("/");
    const dateAsNumber = dateAsArray.reverse().join("-");

    const momentDate = moment(dateAsNumber)
    
    if (!momentDate.isValid()) return;
    
    return momentDate.toDate();
  }

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const personalSchema = object({
    birthdate: date()
      .max(
        new Date(),
        intl.formatMessage({ id: "memberkit.error.register.dob" })
      )
      .transform(parseDateString)
      .required(intl.formatMessage({ id: "webhook.error.message.default" })),
    phone: string()
      .matches(
        /[(][0-9]{2}[)][\s][0-9]{5}[-][0-9]{4}$/g,
        intl.formatMessage({ id: "finish.phone.error.message" })
      )
      .nullable()
      .required(intl.formatMessage({ id: "webhook.error.message.default" })),
  });

  const personalWithCNPJSchema = object({
    identificationNumber: stringRequired,
    fullName: stringRequired,
    birthdate: date()
      .max(
        new Date(),
        intl.formatMessage({ id: "memberkit.error.register.dob" })
      )
      .transform(parseDateString)
      .required(intl.formatMessage({ id: "webhook.error.message.default" })),
    phone: string()
      .matches(
        /[(][0-9]{2}[)][\s][0-9]{5}[-][0-9]{4}$/g,
        intl.formatMessage({ id: "finish.phone.error.message" })
      )
      .nullable()
      .required(intl.formatMessage({ id: "webhook.error.message.default" })),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isStoreWithCNPJ ? personalWithCNPJSchema : personalSchema
    ),
  });
  const { personalData } = StoreRegisterServices();
  const { settings } = useSettings((state) => state);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (store) {
      if (store.owner) {
        reset(
          () => ({
            // birthdate: moment(store.owner.birthdate).format("DD-MM-YYYY"),
            
            phone: store.owner.phone,
            fullName: `${store.owner.firstName} ${store.owner.surname}`,
            identificationNumber: store.owner.identificationNumber,
          }),
          { keepErrors: true }
        );
      } else {
        if (store.store.identificationNumber?.length < 14) {
          reset(
            () => ({
              identificationNumber: store.identificationNumber,
            }),
            { keepErrors: true }
          );
        }
      }
    }
  }, [store]);

  const submitPersonalData = (data: FieldValues) => {
    setIsProcessing(true);
    
    const { birthdate, phone } = data;

    const birthDateBrFormatted = moment(birthdate, "DD/MM/YYYY").toString();
    const birthDateFormattedFinal =
      moment(birthDateBrFormatted).format("YYYY-MM-DD");

    const finalIdentificationNumber = isStoreWithCNPJ
      ? data.identificationNumber.replaceAll(".", "").replace("-", "")
      : store.store.identificationNumber;
    const finalFullName = isStoreWithCNPJ ? data.fullName : store.store.name;

    const payload = {
      birthdate: birthDateFormattedFinal,
      phone: phone,
      identificationNumber: finalIdentificationNumber,
      fullName: finalFullName,
    };

    personalData(payload)
      .then((response) => {
        setProgressStep(isStoreWithCNPJ ? 4 : 2);
        setIsProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  };

  return {
    Controller,
    control,
    handleSubmit,
    submitPersonalData,
    isProcessing,
    errors,
  };
};
