import React from "react";
import { useHistory, useLocation } from "react-router-dom";

// Store
import { useSettings } from "../../../store/settings";

//Components
import RequestPaymentButtonModal from "../Modals/RequestPaymentButtonModal";
import ProfileButtonModal from "../Modals/ProfileButtonModal";
import NotificationButtonModal from "../Modals/NotificationButtonModal";
import ViewAsButtonModal from "../Modals/ViewAsModal";
import { FinishRegisterTopBanner } from "../../FinishRegisterTopBanner";

//Assets
import premePayLogo from "assets/images/global/logo-premepay.svg";

//Styles
import * as styled from "./topbar-styles";

import SessionService from '../../../services/Session';

export const TopBar = (props: any) => {
  const history = useHistory();
  const location = useLocation();

  const { status } = useSettings((state) => state.settings);
 
  return ["/login", "/admin/login", "/new-password", "/signup"].includes(
    location.pathname
  ) ? (
    <span></span>
  ) : (
    <styled.Topbar>
      {status !== "enabled" && status !== "denied" ? (
        <FinishRegisterTopBanner />
      ) : null}
      <nav>
        <styled.Left>
          <img src={premePayLogo} alt="Preme Dashboard" />
          <ViewAsButtonModal viewAsRef={props.viewAsRef} />
        </styled.Left>

        <styled.Right>
          <div
            className="request-payment-container"
            ref={props.requestPaymentRef}
          >
            <RequestPaymentButtonModal />
          </div>

          {/* <NotificationButtonModal /> */}
          <ProfileButtonModal isAtTopbar={true} />
        </styled.Right>
      </nav>
    </styled.Topbar>
  );
};

export default TopBar;
