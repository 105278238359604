import styled from "styled-components";

interface IconProps {
  icon: string;
}

interface ButtonProps {
  disableState?: boolean;
}

export const Button = styled.button`
  background: #fb505a;
  color: ${(props) => props.theme.contentColors.third};
  border-radius: 3px;
  padding: 7px 16px;
  border: 1px solid transparent;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-family: "Rubik", sans-serif !important;

  &:disabled {
    opacity: 0.5;
    background: ${(props) => props.theme.primaryColors.secondary};
    cursor: not-allowed;
  }
`;

export const SimpleButton = styled.button`
  padding: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4px 0;
  text-align: center;
  text-decoration: none;
  color: ${(props) => props.theme.contentColors.secondary};
`;

export const SimpleLimitedButton = styled(SimpleButton)`
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: "…";
`;

export const UnfilledButton = styled(Button)<ButtonProps>`
  background: ${(props) => props.theme.primaryColors.fifth};
  color: ${(props) => props.theme.contentColors.fourth};
  transition: all 0.3s ease-in-out;

  &:disabled {
    opacity: 0.5;
    background: ${(props) => props.theme.backgroundColors.primary};
    cursor: not-allowed;
  }
`;

export const UnfilledProcessingButton = styled(UnfilledButton)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;

  .spinner {
    border: 2px solid ${(props) => props.theme.primaryColors.fourth};
    border-left-color: ${(props) => props.theme.primaryColors.primary};
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin-right: 8px;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const FilledProcessingButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;

  .spinner {
    border: 2px solid ${(props) => props.theme.backgroundColors.sixth};
    border-left-color: ${(props) => props.theme.backgroundColors.primary};
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin-right: 8px;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const DoubleButtonsContainer = styled.div`
  display: flex;
`;

export const TripleButtonsContainer = styled.div`
  display: flex;
`;

export const LeftButton = styled.button`
  background: ${(props) => props.theme.backgroundColors.primary};
  height: 28px;
  border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px 0px 0px 4px;
  font-size: 14px;
  padding: 0 8px;
  display: flex;
  align-items: center;
`;

export const RightButton = styled(LeftButton)`
  border-radius: 0px 4px 4px 0px;
`;

export const CheckboxButtonContainer = styled.div`
  display: flex;
`;

export const RadioButton = styled.label`
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 6px 18px;
    transition: all 0.2s ease-in-out;
    /* height: 32px; */
    font-size: 14px;

    @media screen and (max-width: 916px) {
      width: auto;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + span {
    background: ${(props) => props.theme.primaryColors.primary};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0);
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    color: #fff !important;
  }
`;

export const SingleRadioButton = styled(RadioButton)`
  span {
    border-radius: 4px;
  }
`;

export const LeftRadioButton = styled(RadioButton)`
  span {
    border-radius: 4px 0px 0px 4px;
  }
`;

export const CenterRadioButton = styled(RadioButton)`
  span {
    border-radius: 0px;
  }

  input:disabled + span {
    cursor: not-allowed;
  }
`;

export const RightRadioButton = styled(RadioButton)`
  span {
    border-radius: 0px 4px 4px 0px;
  }
`;

export const WhiteIcon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.backgroundColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size: contain;
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

export const LeftIcon = styled(WhiteIcon)`
  background-color: ${(props) => props.theme.contentColors.primary};
  margin-right: 8px;
  margin-left: 0px;
  width: 10px;
  height: 10px;
`;

export const RightIcon = styled(WhiteIcon)`
  background-color: ${(props) => props.theme.contentColors.primary};
  margin-right: 0px;
  margin-left: 8px;
  width: 10px;
  height: 10px;
`;

export const MinimalButton = styled(LeftButton)`
  border-radius: 4px;
`;

export const RedIcon = styled(WhiteIcon)<IconProps>`
  background-color: ${(props) => props.theme.primaryColors.primary};
`;
