import React from 'react';
import { useHistory } from 'react-router-dom';

//Components
import { LayoutWrapper } from 'components/Layout/Index';
import CreatePaymentLinkForms from 'components/Layout/RequestPayment/PaymentLink';

//Services
import SessionService from 'services/Session';

//Styles
import * as styled from './styles/request-payment-styles';

function CreatePaymentLink() {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }
  
  return (
    <>
      <LayoutWrapper>
        <styled.Container>
          <CreatePaymentLinkForms />
        </styled.Container>
      </LayoutWrapper>
    </>
  )
}

export default CreatePaymentLink;
