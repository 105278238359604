import { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import axios from "axios";
import moment from "moment";
import produce from "immer";

//Types
import { Plans, RequestPaymentPost, SmartRetrial } from "../types";

//Services
import RequestPaymentServices from "../../../../../services/RequestPayment";
import SessionService from "../../../../../services/Session";
import GetUserDataService from "../../../../../services/GetUserData";
import StoreService from "../../../../../services/Store";

//Context
import {
  RequestPaymentContext,
  ContextAllTypes,
} from "../../../../../providers/requestPaymentContext";

//Icons
import visaIcon from "../../../../../assets/images/icon-card-visa.svg";
import masterIcon from "../../../../../assets/images/icon-card-mastercard.svg";
import maestroIcon from "../../../../../assets/images/icon-card-maestro.svg";
import auraIcon from "../../../../../assets/images/icon-card-aura.svg";
import hiperIcon from "../../../../../assets/images/icon-card-hipercard.svg";
import jcbIcon from "../../../../../assets/images/icon-card-jcb.svg";
import americanExpressIcon from "../../../../../assets/images/icon-card-americanexpress.svg";
import dinersIcon from "../../../../../assets/images/icon-card-dinersclub.svg";
import eloIcon from "../../../../../assets/images/icon-card-elo.svg";
import discoverIcon from "../../../../../assets/images/icon-card-discover.svg";

//Validtors
import { customerValidations } from "../../FormValidations/customerValidations";
import { pixValidations } from "../../FormValidations/pixValidations";
import { planValidations } from "../../FormValidations/planValidations";
import { manualCardValidations } from "../../FormValidations/manualCardValidations";
import { smartRetrialsValidations } from "../../FormValidations/smartRetrialsValidations";

//Constants
import brStates from "../../../../../helpers/states-format-id";

export const useManualPaymentHook = () => {
  const history = useHistory();
  
  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [smartRetrial, setSmartRetrial] = useState<SmartRetrial>({
    informRetries: false,
    daysBetweenRetries: "0",
    maxRetries: "0",
    errors: [],
  });

  const [createPlanFirst, setCreatePlanFirst] = useState(true);

  const [labelButton, setLabelButton] = useState("ui.cta.next");

  const [storeNameFinished, setStoreNameFinished] = useState("");

  const [customizeBoleto, setCustomizeBoleto] = useState(false);
  const [preFillCustomersData, setPreFillCustomersData] = useState(false);
  const [informCycle, setInformCycle] = useState(false);
  const [maxSubscriptionCharges, setMaxSubscriptionCharges] = useState("1");
  const [informTax, setInformTax] = useState(false);
  const [pixQrCode, setPixQrCode] = useState("");
  const [pixQrCodeCopyPaste, setPixQrCodeCopyPaste] = useState("");

  const [storeHasENotasKEY, setStoreHasENotasKEY] = useState(false);
  const [informInvoice, setInformInvoice] = useState(false);

  const [isCreatingANewPlan, setIsCreatingANewPlan] = useState(false);

  const applyFeesAfterOrder = [
    0, 0, 3.45, 5.22, 7.02, 8.85, 10.71, 12.6, 14.53, 16.49, 18.48, 20.5, 22.56,
  ];
  const [applySplitsAfterOrder, setApplySplitsAfterOrder] = useState(false);

  const [isPtbr, _] = useState(() => {
    if (navigator.language === "pt-BR") {
      return true;
    }

    return false;
  });

  const [formErrors, setFormErrors] = useState([]);

  const [progressStep, setProgressStep] = useState(0);

  const [paymentType, setPaymentType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [includeFeeTransfer, setIncludeFeeTransfer] = useState(false);

  const [customerCurrency, setCustomerCurrency] = useState("BRL");
  const [customerCurrencySymbol, setCustomerCurrencySymbol] = useState("R$");

  const [cardInstallmentsAmount, setCardInstallmentsAmount] = useState<any>([]);

  const [isCnpj, setIsCnpj] = useState(false);

  const eNotasInputRef = useRef<HTMLInputElement>(null);

  const refName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refQRCode = useRef<HTMLDivElement>(null);

  const {
    manualOrder,
    setManualOrder,
    newPaymentLink,
    setNewPaymentLink,
    newPlan,
    setNewPlan,
    customer,
    setCustomer,
    creditCard,
    setCreditCard,
    tax,
    setTax,
  }: ContextAllTypes = useContext(RequestPaymentContext);
  
  const [requestType, setRequestType] = useState("");
  const [linkGenerator, setLinkGenerator] = useState(false);
  const [barCode, setBarCode] = useState("{BARCODE_NUMBER}");
  const [manual, setManual] = useState(false);
  const [cardOrBoleto, setCardOrBoleto] = useState(false);
  const [card, setCard] = useState(false);
  const [boleto, setBoleto] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [requestPaymentFinished, setRequestPaymentFinished] = useState(false);
  const [requestPaymentBoletoFinished, setRequestPaymentBoletoFinished] =
    useState(false);
  const [requestCreditCardFinished, setRequestCreditCardFinished] =
    useState(false);
  const [boletoOrders, setBoletoOrders] = useState([]);

  const [iconCardBand, setIconCardBand] = useState("");
  const [textCardBand, setTextCardBand] = useState("");

  const [processing, setProcessing] = useState(false);
  const [limitBoleto, setLimitBoleto] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessageState, setSuccessMessageState] = useState("");

  const [plans, setPlans] = useState<Plans[]>([]);

  const [dueDateState, setDueDateState] = useState("");

  const [manualDuedateError, setManualDuedateError] = useState("");

  useEffect(() => {
    if (manualOrder.whenSend !== "") {
      setManualOrder((prevState: any) => {
        return {
          ...prevState,
          sendToCustomer: "true",
        };
      });
    }

    if (manualOrder.whenSend === "2") {
      setManualOrder((prevState: any) => {
        return {
          ...prevState,
          sendToCustomer: "",
          warrantyDays: "",
        };
      });
    }
  }, [manualOrder.whenSend]);

  const [chargeLaterHome, setChargeLaterHome] = useState(false);
  const [chargeLater, setChargeLater] = useState(false);
  const [successManualRequest, setSuccessManualRequest] = useState(false);

  const [isForeigner, setIsForeigner] = useState(false);
  const [lateFee, setLateFee] = useState({
    mode: "",
    amount: "",
    errors: [],
  });
  const [discount, setDiscount] = useState({
    mode: "",
    amount: "",
    errors: [],
  });
  const [interest, setInterest] = useState({
    mode: "",
    amount: "",
    limitDate: "0000-00-00",
    errors: [],
  });
  const [productType, setProductType] = useState("");

  const [daysBeforeGenerateBoleto, setDaysBeforeGenerateBoleto] = useState("1");

  const [creatingPlan, setCreatingPlan] = useState(false);

  const [hasExchangeRates, setHasExchangeRates] = useState(true);

  const [colorsTheme, setColorsTheme] = useState({
    backgroundColor: "",
    color: "",
  });

  const [conversionRates, setConversionRates] = useState();
  const [selected, setSelected] = useState("BR");
  const [fxPlanAmount, setFxPlanAmount] = useState(0);

  const [ipState, setIpState] = useState("");

  const intl = useIntl();

  useEffect(() => {
    if (!isForeigner) {
      getExchange();
    }
  }, []);

  useEffect(() => {
    if (paymentMethod === "pix" && progressStep === 2) {
      setLabelButton("request.payment.pix.generate.pix");
    } else if (
      paymentType === "recurring" &&
      progressStep === 2 &&
      creatingPlan
    ) {
      setLabelButton("ui.cta.next");
    } else if (
      paymentType === "recurring" &&
      progressStep === 2 &&
      !creatingPlan
    ) {
      setLabelButton("ui.cta.next");
    } else if (progressStep === 3 && paymentMethod === "credit-card") {
      if (manualOrder.installments > 1 && !applySplitsAfterOrder) {
        setLabelButton("request.payment.credit.card.in.btn");
      } else if (manualOrder.installments === 1 && !applySplitsAfterOrder) {
        setLabelButton("request.payment.credit.card.of.btn");
      } else {
        setLabelButton("request.payment.credit.card.btn");
      }
    } else if (progressStep === 3 && paymentMethod === "boleto") {
      if (manualOrder.installments > 1 && !applySplitsAfterOrder) {
        setLabelButton("request.payment.generate.first.boleto.label");
      } else if (manualOrder.installments === 1 && !applySplitsAfterOrder) {
        setLabelButton("request.payment.generate.boleto.label");
      } else {
        setLabelButton("request.payment.generate.first.boleto.only.label");
      }
    } else {
      setLabelButton("ui.cta.next");
    }

    if (manualOrder.amount === "NaN") {
      setManualOrder((prevState: any) => {
        return {
          ...prevState,
          amount: "",
        };
      });
    }
  }, [progressStep, paymentType, creatingPlan]);

  useEffect(() => {
    if (storeHasENotasKEY) {
      setInformInvoice(true);

      if (eNotasInputRef.current) {
        eNotasInputRef.current.checked = true;
      }
    }
  }, []);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=jsonp&callback=?")
      .then((response) => {
        let responseJSON = JSON.parse(
          response.data
            .replace("?", "")
            .replace("(", "")
            .replace(")", "")
            .replace(";", "")
        );
        setIpState(responseJSON.ip);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!informInvoice) {
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          whenSend: "2",
          sendToCustomer: "false",
        };
      });
    }
  }, [informInvoice]);

  useEffect(() => {
    if (
      creditCard.cardNumber.match("^4") &&
      !creditCard.cardNumber.match("^(438935)|(451416)|(4576)|(4011)")
    ) {
      setIconCardBand(visaIcon);
      setTextCardBand("Visa");
    } else if (creditCard.cardNumber.match("^5[1-5]")) {
      setIconCardBand(masterIcon);
      setTextCardBand("Mastercard");
    } else if (creditCard.cardNumber.match("^6(?:011|5[0-9]{2})")) {
      setIconCardBand(discoverIcon);
      setTextCardBand("Discover");
    } else if (
      creditCard.cardNumber.match(
        "^(636368)|(438935)|(504175)|(451416)|(636297)|(5067)|(4576)|(4011)"
      )
    ) {
      setIconCardBand(eloIcon);
      setTextCardBand("");
    } else if (creditCard.cardNumber.match("^3(0[0-5]|[68])")) {
      setIconCardBand(dinersIcon);
      setTextCardBand("");
    } else if (creditCard.cardNumber.match("^3[47]")) {
      setIconCardBand(americanExpressIcon);
      setTextCardBand("Amex");
    } else if (creditCard.cardNumber.match("^(?:2131|1800|35)")) {
      setIconCardBand(jcbIcon);
      setTextCardBand("JCB");
    } else if (
      creditCard.cardNumber.match("^(606282)|(3841)") &&
      !creditCard.cardNumber.match("^(6011)")
    ) {
      setIconCardBand(hiperIcon);
      setTextCardBand("Hipercard");
    } else if (creditCard.cardNumber.match("^(?:5[0678]|6304|6390|67)")) {
      setIconCardBand(maestroIcon);
      setTextCardBand("");
    } else if (creditCard.cardNumber.match("^(50)")) {
      setIconCardBand(auraIcon);
      setTextCardBand("Aura");
    }
  }, [creditCard.cardNumber]);

  useEffect(() => {
    StoreService.get().then((response) => {
      setIsForeigner(response.data.isForeigner);
      setStoreHasENotasKEY(response.data.generateInvoice);
      setApplySplitsAfterOrder(response.data.applySplitsAfterOrder);

      if (response.data.isForeigner) {
        switch (response.data.fxMerchant.productType) {
          case 1:
            setProductType("Goods");
            break;
          case 2:
            setProductType("Services");
            break;
          default:
            setProductType("");
            break;
        }
      }
    });
  }, []);

  useEffect(() => {
    RequestPaymentServices.requestPlans().then((response) => {
      setPlans(response.data.plans);
    });

    if (creatingPlan) {
      setProgressStep(2);
    }
  }, [creatingPlan]);

  useEffect(() => {
    let zipcodeClean = customer.address.zipcode.replace("-", "");

    if (zipcodeClean.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${zipcodeClean}/json/`)
        .then((response) => {
          const stateUfEnum = brStates.find(
            (state) => state.uf === response.data.uf
          );

          setCustomer((prevState: any) => {
            return {
              ...prevState,
              address: {
                ...prevState.address,
                street: response.data.logradouro,
                number: "",
                district: response.data.bairro,
                city: response.data.localidade,
                state: stateUfEnum?.value,
                country: 0,
              },
            };
          });
        });
    }
  }, [customer.address.zipcode]);

  async function getExchange() {
    const response = await axios
      .get(
        `https://v6.exchangerate-api.com/v6/ee1a514ea6758da13f9d976a/latest/BRL`
      )
      .then((response) => {
        setConversionRates(response.data.conversion_rates);
      })
      .catch((err) => {
        setHasExchangeRates(false);
        console.log(err);
      });
  }

  function clearString(identificationNumber: string) {
    return identificationNumber.replace(/\D/g, "");
  }

  function createOrder(event: React.SyntheticEvent, planId: number) {
    event.preventDefault();
    setProcessing(true);
    // debugger
    let identificationNumberClean = clearString(customer.identificationNumber);

    const cepClean = clearString(customer.address.zipcode);

    let birthDateGenerated = "";
    let data = "";
    if (customer.birthdate) {
      data = moment(customer.birthdate, "DD/MM/YYYY").toString();
      birthDateGenerated = moment(data).format("YYYY-MM-DD").toString();
    }

    let isEmptyAddress = Object.values(customer.address).every(
      (value) => value !== ""
    );

    const newCustomer = {
      firstName:
        customer.identificationNumber.length > 14
          ? customer.businessName
          : customer.firstName,
      surname:
        customer.identificationNumber.length > 14 ? "" : customer.lastName,
      identificationNumber:
        customerCurrency === "BRL" ? identificationNumberClean : undefined,
      birthdate:
        !isCnpj &&
        birthDateGenerated !== "" &&
        birthDateGenerated !== "Invalid date"
          ? birthDateGenerated
          : undefined,
      date_of_registry: isCnpj ? birthDateGenerated : undefined,
      email: customer.email,
      phone: customer.phone !== "" ? customer.phone : undefined,
      address: isEmptyAddress
        ? undefined
        : {
            street: customer.address.street,
            number: customer.address.number,
            zipcode: cepClean,
            reference: customer.address.reference,
            district: customer.address.district,
            city: customer.address.city,
            state: customer.address.state,
            country: 0,
          },
    };
    RequestPaymentServices.addCustomer(newCustomer)
      .then((responseAddCustomer) => {
        const amountWhitoutCurrencySymbol = manualOrder.amount;
        
        setChargeLaterHome(chargeLater);

        let paymentTypeToSend = "";

        if (paymentMethod === "boleto") {
          paymentTypeToSend = "boleto";
        } else if (paymentMethod === "credit-card") {
          paymentTypeToSend = "credit";
        } else {
          paymentTypeToSend = "pix";
        }

        let additionalFees = 0;

        if (applySplitsAfterOrder) {
          additionalFees =
            (parseFloat(amountWhitoutCurrencySymbol) *
              applyFeesAfterOrder[manualOrder.installments]) /
            100;
        }

        let finalPlanId = createPlanFirst
          ? planId
          : paymentType === "recurring"
          ? manualOrder.planId
          : undefined;

          console.info(manualOrder, manualOrder.planId, "e aí?")

        let bodyRequestObject = {
          planId: manualOrder.planId,
          name: manualOrder.name,
          productCategory: isForeigner ? productType : undefined,
          isTokenized: chargeLater,
          includeFeeTransfer: includeFeeTransfer,
          amount: parseFloat(amountWhitoutCurrencySymbol) + additionalFees,
          description:
            manualOrder.description !== "" ? manualOrder.description : "Compra",
          referencedOrderNumber: undefined,
          customerId: responseAddCustomer.data.id,
          payment: {
            type: paymentTypeToSend,
            installments: manualOrder.planId
              ? undefined
              : manualOrder.installments,
          },
          currency:
            customerCurrency === "BRL" || paymentMethod === "Boleto"
              ? undefined
              : customerCurrency,
          daysBetweenRetries:
            smartRetrial.daysBetweenRetries !== "0"
              ? smartRetrial.daysBetweenRetries
              : undefined,
          maxRetries:
            smartRetrial.maxRetries !== "0"
              ? smartRetrial.maxRetries
              : undefined,
        };

        if (paymentMethod === "boleto") {
          createBoletoOrder(bodyRequestObject);
        } else if (paymentMethod === "credit-card") {
          createCreditCardOrder(bodyRequestObject);
        } else {
          createPixOrder(bodyRequestObject, event);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccessManualRequest(false);

        setErrorMessage("error.unknown.error");
        setProcessing(false);
      });
  }

  function createCreditCardOrder(bodyRequestObject: RequestPaymentPost) {
    const cardNumberClean = clearString(creditCard.cardNumber);
    const expirationSplit = creditCard.expiration.split("/");
    const cvvClean = clearString(creditCard.cvv);

    const newCreditCard = {
      holderName: creditCard.holderName,
      cardBrand: textCardBand,
      cardNumber: cardNumberClean,
      expirationYear: parseInt(`20${expirationSplit[1]}`),
      expirationMonth: parseInt(expirationSplit[0]),
      securityCode: cvvClean,
      customerId: bodyRequestObject.customerId,
    };

    RequestPaymentServices.postCreditCard(newCreditCard)
      .then((responseCreditCard) => {
        const bodyRequestWithCreditCard = produce(
          bodyRequestObject,
          (draft: any) => {
            draft.payment.cardId = responseCreditCard.data.id;
          }
        );

        getUserInformation(bodyRequestWithCreditCard);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);

        setSuccessManualRequest(false);

        setErrorMessage("error.unknown.error");
      });
  }

  function createBoletoOrder(bodyRequestObject: RequestPaymentPost) {
    let duedateFormattedFinal = "";
    let limitDiscountDate = "";
    let brFormatted = "";
    const dueDateGenerated = moment().add(2, "days").format("YYYY-MM-DD");

    if (manualOrder.duedate !== "") {
      brFormatted = moment(manualOrder.duedate, "DD/MM/YYYY").toString();
      duedateFormattedFinal = moment(brFormatted).format("YYYY-MM-DD");
      limitDiscountDate = moment(duedateFormattedFinal)
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      setDueDateState(duedateFormattedFinal);
    } else {
      setDueDateState(
        manualOrder.duedate !== "" ? manualOrder.duedate : dueDateGenerated
      );
      duedateFormattedFinal = moment(
        manualOrder.duedate !== "" ? manualOrder.duedate : dueDateGenerated
      ).format("YYYY-MM-DD");
      limitDiscountDate = moment(duedateFormattedFinal)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }

    const newBoleto = produce(bodyRequestObject, (draft) => {
      draft.payment.dueDate = duedateFormattedFinal;
      draft.chargeDaysBefore = manualOrder.chargeDaysBefore;
      draft.generateAllBoletos = manualOrder.generateAllBoletos;

      if (discount.mode !== "") {
        draft.payment.discount = {
          mode: discount.mode,
          amount:
            discount.mode === "Fixed" ? parseFloat(discount.amount) : null,
          percentage:
            discount.mode === "Percentage" ? parseFloat(discount.amount) : null,
          limitDate: limitDiscountDate,
        };
      }

      if (lateFee.mode !== "") {
        draft.payment.lateFee = {
          mode: lateFee.mode,
          amount: lateFee.mode === "Fixed" ? parseFloat(lateFee.amount) : null,
          percentage:
            lateFee.mode === "Percentage" ? parseFloat(lateFee.amount) : null,
        };
      }

      if (interest.mode !== "") {
        draft.payment.interestOrFine = {
          mode: interest.mode,
          amount:
            interest.mode === "Daily_Amount"
              ? parseFloat(interest.amount)
              : null,
          percentage:
            interest.mode === "Daily_Percentage" ||
            interest.mode === "Monthly_Percentage"
              ? parseFloat(interest.amount)
              : null,
        };
      }

      if (tax.mode !== "" && parseFloat(tax.amount) > 0) {
        draft.tax = {
          mode: tax.mode,
          amount: parseFloat(tax.amount),
        };
      }
    });

    getUserInformation(newBoleto);
  }

  function createPixOrder(
    bodyRequestObject: RequestPaymentPost,
    event: React.SyntheticEvent
  ) {
    getUserInformation(bodyRequestObject, event);
  }

  function getUserInformation(
    finalBodyRequestObject: RequestPaymentPost,
    event?: React.SyntheticEvent
  ) {
    GetUserDataService.getUserData()
      .then((responseUserData) => {
        const hasDevice = responseUserData.data.parse.simple_operating_platform_string ? responseUserData.data.parse.simple_operating_platform_string : "undefined"
        const bodyRequestWithUserInformation = produce(
          finalBodyRequestObject,
          (draft) => {
            if (ipState) {
              draft.userTracking = {
                browser: responseUserData.data.parse.software,
                device:
                hasDevice,
                ip: ipState,
                operatingSystem: responseUserData.data.parse.operating_system,
              };
            }
          }
        );
        postOrder(bodyRequestWithUserInformation, event);
      })
      .catch((err) => {
        console.log(err);

        postOrder(finalBodyRequestObject, event);
      });
    }
    
    function postOrder(
      finalBodyRequestObject: RequestPaymentPost,
      event?: React.SyntheticEvent
      ) {
        console.log(finalBodyRequestObject, 'o que manda')
    const service =
    manualOrder.planId ||
    (finalBodyRequestObject.planId && finalBodyRequestObject.planId > 0)
    ? RequestPaymentServices.requestRecurringPaymentPost
    : RequestPaymentServices.requestPaymentPost;
    service(finalBodyRequestObject)
      .then((response) => {
        console.log(response, 'o que volta')
        console.log(finalBodyRequestObject, 'o que manda')
        if (finalBodyRequestObject.payment.type === "pix") {
          setPixQrCode(response.data.payment.pix.url);
          setPixQrCodeCopyPaste(response.data.payment.pix.copyPaste);

          setProcessing(false);
          handleStep(event!);
        } else {
          setProcessing(false);
          setSuccessManualRequest(true);

          setManualOrder((prevState: any) => {
            return {
              ...prevState,
              amount: response.data.amount || response.data.orders[0].amount,
              // name: manualOrder.name
            };
          });

          setStoreNameFinished(response.data.storeName);
        }
      })
      .catch((err) => {
        setProcessing(false);
        setSuccessManualRequest(false);

        setErrorMessage(
          `request.payment.zoop.error.${err.response.data.errorCode}`
        );
      });
  }

  function handleDiscount(value: string) {
    setDiscount((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleFee(value: string) {
    setLateFee((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleTicket(value: string) {
    setInterest((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleDiscountFeeTicket(type: string, value: string) {
    if (type === "interest") {
      setInterest((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    } else if (type === "lateFee") {
      setLateFee((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    } else if (type === "discount") {
      setDiscount((prevState: any) => {
        return {
          ...prevState,
          mode: value,
        };
      });
    }
  }

  useEffect(() => {
    StoreService.get().then((response) => {
      setLimitBoleto(response.data.store.storeSettings.limitBoleto);
    });

    const themeStorage = localStorage.getItem("premepay-dashboard-theme");

    const { premepayDashboardTheme } = JSON.parse(
      themeStorage ? themeStorage : "{}"
    );

    if (premepayDashboardTheme === "light") {
      setColorsTheme({
        backgroundColor: "#f0f0f0",
        color: "#16161B",
      });
    } else {
      setColorsTheme({
        backgroundColor: "#3E3D46",
        color: "#fff",
      });
    }
  }, []);

  useEffect(() => {
    if (newPaymentLink.name !== "crie-um-plano") {
      setErrorMessage("");
      setManualDuedateError("");
      setSuccessMessageState("");
    }
  }, [newPaymentLink.name]);

  function createPlan(event: React.SyntheticEvent) {
    event.preventDefault();
    setProcessing(true);
    const cleanAmount = newPlan.amount.replace("R$", "");
    RequestPaymentServices.postPlans(
      newPlan.name,
      newPlan.description,
      newPlan.frequency,
      parseFloat(cleanAmount),
      newPlan.grace,
      isForeigner ? productType : undefined,
      newPlan.paymentTypes,
      informCycle ? parseInt(maxSubscriptionCharges) : undefined,
      manualOrder.chargeDaysBefore,
      parseFloat(newPlan.taxAmount),
      newPlan.taxMode,
      newPlan.isVisible,
      undefined
    )
      .then((response) => {
        setProcessing(false);
        setSuccessMessageState(
          intl.formatMessage({
            id: "request.payment.create.plan.success.message",
          })
        );

        setManualOrder((prevState: any) => {
          return {
            ...prevState,
            amount: response.data.amount,
            planId: response.data.id,
          };
        });
        setNewPlan({
          name: "",
          amount: "",
          frequency: 0,
          grace: "",
          description: "",
          productCategory: "",
          paymentTypes: [0, 1, 3],
          taxAmount: "",
          taxMode: "",
          isVisible: false,
        });

        if (createPlanFirst) {
          createOrder(event, response.data.id);
        }

        setCreatingPlan(false);

        // RequestPaymentServices.requestPlans().then(response => {
        //   const plans = response.data;
        //   setPlans(plans);
        // });
      })
      .catch((err) => {
        setProcessing(false);
        setErrorMessage(
          intl.formatMessage({
            id: "request.payment.create.plan.error.message",
          })
        );
      });
  }

  // useEffect(() => {
  //   if (paymentType === "single") {
  //     setCreatePlanFirst(false);
  //   }
  // }, [paymentType]);


  function handleCleanAllToCreateNewRequest() {
    setRequestPaymentFinished(false);
    setRequestCreditCardFinished(false);
    setRequestPaymentBoletoFinished(false);

    setLinkGenerator(false);
    setManual(false);
    setCardOrBoleto(false);
    setRecurring(false);
    setCard(false);
    setBoleto(false);
    setErrorMessage("");
    setCreatingPlan(false);
    setManualDuedateError("");
    setSuccessMessageState("");

    setRequestType("");

    // handleCleanAllObjectState();
  }

  function handleTax(name: string, value: string) {
    setTax((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handlePaymentType(type: string) {
    setPaymentType(type);

    switch (type) {
      case "single":
        setLinkGenerator(true);
        setManual(false);
        break;
      case "recurring":
        setManual(true);
        setLinkGenerator(false);
        break;
      default:
        setLinkGenerator(false);
        setManual(false);
        break;
    }
  }

  function handlePaymentMethod(method: string) {
    setPaymentMethod(method);
    setLabelButton("ui.cta.next");

    switch (method) {
      case "credit-card":
        setCard(true);
        break;
      case "boleto":
        setBoleto(true);
        break;
      default:
        setCard(false);
        setBoleto(false);
        break;
    }
  }

  useEffect(() => {
    if (paymentType !== "" && paymentMethod !== "") {
      setProgressStep(1);
    }
  }, [paymentType, paymentMethod]);

  function handleStep(event: React.SyntheticEvent) {
    event.preventDefault();

    const currentStep = progressStep;

    if (currentStep === 2 && creatingPlan) {
      setIsCreatingANewPlan(true);
    }

    if (currentStep === 1 && manualOrder.planId && manualOrder.planId > 0) {
      setIsCreatingANewPlan(false);
      setCreatingPlan(false);
      setProgressStep(currentStep + 1);
    }

    if (
      paymentMethod !== "" &&
      newPlan.name !== "" &&
      progressStep === 1 &&
      isCreatingANewPlan
    ) {
      setProgressStep(currentStep + 1);
      setCreatingPlan(true);
    }

    if (creatingPlan) {
      setCreatingPlan(false);
    } else {
      setProgressStep(currentStep + 1);
    }
  }

  function handlePreviousStep() {
    const currentStep = progressStep;

    if (currentStep === 2 && isCreatingANewPlan && !creatingPlan) {
      setProgressStep(2);
      setCreatingPlan(true);
    } else if (currentStep === 2 && creatingPlan) {
      setCreatingPlan(false);
      setProgressStep(currentStep - 1);
    } else {
      setCreatingPlan(false);
      setProgressStep(currentStep - 1);
    }
  }

  function handleNewPlanData(name: string, value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleSmartRetrials(name: string, value: string) {
    setSmartRetrial((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleNewPlanAmount(value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  function handleCustomizeBoleto() {
    setCustomizeBoleto(!customizeBoleto);
  }

  function handleTaxAmount(value: string) {
    setNewPlan((prevState: any) => {
      return {
        ...prevState,
        amount: value,
      };
    });
  }

  useEffect(() => {
    if (
      customer.identificationNumber.length > 14 &&
      customerCurrency === "BRL"
    ) {
      setIsCnpj(true);

      setCustomer((prevState: any) => {
        return {
          ...prevState,
          firstName: "",
          lastName: "",
        };
      });

      if (refName.current && refLastName.current) {
        refName.current.disabled = true;
        refLastName.current.disabled = true;
      }
    } else if (customer.identificationNumber.length === 0) {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
    } else {
      if (refName.current && refLastName.current) {
        refName.current.disabled = false;
        refLastName.current.disabled = false;
      }

      setIsCnpj(false);
    }
  }, [customer.identificationNumber]);

  function handleManualOrderData(name: string, value: string) {
    setManualOrder((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleSetGenerateAllBoleto(name: string, value: string) {
    setManualOrder((prevState: any) => {
      return {
        ...prevState,
        [name]: value === "true" ? true : false,
      };
    });
  }

  function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    let currentStep = progressStep;

    if (creatingPlan) {
      if (
        planValidations({
          newPlan,
          progressStep: currentStep,
          handleErrors: setFormErrors,
        })
      ) {
        handleStep(event);
      }
    } else if (paymentMethod === "pix") {
      if (progressStep === 2) {
        if (
          pixValidations({
            manualOrder,
            customer,
            progressStep: currentStep,
            handleErrors: setFormErrors,
          })
        ) {
          createOrder(event, 0);
        }
      } else {
        handleStep(event);
      }
    } else {
      if (paymentMethod === "boleto") {
        if (
          customerValidations({
            customer,
            progressStep: currentStep,
            handleErrors: setFormErrors,
            card,
            boleto,
            pix: false,
            isOptional: false,
            manualOrder,
            newPlan: newPlan,
            lateFee,
            discount,
            interest,
            limitBoleto
          })
        ) {
          if (progressStep === 3) {
            if (
              smartRetrial.informRetries &&
              !smartRetrialsValidations({ smartRetrial, setSmartRetrial })
            ) {
            } else {
              handleGenerateSwitch(event);
            }
          } else {
            handleStep(event);
          }
        }
      } else {
        if (
          manualCardValidations({
            manualOrder,
            customer,
            progressStep: currentStep,
            handleErrors: setFormErrors,
          })
        ) {
          if (progressStep === 3) {
            if (
              smartRetrial.informRetries &&
              !smartRetrialsValidations({ smartRetrial, setSmartRetrial })
            ) {
            } else {
              handleGenerateSwitch(event);
            }
          } else {
            handleStep(event);
          }
        }
      }
    }
  }

  function handleGenerateSwitch(event: React.SyntheticEvent) {
    // if (createPlanFirst) {
    //   return createPlan(event);
    // }
      createOrder(event, 0);
    
  }

  function handleInvoiceData(name: string, value: string) {
    setManualOrder((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function handleChargeDaysBefore(event: string) {
    let numberOfDays = parseInt(event);

    setManualOrder((prevState: any) => {
      return {
        ...prevState,
        chargeDaysBefore: numberOfDays,
      };
    });
  }

  return {
    handlePreviousStep,
    progressStep,
    successManualRequest,
    handlePaymentType,
    paymentType,
    handlePaymentMethod,
    paymentMethod,
    creatingPlan,
    setCreatingPlan,
    setNewPaymentLink,
    newPaymentLink,
    setNewPlan,
    newPlan,
    setManualOrder,
    handleSubmit,
    handleNewPlanData,
    formErrors,
    handleNewPlanAmount,
    customer,
    card,
    setCustomer,
    isForeigner,
    processing,
    manualOrder,
    creditCard,
    setCreditCard,
    colorsTheme,
    tax,
    handleTax,
    selected,
    setSelected,
    conversionRates,
    hasExchangeRates,
    applySplitsAfterOrder,
    errorMessage,
    customerCurrency,
    boleto,
    setProcessing,
    discount,
    handleDiscount,
    lateFee,
    handleFee,
    interest,
    handleTicket,
    handleDiscountFeeTicket,
    maxSubscriptionCharges,
    setMaxSubscriptionCharges,
    daysBeforeGenerateBoleto,
    setDaysBeforeGenerateBoleto,
    manualDuedateError,
    customizeBoleto,
    handleCustomizeBoleto,
    handleSetGenerateAllBoleto,
    handleChargeDaysBefore,
    isPtbr,
    handleManualOrderData,
    preFillCustomersData,
    storeHasENotasKEY,
    informInvoice,
    setInformInvoice,
    eNotasInputRef,
    handleInvoiceData,
    informTax,
    handleTaxAmount,
    includeFeeTransfer,
    setIncludeFeeTransfer,
    informCycle,
    setInformCycle,
    smartRetrial,
    setSmartRetrial,
    handleSmartRetrials,
    labelButton,
    storeNameFinished,
    refQRCode,
    pixQrCode,
    pixQrCodeCopyPaste,
    createPlanFirst,
    limitBoleto
  };
};
