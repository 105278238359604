import { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

//Services
import SessionService from "services/Session";
import BankAccountService from "services/BankAccount";
import FinancialsServices from "services/FinancialsServices";

import formatFinancialTransactions from "../../helpers/formatFinancialTransactions";
import formatFinancialIncoming from "../../helpers/formatFinancialIncoming";

//Types
import { WithdrawalTransaction } from "../types";

export const useProducts = () => {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [withdrawalTransactions, setWithdrawalTransactions] = useState<
  WithdrawalTransaction[]
  >([]);
  const [incomingTransactions, setIncomingTransactions] = useState<
  WithdrawalTransaction[]
  >([]);
  const [incomingAmount, setIncomingAmount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [numberTotalOfOrders, setNumberTotalOfOrders] = useState(1);

  const [openWithdrawalTransaction, setOpenWithdrawalTransaction] = useState({
    visibleDate: "",
    visibleTransaction: false,
    type: "",
  });

  const [isSearch, setIsSearch] = useState(false);
  
  const [searchedOrder, setSearchedOrder] = useState({
    type: "name",
    value: "",
  });
  
  const [isWaitingDownload, setIsWaitingDownload] = useState(false);
  
  const [percentageDownload, setPercentageDownload] = useState(0);
  
  const [periodTypeState, setPeriodTypeState] = useState("");

  const [filterQuery, setFilterQuery] = useState("");
  const [rangeDateFilter, setRangeDateFilter] = useState(["", ""]);
  
  useEffect(() => {
    setPageIndexProcessing(true);

    FinancialsServices.getReceivables(pageIndex).then((response) => {
      BankAccountService.getWithdrawals(pageIndex).then(
        (responseWithdrawals) => {
          setWithdrawalTransactions(
            formatFinancialTransactions(responseWithdrawals.data, response.data)
          );
          setPageIndexProcessing(false);
        }
      );
    });

    FinancialsServices.getPendingReceivables(pageIndex).then((response) => {
      const incomings = formatFinancialIncoming(response.data);
      setIncomingTransactions(incomings);

      const incomingsAmount = incomings.reduce((acc, current) => {
        return acc + current.payinAmount;
      }, 0);
      setIncomingAmount(incomingsAmount);
    });
  }, [pageIndex]);

  function toggleWithdrawalTransaction(date: string, type: string) {
    if (
      openWithdrawalTransaction.type === type &&
      date === openWithdrawalTransaction.visibleDate
    ) {
      setOpenWithdrawalTransaction({
        visibleDate: "",
        visibleTransaction: false,
        type: "",
      });
    } else {
      setOpenWithdrawalTransaction({
        visibleDate: date,
        visibleTransaction: true,
        type: type,
      });
    }
  }

  function redirectToOrderDetails(orderNumber: string, storeId: number) {
    history.push(`/transactions/${orderNumber}/${storeId}/details`);
  }
  
  function handleSearchedOrder(name: string, value: string) {
    setSearchedOrder((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }
  
  function handleSelectFilter(
    statusFilter: string,
    periodFilter: string,
    methodFilter: string,
    typeFilter: string,
    periodType: string
  ) {
    setPeriodTypeState(periodType);

    let filterByStatus = !["all", ""].includes(statusFilter);
    let filterByMethod = !["all", ""].includes(methodFilter);
    let filterByType = ["recurring"].includes(typeFilter);

    let startDate = moment(rangeDateFilter[0], "DD/MM/YYYY");
    let endDate = moment(rangeDateFilter[1], "DD/MM/YYYY");
    let startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    let endDateFormatted = moment(endDate).format("YYYY-MM-DD");

    let startDateQuery = moment(rangeDateFilter[0]).isValid()
      ? `&startDate=${startDateFormatted}`
      : "";
    let endDateQuery = moment(rangeDateFilter[1]).isValid()
      ? `&endDate=${endDateFormatted}`
      : "";
    let methodQuery = filterByMethod ? `&PaymentMethod=${methodFilter}` : "";
    let statusQuery =
      filterByStatus || periodType === "payment"
        ? `&Status=${periodType === "payment" ? "Succeded" : statusFilter}`
        : "";
    let typeQuery = filterByType ? `&Type=Recurring` : "";
    let periodTypeQuery =
      periodType !== "creation" ? `&usePaymentDate=true` : "";

    setFilterQuery(
      `${startDateQuery}${endDateQuery}${methodQuery}${statusQuery}${typeQuery}${periodTypeQuery}`
    );
    let finalQuery = `${startDateQuery}${endDateQuery}${methodQuery}${statusQuery}${typeQuery}${periodTypeQuery}`;

    // getFilteredOrders(finalQuery);
  }
  
  useEffect(() => {
    if(percentageDownload === 100) {
      setTimeout(() => {
        setIsWaitingDownload(false);
      }, 3000);
    }
  }, [percentageDownload])
  
  function exportTransactions() {
    console.log(rangeDateFilter);
    console.log(searchedOrder);
    
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    // const filterStorageString = localStorage.getItem("filter");

    // const { status, method, type } = JSON.parse(
    //   filterStorageString ? filterStorageString : "{}"
    // );

    let startDate = moment(rangeDateFilter[0], "DD/MM/YYYY");
    let endDate = moment(rangeDateFilter[1], "DD/MM/YYYY");
    let startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    let endDateFormatted = moment(endDate).format("YYYY-MM-DD");

    // let filterByStatus = !["all", ""].includes(status);
    // let filterByMethod = !["all", ""].includes(method);
    // let filterByType = ["recurring"].includes(type);

    let startDateQuery = moment(rangeDateFilter[0]).isValid()
      ? `&startDate=${startDateFormatted}`
      : "";
    let endDateQuery = moment(rangeDateFilter[1]).isValid()
      ? `&endDate=${endDateFormatted}`
      : "";
    // let methodQuery = filterByMethod ? `&PaymentMethod=${method}` : "";
    // let statusQuery = filterByStatus ? `&Status=${status}` : "";
    // let typeQuery = filterByType ? `&Type=Recurring` : "";
    // let periodTypeQuery =
    //   periodTypeState !== "creation" ? `&usePaymentDate=true` : "";
    
    const productNameQuery = searchedOrder.value ? `&productName=${searchedOrder.value}` : "";

    let entityTypeAndId =
      session.entityType === "Business"
        ? `?businessId=${session.businessId}`
        : `?storeId=${session.storeId}`;

    let finalQuery = `?storeId=${session.storeId}${startDateQuery}${endDateQuery}${productNameQuery}`;

    setIsWaitingDownload(true);

    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      `${process.env.REACT_APP_API_BASE_URL}/stores/orders-products/export${finalQuery}`,
      true
    );
    req.setRequestHeader("Authorization", `Bearer ${session.token}`);
    req.responseType = "blob";
    req.onload = function () {
      //Convert the Byte Data to BLOB object.
      var blob = new Blob([req.response], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      var url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", "orders-premepay.xlsx");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    req.onprogress = function (event) {
      setPercentageDownload(
        parseFloat(((event.loaded / event.total) * 100).toFixed(0))
      );
    };

    req.send();
  }

  return {
    withdrawalTransactions,
    incomingTransactions,
    openWithdrawalTransaction,
    incomingAmount,
    pageIndex,
    pageIndexProcessing,
    numberTotalOfOrders,

    redirectToOrderDetails,

    setPageIndex,
    setOpenWithdrawalTransaction,

    toggleWithdrawalTransaction,
    handleSearchedOrder,
    handleSelectFilter,
    exportTransactions,
    searchedOrder,
    rangeDateFilter,
    setRangeDateFilter,
    isWaitingDownload,
    percentageDownload,
  };
};
