import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import moment from "moment";

//Services
import OrderService from "services/Order";

import useClickOutside from "../../../../../hooks/useClickOutside";

import Buttons from "../../../../Forms/Buttons";

//Assets
import redInfoIcon from "assets/images/settings/red-info.svg";
import closeIcon from "assets/images/settings/close.svg";

//Styles
import * as styled from "./styles";

const CancelOrderModalButtonModal = ({
  storeId,
  orderNumber,
  afterCancel,
}: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isCanceling, setIsCanceling] = useState(false);

  const cancelBoletoModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  function handleShowModal() {
    if (cancelBoletoModalRef.current) {
      setIsMounted(false);
      setShowModal(false);
    } else {
      setIsMounted(true);
      setShowModal(true);
    }
  }

  useClickOutside(cancelBoletoModalRef, isMounted, setIsMounted);

  const cancelOrder = () => {
    setIsCanceling(true);

    OrderService.cancelBoletoOrder(storeId as number, orderNumber as string)
      .then((response) => {
        setIsCanceling(false);
        handleShowModal();
        afterCancel();
      })
      .catch((err) => {
        console.log(err);
        setIsCanceling(false);
      });
  };

  return (
    <>
      <styled.BoletoToActivateModal>
        <Buttons
          mode="unfilled"
          label={intl.formatMessage({
            id: "cancel.order.modal.yes.cta",
          })}
          handler={handleShowModal}
        />
      </styled.BoletoToActivateModal>

      {showModal && (
        <styled.ModalMainContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={cancelBoletoModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowModal(false);
          }}
        >
          <div className="modal-centered">
            <div className="modal-container">
              <div className="header">
                <div>
                  <h5>
                    {intl.formatMessage({
                      id: "cancel.order.modal.title",
                    })}
                  </h5>
                </div>
                <div>
                  <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => setShowModal(false)}
                  />
                </div>
              </div>

              <div className="form">
                <div className="red-info-container">
                  <img src={redInfoIcon} alt="info" />
                  <p>
                    {intl.formatMessage({
                      id: "cancel.order.modal.desc.1",
                    })}
                  </p>
                </div>
              </div>

              <div className="footer">
                <Buttons
                  mode="unfilled"
                  label={intl.formatMessage({
                    id: "cancel.modal.cancel.cta",
                  })}
                  handler={handleShowModal}
                />
                <Buttons
                  mode={isCanceling ? "filled-processing" : "filled"}
                  label={intl.formatMessage({
                    id: "cancel.order.modal.yes.cta",
                  })}
                  handler={cancelOrder}
                />
              </div>
            </div>
          </div>
        </styled.ModalMainContainer>
      )}
    </>
  );
};

export default CancelOrderModalButtonModal;
