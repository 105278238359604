import axios from "axios";

interface ICustomer {
  name: string;
  surname: string;
  identificationNumber: string;
  email: string;
  phone: string;
  birthdate: string;
  address: {
    street: string;
    number: string;
    zipCode: string;
    reference: string;
    district: string;
    city: string;
    state: string;
    country?: string;
  };
}

interface IPagination {
  pageSize?: number;
  pageIndex?: number;
  query?: string;
}

class CustomersServices {
  async listAllCustomers({
    pageSize = 100,
    pageIndex = 1,
    query = "",
  }: IPagination) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/customers/list?pageSize=${pageSize}&pageIndex=${pageIndex}${query}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers/list?pageSize=${pageSize}&pageIndex=${pageIndex}${query}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async getCustomerDetails(identificationNumber: string, storeId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/customers/${identificationNumber}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async editCustomer(customerId: string, customer: any) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers/${customerId}`,
      {
        ...customer,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async createCustomer(customer: ICustomer) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers`,
      {
        ...customer,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async makeDefaultCreditCard(customerId: number, cardId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers/${customerId}/cards/${cardId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }
}

export default new CustomersServices();
