import { createGlobalStyle } from "styled-components";

import BrandonTextMedium from "../assets/fonts/BrandonText-Medium.otf";
import BrandonTextRegular from "../assets/fonts/BrandonText-Regular.otf";
import BrandonTextBold from "../assets/fonts/BrandonText-Bold.otf";
import BrandonTextLight from "../assets/fonts/BrandonText-Light.otf";
import BerlingskeSerifBold from "../assets/fonts/Berlingske-Serif-Text-Bold.ttf";

export default createGlobalStyle`
@font-face {
    font-family: "Brandon Text Medium";
    src: url(${BrandonTextMedium}) format('opentype');
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: "Brandon Text Light";
    src: url(${BrandonTextLight}) format('opentype');
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: "Brandon Text Regular";
    src: url(${BrandonTextRegular}) format('opentype');
    font-style: normal;
    font-display: auto;
  }
  
  @font-face {
    font-family: "Brandon Text Bold";
    src: url(${BrandonTextBold}) format('opentype');
    font-style: normal;
    font-display: auto;
    font-weight: bold;
  }

  @font-face {
    font-family: "Berlingske Serif Bold";
    src: url(${BerlingskeSerifBold}) format('truetype');
    font-style: normal;
    font-display: auto;
    font-weight: bold;
  }


  body {
    margin: 0;
    padding: 0;
    font-size: 62.5% !important;
    background: ${(props) => props.theme.backgroundColors.fifth};
    box-sizing: border-box;
  
    .intercom-lightweight-app-launcher {

      @media screen and (max-width: 916px) and (max-height: 897px) {
        bottom: 13% !important;
      }

      @media screen and (max-width: 916px) {
        bottom: 13% !important;
      }

      @media only screen 
      and (device-width : 414px) 
      and (device-height : 896px) 
      {

        bottom: 18% !important;
      }


      @media only screen 
      and (device-width : 375px) 
      and (device-height : 812px) 
      {

        bottom: 18% !important;
      }
    }

    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-base-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-3dlight-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-highlight-color: ${(props) =>
      props.theme.backgroundColors.seventh};
    scrollbar-track-color: ${(props) => props.theme.backgroundColors.eighth};
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: ${(props) => props.theme.backgroundColors.seventh};
    scrollbar-dark-shadow-color: ${(props) =>
      props.theme.backgroundColors.seventh};

    ::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar {
      width: 6px;
      background: ${(props) => props.theme.backgroundColors.seventh};
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.backgroundColors.eighth};
    }

    .modal-dialog {

      @media only screen and (max-width: 428px) {
        margin-bottom: 50%;

      }

    }

    .status-blue {
      background: #EFF7FF;
      color: #0E82F8;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Rubik", sans-serif !important;
      font-weight: 500;
    }

    .status-pending {
      background: ${(props) => props.theme.statusBackgroundColors.secondary};
      color: ${(props) => props.theme.statusContentColors.secondary} !important;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Brandon Text Medium" !important;
    }

    .status-succeeded {
      background: ${(props) =>
        props.theme.statusBackgroundColors.primary} !important;
      color: ${(props) => props.theme.statusContentColors.primary} !important;
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Brandon Text Medium" !important;
    }

    .status-failed {
      background: ${(props) =>
        props.theme.statusBackgroundColors.third} !important;
      color: ${(props) => props.theme.statusContentColors.secondary};
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Brandon Text Medium" !important;
    }

    .status-canceled {
      background: ${(props) =>
        props.theme.statusBackgroundColors.fourth} !important;
      color: ${(props) => props.theme.statusContentColors.third};
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Brandon Text Medium" !important;
    }

    .status-deactivated {
      background: ${(props) =>
        props.theme.statusBackgroundColors.fourth} !important;
      color: ${(props) => props.theme.statusContentColors.third};
      padding: 2px 8px;
      border-radius: 2px;
      font-size: 12px !important;
      font-family: "Brandon Text Medium" !important;
    }

    .border-right {
      border-right: 1px solid ${(props) =>
        props.theme.backgroundColors.secondary};
    }

    .border-left {
      border-left: 1px solid ${(props) =>
        props.theme.backgroundColors.secondary};
    }

    .border-bottom {
      border-bottom: 1px solid ${(props) =>
        props.theme.backgroundColors.secondary};
    }

    .border-top {
      border-bottom: 1px solid ${(props) =>
        props.theme.backgroundColors.secondary};
    }

    .border-top--mobile {
      @media screen and (max-width: 768px) {
        border-top: 1px solid ${(props) =>
          props.theme.backgroundColors.secondary};
      }
    }

    .border-bottom--mobile {
      @media screen and (max-width: 768px) {
        border-bottom: 1px solid ${(props) =>
          props.theme.backgroundColors.secondary};
      }
    }

    .full-width {
      min-width: 100%;
    }

    .full-width-button {
      min-width: 100%;

      button {
        min-width: 100%;
      }
    }

    .margin-top-24 {
      margin-top: 24px;
    }

    .margin-bottom-24 {
      margin-bottom: 24px;
    }
    
    .tip-container, .tip-container--img-right {
      margin-bottom: 26px;

      div {
        display: flex;
        margin-top: 16px;

        img {
          margin-right: 4px;
        }

        p {
          font-size: 13px;
          color: ${(props) => props.theme.contentColors.secondary};
          font-family: "Brandon Text Medium" !important;
        }
      }

      p {
        font-size: 13px;
        color: ${(props) => props.theme.contentColors.primary};
      }

      a {
        color: ${(props) => props.theme.contentColors.fourth};
      }
    }

    .payment-method-container {
      margin-bottom: 24px;

      .tip-container--img-right {
        margin-bottom: 0px;

        div {
          margin-top: 0px;
          
          img {
            margin-left: 8px;
          }
        }
      
      }
    }

    .tip-link {
      cursor: pointer;

      img {
        margin-left: 9px;
      }
    }

    .checkbox-span, .radio-span {
      font-size: 14px;
      color: ${(props) => props.theme.contentColors.secondary};
      font-family: "Brandon Text Medium" !important;
    }

    .flex-buttons-container {
      display: flex;

      button {

        &:first-child {
          margin-right: 8px;
        }
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 6em;
      }

      
    }

    .flex-container {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 425px) {
        flex-wrap: wrap;
      }
    }

    .column-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .column-buttons-container {
      width: 100%;
      display: flex;
      height: 90px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
    }

    .input-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      h5 {
        font-size: 14px;
        margin-bottom: 8px;
        font-family: "Brandon Text Medium" !important;
        color: ${(props) => props.theme.contentColors.primary};
        display: flex;
        align-items: center;

        img {
          margin-left: 8px;
        }
      }
    }

    .ant-tour-header {
      padding: 16px !important;
    }

    .ant-tour-placeholder-animated {
      min-height: 600px !important;
    }

    .anticon-close, .ant-tour-footer {
      display: none !important;
    }

    .ant-tour {
      width: 366px !important;
    }

    /* .24margin-top--global {
      margin-top: 24px;
    } */

    .margintop24--global {
      margin-top: 24px;
    }

    .margin-bottom-100 {
      margin-bottom: 100px;
    }

    .filepond--credits {
      display: none !important;
    }

    .form-item--global {
      transition: all .2s ease-in-out;
      margin-bottom: 24px;

      .input-container {
        min-width: 291px;
        max-width: 291px;
      }

      h5 {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 14px;
        font-family: "Brandon Text Medium" !important;
        color: ${(props) => props.theme.contentColors.primary};
      }

      .form-item__optional {
        display: flex;

        p {
          margin-top: 16px;
          margin-bottom: 8px;
          font-size: 10px;
          margin-left: 16px;
          color: ${(props) => props.theme.contentColors.primary};
        }

        .tip-container {
        margin-top: 16px;
        margin-bottom: 8px;
        margin-left: 16px;
      }
      }
    }

    .hidden-desktop {
      display: none;

      @media screen and (max-width: 600px) {
        display: flex;
      }
    }

    .view-all__element {
      margin-left: auto;
      font-size: 14px;
      color: ${(props) => props.theme.primaryColors.primary};
      cursor: pointer;
    }

    .complete-height--minmax {
      min-height: 99vh;
    }

    .complete-height {
      min-height: calc(100vh - 48px);

      @media screen and (max-width: 768px) {
        min-height: auto;
      }
    }

    .success {
      color: #33C344;
      font-size: 1.2em;
    }

    .g-detail-item {
      display: flex;
      padding: 24px 0;
      border-bottom: 1px solid ${(props) => props.theme.backgroundColors.fifth};
      .g-detail-item__label {
        color: ${(props) => props.theme.contentColors.primary};
        width: 30%;
        font-size: 14px;
        align-items: center;
        font-family: "Brandon Text Medium" !important;
        @media screen and (max-width: 916px) {
          min-width: 30%;
          margin-right: 5px;
        }
      }
      .g-detail-item__value {
        color: ${(props) => props.theme.contentColors.secondary};
        font-size: 14px;
        display: flex;
        align-items: center;
        word-break: break-all;
        @media screen and (max-width: 1441px) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media screen and (max-width: 916px) {
          word-break: break-all;
          min-width: 60%;
        }
      }
    }

    .flex-row--centered {
      display: flex;
      flex-direction: row !important;
      align-items: center !important;

       p {
        color: #1B1B1B;
       }
    }

    .error {
      color: #ff0000;
      font-size: 1.2em;
    }

    #launcher {

      @media screen and (max-width: 916px) {
        height: 150px !important; 
      }
      
    }
  }

  * {
    font-family: "Brandon Text Regular", "Ubuntu", sans-serif !important;
    outline: none !important;
    margin: 0px;
    padding: 0px;
  }

  /*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

  body,
  html {
    height: 100%;
  }

  /* html {
    touch-action:none;
  } */

  /*---------------------------------------------*/
  a {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  a:focus {
    outline: none !important;
  }

  a:hover {
    text-decoration: none;
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }

  /*---------------------------------------------*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
  }

  p {
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
  }

  ul,
  li {
    margin: 0px;
    list-style-type: none;
  }

  /*---------------------------------------------*/
  input {
    outline: none;
    border: none;
  }

  textarea {
    outline: none;
    border: none;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }

  textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }
  textarea:focus:-moz-placeholder {
    color: transparent;
  }
  textarea:focus::-moz-placeholder {
    color: transparent;
  }
  textarea:focus:-ms-input-placeholder {
    color: transparent;
  }

  input::-webkit-input-placeholder {
    color: #adadad;
  }
  input:-moz-placeholder {
    color: #adadad;
  }
  input::-moz-placeholder {
    color: #adadad;
  }
  input:-ms-input-placeholder {
    color: #adadad;
  }

  textarea::-webkit-input-placeholder {
    color: #adadad;
  }
  textarea:-moz-placeholder {
    color: #adadad;
  }
  textarea::-moz-placeholder {
    color: #adadad;
  }
  textarea:-ms-input-placeholder {
    color: #adadad;
  }

  /*---------------------------------------------*/
  button {
    outline: none !important;
    border: none;
    background: transparent;
  }

  button:hover {
    cursor: pointer;
  }

  iframe {
    border: none !important;
  }

  .main-title {
    font-family: "Berlingske Serif Bold" !important;
    font-size: 30px;
    line-height: 36px;
    color: #fff;
    line-height: 36px;
  }

  .main-CTA--white {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 14px 36px;
    border-radius: 3px;
    height: 50px;
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to bottom, #FFF 0%, #F8F8F8 100%) border-box;
    border: 1px solid transparent;
    color: #FF7276;
    width: auto;
    text-decoration: none !important;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    span {
      font-family: "Brandon Text Bold" !important;
      font-size: 16px;
      color: #FF7276;
    }

    &:hover, &:active {
      transform: translate3d(1px, 1px, 0);
      background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(to bottom, #FFF 0%, #F8F8F8 100%) border-box;
      /* box-shadow: inset 0px 8px 8px rgba(0, 0, 0, 0.1); */
      transition: all 0.2s ease-in-out;
    }
  }
`;
