import styled, { css } from "styled-components";

interface IconProps {
  icon: string;
}

const financialBoxFontVariant = css`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.contentColors.primary};
`;

export const ContentContainer = styled.div`
  left: 220px;
  position: relative;
  width: calc((100% - 220px));
  padding: 48px 0px 0px 0px;
  background: ${(props) => props.theme.backgroundColors.primary};
  min-height: 100vh;
  padding-bottom: 10px;

  &.double-top-margin {
    padding: 96px 0px 0px 0px;
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .btn-small {
    width: auto !important;
  }

  .skeleton {
    background-color: ${(props) => props.theme.backgroundColors.fourth};
  }
  .arrow-right {
    img {
      transform: rotate(-90deg);
    }
  }

  .margin-left {
    margin-left: 10px;

    @media screen and (max-width: 916px) {
      margin-left: 0px;
    }
  }

  .search-container {
    padding: 1px;
    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.backgroundColors.seventh} 0%,
      ${(props) => props.theme.backgroundColors.eighth} 100%
    );
    border-radius: 3px;
    outline: none;
    width: auto;

    .search-wrapper {
      display: flex;
      align-items: center;
      width: 300px;
      /* padding: 8px 16px; */
      background: ${(props) => props.theme.backgroundColors.secondary};
      border-radius: 3px;
      position: relative;
      height: 34px;

      input {
        border: none;
        width: 100%;
        color: ${(props) => props.theme.textColors.primary};
        background: transparent;
      }

      @media screen and (max-width: 916px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 916px) {
      width: 90%;
      margin: 4em auto;
    }
  }

  input::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  .small-search-wrapper {
    width: 157px !important;
    padding: 0 10px;
    background: ${(props) => props.theme.backgroundColors.fourth};
    border-radius: 8px;
    position: relative;
    margin-left: 0px !important;
    .search-icon {
      height: 12px;
      width: 12px;
      position: absolute;
      right: 10px;
      top: calc(50% - 6px);
    }
    input {
      border: none;
      width: 100%;
      height: 42px;
      color: ${(props) => props.theme.textColors.primary};
      background: transparent;
    }
  }

  .padding-vertical-unset {
    padding-top: 0;
    padding-bottom: 0;
  }
  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1;
  }

  .m-left-25 {
    margin-left: 25px;
  }

  .m-right-12-5 {
    margin-right: 12.5px;
  }

  .m-left-12-5 {
    margin-left: 12.5px;
  }

  .w-100 {
    width: 100%;
  }

  .green {
    color: green !important;
  }

  .yellow {
    color: #ffc400 !important;
  }

  .red {
    color: #ff5660 !important;
  }

  .blocked-red {
    color: #ff0000 !important;
  }

  .div-status-customer {
    width: 50%;
  }

  .content-box,
  .content-box--v2 {
    background: ${(props) => props.theme.backgroundColors.primary};
    padding: 34px 60px 34px 60px;

    @media screen and (max-width: 426px) {
      padding: 16px;
    }
  }

  .content-box--v2 {
    display: flex;
    align-items: center;
    padding: 34px 0px 34px 0px;

    @media screen and (max-width: 1024px) {
      padding: 8px 60px 8px 60px;
    }

    @media screen and (max-width: 426px) {
      padding: 16px;
      margin: 0 auto;
    }
  }

  .content-box-live-map {
    padding: 0px !important;
    border-left: 1px solid ${(props) => props.theme.backgroundColors.secondary};

    @media screen and (max-width: 916px) {
      width: 100% !important;
    }
  }

  .list-box,
  .list-box--admin {
    flex-direction: row;
    flex: 1;

    @media screen and (max-width: 916px) {
      margin-left: 0px;
    }
  }

  .list-box--admin {
    margin-top: 0px;

    @media screen and (max-width: 916px) {
      margin-top: 25px;
    }
  }

  .map {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .content-box.list-box.map {
    display: flex;
  }

  .info-label {
    width: 100%;
    display: flex;
    flex-direction: column;

    .label {
      font-size: 1.2em;
    }

    .label--with-icon {
      display: flex;
      font-size: 14px;

      img {
        margin-left: 6px;
      }
    }

    .value {
      font-size: 2em;
      color: ${(props) => props.theme.contentColors.secondary};
    }
    
    .skeleton-container {
      padding: 0 16px 0 0;
    }

    .brl-amount {
      ${financialBoxFontVariant};
    }

    @media screen and (max-width: 916px) {
      flex-wrap: wrap;
    }
  }
  .pagination {
    width: 150px;
    border-radius: 30px;
    color: ${(props) => props.theme.textColors.primary};
    align-items: center;
    font-size: 1.2em;
    justify-content: space-between;
    padding: 2px;
    margin-top: 20px;

    .disabled {
      opacity: 0.5;
    }

    .processing {
      cursor: progress !important;
    }

    .arrow {
      cursor: pointer;

      &.left {
        transform: rotate(90deg);
      }
      &.right {
        transform: rotate(-90deg);
      }
      width: 25px;
    }

    img {
      width: 100%;
      height: 9px;
    }
  }

  @media screen and (max-width: 768px) {
    left: 0px;
    width: 100%;
  }
`;

export const UnfilledButtonIconMask = styled.button`
  border: 1.5px solid ${(props) => props.theme.backgroundColors.primary} !important;
  background: transparent !important;
  width: auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.backgroundColors.primary};
  border-radius: 3px;
  height: 36px;
  position: relative;
  text-align: left;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  &:hover {
    color: ${(props) => props.theme.backgroundColors.primary} !important;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media screen and (max-width: 1108px) {
    padding: 8px 8px;
  }
`;

export const IconDiv = styled.div<IconProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  width: 22px;
  height: 22px;
  margin-right: 8px;
  border-radius: 60%;
`;

export const LogoDiv = styled.div<IconProps>`
  margin-left: 2px;
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  width: 100px;
  height: 25px;

  @media screen and (max-width: 916px) {
    width: 80px;
  }

  @media screen and (max-width: 321px) {
    width: 53px;
  }
`;

export const SearchIcon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url("https://image.flaticon.com/icons/svg/1086/1086933.svg")
    no-repeat center;
  mask: url("https://image.flaticon.com/icons/svg/1086/1086933.svg") no-repeat
    center;
  mask-size: contain;
  width: 18px;
  height: 18px;
`;

export const UnfilledButton = styled.button`
  border: 1.5px solid ${(props) => props.theme.backgroundColors.sixth} !important;
  background: transparent !important;
  width: auto !important;
  padding-right: 3em !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.textColors.primary};
  border-radius: 19px;
  height: 35px;
  position: relative;
  font-size: 1.2em;
  text-align: left;
  padding-left: 15px;

  img {
    width: 22px;
    position: absolute;
    right: 5px;
    top: calc(50% - 11px);
  }

  &:hover {
    color: ${(props) => props.theme.textColors.primary} !important;
  }
`;

export const SelectDefault = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  padding-right: 20px;

  img {
    width: 15px;
    height: 10px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .select-label {
    font-size: 1.2em;
    color: #a0a0a0;
    margin-right: 1em;
  }
  select {
    height: 34px;
    appearance: none;
    background: transparent;
    background: url("/images/arrow-down-trick.svg") center right no-repeat;
    background-size: 35px 10px;
    border: none;
    color: ${(props) => props.theme.textColors.primary};
    outline: none;
    font-size: 1.2em;
  }

  .container-options-select {
    background-color: ${(props) => props.theme.backgroundColors.fourth};
    padding: 0.5em 3em 0.5em 1.5em;
    border-radius: 8px;
    text-align: left;
    text-align-last: left;
  }
`;

export const InputWrapper = styled.div`
  width: 100% !important;
  padding: 1px;
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.theme.backgroundColors.seventh} 0%,
    ${(props) => props.theme.backgroundColors.eighth} 100%
  );
  border-radius: 3px;
  outline: none;
  max-height: 36px;

  input,
  textarea {
    background: ${(props) => props.theme.backgroundColors.fourth};
    width: 220px;
    height: 34px;
    border: none;
    border-radius: 3px;
    color: ${(props) => props.theme.textColors.primary};
    outline: none;
    padding: 0 15px;
  }

  .hide-show {
    cursor: pointer;
    transition: all 0.3s;
  }

  textarea {
    height: 35px;
    resize: none;
    width: 100%;

    @media screen and (max-width: 916px) {
      height: 35px;
    }
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 1;
    filter: invert(0.8);
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 1;
    filter: invert(0.8);
  }
`;

export const IconItem = styled.div<IconProps>`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const WrapperInputWithLinearBorder = styled.div`
  background: linear-gradient(
        ${(props) => props.theme.backgroundColors.fourth},
        ${(props) => props.theme.backgroundColors.fourth}
      )
      padding-box,
    linear-gradient(
        to bottom,
        ${(props) => props.theme.backgroundColors.seventh},
        ${(props) => props.theme.backgroundColors.eighth}
      )
      border-box;
  border-radius: 3px;
  border: 1px solid transparent;
  width: 100%;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;

  .div-input {
    width: 90% !important;

    input {
      font-size: 14px;
      max-height: 34px !important;
      background-color: transparent !important;
      color: ${(props) => props.theme.textColors.primary};
    }
  }
`;

export const WrapperInput = styled.div`
  background: linear-gradient(
        ${(props) => props.theme.backgroundColors.fourth},
        ${(props) => props.theme.backgroundColors.fourth}
      )
      padding-box,
    linear-gradient(
        to bottom,
        ${(props) => props.theme.backgroundColors.seventh},
        ${(props) => props.theme.backgroundColors.eighth}
      )
      border-box;
  border-radius: 3px;
  border: 1px solid transparent;
  /* min-height: 36px; */
  display: flex;
  width: 343px;

  .float-placeholder {
    position: relative;
    display: flex;
    padding-top: 0px !important;
    width: 90%;

    input {
      font-size: 14px;
      background: ${(props) => props.theme.backgroundColors.fourth};
      width: 100%;
      min-height: 36px;
      max-height: 36px;
      padding: 0 8px 0 8px;
      color: ${(props) => props.theme.textColors.primary};

      &::placeholder {
        color: transparent;
      }
    }

    label {
      font-family: "Brandon Text Light";
      font-size: 14px;
      pointer-events: none;
      color: ${(props) => props.theme.textColors.primary};
      padding-left: 9px;
      position: absolute;
      top: 0;
      margin-top: 8px;
      transition: all 0.3s ease-out;
    }

    input:focus + label,
    input:not(:placeholder-shown) + label {
      font-size: 8px;
      margin-top: 2px;
    }
  }

  .div-input {
    /* width: 90% !important; */
    display: flex;
    align-items: center;

    input {
      font-size: 14px;
      background: ${(props) => props.theme.backgroundColors.fourth};
      width: 90%;
      min-height: 36px;
      max-height: 36px;
      padding: 0 8px 0 8px;
      color: ${(props) => props.theme.textColors.primary};
    }
  }
`;

export const InputContainer = styled(WrapperInput)`
  width: 100%;
`;

export const LogoContainer = styled.div`
  a {
    display: flex;

    img {
      width: auto;
      height: 25px;

      @media screen and (max-width: 916px) {
        width: 60px;
        margin-right: 0 !important;
      }

      @media screen and (max-width: 321px) {
        width: 40px;
      }
    }
  }
`;

export const LogoContainerLogin = styled(LogoContainer)`
  a {
    img {
      margin-bottom: 30px;
      min-width: 117px !important;
      min-height: 30px !important;
    }
  }
`;
