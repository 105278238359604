import React from "react";
import { useIntl } from "react-intl";
import { ErrorMessage } from "@hookform/error-message";

import Buttons from "../../../../components/Forms/Buttons";
import InputFloat from "../../../../components/Forms/InputFloatLabel";

import * as styled from "../../styles";

import { usePersonalInformation } from "./hooks";

export const PersonalInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  const {
    Controller,
    control,
    handleSubmit,
    submitPersonalData,
    isProcessing,
    errors,
  } = usePersonalInformation({ setProgressStep, isStoreWithCNPJ, store });

  return (
    <>
      <form onSubmit={handleSubmit(submitPersonalData)}>
        <div className="form-item">
          <label>CPF</label>
          <Controller
            name="identificationNumber"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal-mask"
                mask={"999.999.999-99"}
                handler={() => {}}
                // disabled={isStoreWithCNPJ}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="identificationNumber"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        {isStoreWithCNPJ ? (
          <>
            <div className="form-item">
              <label>
                {intl.formatMessage({
                  id: "signup.form.full.name",
                })}
              </label>

              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <InputFloat mode="normal" handler={() => {}} {...field} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="fullName"
                render={({ message }) => <p className="error">{message}</p>}
              />
            </div>
          </>
        ) : null}

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.birth",
            })}
          </label>

          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal-mask-for-rhf"
                mask="99/99/9999"
                handler={() => {}}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="birthdate"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "request.payment.boleto.phone",
            })}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputFloat
                mode="normal-mask-for-rhf"
                mask="(99) 99999-9999"
                handler={() => {}}
                // maxLength={15}
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <styled.ButtonFooterContainer>
          <Buttons
            mode={!isProcessing ? "filled" : "filled-processing"}
            label={intl.formatMessage({
              id: "login.button.login.continue",
            })}
            type="submit"
            handler={() => {}}
          />
        </styled.ButtonFooterContainer>
      </form>
    </>
  );
};
