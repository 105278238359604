import React from "react";
import { useIntl } from "react-intl";
import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import Buttons from "../../../../components/Forms/Buttons";

import * as styled from "../../styles";

import { useDocumentsInformation } from "./hooks";

export const DocumentsInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
}: any) => {
  // Register the plugins
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType
  );

  const intl = useIntl();

  const {
    handleSubmit,
    submitBusinessData,
    incorporationDocument,
    setIncorporationDocument,
    proofOfActivity,
    setProofOfActivity,
    identificationDocument,
    setIdentificationDocument,
    proofOfAddress,
    setProofOfAddress,
    additionalDocuments,
    setAdditionalDocuments,
    isProcessing,
    globalErrors,
  } = useDocumentsInformation({ setProgressStep, isStoreWithCNPJ });

  return (
    <>
      <form onSubmit={handleSubmit(submitBusinessData)}>
        {isStoreWithCNPJ ? (
          <>
            <div className="form-item">
              <label>
                {intl.formatMessage({
                  id: "finish.register.incorporation.document.label",
                })}
              </label>

              <div className="filepond-container">
                <FilePond
                  labelIdle={intl.formatMessage({
                    id: "finish.register.documents.label",
                  })}
                  files={incorporationDocument}
                  onupdatefiles={setIncorporationDocument}
                  allowMultiple={false}
                  acceptedFileTypes={[
                    "image/png",
                    "image/jpeg",
                    "image/svg",
                    "image/jpg",
                    "application/pdf",
                  ]}
                  maxFiles={2}
                  name="logo"
                ></FilePond>
                {globalErrors.includes("incorporationDocument") ? (
                  <p className="error">
                    {intl.formatMessage({
                      id: "webhook.error.message.default",
                    })}
                  </p>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "finish.register.documents.proof.activity",
            })}
          </label>

          <div className="filepond-container">
            <FilePond
              labelIdle={intl.formatMessage({
                id: "finish.register.documents.label",
              })}
              files={proofOfActivity}
              onupdatefiles={setProofOfActivity}
              allowMultiple={false}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "image/svg",
                "image/jpg",
                "application/pdf",
              ]}
              maxFiles={2}
              name="logo"
            ></FilePond>
            {globalErrors.includes("proofOfActivity") ? (
              <p className="error">
                {intl.formatMessage({ id: "webhook.error.message.default" })}
              </p>
            ) : null}
          </div>
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: isStoreWithCNPJ
                ? "finish.register.documents.proof.partner"
                : "finish.register.documents.proof.personal",
            })}
          </label>

          <div className="filepond-container">
            <FilePond
              labelIdle={intl.formatMessage({
                id: "finish.register.documents.label",
              })}
              files={identificationDocument}
              onupdatefiles={setIdentificationDocument}
              allowMultiple={false}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "image/svg",
                "image/jpg",
                "application/pdf",
              ]}
              maxFiles={2}
              name="logo"
            ></FilePond>
            {globalErrors.includes("identificationDocument") ? (
              <p className="error">
                {intl.formatMessage({ id: "webhook.error.message.default" })}
              </p>
            ) : null}
          </div>
        </div>

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: isStoreWithCNPJ
                ? "finish.register.documents.proof.address.partner"
                : "finish.register.documents.proof.address",
            })}
          </label>

          <div className="filepond-container">
            <FilePond
              labelIdle={intl.formatMessage({
                id: "finish.register.documents.label",
              })}
              files={proofOfAddress}
              onupdatefiles={setProofOfAddress}
              allowMultiple={false}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "image/svg",
                "image/jpg",
                "application/pdf",
              ]}
              maxFiles={1}
              name="logo"
            ></FilePond>
            {globalErrors.includes("proofOfAddress") ? (
              <p className="error">
                {intl.formatMessage({ id: "webhook.error.message.default" })}
              </p>
            ) : null}
          </div>
        </div>
        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "finish.register.documents.additional.documents",
            })}{" "}
            <span>
              {intl.formatMessage({
                id: "request.payment.optional.label",
              })}
            </span>
          </label>

          <div className="filepond-container">
            <FilePond
              labelIdle={intl.formatMessage({
                id: "finish.register.documents.label",
              })}
              files={additionalDocuments}
              onupdatefiles={setAdditionalDocuments}
              allowMultiple={false}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "image/svg",
                "image/jpg",
                "application/pdf",
              ]}
              maxFiles={5}
              name="logo"
            ></FilePond>
            {globalErrors.includes("additionalDocuments") && isStoreWithCNPJ ? (
              <p className="error">
                {intl.formatMessage({ id: "webhook.error.message.default" })}
              </p>
            ) : null}
          </div>
        </div>

        <styled.ButtonFooterContainer>
          <Buttons
            mode={!isProcessing ? "filled" : "filled-processing"}
            label={intl.formatMessage({
              id: "finish.register",
            })}
            type="submit"
            handler={() => {}}
          />
        </styled.ButtonFooterContainer>
      </form>
    </>
  );
};
