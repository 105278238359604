import axios from "axios";

import { useAuth } from "../store/auth";

export const StoreRegisterServices = () => {
  const { session } = useAuth((state) => state);

  const personalData = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup/stores/${session.storeId}/owner-details/`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const businessData = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup/stores/${session.storeId}/business-details`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const addressData = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup/stores/${session.storeId}/address`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const bankAccountData = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/bank_accounts`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const documentsData = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup/stores/${session.storeId}/documents`,

      [...data],

      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const getStoreDetails = async () => {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  return {
    personalData,
    businessData,
    addressData,
    bankAccountData,
    documentsData,
    getStoreDetails,
  };
};
