import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { ApexOptions } from "apexcharts";

//Services
import ReportsServices from "services/ReportsCSV";

export default function RefundsChart() {
  const [refundsObjects, setRefundsObjects] = useState<any[]>([]);
  const [refundsValues, setRefundsValues] = useState<number[]>([]);

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];
  const [monthLabels, setMonthLabels] = useState<string[]>([]);
  const intl = useIntl();

  useEffect(() => {
    ReportsServices.getRefunds().then((response) => {
      let refundsResults: any[] = [];

      response.data.forEach((item: any) => {
        let amount = 0;

        item.payments.forEach((payment: any) => {
          if (payment.Credit) {
            amount = amount + payment.Credit;
          }

          if (payment.Boleto) {
            amount = amount + payment.Boleto;
          }

          if (payment.Pix) {
            amount = amount + payment.Pix;
          }
        });

        refundsResults.push({ month: item.month, amount: amount });
      });

      setRefundsObjects(refundsResults);
    });
  }, []);

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1;

    setMonthLabels(months.splice(0, currentMonth + 1));

    const refundsChartValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let index = 0; index <= currentMonth; index++) {
      refundsObjects.forEach((item) => {
        if (item.month === index) {
          refundsChartValues[index - 1] = item.amount;
        }
      });
    }

    setRefundsValues(refundsChartValues.splice(0, currentMonth + 1));
  }, [refundsObjects]);

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    xaxis: {
      categories: monthLabels,
    },
    yaxis: {
      show: false,
    },
    colors: ["#FF7276", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
  };

  const series = [
    {
      name: `${intl.formatMessage({ id: "reports.refunds.title" })}`,
      data: refundsValues,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="250"
    />
  );
}
