import { useEffect } from "react";

function useClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  stateIsMounted: boolean,
  handleIsMounted: any
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        handleIsMounted(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, stateIsMounted]);
}

export default useClickOutside;
