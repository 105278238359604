import axios from "axios";

interface Store {
  identificationNumber: string;
  name: string;
  socialName: string;
  phone: string;
  address: {
    street: string;
    number: string;
    zipcode: string;
    reference: string;
    district: string;
    city: string;
    state: string;
    country: number;
  };
  logoPath: string;
  owner: {
    firstName: string;
    surname: string;
    email: string;
    identificationNumber: string;
    birthdate: string;
    phone: string;
    address: {
      street: string;
      number: string;
      zipcode: string;
      reference: string;
      district: string;
      city: string;
      state: string;
      country: number;
    };
  };
  mcc: number;
}

interface ISignup {
  plan: string;
  identificationNumber: string;
  name: string;
  email: string;
  password: string;
}

class CreateStoreService {
  async singup(user: ISignup, endpoint: string) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/signup${
        endpoint && endpoint !== "" ? `?${endpoint}` : ""
      }`,
      {
        plan: user.plan,
        identificationNumber: user.identificationNumber,
        name: user.name,
        email: user.email,
        password: user.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  async createStore(store: Store) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/store/create`,
      {
        identificationNumber: store.identificationNumber,
        name: store.name,
        socialName: store.socialName,
        phone: store.phone,
        address: {
          street: store.address.street,
          number: store.address.number,
          zipcode: store.address.zipcode,
          reference: "",
          district: store.address.district,
          city: store.address.city,
          state: store.address.state,
          country: 0,
        },
        logoPath: store.logoPath,
        owner: {
          firstName: store.owner.firstName,
          surname: store.owner.surname,
          email: store.owner.email,
          identificationNumber: store.owner.identificationNumber,
          birthdate: store.owner.birthdate,
          phone: store.owner.phone,
          address: {
            street: store.owner.address.street,
            number: store.owner.address.number,
            zipcode: store.owner.address.zipcode,
            reference: "",
            district: store.owner.address.district,
            city: store.owner.address.city,
            state: store.owner.address.state,
            country: 0,
          },
        },
        mcc: store.mcc,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async sendDocs(documents: any, storeId: number, fileTypes: any) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/store/documents`,
      {
        storeId: storeId,
        fileTypes: fileTypes,
        document: documents,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async getCategories() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `https://api.zoop.ws/v1/merchant_category_codes?limit=300`,
      {
        headers: {
          Authorization: `Basic enBrX3Byb2RfU1FLMHFhdXA1YUQwSWtudHViWGdibVFBOg==`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async insertBankAccount(
    id: number,
    holderName: string,
    bankCode: string,
    routingNumber: string,
    accountNumber: string,
    identificationNumber: string
  ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${id}/bank_accounts`,
      {
        holderName: holderName,
        bankCode: bankCode,
        routingNumber: routingNumber,
        accountNumber: accountNumber,
        identificationNumber: identificationNumber,
        type: "checking",
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new CreateStoreService();
