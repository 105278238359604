import React from "react";

//Components
import { LayoutWrapper } from "components/Layout/Index";
import BoxDetails from "components/Details/BoxDetails";

//Hooks
import { useValidateSession } from "../hooks/useValidateSession";

//Styles
import * as indexStyled from "../styles/index-styles";

function Transactions() {
  useValidateSession();

  return (
    <LayoutWrapper>
      <indexStyled.RowList className=" transactions_details">
        <BoxDetails />
      </indexStyled.RowList>
    </LayoutWrapper>
  );
}

export default Transactions;
