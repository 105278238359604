import React, { createContext, useState } from "react";
import dark from '../styles/themes/dark';
import light from '../styles/themes/light';

export const ToggleTemaContext = createContext<any>({});

const ToggleTemaProvider = ({ children }: any) => {
  const [theme, setTheme] = useState(() => {
    const themeStorage = localStorage.getItem('premepay-dashboard-theme');

    const { premepayDashboardTheme } = JSON.parse(themeStorage ? themeStorage : '{}');

    if(premepayDashboardTheme === 'light'){
        return light;
    }

    return dark;
  });

  // useEffect(() => {
  //   localStorage.setItem('premepay-dashboard-theme', JSON.stringify({ premepayDashboardTheme: theme.title }));
  // }, [theme]);

  const toggleTheme = () => {
    localStorage.setItem('premepay-dashboard-theme', JSON.stringify({ premepayDashboardTheme: theme.title === 'dark' ? 'light' : 'dark' }));
    setTheme(theme.title === 'dark' ? light : dark);
  }

  return (
    <ToggleTemaContext.Provider value={{theme, toggleTheme}}>
      {children}
    </ToggleTemaContext.Provider>

  )
}

export default ToggleTemaProvider;