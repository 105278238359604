import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { ApexOptions } from "apexcharts";

//Services
import OrderService from "services/Order";

interface IProps {
  recentOrders?: any;
}

interface IState {
  data: number[];
}

export default function SingleChart(props: any) {
  const [data, setData] = useState<number[]>([]);

  const intl = useIntl();

  useEffect(() => {
    getDayData();
  }, [props.recentOrders]);

  function getDayData() {
    const currentMonth = new Date().getMonth() + 1;

    OrderService.getCountPerMonth()
      .then((response) => {
        let newData: number[] = [];
        for (let index = 1; index <= currentMonth; index++) {
          let month = response.data.find((x: any) => x.Month == index);
          if (month === undefined) {
            newData[index - 1] = 0;
          } else {
            newData[index - 1] = month.Count;
          }
        }
        setData(newData);
      })
      .catch((err) => console.log(err));
  }

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    colors: ["#FF7276", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
  };

  const series = [
    {
      name: `${intl.formatMessage({ id: "reports.sales.title" })}`,
      data: data,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      width="100%"
      height="250"
    />
  );
}
