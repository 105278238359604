import styled from "styled-components";

export const LoginSection = styled.section`
  background: ${(props) => props.theme.secondaryBackgroundColors.sixth};
`;

export const Limiter = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const ContainerLogin100 = styled.div`
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  flex-direction: column;
  /* background: #282731; */
`;

export const WrapLogin100 = styled.div`
  min-height: 400px;
  width: 408px;
  background: ${(props) => props.theme.secondaryBackgroundColors.sixth};
  border-radius: 10px;
  overflow: hidden;
  padding: 24px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);

  @media (max-width: 916px) {
    padding: 24px;
    width: 311px !important;
  }
`;

export const Login100Form = styled.form`
  width: 100%;

  p {
    margin-top: 8px;
  }
`;

export const Login100FormTitle = styled.span`
  font-size: 12px;
  left: 0px;
  font-weight: bold;
  color: #000;
`;

export const Login100FormTitleI = styled.i`
  font-size: 60px;
`;

/*------------------------------------------------------------------
[ Input ]*/

export const WrapInput100 = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-item {
    transition: all 0.2s ease-in-out;

    h5 {
      margin-top: 32px;
      margin-bottom: 8px;
      font-size: 14px;
      font-family: "Brandon Text Medium" !important;
      color: ${(props) => props.theme.contentColors.primary};
    }
  }
`;

export const RecoverPass = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;

  a {
    color: #000;
    cursor: pointer;

    :hover {
      color: #ff7276 !important;
    }
  }
`;

export const FocusInput100 = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  &:after {
    font-size: 15px;
    color: #999999;
    line-height: 1.2;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;
    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    background: #6a7dfe;
    background: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    background: -o-linear-gradient(left, #21d4fd, #b721ff);
    background: -moz-linear-gradient(left, #21d4fd, #b721ff);
    background: linear-gradient(left, #21d4fd, #b721ff);
  }
`;

/*---------------------------------------------*/

/*------------------------------------------------------------------
[ Button ]*/

export const ContainerLogin100FormBtn = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
`;

export const WrapLogin100FormBtn = styled.div`
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
`;

export const Login100FormBgBtn = styled.div`
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: ${(props) => props.theme.backgroundColors.primary};
  /* background: -webkit-linear-gradient(right, #fff, #f4747e, #f4747e, #f4747e);
  background: -o-linear-gradient(right, #fff, #f4747e, #fff, #f4747e);
  background: -moz-linear-gradient(right, #fff, #f4747e, #21d4fd, #f4747e);
  background: linear-gradient(right, #fff, #f4747e, #fff, #f4747e); */
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
`;

export const Login100FormBtn = styled.button`
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
`;

export const CreateAccountCall = styled.div`
  margin-top: 24px;
  color: #000;

  p {
    color: #000;
  }

  a {
    color: #000;
    cursor: pointer;

    :hover {
      color: #ff7276 !important;
    }
  }
`;

/*---------------------------------------------*/

export const ErrorMessage = styled.p`
  color: #ff0000;
  margin: 0px !important;
  position: relative;
  top: -5px;
`;

export const SuccessMessage = styled.p`
  color: #30b935;
  margin: 0px !important;
  position: relative;
  top: -5px;
`;

export const RecaptchaContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: center;
`;

export const CountDownContainer = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-top: 30px;
  }

  p {
    margin-top: 0;
    line-height: 1.2;
    color: #ff2230;
  }
`;
