import { useState, useEffect } from "react";

//Services
import CustomerServices from "services/Customers";
import RequestPaymentServices from "services/RequestPayment";

//Types
import { Customer, AllPlans } from "../types";

export const usePlans = () => {
  const [isItemsLoaded, setIsItemsLoaded] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [plans, setPlans] = useState<AllPlans>();
  const [numberTotalOfPlans, setNumberTotalOfPlans] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [startShowOrders, setStartShowOrders] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [isWaitingDownload, setIsWaitingDownload] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [percentageDownload, setPercentageDownload] = useState(0);

  const [searchedCustomer, setSearchedCustomer] = useState({
    type: "email",
    value: "",
  });

  const frequencies = [
    "daily",
    "weekly",
    "monthly",
    "quarterly",
    "halfYearly",
    "yearly",
  ];

  useEffect(() => {
    getPlans();
  }, [pageIndex]);

  useEffect(() => {
    if (percentageDownload === 100) {
      setTimeout(() => {
        setIsWaitingDownload(false);

        setPercentageDownload(0);
      }, 1000);
    }
  }, [percentageDownload]);

  function getPlans() {
    setPageIndexProcessing(true);
    setIsItemsLoaded(false);

    RequestPaymentServices.requestPlans().then((response) => {
      setPlans(response.data);

      setPageIndexProcessing(false);
      setNumberTotalOfPlans(response.data.total);
      setIsItemsLoaded(true);
    });

    // CustomerServices.listAllCustomers({
    //   pageSize: 100,
    //   pageIndex: pageIndex,
    //   query:
    //     searchedCustomer.value !== ""
    //       ? `&${searchedCustomer.type}=${searchedCustomer.value}`
    //       : "",
    // }).then((response) => {
    //   let allCustomers: ICustomer[] = [];

    //   setCustomers(allCustomers);

    // });
  }

  function handleSearchedCustomer(name: string, value: string) {
    setSearchedCustomer((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  return {
    isSearch,
    searchedCustomer,
    isWaitingDownload,
    percentageDownload,
    showNotFound,
    plans,
    isItemsLoaded,
    pageIndex,
    setPageIndex,
    startShowOrders,
    pageIndexProcessing,
    numberTotalOfPlans,
    handleSearchedCustomer,
    getPlans,
    frequencies,
  };
};
