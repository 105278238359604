import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

type PlanProps = {
  newPlan: any;
  progressStep: number;
  handleErrors: any;
};

export function planValidations({
  newPlan,
  progressStep,
  handleErrors,
}: PlanProps) {
  dayjs.extend(customParseFormat);

  let errors = [];

  if (newPlan.name.length === 0) errors.push("planName");

  if (parseFloat(newPlan.amount) <= 0 || !newPlan.amount)
    errors.push("planAmount");

  // if (newPlan.grace !== "" && progressStep > 2) {
  //   const formattedDate = dayjs(newPlan.grace).format("DD/MM/YYYY");
  //   const isValidDate = dayjs(newPlan.grace, "DD/MM/YYYY", true).isValid();

  //   const isGracePeriodBefore = dayjs(formattedDate).isBefore(dayjs());

  //   if (!isValidDate || isGracePeriodBefore) {
  //     errors.push("invalidGracePeriod");
  //   }
  // }

  handleErrors(errors);

  return errors.length === 0;
}
