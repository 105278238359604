import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Controller, useForm, FieldValues } from "react-hook-form";

//Services
import { MemberkitServices } from "services/Memberkit";

import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Buttons from "../../Forms/Buttons";
import UserProfile from "../TopBar/UserProfile";

// Store
import { useSettings } from "../../../store/settings";

//Components
import InputFloat from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";

//Assets
import blueInfoIcon from "assets/images/settings/blue-info.svg";
import greyInfoIcon from "assets/images/settings/grey-info.svg";
import closeIcon from "assets/images/settings/close.svg";
import defaultProductImage from "assets/images/settings/default-product-image.svg";

//Styles
import * as styled from "./modals-styles";

interface ICourse {
  externalId: number;
  image_url: string;
  name: string;
}

const MemberkitButtonModal = (props: any) => {
  const { registerConnector, importCourses, integrate } = MemberkitServices();

  const [isMounted, setIsMounted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const [isIntegrating, setIsIntegrating] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [connectingStep, setConnectingStep] = useState(1);
  const [connectorId, setConnectorId] = useState(0);

  const [apiKey, setApiKey] = useState("");
  const [courses, setCourses] = useState([]);

  const { environment } = useSettings((state) => state.settings);

  const [isProd, setIsProd] = useState(() => {
    if (environment === "prod") {
      return true;
    }

    return false;
  });

  const memberkitModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function handleShowModal() {
    if (memberkitModalRef.current) {
      setIsMounted(false);
      setShowModal(true);
    } else {
      setIsMounted(true);
      setShowModal(true);
    }
  }

  useClickOutside(memberkitModalRef, isMounted, setIsMounted);

  const submitConnector = () => {
    setIsConnecting(true);
    setError(false);

    const newConnector = {
      apiKey: apiKey,
      name: "memberkit",
      tag: "memberkit",
    };

    registerConnector(newConnector)
      .then((response: any) => {
        setIsConnecting(false);
        setConnectingStep(2);
        setConnectorId(response.data.id);

        importCourses(response.data.id)
          .then((response: any) => {
            setIsConnecting(false);
            setCourses(response.data);
            setIsConnected(true);
          })
          .catch((err: any) => {
            console.log(err);
            setIsConnecting(false);
            setError(true);
          });
      })
      .catch((err: any) => {
        setIsConnecting(false);
        console.log(err);
      });
  };

  const submitIntegration = (data: FieldValues) => {
    setIsIntegrating(true);

    const newCoursesWithValues = [];

    for (const [key, value] of Object.entries(data)) {
      const newCourseValue = {
        externalId: parseInt(key),
        unitPrice: value,
      };

      newCoursesWithValues.push(newCourseValue);
    }

    integrate(newCoursesWithValues, connectorId)
      .then((response: any) => {
        history.push("/products");
        setIsIntegrating(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsIntegrating(false);
      });
  };

  return (
    <>
      <div>
        <Buttons
          mode="unfilled"
          label={intl.formatMessage({
            // id: `settings.integrations.connect${props.isConnected ? "ed" : ""}`,
            id: `settings.integrations.connect`,
          })}
          handler={handleShowModal}
        />
      </div>

      {showModal && (
        <styled.MemberkitContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={memberkitModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowModal(false);
          }}
        >
          <div className="modal-centered">
            <div className="modal-container">
              <div className="header">
                <div>
                  <h5>
                    {intl.formatMessage({
                      id: "settings.memberkit.modal.title",
                    })}
                  </h5>
                </div>
                <div>
                  <img
                    src={closeIcon}
                    alt="close"
                    onClick={() => setShowModal(false)}
                  />
                </div>
              </div>

              {isConnected ? (
                <>
                  <form onSubmit={handleSubmit(submitIntegration)}>
                    <div className="form">
                      <strong>
                        {intl.formatMessage({
                          id: "settings.memberkit.modal.success.connection.label",
                        })}
                      </strong>
                      <p>
                        {intl.formatMessage({
                          id: "settings.memberkit.modal.success.connection.desc",
                        })}
                      </p>

                      <div className="grey-info-container">
                        <div>
                          <img src={greyInfoIcon} alt="info" />
                        </div>
                        <div>
                          <strong>
                            {intl.formatMessage({
                              id: "settings.memberkit.modal.do.you.sell.title",
                            })}
                          </strong>
                          <p>
                            {intl.formatMessage({
                              id: "settings.memberkit.modal.do.you.sell.desc",
                            })}
                          </p>
                        </div>
                      </div>

                      <div className="form-container">
                        <div className="form-item">
                          <div className="courses">
                            <div className="course__item">
                              <label className="course__left">
                                {intl.formatMessage({
                                  id: "settings.memberkit.modal.course.integration",
                                })}
                              </label>
                              <label className="course__right">
                                {intl.formatMessage({
                                  id: "settings.memberkit.modal.price.integration",
                                })}
                              </label>
                            </div>
                          </div>

                          <div className="courses">
                            {courses && courses.length > 0
                              ? courses.map((course: ICourse) => (
                                  <div className="course__item">
                                    <div className="course__left">
                                      <styled.Background
                                        bg={
                                          course.image_url ||
                                          course.image_url !== ""
                                            ? course.image_url
                                            : defaultProductImage
                                        }
                                      />

                                      <p>{course.name}</p>
                                    </div>

                                    <div className="form-item select-and-input-container">
                                      <div className="m-20-width">
                                        <Select
                                          mode="clean-normal-child"
                                          disabled={true}
                                          name="currency"
                                          placeholder=""
                                          value="BRL"
                                          handler={() => {}}
                                        >
                                          <option
                                            className="bold-option"
                                            key="BRL"
                                            value="BRL"
                                          >
                                            R$
                                          </option>
                                        </Select>
                                      </div>

                                      <div className="m-half-width">
                                        <Controller
                                          name={course.externalId.toString()}
                                          control={control}
                                          render={({ field }) => (
                                            <InputFloat
                                              disableCurrencyInput={false}
                                              mode="normal-currency"
                                              handler={() => {}}
                                              isInputWithoutBorder={true}
                                              {...field}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="footer">
                      <Buttons
                        mode={!isIntegrating ? "filled" : "filled-processing"}
                        label={intl.formatMessage({
                          id: "settings.memberkit.modal.finish.integration",
                        })}
                        type="submit"
                        handler={() => {}}
                      />
                    </div>
                  </form>
                </>
              ) : (
                <>
                  {isConnecting ? (
                    <div className="loading-connecting-container">
                      <div className="spinner-container">
                        <div className="spinner"></div>
                        <p>
                          {intl.formatMessage({
                            id:
                              connectingStep === 1
                                ? "settings.memberkit.modal.connecting.label"
                                : "settings.memberkit.modal.connecting.label.2",
                          })}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="form">
                        <strong>
                          {intl.formatMessage({
                            id: "settings.memberkit.modal.key.label",
                          })}
                        </strong>

                        <div className="blue-info-container">
                          <img src={blueInfoIcon} alt="info" />
                          <p>
                            <a>
                              {intl.formatMessage({
                                id: "settings.memberkit.modal.where.key.label.1",
                              })}
                            </a>
                            {intl.formatMessage({
                              id: "settings.memberkit.modal.where.key.label.2",
                            })}
                          </p>
                        </div>

                        <form>
                          <div className="form-container">
                            <div className="form-item">
                              <label>
                                {intl.formatMessage({
                                  id: "settings.memberkit.modal.api.key.label",
                                })}
                              </label>

                              <InputFloat
                                mode="normal"
                                placeholder={intl.formatMessage({
                                  id: "settings.memberkit.modal.api.secret.key.label",
                                })}
                                disabled={false}
                                value={apiKey}
                                handler={setApiKey}
                              />

                              {error ? (
                                <p className="error">
                                  {intl.formatMessage({
                                    id: "memberkit.error.register",
                                  })}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="footer">
                        <Buttons
                          mode="unfilled"
                          label={intl.formatMessage({
                            id: "settings.memberkit.modal.cancel.label",
                          })}
                          handler={handleShowModal}
                        />
                        <Buttons
                          mode="filled"
                          label={intl.formatMessage({
                            id: "settings.memberkit.modal.add.label",
                          })}
                          handler={submitConnector}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </styled.MemberkitContainer>
      )}
    </>
  );
};

export default MemberkitButtonModal;
