import { useState, useEffect } from "react";
import { Controller, useForm, FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

// Store
import { useSettings } from "../../../../../store/settings";

import { StoreRegisterServices } from "../../../../../services/StoreRegister";

import banksAndCodes from "../../../../../helpers/banksAndCodes";

import noLogoBank from "../../../../../assets/images/icon-banco-nologo.svg";

interface IBank {
  bankName: string;
  bankCode: string;
  bankLogo?: string;
}

export const useBankInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();
  const { identificationNumber } = useSettings((state) => state.settings);

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const bankAccountSchema = object({
    identificationNumber: stringRequired,
    holderName: stringRequired,
    branch: stringRequired,
    account: stringRequired,
    bank: stringRequired,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(bankAccountSchema),
  });

  const [bankNameSelected, setBankNameSelected] = useState("");
  const [bankIconSelected, setBankIconSelected] = useState("");
  const [banksAndCodesState, setBanksAndCodesState] = useState<IBank[]>([]);

  const { bankAccountData } = StoreRegisterServices();

  const [showBankList, setShowBankList] = useState(false);
  const [bank, setBank] = useState({
    bankName: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (store) {
      if (store.bankAccounts && store.bankAccounts.length > 0) {
        const currentBank = store.bankAccounts[store.bankAccounts.length - 1];
        reset(
          (formValues) => ({
            ...formValues,
            identificationNumber: store.identificationNumber,
            bank: currentBank.bankCode,
            account: currentBank.accountNumber,
            branch: currentBank.routingNumber,
            holderName: currentBank.holderName ? currentBank.holderName : "",
          }),
          { keepErrors: true }
        );

        const bankData = banksAndCodes.filter(
          (bank) => bank.bankCode === currentBank.bankCode
        );

        if (bank.bankName === "") {
          handleSelectABank(
            bankData[0].bankName,
            bankData[0].bankLogo ? bankData[0].bankLogo : noLogoBank,
            bankData[0].bankCode
          );
        }
      } else {
        reset(
          (formValues) => ({
            ...formValues,
            identificationNumber: store.identificationNumber,
            bank: bank.bankName,
          }),
          { keepErrors: true }
        );
      }
    }
  }, [store, bank]);

  const submitBankInformation = (data: FieldValues) => {
    setIsProcessing(true);
    const { account, branch, holderName, identificationNumber } = data;

    const cleanIdentificationNumber = identificationNumber
      .replaceAll(".", "")
      .replace("-", "")
      .replace("/", "");

    const payload = {
      holderName: holderName,
      bankCode: bank.bankName,
      routingNumber: branch,
      accountNumber: account,
      identificationNumber: cleanIdentificationNumber,
      type: "checking",
    };

    bankAccountData(payload)
      .then((response) => {
        setIsProcessing(false);
        setProgressStep(5);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  };

  const handleOpenBankList = () => {
    showBankList ? setShowBankList(false) : setShowBankList(true);
  };

  const handleSelectABank = (
    name: string,
    iconBank: string,
    codeBank: string
  ) => {
    if (codeBank === "237N") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("N", ""),
        };
      });
    } else if (codeBank === "121N") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("N", ""),
        };
      });
    } else if (codeBank === "394F") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("F", ""),
        };
      });
    } else {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank,
        };
      });
    }

    setBanksAndCodesState([]);
    setShowBankList(false);
    setBankNameSelected(name);
    setBankIconSelected(iconBank);
  };

  const handleSearchBank = (value: any) => {
    let searchedBankValue: IBank[] = banksAndCodes.filter((bank) => {
      return bank.bankName.toLowerCase() === value.toLowerCase();
    });

    setBanksAndCodesState(searchedBankValue);
  };

  return {
    Controller,
    control,
    handleSubmit,
    submitBankInformation,

    banksAndCodesState,
    banksAndCodes,
    isProcessing,
    errors,
    handleOpenBankList,
    showBankList,
    handleSelectABank,
    bankNameSelected,
    bankIconSelected,
    handleSearchBank,
  };
};
