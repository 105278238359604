import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { ApexOptions } from "apexcharts";

//Services
import ReportsServices from "services/ReportsCSV";

export default function PaymentMethodsChart() {
  const [creditCardObjects, setCreditCardObjects] = useState<any[]>([]);
  const [creditCardValues, setCreditCardValues] = useState<number[]>([]);
  const [boletoObjects, setBoletoObjects] = useState<any[]>([]);
  const [boletoValues, setBoletoValues] = useState<number[]>([]);
  const [pixObjects, setPixObjects] = useState<any[]>([]);
  const [pixValues, setPixValues] = useState<number[]>([]);

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const [monthLabels, setMonthLabels] = useState<string[]>([]);

  const intl = useIntl();

  useEffect(() => {
    ReportsServices.getPaymentMethods().then((response) => {
      let hasDataMonth: any[] = [];
      let creditCardResults: any[] = [];
      let boletoResults: any[] = [];
      let pixResults: any[] = [];

      response.data.forEach((item: any) => {
        hasDataMonth[item.month - 1] = item.payments;

        item.payments.forEach((payment: any) => {
          if (payment.Credit) {
            creditCardResults.push({
              paymentMethod: "credit-card",
              month: item.month,
              amount: payment.Credit,
            });
          }

          if (payment.Boleto) {
            boletoResults.push({
              paymentMethod: "boleto",
              month: item.month,
              amount: payment.Boleto,
            });
          }

          if (payment.Pix) {
            pixResults.push({
              paymentMethod: "pix",
              month: item.month,
              amount: payment.Pix,
            });
          }
        });
      });

      setCreditCardObjects(creditCardResults);
      setBoletoObjects(boletoResults);
      setPixObjects(pixResults);
    });
  }, []);

  useEffect(() => {
    const currentMonth = new Date().getMonth();

    setMonthLabels(months.splice(0, currentMonth + 1));

    const creditCardChartValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const boletoChartValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const pixChartValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let index = 0; index <= currentMonth; index++) {
      creditCardObjects.forEach((item) => {
        if (item.month === index) {
          creditCardChartValues[index - 1] = item.amount;
        }
      });

      boletoObjects.forEach((item) => {
        if (item.month === index) {
          boletoChartValues[index - 1] = item.amount;
        }
      });

      pixObjects.forEach((item) => {
        if (item.month === index) {
          pixChartValues[index - 1] = item.amount;
        }
      });
    }

    setCreditCardValues(creditCardChartValues.splice(0, currentMonth + 1));
    setBoletoValues(boletoChartValues.splice(0, currentMonth + 1));
    setPixValues(pixChartValues.splice(0, currentMonth + 1));
  }, [creditCardObjects, boletoObjects, pixObjects]);

  var options: ApexOptions = {
    series: [
      {
        name: intl.formatMessage({
          id: "manager.links.details.payment.methods.0",
        }),
        data: creditCardValues,
      },
      {
        name: intl.formatMessage({
          id: "manager.links.details.payment.methods.1",
        }),
        data: boletoValues,
      },
      {
        name: intl.formatMessage({
          id: "manager.links.details.payment.methods.3",
        }),
        data: pixValues,
      },
    ],
    chart: {
      type: "bar",

      height: 250,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    xaxis: {
      // type: "string",
      categories: monthLabels,
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0067BD", "#FF6F00", "#009959"],
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="bar"
      width="100%"
      height="250"
    />
  );
}
