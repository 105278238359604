import styled, { css } from "styled-components";

type TTable = {
  hideBorder?: boolean;
};

export const RowList = styled.div`
  justify-content: space-between;
  display: flex;

  .list-transactions {
    flex-direction: column;
  }

  &.column-in-mobile {
    @media screen and (max-width: 916px) {
      display: flex;
      flex-direction: column !important;

      .width-100-mobile {
        width: 100% !important;
        margin-left: 0px;
      }

      .faq-column-in-mobile {
        display: flex;
        flex-direction: column !important;
      }
    }
  }
`;

export const RowListWithMarginBottom = styled(RowList)`
  margin-bottom: 8em;
`;

export const TableList = styled.div`
  flex-basis: 100%;
  height: auto;

  .title {
    font-size: 18px;
    font-family: "Brandon Text Medium" !important;
    color: ${(props) => props.theme.contentColors.secondary};
    display: flex;
  }

  .empty-mobile {
    display: none;

    @media screen and (max-width: 916px) {
      display: flex !important;
    }
  }

  .view-all__element {
    margin-left: auto;
    font-size: 14px;
    color: ${(props) => props.theme.primaryColors.primary};
    cursor: pointer;
  }

  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    li,
    .li-payment-links {
      list-style: none;
      color: ${(props) => props.theme.contentColors.primary};
      flex: 1;
      font-size: 14px;
      margin: 0 8px 0 0;

      span {
        margin-right: 8px;
      }
    }

    .li-payment-links {
      color: ${(props) => props.theme.contentColors.primary};
      margin: 0 8px 0 0;
    }
  }
`;

export const TableListHeader = styled.div`
  margin: 20px 0;

  ul {
    li {
      font-family: "Brandon Text Medium" !important;

      &:last-child {
        max-width: 85px;
      }

      @media screen and (max-width: 1024px) {
        &:nth-child(5) {
          display: none;
        }

        &:nth-child(6) {
          display: none;
        }

        &:nth-child(7) {
          display: none;
        }
      }

      @media screen and (max-width: 560px) {
        &:nth-child(3) {
          display: none;
        }
      }

      @media screen and (max-width: 460px) {
        &:nth-child(4) {
          display: none;
        }
      }
    }
  }

  .list-webhooks--header {
    ul {
      li {
        font-family: "Brandon Text Medium" !important;

        &:first-child,
        &:last-child {
          max-width: 60px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const GridHeader = styled.div`
  margin: 20px 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 0.5fr 0.7fr 0.7fr;
  grid-gap: 8px;

  p {
    font-family: "Brandon Text Medium" !important;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    p:nth-child(2),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(6) {
      display: none;
    }
  }
`;

export const GridPlansContainer = styled.div`
  .grid {
    cursor: pointer;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 0.5fr 0.7fr 0.7fr;
    grid-gap: 8px;
    padding: 17px 0;
    border-bottom: 1px solid
      ${(props) => props.theme.backgroundColors.secondary};

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      p:nth-child(2),
      p:nth-child(4),
      p:nth-child(5),
      p:nth-child(6) {
        display: none;
      }
    }
  }

  .last-item {
    border-bottom: 0px;
  }

  .skeleton {
    background-color: ${(props) => props.theme.backgroundColors.fourth};
  }

  span {
    display: inline-block;
    min-width: 100%;

    svg {
      min-width: 100%;
      border-radius: 3px;
    }
  }
`;

export const GridHeaderCustomers = styled(GridHeader)`
  grid-template-columns: 2fr 2fr 1fr;
`;

export const GridCustomersContainer = styled.div`
  .grid {
    cursor: pointer;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 8px;
    padding: 17px 0;
    border-bottom: 1px solid
      ${(props) => props.theme.backgroundColors.secondary};

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      p:nth-child(2),
      p:nth-child(4),
      p:nth-child(5),
      p:nth-child(6) {
        display: none;
      }
    }
  }

  .last-item {
    border-bottom: 0px;
  }

  .skeleton {
    background-color: ${(props) => props.theme.backgroundColors.fourth};
  }

  span {
    display: inline-block;
    min-width: 100%;

    svg {
      min-width: 100%;
      border-radius: 3px;
    }
  }
`;

export const TableItems = styled.div`
  a {
    font-size: unset;

    ul {
      min-height: 53px;
      padding: 4px 0;
      /* max-height: 53px; */
      border-bottom: 1px solid
        ${(props) => props.theme.backgroundColors.secondary};

      li {
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.contentColors.secondary};

        &:last-child {
          max-width: 85px;
        }

        @media screen and (max-width: 1024px) {
          &:nth-child(5) {
            display: none;
          }

          &:nth-child(6) {
            display: none;
          }

          &:nth-child(7) {
            display: none;
          }
        }

        @media screen and (max-width: 560px) {
          &:nth-child(3) {
            display: none;
          }
        }

        @media screen and (max-width: 460px) {
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }

    &:last-child {
      ul {
        border-bottom: 0px;
      }
    }
  }

  .transactions-list-table {
    a {
      ul {
        li {
          @media screen and (max-width: 460px) {
            &:nth-child(5) {
              display: none;
            }

            &:nth-child(6) {
              display: none;
            }
          }
        }
      }
    }
  }

  .list-webhooks {
    margin: 16px 0;

    ul {
      margin: 8px 0;

      li {
        font-family: "Brandon Text Regular" !important;

        &:first-child,
        &:last-child {
          max-width: 60px;
        }

        &:nth-child(2) {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        /* foi necesário centralizar na vertical assim, com flex e align items ele tava tirando as reticências */
        &:not(:last-child) {
          padding-top: 8px;
        }
      }
    }
  }

  .button-edit-li {
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    color: ${(props) => props.theme.textColors.primary};
  }

  .settings-ul {
    ul {
      background: ${(props) => props.theme.backgroundColors.fourth};
      border-radius: 3px !important;

      li {
        color: ${(props) => props.theme.textColors.primary};
      }
    }
  }

  .green {
    color: green !important;
  }

  .yellow {
    color: #ffc400 !important;
  }

  .red {
    color: #ff5660 !important;
  }

  .blocked-red {
    color: #ff0000 !important;
  }

  .skeleton-container {
    margin: 15px 0;
  }
  
  .skeleton-container {
    width: 100%;
    margin: 16px 0;
  }

  @media screen and (max-width: 916px) {
    padding-bottom: 8em;
  }
`;

export const TransactionsTable = styled(TableItems)`
  a {
    ul {
      li {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        padding-top: 10px;
      }
    }
  }
`;

export const FullTable = styled.table`
  width: 100%;
  font-size: 14px;
  margin-bottom: 4px;

  tr {
    display: flex;
    border-bottom: 1px solid
      ${(props) => props.theme.backgroundColors.secondary};
    min-height: 53px;
    max-height: 53px;

    td,
    th {
      max-width: 33%;
      flex: 1;
      align-items: center;

      &:nth-child(2) {
        min-width: 20% !important;
        max-width: 20% !important;

        @media screen and (max-width: 1300px) {
          display: none;
        }
      }

      &:first-child {
        max-width: 15%;

        @media screen and (max-width: 1300px) {
          max-width: 25%;
        }

        @media screen and (max-width: 768px) {
          max-width: 15%;
        }

        @media screen and (max-width: 680px) {
          max-width: 25%;
        }
      }

      @media screen and (max-width: 1300px) {
        &:nth-child(4) {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        &:nth-child(4) {
          display: flex;
        }
      }

      @media screen and (max-width: 680px) {
        &:nth-child(4) {
          display: none;
        }
      }

      &:last-child {
        max-width: 13%;

        @media screen and (max-width: 1300px) {
          max-width: 20%;
        }

        @media screen and (max-width: 768px) {
          max-width: 13%;
        }

        @media screen and (max-width: 680px) {
          max-width: 20%;
        }

        @media screen and (max-width: 425px) {
          max-width: 25%;
        }

        @media screen and (max-width: 320px) {
          max-width: 27%;
        }
      }
    }

    th {
      font-family: "Brandon Text Medium" !important;
      color: ${(props) => props.theme.contentColors.primary};
    }

    td {
      display: flex;
      color: ${(props) => props.theme.contentColors.secondary};
      word-break: break-all;
      padding: 0 4px 0 0;
    }

    &:last-child {
      border-bottom: 0px solid transparent !important;
    }

    @media screen and (max-width: 1300px) {
      justify-content: space-between;
    }
  }

  .remove--border-bottom {
    border-bottom: 0px !important;
  }

  .height--auto {
    min-height: 70px;
    max-height: 70px;
  }

  .height--550-user-edit-form {
    min-height: 550px;
  }
`;

export const FullTableUsers = styled(FullTable)`
  tr {
    td,
    th {
      &:first-child {
        min-width: 30% !important;
        max-width: 30% !important;
      }

      &:nth-child(2) {
        min-width: 50% !important;
        max-width: 50% !important;

        @media screen and (max-width: 1367px) {
          min-width: 45% !important;
          max-width: 45% !important;
        }
      }

      &:last-child {
        max-width: 20% !important;
        justify-content: flex-end;

        @media screen and (max-width: 1367px) {
          min-width: 25% !important;
          max-width: 25% !important;
        }

        @media screen and (max-width: 1100px) {
          min-width: 30% !important;
          max-width: 30% !important;
        }
      }
    }
  }
`;

export const FullTableLinks = styled(FullTable)`
  tr {
    th {
      display: flex;
      align-items: center;
    }

    td,
    th {
      &:first-child {
        min-width: 10% !important;
        max-width: 10% !important;

        @media screen and (max-width: 1100px) {
          min-width: 12% !important;
          max-width: 12% !important;
        }

        @media screen and (max-width: 990px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }

        @media screen and (max-width: 768px) {
          min-width: 20% !important;
          max-width: 20% !important;
        }

        @media screen and (max-width: 425px) {
          min-width: 25% !important;
          max-width: 25% !important;
        }
      }

      &:nth-child(2) {
        min-width: 17% !important;
        max-width: 17% !important;

        @media screen and (max-width: 1367px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }
      }

      &:nth-child(3) {
        padding-right: 4%;

        @media screen and (max-width: 1441px) {
          padding-right: 0%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        @media screen and (max-width: 1100px) {
          flex-direction: row;
          margin-bottom: 0px;
          max-width: 50%;
        }

        @media screen and (max-width: 990px) {
          padding-right: 0%;
          flex-direction: row;
          max-width: 60%;
        }

        @media screen and (max-width: 425px) {
          min-width: 40% !important;
          max-width: 40% !important;
        }
      }

      &:nth-child(4) {
        min-width: 12% !important;
        max-width: 12% !important;
      }

      &:nth-child(5) {
        min-width: 15% !important;
        max-width: 15% !important;

        @media screen and (max-width: 1100px) {
          display: none;
        }
      }

      &:nth-child(6) {
        min-width: 10% !important;
        max-width: 10% !important;

        @media screen and (max-width: 940px) {
          display: none;
        }
      }

      &:last-child {
        max-width: 10% !important;
        justify-content: flex-end;

        @media screen and (max-width: 1367px) {
          min-width: 10% !important;
          max-width: 10% !important;
        }

        @media screen and (max-width: 1100px) {
          min-width: 12% !important;
          max-width: 12% !important;
        }

        @media screen and (max-width: 1100px) {
          min-width: 13% !important;
          max-width: 13% !important;
        }

        @media screen and (max-width: 940px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }

        @media screen and (max-width: 768px) {
          min-width: 20% !important;
          max-width: 20% !important;
        }

        @media screen and (max-width: 425px) {
          min-width: 25% !important;
          max-width: 25% !important;
        }
      }
    }

    .space-between {
      display: flex;
      justify-content: space-between;

      .copy-button--none-mobile {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }
`;

export const FullTableProducts = styled(FullTable)`
  tr {
    th {
      display: flex;
      align-items: center;
    }

    td,
    th {
      &:first-child {
        min-width: 10% !important;
        max-width: 10% !important;

        @media screen and (max-width: 1100px) {
          min-width: 12% !important;
          max-width: 12% !important;
        }

        @media screen and (max-width: 990px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }

        @media screen and (max-width: 768px) {
          min-width: 20% !important;
          max-width: 20% !important;
        }

        @media screen and (max-width: 425px) {
          min-width: 25% !important;
          max-width: 25% !important;
        }
      }

      &:nth-child(2) {
        min-width: 40% !important;
        max-width: 40% !important;

        @media screen and (max-width: 1300px) {
          display: flex;
        }
      }

      &:nth-child(3) {
        @media screen and (max-width: 1300px) {
          display: none;
        }
      }

      &:last-child {
        max-width: 10% !important;
        justify-content: flex-end;

        @media screen and (max-width: 1367px) {
          min-width: 10% !important;
          max-width: 10% !important;
        }

        @media screen and (max-width: 1100px) {
          min-width: 12% !important;
          max-width: 12% !important;
        }

        @media screen and (max-width: 1100px) {
          min-width: 13% !important;
          max-width: 13% !important;
        }

        @media screen and (max-width: 940px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }

        @media screen and (max-width: 768px) {
          min-width: 20% !important;
          max-width: 20% !important;
        }

        @media screen and (max-width: 425px) {
          min-width: 25% !important;
          max-width: 25% !important;
        }
      }
    }

    .space-between {
      display: flex;
      justify-content: space-between;

      .copy-button--none-mobile {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }
`;

export const EditStoreContainer = styled.div`
  display: flex;
  max-width: 60%;
  margin-bottom: 16px;
`;

export const EditUserContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-direction: column;
`;

export const ErrorComp = styled.div`
  color: #ff0000 !important;
  padding: 12px 0px 16px 0px;
  font-size: 12px;
`;

export const ErrorCompWithoutPadding = styled(ErrorComp)`
  padding: 0px;
`;

export const TableItemsMobile = styled(TableItems)`
  display: none;

  @media screen and (max-width: 916px) {
    .value {
      .filepond-div {
        img {
          width: 250px;
          height: auto;

          @media screen and (max-width: 916px) {
            width: 130px;
          }
        }
      }

      @media screen and (max-width: 916px) {
        word-break: break-all;
        min-width: auto !important;
      }
    }

    .wrap-mobile {
      flex-wrap: nowrap !important;

      .ul-li-column {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        li {
          flex-wrap: wrap;
          max-width: 215px;
          word-break: break-word;
        }
      }
    }

    h3 {
      font-size: 16px;
    }

    display: flex;
    flex-direction: column;
  }
`;

export const DevelopersMobile = styled.div`
  background: ${(props) => props.theme.backgroundColors.fourth};
  border-radius: 3px;
  display: none;
  flex-direction: column;
  margin-top: 8px;

  .row {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
  }

  .label-ul-values {
    padding: 15px 20px 15px 2px !important;
    max-width: 220px;
  }

  .label-ul {
    padding: 15px 2px 15px 20px !important;

    .ul-label {
      color: ${(props) => props.theme.secondaryTextColors.eighth};
    }
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      color: ${(props) => props.theme.textColors.primary};
      line-height: 24px;
    }
  }

  @media screen and (max-width: 916px) {
    display: flex !important;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  .customer-pagination {
    padding: 2px 25px 2px 2px;
  }
`;

export const Map = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContextBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;

  .selects {
    display: flex;
  }

  @media screen and (max-width: 916px) {
    margin-bottom: 2em;
  }
`;
