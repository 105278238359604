import React from 'react';
import { useIntl } from 'react-intl';

//Styles
import * as styled from './styles';

type TLoading = {
  isLoading: boolean;
  percentage: number;
}

const DownloadLoading = ({ isLoading, percentage }: TLoading) => {
  const intl = useIntl();

  return(<>
    {isLoading && <styled.LoaderContainer percentage={percentage}>
      <p>{intl.formatMessage({ id: "download.loading.text" })}</p>
      <strong>{percentage}% {intl.formatMessage({ id: "download.loading.text.to.complete" })}</strong>
      <styled.ProgressContainer>
        <styled.ProgressBar percentage={percentage} />
      </styled.ProgressContainer>
    </styled.LoaderContainer>}
  </>);
}

export default DownloadLoading ;
