import axios from 'axios';

class BexsServices {

  async getBexsFXRate(storeCurrency: string, producCategory: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/fx/stores/${session.storeId}/exchange?currency=${storeCurrency}&paymentType=payin&category=${producCategory}`, 
    {
        headers: {
            'Authorization': `Bearer ${session.token}`,
            'Content-Type': "application/json"
        }
    });
  }
}

export default new BexsServices;