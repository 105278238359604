import styled from 'styled-components';

export const ServiceContainer = styled.div`
  padding: 24px 0;

  .service-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.backgroundColors.secondary};
`;