import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Services
import CustomerServices from "services/Customers";

//Helpers
import formatDateWithFuso from "helpers/formatDateWithFuso";

export const useDetails = ({ id, storeId }: any) => {
  const [customer, setCustomer] = useState<any>();
  const [isAdmin, setIsAdmin] = useState(false);

  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (/admin/.test(location.pathname)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    CustomerServices.getCustomerDetails(id, storeId)
      .then((response) => {
        const customerResponse = response.data.customer;

        const customerDetails = {
          ...response.data,
          customer: {
            ...customerResponse,
            createdOn: formatDateWithFuso(
              customerResponse.createdOn,
              "DD/MM/YYYY - HH:mm:ss"
            ),
            lastPayment: formatDateWithFuso(
              customerResponse.lastPayment,
              "P, HH:mm"
            ),

            // format(
            //   new Date(dateFormattedTimeZoneLastPayment),
            //   ,
            //   {
            //     locale: navigator.language === "pt-BR" ? ptBR : undefined,
            //   }
            // ),
          },
        };

        setCustomer(customerDetails);
      })
      .catch((err) => {
        if (err) {
          history.push("/");
          console.log(err);
        }
      });
  }, []);

  return {
    customer,
    setCustomer,
  };
};
