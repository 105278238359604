import React from "react";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";

import Buttons from "../../../../components/Forms/Buttons";
import TipButtonModal from "../../../../components/Layout/Modals/TipButtonModal";

import { useCreditCardItem } from "./hook";

import * as styled from "./styles";

type DetailData = {
  card: any;
  customerId: number;
  loading?: {
    width: number;
  };
  isTheLastItem: boolean;
};

export const CreditCardItem = ({
  card,
  customerId,
  loading,
  isTheLastItem,
}: DetailData) => {
  const { networkIcon, isProcessing, handleDefaultCreditCard } =
    useCreditCardItem({ card, customerId });

  const intl = useIntl();

  return (
    <styled.CreditCardItemContainer isTheLastItem={isTheLastItem}>
      <styled.CreditCardDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "detail.order.payment.method",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value value--full-width flex-row--centered">
            <p>{intl.formatMessage({ id: "payment.credit.card" })}</p>

            <div className="button-container">
              {
                card.isDefault ? (
                  <div className="default">
                    <TipButtonModal
                      text={intl.formatMessage({
                        id: "tip.settings.fees.boleto",
                      })}
                    />{" "}
                    Default
                  </div>
                ) : null
                // !isProcessing ? (
                //   <Buttons
                //     mode="unfilled"
                //     label={intl.formatMessage({
                //       id: "customers.page.saved.cards.make.default",
                //     })}
                //     handler={handleDefaultCreditCard}
                //   />
                // ) : (
                //   <Buttons
                //     mode="unfilled-processing"
                //     label=""
                //     handler={() => {}}
                //   />
                // )
              }
            </div>
          </div>
        )}
      </styled.CreditCardDetailItem>

      <styled.CreditCardDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "customers.page.saved.cards.network",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            <img src={networkIcon} alt={card.cardBrand} />
          </div>
        )}
      </styled.CreditCardDetailItem>

      <styled.CreditCardDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "detail.order.card.holder",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">{card.holderName}</div>
        )}
      </styled.CreditCardDetailItem>

      <styled.CreditCardDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "detail.order.card.number",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            {card.firstDigits} **** **** {card.lastDigits}
          </div>
        )}
      </styled.CreditCardDetailItem>

      <styled.CreditCardDetailItem className="g-detail-item">
        <div className="g-detail-item__label">
          {" "}
          {intl.formatMessage({
            id: "detail.order.expiry.date",
          })}{" "}
        </div>
        {loading ? (
          <Skeleton width={loading.width} className="skeleton" />
        ) : (
          <div className="g-detail-item__value">
            {parseInt(card.expirationMonth)}/{card.expirationYear}
          </div>
        )}
      </styled.CreditCardDetailItem>
    </styled.CreditCardItemContainer>
  );
};
