import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

//Services
import ProductServices from "services/Product";
import SessionService from "services/Session";

//Components
import DetailItem from "./DetailItem";
import { DynamicDetailItem } from "../DynamicDetailItem";
import Buttons from "../Forms/Buttons";

//Assets
import tipIcon from "assets/images/global/tip-icon.svg";
import memberkitIcon from "assets/images/integrations/memberkit.svg";

//Styles
import * as indexStyled from "../../styles/index-styles";
import * as styled from "./details-styles";

type TTax = {
  mode: number;
  amount: number;
};

type TProduct = {
  createdOn: string;
  currency: string;
  description: string;
  id: number;
  images: any;
  metadata: any;
  name: string;
  packageDimension: any;
  shippable: string;
  status: string;
  // store: any;
  unitLabel: string;
  unitPrice: number;
  updatedOn: string;
  url: string;
  productSource?: {
    label: string;
    logo: string;
  };
};

const ProductBoxDetails = () => {
  const history = useHistory();
  let location = useLocation();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  let { id } = useParams<any>();
  let intl = useIntl();

  const [tax, setTax] = useState<TTax>();
  const [processing, setProcessing] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [editedImages, setEditedImages] = useState(false);
  const [processingCancelSubscription, setProcessingCancelSubscription] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isNewProduct, setIsNewProduct] = useState(() => {
    return /new/.test(location.pathname);
  });

  const [imagesIds, setImagesIds] = useState<number[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<number[]>([]);
  const [newImages, setNewImages] = useState<any[]>([]);
  const [columnLabelValue, setColumnLabelValue] = useState<string[]>([]);
  const [columnLabelValuePreFill, setColumnLabelValuePreFill] = useState<
    string[]
  >([]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProcessingSave, setIsProcessingSave] = useState(false);
  const [paymentLinkOrdersLoaded, setPaymentLinkOrdersLoaded] =
    useState<boolean>(false);
  const [productOrders, setProductOrders] = useState<any>();
  const [recentOrdersTemp, setRecentOrdersTemp] = useState([]);
  const [storeId, setStoreId] = useState(() => {
    const session = localStorage.getItem("session");

    const { storeId } = JSON.parse(session ? session : "{}");

    return storeId;
  });

  const defaultProductObj = {
    createdOn: "",
    currency: "",
    description: "",
    id: 0,
    images: [],
    metadata: {},
    name: "",
    packageDimension: [],
    shippable: "",
    status: "",
    // store: {},
    unitLabel: "",
    unitPrice: 0,
    updatedOn: "",
    url: "",
    productSource: {
      label: "",
      logo: "",
    },
  };

  const [product, setProduct] = useState<TProduct>(defaultProductObj);
  const [productToUpdate, setProductToUpdate] =
    useState<TProduct>(defaultProductObj);

  const [productBackup, setProductBackup] =
    useState<TProduct>(defaultProductObj);

  function loadProductData() {
    ProductServices.getProductDetails(parseInt(id)).then((response) => {
      let newProduct = { ...response };
      let newPackageDimensions: string[] = [];
      let labels: any;
      
      if (response.packageDimension) {
        labels = Object.keys(response.packageDimension).map(function (
          key
        ) {
          newPackageDimensions.push(
            `${intl.formatMessage({ id: `create.product.${key}` })}: ${
              response.packageDimension[key]
            } ${intl.formatMessage({
              id: `create.product.${
                key !== "weight" ? "centimeters" : "kilograms"
              }`,
            })}`
          );
        });
      }

      if (response.connector) {
        newProduct.productSource = {
          label: "Memberkit",
          logo: memberkitIcon,
        };
      }

      newProduct.packageDimension = [...newPackageDimensions];
      setProduct(newProduct);
      setNewImages(response.images);
      setProductBackup(newProduct);
    });

    ProductServices.getAllProductOrders(parseInt(id)).then((response) => {
      setProductOrders(response.data);

      setPaymentLinkOrdersLoaded(true);
    });
  }

  useEffect(() => {
    loadProductData();
  }, []);

  function cancelEdition() {
    setIsEditting(false);
    setProduct(productBackup);
  }

  function deleteProduct() {
    setIsProcessing(true);

    ProductServices.deleteProduct(parseInt(id))
      .then((response) => {
        loadProductData();
        setIsProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  }

  async function updateProduct() {
    setIsProcessingSave(true);

    let dimensions: number[] = [];
    Object.entries(product.packageDimension).forEach(([key, value]: any) => {
      dimensions.push(parseFloat(value.split(":")[1]));
    });

    const productToUpdate = {
      currency: product.currency,
      description: product.description,
      metadata: {},
      name: product.name,
      packageDimension: {
        height: dimensions[0],
        length: dimensions[1],
        weight: dimensions[2],
        width: dimensions[3],
      },
      shippable: product.shippable,
      status: product.status,
      unitLabel: product.unitLabel,
      unitPrice: product.unitPrice,
      url: product.url,
    };

    if (editedImages) {
      if (imagesToDelete.length > 0) {
        for (let index = 0; index <= imagesToDelete.length - 1; index++) {
          await ProductServices.deleteImageProduct(
            parseInt(id),
            imagesToDelete[index]
          );
        }
      }

      const newImagesFromLocalStorage = JSON.parse(
        localStorage.getItem("newEdittedProductImages") || "{}"
      );

      ProductServices.updateProductImage(
        newImagesFromLocalStorage,
        parseInt(id)
      )
        .then((response) => {
          makeUpdateProductRequest(productToUpdate);
        })
        .catch((err) => {
          console.log(err);
          setPaymentLinkOrdersLoaded(true);
          setIsEditting(false);
          setIsProcessingSave(false);
        });
    } else {
      makeUpdateProductRequest(productToUpdate);
    }
  }

  function makeUpdateProductRequest(productToUpdate: any) {
    ProductServices.updateProduct(parseInt(id), productToUpdate)
      .then((response) => {
        let newProduct = { ...response.data };
        let newPackageDimensions: string[] = [];

        let labels = Object.keys(response.data.packageDimension).map(function (
          key
        ) {
          newPackageDimensions.push(
            `${intl.formatMessage({ id: `create.product.${key}` })}: ${
              response.data.packageDimension[key]
            } ${intl.formatMessage({
              id: `create.product.${
                key !== "weight" ? "centimeters" : "kilograms"
              }`,
            })}`
          );
        });

        newProduct.packageDimension = [...newPackageDimensions];
        setProduct(newProduct);
        setProductBackup(newProduct);
        setPaymentLinkOrdersLoaded(true);
        setIsEditting(false);
        setIsProcessingSave(false);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessingSave(false);
      });
  }

  function createPaymentLink() {
    history.push({
      pathname: "/request-payment/create-payment-link",
      state: {
        id: id,
        productName: product.name,
        unitPrice: product.unitPrice,
      },
    });
  }

  return (
    <styled.LeftRightContainer>
      <styled.LeftPaymentLinkDetails className="border-right complete-height">
        <styled.BoxDetails className="border-bottom">
          <styled.Header
            className={`border-bottom ${isNewProduct ? "blue-title" : ""}`}
          >
            {!isNewProduct && (
              <div className="edit-header">
                <FormattedMessage id="manager.links.all.details.title" />

                {!isEditting ? (
                  <Buttons
                    mode="unfilled"
                    label={intl.formatMessage({
                      id: "settings.team.list.edit",
                    })}
                    handler={() => setIsEditting(true)}
                  />
                ) : null}

                {isEditting ? (
                  <div className="edit-buttons-container">
                    {!isProcessingSave ? (
                      <Buttons
                        mode="filled"
                        label={intl.formatMessage({
                          id: "user.profile.ui.save",
                        })}
                        handler={updateProduct}
                      />
                    ) : (
                      <Buttons
                        mode="filled-processing"
                        label=""
                        handler={() => {}}
                      />
                    )}

                    <Buttons
                      mode="unfilled"
                      label={intl.formatMessage({
                        id: "home.withdrawall.cancel",
                      })}
                      handler={cancelEdition}
                      disable={isProcessingSave}
                    />
                  </div>
                ) : null}
              </div>
            )}

            {isNewProduct && (
              <FormattedMessage id="product.details.success.created.title" />
            )}

            <div className="tip">
              <img src={tipIcon} alt="tip" />
              <p>{intl.formatMessage({ id: "manager.links.details.tip" })}</p>
            </div>

            <p>
              {intl.formatMessage({
                id: "product.details.success.created.subtitle",
              })}
            </p>

            <div className="desactivate-link-container">
              {!isEditting ? (
                <Buttons
                  mode="filled"
                  label={intl.formatMessage({
                    id: "product.details.create.payment.link",
                  })}
                  handler={createPaymentLink}
                />
              ) : null}
              {/* { !isProcessing ? <Buttons mode="filled" label={intl.formatMessage({ id: "delete.product.label" })} handler={deleteProduct} /> : <Buttons mode="filled-processing" label="" handler={() => {}} />}
              </>} */}
            </div>
          </styled.Header>

          <styled.DetailsItems>
            {product !== undefined ? (
              (() => {
                let value = "";
                let classStatus = "";

                if (product.status === "Expired") {
                  value = "expired";
                  classStatus = "status-failed";
                } else if (product.status === "Active") {
                  value = "active";
                  classStatus = "status-succeeded";
                } else {
                  value = "deactivated";
                  classStatus = "status-deactivated";
                }

                return (
                  <DetailItem
                    label={intl.formatMessage({ id: "detail.order.status" })}
                    value={intl.formatMessage({ id: `status.${value}` })}
                    classStatus={classStatus}
                  />
                );
              })()
            ) : (
              <DetailItem
                label={intl.formatMessage({ id: "detail.order.status" })}
                value=""
                classStatus=""
                loading={{ width: 75 }}
              />
            )}

            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.header.table.title",
              })}
              value={product.name}
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="name"
              handlerData={setProduct}
            />

            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.details.description",
              })}
              value={product.description}
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="description"
              handlerData={setProduct}
            />

            <DetailItem
              label={intl.formatMessage({
                id: "manager.links.header.table.price",
              })}
              value={
                !isEditting
                  ? intl.formatNumber(product?.unitPrice, {
                      style: "currency",
                      currency: product?.currency,
                    })
                  : product.unitPrice.toString()
              }
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="unitPrice"
              handlerData={setProduct}
              productCurrency={product.currency}
            />

            <DetailItem
              label={intl.formatMessage({ id: "create.product.media.label" })}
              isMediaItem={true}
              value={""}
              media={product.images}
              handleName="images"
              handlerData={setProduct}
              isEditting={isEditting}
              setEditedImages={setEditedImages}
              imagesToDelete={setImagesToDelete}
              imagesIds={imagesIds}
              setImagesIds={setImagesIds}
              newImages={newImages}
              setNewImages={setNewImages}
            />

            {/* <div className="border-bottom"></div> */}
          </styled.DetailsItems>
        </styled.BoxDetails>
      </styled.LeftPaymentLinkDetails>

      <styled.Right>
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="manager.links.customized.details.title" />
          </styled.Header>

          <styled.DetailsItems>
            <DynamicDetailItem
              label={intl.formatMessage({
                id: "products.details.product.source",
              })}
              loading={product === undefined ? { width: 50 } : undefined}
            >
              <styled.ProductSourceValue>
                <p>{product.productSource?.label}</p>
                <img
                  src={product.productSource?.logo}
                  alt="product source logo"
                />
              </styled.ProductSourceValue>
            </DynamicDetailItem>

            <DetailItem
              label={intl.formatMessage({ id: "product.details.shippable" })}
              value={intl.formatMessage({
                id: `manager.links.details.include.fee.transfer.${
                  product.shippable ? "yes" : "no"
                }`,
              })}
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="shippable"
              handlerData={setProduct}
            />

            <DetailItem
              isColumnListValues={true}
              label={intl.formatMessage({ id: "product.details.package" })}
              value={product.packageDimension}
              loading={product === undefined ? { width: 50 } : undefined}
              columnList={product.packageDimension}
              isReceivables={false}
              handleName="packageDimension"
              isEditting={isEditting}
              handlerData={setProduct}
            />

            <DetailItem
              label={intl.formatMessage({ id: "product.details.unit.label" })}
              value={product.unitLabel}
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="unitLabel"
              handlerData={setProduct}
            />

            <DetailItem
              label={intl.formatMessage({ id: "create.product.page.label" })}
              value={product.url}
              loading={product === undefined ? { width: 50 } : undefined}
              isEditting={isEditting}
              handleName="url"
              handlerData={setProduct}
            />
          </styled.DetailsItems>
        </styled.BoxDetails>

        {!isNewProduct && (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              <FormattedMessage id="manager.links.details.sales.title" />
            </styled.Header>

            <indexStyled.TableList>
              <indexStyled.TableListHeader>
                <ul>
                  <li>
                    <FormattedMessage id="home.status" />
                  </li>
                  <li>
                    <FormattedMessage id="home.order" />
                  </li>
                  <li>
                    <FormattedMessage id="home.name" />
                  </li>
                  <li></li>
                </ul>
              </indexStyled.TableListHeader>

              <indexStyled.TableItems>
                {paymentLinkOrdersLoaded && productOrders.length === 0 ? (
                  <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                ) : null}

                {paymentLinkOrdersLoaded ? (
                  productOrders.map((order: any) => {
                    let status;
                    switch (order.status) {
                      case "Pending":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.pending" />
                            </span>
                          </li>
                        );
                        break;
                      case "Succeded":
                        status = (
                          <li>
                            <span className="status-succeeded">
                              <FormattedMessage id="payment.confirmed" />
                            </span>
                          </li>
                        );
                        break;
                      case "Canceled":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.canceled" />
                            </span>
                          </li>
                        );
                        break;
                        case "Expiration":
                          status = (
                            <li>
                              <span className="status-failed">
                                <FormattedMessage id="payment.expiration" />
                              </span>
                            </li>
                          );
                          break;
                      case "Failed":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.failed" />
                            </span>
                          </li>
                        );
                        break;
                      case "Blocked":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.blocked" />
                            </span>
                          </li>
                        );
                        break;
                      case "Disputed":
                        status = (
                          <li>
                            <span className="status-pending">
                              <FormattedMessage id="payment.disputed" />
                            </span>
                          </li>
                        );
                        break;
                      case "ChargedBack":
                        status = (
                          <li>
                            <span className="status-failed">
                              <FormattedMessage id="payment.charged.back" />
                            </span>
                          </li>
                        );
                        break;
                      case "DisputeSucceeded":
                        status = (
                          <li>
                            <span className="status-succeeded">
                              <FormattedMessage id="payment.confirmed" />
                            </span>
                          </li>
                        );
                        break;
                      default:
                        status = <li></li>;
                        break;
                    }

                    return (
                      <a key={order.number}>
                        <ul>
                          <li>
                            <span className="status-succeeded">
                              <FormattedMessage id="payment.confirmed" />
                            </span>
                          </li>
                          <li>#{order.number}</li>
                          <li>{order.customer}</li>
                          <li>
                            <div>
                              <Buttons
                                mode="unfilled"
                                label={intl.formatMessage({
                                  id: "manager.links.details.sales.view",
                                })}
                                handler={() =>
                                  history.push(
                                    `/transactions/${order.number}/${storeId}/details`
                                  )
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </a>
                    );
                  })
                ) : (
                  <Skeleton count={1} height={25} className="skeleton" />
                )}
              </indexStyled.TableItems>
            </indexStyled.TableList>
          </styled.BoxDetails>
        )}
      </styled.Right>
    </styled.LeftRightContainer>
  );
};

export default ProductBoxDetails;
