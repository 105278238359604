import styled, { css } from "styled-components";

type ExpandProps = {
  url: string;
};

type SelectProps = {
  ref?: any;
};

type TProgressBar = {
  step: number;
};

type TProgressBar5 = {
  step: number;
};

export const LeftRightContainer = styled.section`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 916px) {
    display: flex;
    flex-direction: column !important;
  }
`;

export const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.only-left {
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1300px) {
    width: 50%;
  }

  @media screen and (max-width: 916px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 40%;

  @media screen and (max-width: 1300px) {
    width: 50%;
  }

  @media screen and (max-width: 916px) {
    width: 100%;
  }
`;

export const BoxDetails = styled.div`
  background: ${(props) => props.theme.backgroundColors.primary};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 38px 39px 38px 60px;

  .centered-container,
  .centered-container-smaller {
    width: 350px;
    margin: 0 auto;
    min-height: calc(100vh - 125px);
  }

  .centered-container-smaller {
    width: 350px;
  }

  .details-button {
    width: 100%;
    display: flex;
    justify-content: center;

    .btn {
      text-align: center;
      width: 170px;
      outline: none;
    }
  }

  .details-items-input-100 {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 20px 20px 0 20px;
  }

  .details-items-edit-form {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 0px 0px 10px 0px !important;
    animation: topDown 0.3s ease-in-out forwards;
    /* opacity: 0; */

    @keyframes topDown {
      from {
        transform: translateY(-10px);
      }
      to {
        transform: translateY(0px);
        /* opacity: 1 */
      }
    }
  }

  @media screen and (max-width: 1080px) {
    padding: 24px 16px;
  }
`;

export const Header = styled.header`
  margin-bottom: 16px;
  display: flex;
  font-size: 18px;
  font-family: "Brandon Text Medium" !important;
  color: ${(props) => props.theme.contentColors.secondary};

  .header-right {
    display: flex;
    align-items: center;
    widows: 100%;
    justify-content: space-between;

    .btn {
      font-size: 0.8em;
      width: auto !important;
    }
  }

  .edit-version {
    font-size: 0.66em !important;

    .btn {
      font-size: 1.2em;
      width: auto !important;
    }
  }

  .column-header {
    display: flex;
    flex-direction: column;

    .return-container {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      img {
        margin-right: 8px;
      }

      &:hover {
        filter: brightness(50%);
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;

export const ProgressBar3StepsContainer = styled.div<TProgressBar>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .background {
    width: 32.5%;
    height: 4px;
    background: ${(props) => props.theme.backgroundColors.sixth};

    &:first-child {
      border-radius: 70px 0 0 70px;
    }

    &:last-child {
      border-radius: 0 70px 70px 0;
    }
  }

  .progress-1 {
    border-radius: 70px 0 0 70px;
  }

  .progress-3 {
    border-radius: 0 70px 70px 0;
  }

  .progress-1,
  .progress-2,
  .progress-3 {
    width: 0%;
    height: 4px;
    background: ${(props) => props.theme.primaryColors.sixth};
    transition: all 0.3s ease-in-out;
  }

  ${(props) =>
    props.step === 1 &&
    css`
      .progress-1 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

  ${(props) =>
    props.step === 2 &&
    css`
      .progress-1,
      .progress-2 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

  ${(props) =>
    props.step === 3 &&
    css`
      .progress-1,
      .progress-2,
      .progress-3 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}
`;

export const ProgressBar5StepsContainer = styled.div<TProgressBar5>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .background {
    width: 19.5%;
    height: 4px;
    background: ${(props) => props.theme.backgroundColors.sixth};

    &:first-child {
      border-radius: 70px 0 0 70px;
    }

    &:last-child {
      border-radius: 0 70px 70px 0;
    }
  }

  .progress-1 {
    border-radius: 70px 0 0 70px;
  }

  .progress-5 {
    border-radius: 0 70px 70px 0;
  }

  .progress-1,
  .progress-2,
  .progress-3,
  .progress-4,
  .progress-5 {
    width: 0%;
    height: 4px;
    background: ${(props) => props.theme.primaryColors.sixth};
    transition: all 0.3s ease-in-out;
  }

  ${(props) =>
    props.step >= 1 &&
    css`
      .progress-1 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

  ${(props) =>
    props.step >= 2 &&
    css`
      .progress-2 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

    ${(props) =>
    props.step >= 3 &&
    css`
      .progress-3 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

  ${(props) =>
    props.step >= 4 &&
    css`
      .progress-4 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}

  ${(props) =>
    props.step >= 5 &&
    css`
      .progress-5 {
        transition: all 0.3s ease-in-out;
        width: 100%;
      }
    `}
`;

export const FormContainer = styled.div`
  h5 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
    color: ${(props) => props.theme.contentColors.primary};
  }
  
 

  .insider-container,
  .insider-container--few {
    margin-left: 32px;
    margin-bottom: 24px;
  }

  .insider-container--few {
    margin-left: 8px;
  }

  .flex-header-form-item {
    display: flex;
    width: 100%;

    h5 {
      &:first-child {
        min-width: 60%;
        max-width: 60%;
      }

      &:last-child {
        min-width: 40%;
        max-width: 40%;
      }
    }
  }

  .select-and-input-container,
  .inputs-container {
    display: flex;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    border-radius: 4px;
    align-items: center;
    height: 40px;

    .m-half-width {
      min-width: 50%;
      max-width: 50%;
    }

    .m-20-width {
      min-width: 20%;
      max-width: 20%;
    }

    .m-60-width {
      min-width: 60%;
      max-width: 60%;
    }

    .m-40-width {
      min-width: 40%;
      max-width: 40%;
    }

    .m-half-width--flex {
      display: flex;
    }

    div {
      &:last-child {
        height: 100%;
        border-left: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      }
    }

    input {
      height: 24px;
      padding: 0 8px;
      width: 100%;
      background: transparent;
    }

    input[type="time"],
    input[type="date"] {
      height: 24px;
      padding: 0px;
      width: 100%;
      background: transparent;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }

  }

  .select-and-input-container {
    &:not(:first-child) {
      margin-top: 0px;
    }
  }

  .inputs-container {
    border: 0px;

    div {
      &:last-child {
        height: 100%;
        border: 0px;

        input {
          text-align: center;
        }
      }
    }
  }

  .form-item {
    transition: all 0.2s ease-in-out;
    margin-bottom: 24px;

    .button-container {
      margin: 16px 0 0 8px;
    }

    .form-item__optional {
      display: flex;

      p {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 10px;
        margin-left: 16px;
        color: ${(props) => props.theme.contentColors.primary};
      }

      .tip-container {
        margin-top: 16px;
        margin-bottom: 8px;
        margin-left: 16px;

        div {
          margin-top: 0px;
        }
      }
    }

    .flex {
      display: flex;
      align-items: center;

      label {
        margin-right: 8px;
        margin-bottom: 0px;
      }
    }
  }

  .inputs-table-container {
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    border-radius: 4px;
    padding: 1px 1px 0 1px;
  }

  .form-item--without-margin {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  .button-bottom-container {
    margin-top: 32px;
  }
  
  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }
  
  .margin-top-16 {
    margin-top: 16px;
  }

  .qr-code-container {
    img {
      max-width: 250px;
    }
  }
`;

export const CheckoutPreviewContainer = styled.div``;

export const CheckoutHeader = styled.div``;

export const ManualPaymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;

  img {
    margin: 32px 0 25px 0;
  }

  .text-container {
    display: flex;
    max-width: 237px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.contentColors.secondary};
    margin-bottom: 32px;

    p {
      font-size: 12px;
      color: ${(props) => props.theme.contentColors.secondary};
      text-align: center;
    }
  }

  .medium {
    font-size: 16px !important;
    font-family: "Brandon Text Medium" !important;
  }

  .bold {
    font-size: 16px !important;
    font-family: "Brandon Text Bold" !important;
  }
`;

export const ModalContent = styled.div`
  background: ${(props) => props.theme.backgroundColors.secondary} 0% 0%
    no-repeat padding-box !important;
  border-radius: 6px;
  opacity: 1;
  min-width: 500px;
  max-width: 500px;

  @media screen and (max-width: 916px) {
    padding-bottom: 10vh;
  }

  @media screen and (max-width: 525px) {
    min-width: 474px;
    max-width: 474px;
  }

  @media screen and (max-width: 425px) {
    min-width: auto;
    max-width: 325px;
  }

  @media screen and (max-width: 321px) {
    min-width: 266px;
    max-width: 266px;
  }

  .modal-payment-request {
    width: 110% !important;
  }

  .h4,
  h4 {
    font-size: 1.5em;
  }
  .modal-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #282731 !important;
    color: ${(props) => props.theme.textColors.primary};
  }
  .modal-title {
    text-align: left;
    font: Regular 12px/13px Ubuntu;
    letter-spacing: 0;
    color: ${(props) => props.theme.textColors.primary};
    opacity: 1;
  }

  .arrow-right {
    img {
      transform: rotate(-90deg);
    }
  }
  .modal-body {
    padding-bottom: 0rem;
    background: ${(props) => props.theme.backgroundColors.secondary};
    border-radius: 0 0 6px 6px;

    @media screen and (max-width: 916px) {
      padding-bottom: 1rem;
    }
  }
  .payment-request-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .btn {
      text-align: center;
      width: 170px;
      margin-right: 22px;
    }
  }

  .modal-header-right {
    display: flex;
    align-items: center;

    button {
      width: 165px;
      margin-right: 8px;
    }
  }

  .content-padding {
    padding: 15px 20px !important;

    .button-see-details {
      display: flex;
      justify-content: flex-end;

      img {
        width: 18px;
      }
    }

    .skeleton {
      background-color: ${(props) => props.theme.backgroundColors.fourth};
    }
  }

  .link-details-container {
    border-radius: 3px;
    padding: 15px 20px;
    background: ${(props) => props.theme.backgroundColors.fourth};
    font-size: 12px;
    margin-bottom: 10px;

    .input-hidden {
      height: 0px !important;
    }

    .content-link {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width: 916px) {
        flex-direction: column;
      }
    }

    .left {
      .label {
        font-size: 12px;
        color: ${(props) => props.theme.textColors.primary} !important;
      }

      .value {
        font-size: 12px;
        margin-bottom: 16px;
        color: ${(props) => props.theme.textColors.primary} !important;
      }

      .bold {
        font-weight: bold;
      }
    }

    .right {
      img {
        max-width: 100px;
        margin-left: 16px;
      }
    }

    .buttons-content {
      width: 100%;

      button {
        font-size: 12px;
        height: 36px;
      }

      .unfilled-buttons-content {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        button {
          width: 47%;
        }
      }
    }
  }
`;

export const RequestForm = styled.div`
  color: ${(props) => props.theme.textColors.primary};
  font-size: 1.2em;

  h5 {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    width: 85%;
    justify-content: space-between;

    @media screen and (max-width: 916px) {
      width: 100%;
      flex-direction: column;
    }
  }

  label span {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #f6f6f6;
    padding: 10px 18px;
    border-radius: 3px;
    transition: background-color 0.3s;
    height: 36px;

    @media screen and (max-width: 916px) {
      width: auto;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + span {
    .unchecked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .checked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }
  }

  input[type="radio"]:checked + span {
    background: linear-gradient(
          ${(props) => props.theme.backgroundColors.primary},
          ${(props) => props.theme.backgroundColors.primary}
        )
        padding-box,
      /*this is your grey background*/
        linear-gradient(to bottom, #ff8887 0%, #ff6565 100%) border-box;
    /* background-color: ${(props) => props.theme.backgroundColors.primary}; */
    /* border: 1px solid ${(props) => props.theme.backgroundColors.primary}; */
    border: 1px solid transparent;
    transition: background-color 0.3s;
    color: #fff !important;

    .checked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .unchecked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }
  }

  img {
    width: 13px;
    height: 13px;
    margin-right: 13px;
  }

  .c-request-form--width-auto {
    width: auto;
    font-size: 12px;

    .checked-label--margin-right {
      margin-right: 16px;
    }
  }
`;

export const RequestType = styled(RequestForm)`
  div {
    display: flex;
    width: 85%;
    justify-content: flex-start;

    .label--margin-right {
      margin-right: 32px;
    }

    @media screen and (max-width: 916px) {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const LinkPaymentMethod = styled.div`
  color: ${(props) => props.theme.textColors.primary};
  overflow: auto;
  font-size: 1.2em;
  margin-top: 20px;

  .type-container {
    width: auto;
    display: flex;
    justify-content: flex-start;
    label {
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  h5 {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    justify-content: flex-start;

    .label--margin-right {
      margin-right: 32px;
    }
  }

  .width-100 {
    width: 100%;
  }

  label span {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #f6f6f6;
    padding: 10px 18px;
    border-radius: 3px;
    transition: background-color 0.3s;
    height: 36px;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + span {
    .unchecked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .checked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }
  }

  input[type="checkbox"]:checked + span {
    background: linear-gradient(
          ${(props) => props.theme.backgroundColors.primary},
          ${(props) => props.theme.backgroundColors.primary}
        )
        padding-box,
      /*this is your grey background*/
        linear-gradient(to bottom, #ff8887 0%, #ff6565 100%) border-box;
    /* background-color: ${(props) => props.theme.backgroundColors.primary}; */
    border: 1px solid transparent;
    transition: background-color 0.3s;
    color: #fff !important;

    .checked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .unchecked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + span {
    .unchecked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .checked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }
  }

  input[type="radio"]:checked + span {
    background: linear-gradient(
          ${(props) => props.theme.backgroundColors.primary},
          ${(props) => props.theme.backgroundColors.primary}
        )
        padding-box,
      /*this is your grey background*/
        linear-gradient(to bottom, #ff8887 0%, #ff6565 100%) border-box;
    /* background-color: ${(props) => props.theme.backgroundColors.primary}; */
    border: 1px solid transparent;
    transition: background-color 0.3s;
    color: #fff !important;

    .checked-icon {
      display: flex;
      transition: 0.5s ease-in-out;
    }

    .unchecked-icon {
      display: none;
      transition: 0.5s ease-in-out;
    }

    .buttons {
      width: 100% !important;
      padding: 1px;
      background-image: linear-gradient(to bottom, #ff8887 0%, #ff6565 100%);
      border-radius: 3px;
      outline: none;
      max-height: 36px;
    }
  }

  img {
    width: 13px;
    height: 13px;
    margin-right: 13px;
  }
`;

export const ChargeLater = styled.div`
  display: flex;
  /* margin-bottom: 20px; */
  align-items: center;
  justify-content: center;

  .margin-button {
    margin-top: 10px;
  }

  p {
    font-size: 12px;
    padding: 0.5em;
    width: 24em;
  }

  label span {
    min-width: 168px;
    max-width: 100%;
  }
`;

export const CustomerFees = styled(ChargeLater)`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin-bottom: 0px;
  }

  label span {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #f6f6f6;
    padding: 10px 18px;
    border-radius: 3px;
    transition: background-color 0.3s;
    height: 36px;
    min-width: 168px;
    max-width: 100%;
  }
`;

export const LinkGeneratedHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #33c344;
  }

  .error-title {
    color: #f00 !important;
  }
`;

export const Error = styled.p`
  color: #f00 !important;
  margin: 0px;
`;

export const Success = styled.p`
  color: #33c344 !important;
`;

export const BoxPaymentRequest = styled.div`
  background: ${(props) => props.theme.backgroundColors.secondary};
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;

  .create-plan {
    margin: 1em 0;
  }

  form {
    .brasilian-time--form {
      color: ${(props) => props.theme.textColors.third};
      margin-left: 77%;
      transform: translateY(-10px) !important;

      @media screen and (max-width: 768px) {
        margin-left: 0%;
        /* transform: translateY(-10px) !important; */
      }
    }

    @media screen and (max-width: 916px) {
      width: 100%;
    }
  }
`;

export const PaymentRequestItems = styled.div`
  flex-direction: column;
  margin-top: 20px;

  .button--margin-top {
    margin-top: 16px;
    min-width: 100%;

    button {
      min-width: 100%;
    }
  }

  .brasilian-time {
    color: ${(props) => props.theme.textColors.third};
    transform: translateY(-10px);
    text-align: right;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }

  .description-label {
    @media screen and (max-width: 916px) {
      height: 30px;
    }
  }

  .input-hidden {
    height: 0px !important;
  }

  .margin--top {
    margin-top: 16px;
  }

  .payment-request-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 1.2em;

    .label {
      color: ${(props) => props.theme.textColors.third};
      min-width: 30%;
      max-width: 30%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 916px) {
        width: 50%;
      }
    }

    .input--half--expiration {
      width: 100%;
      justify-content: space-between;

      div {
        min-width: 48% !important;
        max-width: 48% !important;

        input {
          min-width: 100%;
          width: 100%;
        }
      }
    }

    .value {
      width: 90%;

      input {
        width: 100%;

        @media screen and (max-width: 916px) {
          width: 100%;
        }
      }

      .value-text {
        width: 220px;
      }
      color: ${(props) => props.theme.textColors.primary};

      @media screen and (max-width: 916px) {
        width: 100%;
      }

      select {
        width: 100%;
      }
    }

    /* .payment-request-item{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 1px;
      background-image: linear-gradient(to bottom, ${(props) =>
      props.theme.backgroundColors.seventh} 0%, ${(props) =>
      props.theme.backgroundColors.eighth} 100%);
      border-radius: 3px;
      max-height: 39px;

      
      .value-text {
        width: 220px;
      }
      color: ${(props) => props.theme.textColors.primary};

      @media screen and (max-width: 916px) {
        & > div {
          min-width: 100% !important;
        }
      }
    } */

    .value-95 {
      padding: 0 16px;

      /*       
      select {
        width: 95%;
      } */
    }

    .width-select,
    .width-select-state,
    .select--half {
      width: 100%;
      border-radius: 3px;
      outline: none;
      height: 36px;
    }

    .input--half,
    .select--half {
      width: 49%;
    }

    .input--half {
      .input__element {
        background: linear-gradient(
              ${(props) => props.theme.backgroundColors.fourth},
              ${(props) => props.theme.backgroundColors.fourth}
            )
            padding-box,
          linear-gradient(
              to bottom,
              ${(props) => props.theme.backgroundColors.seventh},
              ${(props) => props.theme.backgroundColors.eighth}
            )
            border-box;
        border-radius: 3px;
        border: 1px solid transparent;
        /* font-family: "Brandon Text Regular" !important; */
        width: 100%;
        height: 36px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: ${(props) => props.theme.textColors.primary};
        padding: 0 8px;
      }
    }

    .width-select-100 {
      width: 100%;
      padding: 1px;
      background-image: linear-gradient(
        to bottom,
        ${(props) => props.theme.backgroundColors.seventh} 0%,
        ${(props) => props.theme.backgroundColors.eighth} 100%
      );
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      height: 36px;
    }

    .value-limited {
      width: 95%;
      color: ${(props) => props.theme.textColors.primary};

      .error-title {
        color: #f00 !important;
      }

      .card-band-finish {
        max-width: 25px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }
  }

  .flex-direction-collumn {
    flex-direction: column;
  }

  .payment-link {
    word-wrap: break-word;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.textColors.primary};
    top: -20px;
    margin-bottom: 20px;
  }
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  flex-direction: column;

  p {
    margin: 20px 0;
    color: ${(props) => props.theme.textColors.primary};
  }

  .div-canvas-qrcode {
    margin-bottom: 15px;
  }
`;

export const CloseIcon = styled.div`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url("/images/close.svg") no-repeat center;
  mask: url("/images/close.svg") no-repeat center;
  height: 25px;
  width: 25px;
  cursor: pointer;
`;

export const ExpandPersonalData = styled.a<ExpandProps>`
  cursor: pointer;
  display: flex;
  align-items: center;

  .expand-icon {
    -webkit-mask: url(${(props) => props.url}) no-repeat center;
    mask: url(${(props) => props.url}) no-repeat center;
    background-color: ${(props) => props.theme.textColors.primary};
    background-size: cover;
    height: 20px;
    width: 20px;
  }
`;

export const SelectState = styled.select<SelectProps>`
  background-color: ${(props) => props.theme.backgroundColors.fourth};
  width: 100%;
  border-radius: 3px;
  color: ${(props) => props.theme.textColors.primary};
  padding: 6px 16px 6px 10px;
  border: 0px;
  right: 0px;
  height: 34px;
`;

export const InputStyled = styled.input`
  background: linear-gradient(
        ${(props) => props.theme.backgroundColors.fourth},
        ${(props) => props.theme.backgroundColors.fourth}
      )
      padding-box,
    linear-gradient(
        to bottom,
        ${(props) => props.theme.backgroundColors.seventh},
        ${(props) => props.theme.backgroundColors.eighth}
      )
      border-box;
  border-radius: 3px;
  border: 1px solid transparent;
  /* font-family: "Brandon Text Regular" !important; */
  width: auto;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.textColors.primary};
  padding: 0 8px;

  /* option {
    background: ${(props) => props.theme.backgroundColors.fourth};
  } */
`;

export const CountrySelectContainer = styled.div`
  min-width: 100%;
  max-width: 100%;
  padding: 0 4px 0 4px;
  text-align: center;
  position: relative;
  height: 36px;
  border-radius: 3px;
  background: linear-gradient(
        ${(props) => props.theme.backgroundColors.fourth},
        ${(props) => props.theme.backgroundColors.fourth}
      )
      padding-box,
    linear-gradient(
        to bottom,
        ${(props) => props.theme.backgroundColors.seventh},
        ${(props) => props.theme.backgroundColors.eighth}
      )
      border-box;

  div {
    min-width: 100%;
    max-width: 100%;
    height: 36px;
    padding-bottom: 0px;
    font-size: 14px;

    input {
      background: linear-gradient(
            ${({ theme }) => theme.backgroundColors.secondary},
            ${(props) => props.theme.backgroundColors.secondary}
          )
          padding-box,
        linear-gradient(
            to bottom,
            ${(props) => props.theme.backgroundColors.seventh},
            ${(props) => props.theme.backgroundColors.eighth}
          )
          border-box;
      border-radius: 3px;
      color: ${(props) => props.theme.textColors.primary};
    }
  }

  button {
    padding: 0px;
    border: 0px;

    span {
      color: ${(props) => props.theme.textColors.fourth};

      .ReactFlagsSelect-module_label__27pw9 {
        color: ${(props) => props.theme.textColors.primary};
      }
    }
  }

  span {
    font-size: 14px;
    padding-left: 5px;

    svg {
      min-width: 16px;
    }
  }

  .fixed-label {
    position: absolute;
    top: 5px;
    left: 25px;
  }

  .ReactFlagsSelect-module_filterBox__3m8EU {
    background: linear-gradient(
          ${(props) => props.theme.backgroundColors.fourth},
          ${(props) => props.theme.backgroundColors.fourth}
        )
        padding-box,
      linear-gradient(
          to bottom,
          ${(props) => props.theme.backgroundColors.seventh},
          ${(props) => props.theme.backgroundColors.eighth}
        )
        border-box;
  }

  ul {
    background: linear-gradient(
          ${(props) => props.theme.backgroundColors.fourth},
          ${(props) => props.theme.backgroundColors.fourth}
        )
        padding-box,
      linear-gradient(
          to bottom,
          ${(props) => props.theme.backgroundColors.seventh},
          ${(props) => props.theme.backgroundColors.eighth}
        )
        border-box;

    scrollbar-base-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-base-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-3dlight-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-highlight-color: ${(props) =>
      props.theme.backgroundColors.fourth};
    scrollbar-track-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-arrow-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-shadow-color: ${(props) => props.theme.backgroundColors.fourth};
    scrollbar-dark-shadow-color: ${(props) =>
      props.theme.backgroundColors.fourth};

    ::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.backgroundColors.fourth};
    }

    ::-webkit-scrollbar {
      width: 6px;
      background: ${(props) => props.theme.backgroundColors.fourth};
    }

    ::-webkit-scrollbar-thumb {
      background: #000;
    }

    li {
      margin: 0px;
      padding: 4px 4px 4px 0px;

      span {
        .ReactFlagsSelect-module_label__27pw9 {
          opacity: 1;
        }

        svg {
          min-width: 16px;
        }
      }

      &:hover {
        background: ${(props) => props.theme.backgroundColors.eighth};
        transition: all 0.3s;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  color: #ff0000;
  margin: 0px;
`;
