import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FieldValues } from "react-hook-form";

//Services
import CreateStores from "services/CreateStore";
import SessionServices from "services/Session";
import StoreServices from "services/Store";
import WebhooksServices from "services/Webhooks";

//Store
import { useAuth } from "../../../store/auth";
import { useSettings } from "../../../store/settings";

//Helpers
import { countDown } from "helpers/countDown";

//Assets
import eyeIcon from "assets/images/icon-eye.svg";
import hideIcon from "assets/images/icon-hide.svg";

export const useSignup = () => {
  const history = useHistory();
  const location = useLocation();

  const setSession = useAuth((state) => state.setSession);
  const setSettings = useSettings((state) => state.setSettings);

  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");
  const [isCnpj, setIsCnpj] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [planFromUTM, setPlanFromUTM] = useState("basic");
  const [utmSource, setUtmSource] = useState("");
  const [allParams, setAllParams] = useState<{ [k: string]: string }>({});
  const [hideShowPassIcon, setHideShowPassIcon] = useState(eyeIcon);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [showPassProperties, setShowPassProperties] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [timerToNuvemshopRegister, setTimerToNuvemshopRegister] = useState(-1);

  const [verifyPass, setVerifyPass] = useState({
    characters: false,
    lower: false,
    upper: false,
    number: false,
  });
  const [isValidPass, setIsValidPass] = useState(false);

  const toggleShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
      setHideShowPassIcon(hideIcon);
    } else {
      setHideShowPassIcon(eyeIcon);
      setInputType("password");
    }
  };

  const hasPropValue = (value: string | undefined) => {
    return value && value !== "";
  };

  const createDashboardUser = (data: FieldValues) => {
    const isValidFullName = data.fullName.split(" ").length > 1;

    if (isValidPass && isValidFullName) {
      setIsCreatingUser(true);

      const cleanedIdentificationNumber = data.identificationNumber
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "");

      const newUserPayload = {
        plan: planFromUTM,
        identificationNumber: cleanedIdentificationNumber,
        name: data.fullName,
        email: data.email.toLowerCase(),
        password: data.password,
      };

      let endpointArray = [];
      let endpointString = "";
      let endpointObject = {
        firstname: `firstname=${data.fullName}`,
        email: `email=${data.email.toLowerCase()}`,
        phone: `phone=${data.phone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "")
          .replaceAll("-", "")}`,
        identificationnumber: `identificationnumber=${cleanedIdentificationNumber}`,
        plan: planFromUTM ? `plan=${planFromUTM}` : "",
        utm_source: hasPropValue(allParams.utm_source)
          ? `utm_source=${allParams.utm_source}`
          : "",
        utm_medium: hasPropValue(allParams.utm_medium)
          ? `utm_medium=${allParams.utm_medium}`
          : "",
        utm_campaign: hasPropValue(allParams.utm_campaign)
          ? `utm_campaign=${allParams.utm_campaign}`
          : "",
        utm_content: hasPropValue(allParams.utm_content)
          ? `utm_content=${allParams.utm_content}`
          : "",
        utm_term: hasPropValue(allParams.utm_term)
          ? `utm_term=${allParams.utm_term}`
          : "",
      };

      for (const [_, value] of Object.entries(endpointObject)) {
        if (value !== "") {
          endpointArray.push(value);
        }
      }

      endpointString = endpointArray.join("&");

      const { code } = allParams;

      CreateStores.singup(newUserPayload, endpointString)
        .then((responseSignup) => {
          SessionServices.getToken({
            username: data.email.toLowerCase(),
            password: data.password,
            code: code,
          })
            .then((response) => {
              const token = response.data.token;
              const storeId = response.data.storeId;

              SessionServices.getUserInfos(token).then((responseUser: any) => {
                StoreServices.getDealType(token, storeId).then(
                  (responseDealType) => {
                    if (window.gtag_report_conversion) {
                      window.gtag_report_conversion(location.pathname);
                    }

                    localStorage.setItem(
                      "session",
                      JSON.stringify({
                        token: token,
                        expiryAt: response.data.expiryAt,
                        storeId: storeId,
                        entityType: response.data.entityType,
                        businessId: response.data.businessId,
                        role: response.data.role,
                        name: response.data.name,
                        hasCommissioning:
                          responseDealType.data.dealType === 0 ||
                          responseDealType.data.dealType === 2
                            ? true
                            : false,
                      })
                    );

                    const storeEnvironment =
                      responseDealType.data.status !== "enabled" &&
                      responseDealType.data.status !== "denied"
                        ? "test"
                        : "prod";
                    setSettings({
                      environment: storeEnvironment,
                      status: responseDealType.data.store.status,
                      identificationNumber: cleanedIdentificationNumber,
                      fullName: data.fullName,
                      lastStepOfStoreRegister: 1,
                      isForeigner: false,
                      shouldCreateDefaultlLink: storeId === 359
                    });

                    setSession({
                      token: token,
                      expiryAt: response.data.expiryAt,
                      storeId: storeId,
                      entityType: response.data.entityType,
                      businessId: response.data.businessId,
                      role: response.data.role,
                      name: response.data.name,
                      hasCommissioning:
                        responseDealType.data.dealType === 0 ||
                        responseDealType.data.dealType === 2
                          ? true
                          : false,
                    });

                    localStorage.setItem(
                      "storeIdSession",
                      JSON.stringify({
                        storeIdSession: response.data.storeId,
                      })
                    );

                    localStorage.setItem(
                      "@PrimefySidebarInfos",
                      JSON.stringify({
                        userName: responseUser.data.user.name,
                        businessName: responseUser.data.stores[0].businessName,
                        logoPath: responseUser.data.stores[0].logoPath,
                        storeId: storeId,
                        role: response.data.role,
                      })
                    );

                    localStorage.removeItem("@PremeRecurringHasAccount");
                    localStorage.setItem(
                      "@PremeRecurringHasAccount",
                      JSON.stringify({ alreadyHasAccount: false })
                    );

                    history.push("/");
                  }
                );
              });
            })
            .catch((error) => {
              console.log(error.data);
            })
            .finally(() => {
              setIsCreatingUser(false);
            });
        })
        .catch((err) => {
          console.log(err);
          err.response.data.detail === "user already registered"
            ? setSignupError("emailExist")
            : setSignupError("identificationNumberExist");

          setIsCreatingUser(false);
        });
    }

    if (!isValidPass) {
      setSignupError("pass");
    }

    if (!isValidFullName) {
      setSignupError("shortFullName");
    }
  };

  useEffect(() => {
    const requirements = [];
    for (const [key, value] of Object.entries(verifyPass)) {
      requirements.push(value);
    }

    setIsValidPass(requirements.every((requirement) => !!requirement));
  }, [verifyPass]);

  useEffect(() => {
    let allParams = new URLSearchParams(location.search);
    let allFormattedParams = Object.fromEntries(allParams.entries());

    if (allFormattedParams.plan) {
      setPlanFromUTM(allFormattedParams.plan);
    }

    setAllParams(allFormattedParams);
  }, []);

  useEffect(() => {
    const { code } = allParams;

    if (code !== undefined && code !== "") {
      WebhooksServices.sendNuvemshopCode(code)
        .then((response) => {})
        .catch((err) => console.log(err));
    }
  }, [allParams]);

  useEffect(() => {
    const { code } = allParams;
    if (code && code !== "") {
      countDown(setTimerToNuvemshopRegister);
    }
  }, [allParams]);

  return {
    toggleShowPassword,
    createDashboardUser,
    inputType,
    hideShowPassIcon,
    isCreatingUser,
    cpfCnpjLabel,
    setCpfCnpjLabel,
    isCnpj,
    setIsCnpj,
    verifyPass,
    setVerifyPass,
    showPassProperties,
    setShowPassProperties,
    signupError,
    isValidPass,
    timerToNuvemshopRegister,
    allParams,
  };
};
