import React, { useState } from "react";

//Assets
import defaultAvatar from "assets/images/global/default-avatar.png";
import avatar from "../../../assets/images/avatar.svg";

//Styles
import * as styled from "./topbar-styles";

type TUserProfile = {
  handleShowModal: () => void;
  isAtTopbar: boolean;
};

const UserProfile = ({ handleShowModal, isAtTopbar }: TUserProfile) => {
  const [userInfos, setUserInfos] = useState(() => {
    const sidebarInfos = localStorage.getItem("@PrimefySidebarInfos");
    const { userName, businessName, logoPath } = JSON.parse(
      sidebarInfos ? sidebarInfos : "{}"
    );

    return {
      userName: userName,
      businessName: businessName,
      logoPath: logoPath && logoPath !== "" ? logoPath : defaultAvatar,
    };
  });

  return (
    <styled.UserProfileContainer
      onClick={handleShowModal}
      isAtTopbar={isAtTopbar}
    >
      <div className="name">
        <p>{userInfos.userName}</p>
      </div>

      <div className="avatar-photo">
        <div>
          <img
            src={userInfos.logoPath ? userInfos.logoPath : avatar}
            alt="profile"
          />
        </div>
      </div>
    </styled.UserProfileContainer>
  );
};

export default UserProfile;
