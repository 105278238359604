import React, { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useIntl } from 'react-intl';
import QRCode from 'qrcode.react';

//Helpers
import download from 'helpers/download';

//Components
import Buttons from '../Forms/Buttons';

//Assets
import qrCodeIcon from 'assets/images/global/qrcode.svg';

//Styles
import * as styled from './details-styles';

type DetailData = {
  link: string;
  label: string;
  imgValue?: string;
  loading?: {
      width: number
  }
}

const QRCodeItem = ({ link, label, imgValue = qrCodeIcon, loading }: DetailData) => {
  const intl = useIntl();

  const paymentLinkQrCodeRef = useRef<HTMLDivElement>(null);

  return (<><styled.DetailItem>
          <div className={`label`}> {label} </div>
          {loading ? <Skeleton width={loading.width} className="skeleton" /> : <div className={`value-column`}>
            {/* <img src={imgValue} alt={imgValue} />  */}
            <div className="qr-code" ref={paymentLinkQrCodeRef}>
              <QRCode
                value={link}
                size={128}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
                renderAs={"svg"}
                // imageSettings={{
                //   src: "https://static.zpao.com/favicon.png",
                //   x: undefined,
                //   y: undefined,
                //   height: 24,
                //   width: 24,
                //   excavate: true,
                // }}
              />
            </div>

            <Buttons mode="unfilled" label={intl.formatMessage({ id: "manager.links.details.download.qrcode" })} handler={() => download(paymentLinkQrCodeRef, 'QRCode', '')} />
          </div> }
      </styled.DetailItem >
  </>)
}

export default QRCodeItem;
