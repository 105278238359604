import styled, { css } from "styled-components";

interface IconProps {
  icon: string;
}

type TInputProps = {
  bgColors?: string;
  isInputWithoutBorder?: boolean;
  borderSide?: string;
  bottomSeparator?: boolean;
  disabled?: boolean;
};

type ExpandSearchProps = {
  value: string;
};

export const Input = styled.div<TInputProps>`
  background: ${({ theme }) => theme.backgroundColors.primary};

  ${(props) =>
    props.isInputWithoutBorder
      ? css`
          border: 1px solid transparent !important;
          height: 24px;
          border-radius: 3px;
        `
      : css`
          border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
          height: 40px;
          border-radius: 3px;
        `}

  ${(props) =>
    (props.borderSide === "right" || props.bottomSeparator) &&
    css`
      border: 0px;
    `}

  ${(props) =>
    props.borderSide === "right" &&
    css`
      border-right: 1px solid ${(props) => props.theme.backgroundColors.eighth} !important;
      border-radius: 0px;
    `}

  ${(props) =>
    props.bottomSeparator &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.backgroundColors.eighth} !important;
      border-radius: 0px;
    `}

  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.contentColors.primary};
  padding: 0 8px;

  img {
    cursor: pointer;
    transition: all 0.3s;
  }

  input {
    width: 100%;
    background: transparent;

    &:disabled {
      cursor: not-allowed;
      color: #737373;
    }
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 1;
    filter: invert(0.8);
  }

  input[type="date"]::-webkit-datetime-edit-day-field:focus,
  input[type="date"]::-webkit-datetime-edit-month-field:focus,
  input[type="date"]::-webkit-datetime-edit-year-field:focus {
    background-color: ${(props) => props.theme.primaryColors.primary};
    color: white;
    outline: none;
  }

  input[type="date"]::-webkit-datetime-edit-day-field:hover,
  input[type="date"]::-webkit-datetime-edit-month-field:hover,
  input[type="date"]::-webkit-datetime-edit-year-field:hover {
    background-color: ${(props) => props.theme.primaryColors.primary};
    color: white;
    outline: none;
  }

  input[type="time"]::-webkit-datetime-edit-day-field:hover,
  input[type="time"]::-webkit-datetime-edit-month-field:hover,
  input[type="time"]::-webkit-datetime-edit-year-field:hover {
    background-color: ${(props) => props.theme.primaryColors.primary};
    color: white;
    outline: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 1;
    filter: invert(0.8);
  }

  span {
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
    margin-right: 8px;
    color: ${(props) => props.theme.contentColors.secondary};
  }
`;

export const InputWithFixedLabel = styled(Input)`
  input {
    min-width: 60%;
    max-width: 60%;
  }

  span {
    min-width: 40%;
    max-width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid ${(props) => props.theme.backgroundColors.secondary} !important;
  }
`;

export const SearchInput = styled(Input)`
  border: 2px solid ${(props) => props.theme.backgroundColors.secondary} !important;
  background: ${(props) => props.theme.backgroundColors.primary};
`;

export const ExpandSearch = styled.div<ExpandSearchProps>`
  display: flex;
  border-radius: 4px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0px 8px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  margin-right: 8px;
  width: auto;

  .wrapper {
    position: relative;

    input[value !== ""] ~ .placeholder {
      opacity: 0;
    }
  }

  .placeholder {
    position: absolute;
    font-size: 14px;
    pointer-events: none;
    left: -1px;
    transition: 0.01s ease all;
    color: ${(props) => props.theme.contentColors.secondary};
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;

    ${(props) =>
      props.value === ""
        ? css`
            opacity: 1;
            transition: 0.01s ease all;
            width: 43px;
          `
        : css`
            opacity: 0;
            transition: 0.01s ease all;
            width: 220px;
          `}
  }

  input:focus ~ .placeholder {
    width: 220px;
    transition: all 0.3s ease-in;
    color: ${(props) => props.theme.contentColors.primary};
  }

  input {
    ${(props) =>
      props.value === ""
        ? css`
            width: 43px;
          `
        : css`
            width: 220px;
          `}

    background: transparent;
    transition: all 0.3s ease-in;

    &:focus {
      width: 220px;
      transition: all 0.3s ease-in;
    }
  }

  @media screen and (max-width: 916px) {
    width: auto;
  }
`;

export const Icon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.textColors.primary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size: contain;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const LeftIcon = styled.div<IconProps>`
  background-color: ${(props) => props.theme.contentColors.secondary};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size: contain;
  margin-right: 8px;
  margin-left: 0px;
  width: 10px;
  height: 10px;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  input + span {
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: transparent;
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      display: inline-block;
      vertical-align: middle;
      transition: all 0.1s ease;
      margin: 0 8px 3px 0;
    }
  }

  input:disabled + span {
    cursor: not-allowed;
    color: #737373;
  }

  input[type="checkbox"]:checked + span:before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.8 1.4L7.4 -9.53674e-08L3.4 4L1.4 2L-9.53674e-08 3.4L3.4 6.8L8.8 1.4Z' fill='white'/%3E%3C/svg%3E");
    border: 1px solid transparent;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0);
    background-color: ${(props) => props.theme.primaryColors.primary};
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.1s ease;
  }
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  input + span {
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      display: inline-block;
      vertical-align: middle;
      transition: all 0.1s ease;
      margin: 0 8px 3px 0;
    }
  }

  input[type="radio"]:checked + span:before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.8 1.4L7.4 -9.53674e-08L3.4 4L1.4 2L-9.53674e-08 3.4L3.4 6.8L8.8 1.4Z' fill='white'/%3E%3C/svg%3E");
    border: 1px solid transparent;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0);
    background-color: ${(props) => props.theme.primaryColors.primary};
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.1s ease;
  }
`;
