import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FormattedMessage } from 'react-intl';

//Styles
import * as styled from './details-styles';

type DetailData = {
    label: string;
    value: string;
    foreignValue: string;
    loading?: {
        width: number
    }
}

const DetailItemWithForeignAmount = ({ label, value, foreignValue, loading }: DetailData) => {

  return (
      <styled.DetailItem>
        <div className="label"> {label} </div>
        {loading ? <Skeleton width={loading.width} className="skeleton" /> : <span className="value">{foreignValue} ({value})</span> }
      </styled.DetailItem>
  )
}

export default DetailItemWithForeignAmount;
