import React, { useState } from 'react';
import searchIcon from '../../assets/images/search.svg';
import { useHistory, useLocation } from 'react-router-dom';

import Input from '../Forms/InputFloatLabel';

interface Placeholder {
  placeholder: string;
  css?: string;
}

const Search = ({ placeholder, css = '' }: Placeholder) => {
  const [searchTarget, setSearchTarget] = useState('');

  const history = useHistory();
  const location = useLocation();

  function formatText() {
    let queryValueId = '';
    let queryValueCpf = '';

    let searchTargetUpperCase = searchTarget.toUpperCase();

    if ((searchTargetUpperCase.startsWith('id: #')) || (searchTargetUpperCase.startsWith('ID: #'))) {
      queryValueId = searchTargetUpperCase.slice(5);
    } else if ((searchTargetUpperCase.startsWith('id: ')) || (searchTargetUpperCase.startsWith('ID: '))) {
      queryValueId = searchTargetUpperCase.slice(4);
    } else if (searchTargetUpperCase.startsWith('#')) {
      queryValueId = searchTargetUpperCase.slice(1);
    } else if (searchTargetUpperCase.startsWith('P')) {
      queryValueId = searchTargetUpperCase;
    } else if (searchTargetUpperCase.startsWith('cpf: ')) {
      queryValueCpf = searchTargetUpperCase.slice(5);
    } else if (!isNaN(parseFloat(searchTargetUpperCase))) {
      queryValueCpf = searchTargetUpperCase;
    }
    const formattedText = queryValueCpf === '' ? queryValueId : queryValueCpf;
    pushRoute(formattedText);
  }

  function pushRoute(text: string) {
    if (location.pathname === '/search') {
      history.push({
        pathname: '/search',
        state: text
      });
    } else {
      history.push({
        pathname: /admin/.test(location.pathname) ? '/admin/transactions' : '/transactions',
        state: text
      });
    }

  }

  // function handleOption(event: React.FormEvent<HTMLInputElement>){

  //     setSearchTarget(event.currentTarget.value);
  // }

  return (<>
    <div className="margin-left">
      <div className="search-container">
        <div className={`${css} search-wrapper `} >
          <Input 
            mode="search" 
            placeholder={placeholder} 
            handler={setSearchTarget} 
            icon={searchIcon} 
            list="options-search" 
            onKeyDown={e => (e.keyCode === 13) ? formatText() : null} />
        </div>
      </div>
    </div>

    <datalist id="options-search" className="data-list">
      <option value="id: " >para buscar pelo número da Venda</option>
      <option value="cpf: " >para buscar pelo CPF do comprador</option>
    </datalist>
  </>
  )
}

export default Search;
