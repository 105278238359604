import React, { useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

//Hooks
import useClickOutside from '../../../hooks/useClickOutside';

//Components
import Buttons from '../../Forms/Buttons';

import arrowIcon from 'assets/images/topbar/select-arrow.svg';
import exportIcon from 'assets/images/global/export.svg';

//Styles
import * as styled from './modals-styles';

type TModal = {
  leftHandler?: any;
  rightHandler?: any;
}

const SelectAndButtonModal = ({ leftHandler, rightHandler }: TModal) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSelectAndButtonModal, setShowSelectAndButtonModal] = useState(false);

  const selectAndButtonModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  let location = useLocation();

  function handleShowModal() {
    if(selectAndButtonModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  useClickOutside(selectAndButtonModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <Buttons mode="selec-and-button" label="This year" labelRight={intl.formatMessage({ id: "filter.label.btn.export" })} icon={arrowIcon} iconRight={exportIcon} handler={handleShowModal} handlerRight={rightHandler} />
      
      {showSelectAndButtonModal && <styled.PeriodContainer 
        style={isMounted ? { animation: 'scaleModal .2s ease forwards' } : { animation: 'reveserScameModal .1s ease-out forwards' }} 
        ref={selectAndButtonModalRef}
        onAnimationEnd={() => { if (!isMounted) setShowSelectAndButtonModal(false) }}
      >

        <div className="buttons-container">
          <div className="button__item">
            <Buttons mode='simple' label={intl.formatMessage({ id: "reports.filter.period.7" })} handler={() => {}} />
          </div>
          <div className="button__item">
            <Buttons mode='simple' label={intl.formatMessage({ id: "reports.filter.period.30" })} handler={() => {}} />
          </div>
          <div className="button__item">
            <Buttons mode='simple' label="This year" handler={() => {}} />
          </div>
          <div className="button__item">
            <Buttons mode='simple' label="2020" handler={() => {}} />
          </div>
          <div className="button__item">
            <Buttons mode='simple' label="2019" handler={() => {}} />
          </div>
        </div>
      </styled.PeriodContainer>}
    </styled.RelativeContainer>
  )
} 

export default SelectAndButtonModal;
