import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

//Hooks
import useCreateCustomer from "./hook";

//Components
import { LayoutWrapper } from "components/Layout/Index";
import Buttons from "components/Forms/Buttons";
import Select from "components/Forms/Select";
import InputFloat from "components/Forms/InputFloatLabel";
import { Input } from "components/Forms/Input/Input";

//Services
import SessionService from "services/Session";

//Helpers
import brStates from "helpers/states-format-id";

import * as styled from "./styles";

const CreateCustomer = () => {
  const history = useHistory();
  const intl = useIntl();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const {
    processing,
    submitNewCustomer,
    handleSubmit,
    control,
    errors,
    customerAddressData,
    isCnpj,
    cpfCnpjLabel,
  } = useCreateCustomer();

  return (
    <LayoutWrapper>
      <styled.LeftRightContainer>
        <styled.Left className="border-right">
          <styled.BoxDetails className="border-bottom">
            <div className="centered-container">
              <styled.Header>
                <div className="column-header">
                  <FormattedMessage id="customers.page.create.customer" />
                </div>
              </styled.Header>

              <styled.FormContainer>
                <form onSubmit={handleSubmit(submitNewCustomer)}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={intl.formatMessage({
                          id: !isCnpj
                            ? "detail.order.name.first"
                            : "detail.order.business.name",
                        })}
                        {...field}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => <p className="error">{message}</p>}
                  />

                  {!isCnpj ? (
                    <>
                      <Controller
                        name="surname"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label={intl.formatMessage({
                                id: "detail.order.name.surname",
                              })}
                              {...field}
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="surname"
                        render={({ message }) => (
                          <p className="error">{message}</p>
                        )}
                      />
                    </>
                  ) : null}

                  <div className="form-item">
                    <label>{cpfCnpjLabel}</label>

                    <Controller
                      name="identificationNumber"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          mode="normal-mask"
                          mask={
                            isCnpj ? "99.999.999/9999-99" : "999.999.999-99"
                          }
                          handler={() => {}}
                          {...field}
                          maxLength={18}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="identificationNumber"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <div className="form-item">
                    <label>
                      {intl.formatMessage({
                        id: !isCnpj
                          ? "detail.order.dob"
                          : "detail.order.register",
                      })}
                    </label>

                    <Controller
                      name="birthdate"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          mode="normal-mask"
                          mask={"99/99/9999"}
                          handler={() => {}}
                          {...field}
                          maxLength={10}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="birthdate"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input label="Email" disabled={false} {...field} />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => <p className="error">{message}</p>}
                  />

                  <div className="form-item">
                    <label>Phone</label>

                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <InputFloat
                          mode="normal-mask"
                          mask={"(99) 99999-9999"}
                          disabled={false}
                          handler={() => {}}
                          {...field}
                          maxLength={15}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  {customerAddressData.map((item, index) => (
                    <>
                      <Controller
                        name={`address.${item.name}`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            label={intl.formatMessage({
                              id: `detail.order.${item.label}`,
                            })}
                            disabled={false}
                            maxLength={8}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`address.${item.name}`}
                        render={({ message }) => (
                          <p className="error">{message}</p>
                        )}
                      />
                    </>
                  ))}

                  <div className="form-item">
                    <label>
                      {intl.formatMessage({ id: "detail.order.state" })}
                    </label>

                    <Controller
                      name="address.state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={brStates}
                          mode="normal"
                          placeholder={""}
                          handler={() => {}}
                          {...field}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="address.state"
                      render={({ message }) => (
                        <p className="error">{message}</p>
                      )}
                    />
                  </div>

                  <div className="button-bottom-container">
                    {!processing && (
                      <Buttons
                        mode="filled"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({
                          id: "customers.page.finish.create.customer",
                        })}
                        customClass="full-width"
                      />
                    )}

                    {processing && (
                      <Buttons
                        mode="filled-processing"
                        handler={() => {}}
                        type="submit"
                        label=""
                        customClass="full-width"
                        disable={true}
                      />
                    )}
                  </div>
                </form>
              </styled.FormContainer>
            </div>
          </styled.BoxDetails>
        </styled.Left>
      </styled.LeftRightContainer>
    </LayoutWrapper>
  );
};

export default CreateCustomer;
