import React, { useState } from 'react';

//Assets
import arrowIcon from 'assets/images/topbar/select-arrow.svg';

//Styles
import * as styled from './faq-styles';

type FaqItemData = {
    question: string;
    answer: string;
}

const FaqItem = ({ question, answer }: FaqItemData) => {
    let [isOpen, setIsOpen] = useState(false);
    return (
      <styled.FaqItem onClick={e => setIsOpen(!isOpen)}>
        <styled.Question arrowIcon={arrowIcon}>
          <span className={`${isOpen ? 'active' : ''}`}>{question}</span>
        </styled.Question>
        
        {isOpen && <div className="answer"> {answer}</div>}
      </styled.FaqItem>
    )
}

export default FaqItem
