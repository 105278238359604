import styled from "styled-components";

interface HeaderStatusProps {
  bg?: string;
}

export const BoletoToActivateModal = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  animation: fade 0.2s ease forwards;
`;

export const ModalMainContainer = styled.div`
  z-index: 99999999999;
  position: fixed;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  left: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-centered {
    width: 600px;
    font-size: 14px !important;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 8px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      border-bottom: 1px solid ${(props) => props.theme.backgroundColors.third};

      h5 {
        color: #202223;
        font-size: 20px;
      }

      img {
        cursor: pointer;
      }
    }

    .loading-connecting-container {
      height: 200px;
      display: flex;
      justify-content: center;

      .spinner-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .spinner {
          border: 6px solid ${(props) => props.theme.primaryColors.fifth};
          border-left-color: #0e82f8;
          height: 82px;
          width: 82px;
          border-radius: 50%;
          animation: spin 0.8s linear infinite;
          margin-right: 8px;

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }

        p {
          margin-top: 16px;
          color: #202223;
          font-size: 16px;
        }
      }
    }

    .form {
      padding: 0px 20px;

      .red-info-container {
        background: #ffebe9;
        border-radius: 3px;
        padding: 16px;
        margin: 24px 0;
        display: flex;
        align-items: center;

        p,
        a {
          color: #000;
          font-size: 14px;
        }

        a {
          text-decoration: underline;
          cursor: pointer;
        }

        img {
          margin-right: 8px;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 20px;
      border-top: 1px solid ${(props) => props.theme.backgroundColors.third};

      button {
        font-weight: 500 !important;

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
`;

export const Background = styled.div<HeaderStatusProps>`
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 3px;
  margin-right: 8px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Tip = styled.div`
  .tip-intern-container {
    padding: 16px 24px;

    div {
      display: flex;
      margin-bottom: 5px;

      img {
        margin-right: 4px;
      }

      p {
        font-size: 13px;
        color: ${(props) => props.theme.contentColors.secondary};
        font-family: "Brandon Text Medium" !important;
      }
    }

    p {
      font-size: 13px;
      color: ${(props) => props.theme.contentColors.primary};
    }
  }

  border-top: 1px solid ${(props) => props.theme.backgroundColors.third};
`;
