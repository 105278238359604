import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Services
import SessionService from "services/Session";
import BankAccountService from "services/BankAccount";
import FinancialsServices from "services/FinancialsServices";

//Helpers
import formatFinancialTransactions from "../../helpers/formatFinancialTransactions";
import formatFinancialIncoming from "../../helpers/formatFinancialIncoming";

//Types
import { WithdrawalTransaction } from "../types";

export const useFinancials = () => {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [withdrawalTransactions, setWithdrawalTransactions] = useState<
    WithdrawalTransaction[]
  >([]);
  const [incomingTransactions, setIncomingTransactions] = useState<
    WithdrawalTransaction[]
  >([]);
  const [incomingAmount, setIncomingAmount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [numberTotalOfOrders, setNumberTotalOfOrders] = useState(1);

  const [openWithdrawalTransaction, setOpenWithdrawalTransaction] = useState({
    visibleDate: "",
    visibleTransaction: false,
    type: "",
  });

  useEffect(() => {
    setPageIndexProcessing(true);

    FinancialsServices.getReceivables(pageIndex).then((response) => {
      BankAccountService.getWithdrawals(pageIndex).then(
        (responseWithdrawals) => {
          setWithdrawalTransactions(
            formatFinancialTransactions(
              responseWithdrawals.data,
              response.data.items
            )
          );
          setPageIndexProcessing(false);
        }
      );
    });

    FinancialsServices.getPendingReceivables(pageIndex).then((response) => {
      const incomings = formatFinancialIncoming(response.data.items);
      setIncomingTransactions(incomings);

      const incomingsAmount = incomings.reduce((acc, current) => {
        return acc + current.payinAmount;
      }, 0);
      setIncomingAmount(incomingsAmount);
    });
  }, [pageIndex]);

  function toggleWithdrawalTransaction(date: string, type: string) {
    if (
      openWithdrawalTransaction.type === type &&
      date === openWithdrawalTransaction.visibleDate
    ) {
      setOpenWithdrawalTransaction({
        visibleDate: "",
        visibleTransaction: false,
        type: "",
      });
    } else {
      setOpenWithdrawalTransaction({
        visibleDate: date,
        visibleTransaction: true,
        type: type,
      });
    }
  }

  function redirectToOrderDetails(orderNumber: string, storeId: number) {
    history.push(`/transactions/${orderNumber}/${storeId}/details`);
  }

  return {
    withdrawalTransactions,
    incomingTransactions,
    openWithdrawalTransaction,
    incomingAmount,
    pageIndex,
    pageIndexProcessing,
    numberTotalOfOrders,

    redirectToOrderDetails,

    setPageIndex,
    setOpenWithdrawalTransaction,

    toggleWithdrawalTransaction,
  };
};
