import create from "zustand";

type SelectedPlanBackup = {
  gracePeriod: number;
  maxCharges: number;
  chargeDaysBefore: number;
};

interface RequestPaymentLink {
  selectedPlanBackup: SelectedPlanBackup;
  handleSelectedPlanBackup: (selectedPlan: SelectedPlanBackup) => void;
}

const initialValue: RequestPaymentLink["selectedPlanBackup"] = {
  gracePeriod: 0,
  maxCharges: 0,
  chargeDaysBefore: 0,
};

export const useRequestPaymentLinkStore = create<RequestPaymentLink>((set) => ({
  selectedPlanBackup: { ...initialValue },
  handleSelectedPlanBackup: (selectedPlan) =>
    set(() => ({ selectedPlanBackup: selectedPlan })),
}));

// requestPaymentLink: {
//   ...requestPaymentLink,
//   selectedPlanBackup: selectedPlan,
// },
