import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  RequestPaymentContext,
  ContextAllTypes,
} from "../../../../providers/requestPaymentContext";
import { FilePond, registerPlugin } from "react-filepond";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import useProduct from "./hook";

import currencyAndSymbolUnique from "../../../../helpers/currencyAndSymbolUnique";

import Buttons from "../../../Forms/Buttons";
import Select from "../../../Forms/Select";
import Input from "../../../Forms/InputFloatLabel";
import TextArea from "../../../Forms/TextArea";

import { formValidation } from "../FormValidations/paymentLinkValidations";
import { planValidations } from "../FormValidations/planValidations";
import { customerValidations } from "../FormValidations/customerValidations";
import { productValidations } from "../FormValidations/productValidations";
import ErrorMessage from "../FormValidations/ErrorMessage";

import leftArrow from "../../../../assets/images/global/leaft-arrow.svg";

import SessionService from "../../../../services/Session";

import * as styled from "../payment-request-styles";

type TCurrency = {
  id: number;
  currency: string;
  symbol: string;
};

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType
);

const PaymentLink = () => {
  const history = useHistory();
  const intl = useIntl();

  if (!SessionService.isValid()) {
    SessionService.clean();

    // if (/test/.test(window.location.href)) {
    //   window.location.href = `https://dashboard.premepay.com/login`;
    // } else {
    //   history.push("/login");
    // }
    history.push("/login");
  }

  const { product, setProduct }: ContextAllTypes = useContext(
    RequestPaymentContext
  );

  const {
    shippableItems,
    useShippable,
    useNameUnitLabel,
    useSalesPage,
    productMedia,
    processing,

    setImages,
    setProductMedia,
    setUseNameUnitLabel,
    setUseShippable,
    setUseSalesPage,
    handleProductData,
    handleShippableProductData,
    handleProductAmount,

    submitNewProduct,
  } = useProduct({ product, setProduct });

  const [labelButton, setLabelButton] = useState("ui.cta.next");

  const [customerCurrency, setCustomerCurrency] = useState("BRL");
  const [customerCurrencySymbol, setCustomerCurrencySymbol] = useState("R$");

  const [formErrors, setFormErrors] = useState([]);

  const [brValueOrder, setBrValueOrder] = useState("0");
  const [brlTaxAmount, setBrlTaxAmount] = useState("0");

  const [productType, setProductType] = useState("");

  const [conversionRates, setConversionRates] = useState();

  const [progressStep, setProgressStep] = useState(1);

  const [isForeigner, setIsForeigner] = useState(false);

  const [foreignerStoreCurrencySelect, setForeignerStoreCurrencySelect] =
    useState<TCurrency[]>();

  const [storeCurrencyInfo, setStoreCurrencyInfo] = useState(() => {
    const currencyInfo = localStorage.getItem("@PrimefyStore");

    const { currency, currencySymbol } = JSON.parse(
      currencyInfo ? currencyInfo : "{}"
    );

    return {
      currency,
      currencySymbol,
    };
  });

  const [isPtbr, setIsPtbr] = useState(() => {
    if (navigator.language === "pt-BR") {
      return true;
    }

    return false;
  });

  useEffect(() => {
    if (progressStep === 2) {
      setLabelButton("request.payment.create.product.label");
    } else {
      setLabelButton("ui.cta.next");
    }
  }, [progressStep]);

  useEffect(() => {
    if (isForeigner) {
      let allowedCurrencies = [
        { id: 0, currency: "BRL", symbol: "R$" },
        {
          id: 1,
          currency: storeCurrencyInfo.currency,
          symbol: storeCurrencyInfo.currencySymbol,
        },
      ];

      setForeignerStoreCurrencySelect(allowedCurrencies);
    }
  }, [isForeigner]);

  async function getExchange() {
    const response = await axios
      .get(
        `https://v6.exchangerate-api.com/v6/ee1a514ea6758da13f9d976a/latest/BRL`
      )
      .then((response) => {
        setConversionRates(response.data.conversion_rates);
      });
  }

  function paymentLinkGenerator(
    event: React.SyntheticEvent,
    title: string,
    description: string,
    planId: number
  ) {
    event.preventDefault();

    // RequesPaymentService.requestPaymentLinkPost({

    // }).then(response => {
    //   history.push(`/payment-links/${response.data.url}/new`);

    //   setIDUrl(response.data.id);
    //   setProcessing(false);
    //   setSuccessCardAndBoletoLinkRequest(true);
    // })
    //   .catch(() => {
    //     setSuccessCardAndBoletoLinkRequest(false);
    //     setProcessing(false);
    //   });
    // }
  }

  function handleStep(event: React.SyntheticEvent) {
    event.preventDefault();

    const currentStep = progressStep;

    if (
      productValidations({
        product,
        progressStep: currentStep,
        handleErrors: setFormErrors,
      })
    ) {
      setProgressStep(currentStep + 1);
    }
  }

  function handlePreviousStep() {
    const currentStep = progressStep;

    setProgressStep(currentStep - 1);
  }

  // useEffect(() => {
  //   let filteredCountry = currencyAndSymbolUnique.filter((currency) => {
  //     if (currency.currency === customerCurrency) {
  //       return currency.currency;
  //     }
  //   });

  //   localStorage.setItem('@PrimefyDashboard-currency', JSON.stringify({
  //     currency: customerCurrency
  //   }));

  //   setCustomerCurrencySymbol(filteredCountry.length > 0 ? filteredCountry[0].symbol : 'US$');

  // }, [customerCurrency])

  return (
    <>
      <styled.LeftRightContainer>
        <styled.Left className="border-right">
          <styled.BoxDetails className="border-bottom">
            <div className="centered-container">
              <styled.Header>
                <div className="column-header">
                  {progressStep > 1 ? (
                    <div
                      className="return-container"
                      onClick={handlePreviousStep}
                    >
                      <img src={leftArrow} alt="left arrow" />
                      <p>
                        {intl.formatMessage({
                          id: "request.payment.header.return",
                        })}
                      </p>
                    </div>
                  ) : null}

                  <FormattedMessage
                    id={
                      progressStep < 2
                        ? "create.product.title"
                        : "cutomize.product.title"
                    }
                  />
                </div>
              </styled.Header>

              <styled.ProgressBar3StepsContainer step={progressStep}>
                <div className="background">
                  <div className="progress-1"></div>
                </div>
                <div className="background">
                  <div className="progress-2"></div>
                </div>
                <div className="background">
                  <div className="progress-3"></div>
                </div>
              </styled.ProgressBar3StepsContainer>

              <styled.FormContainer>
                <form
                  onSubmit={(event) => {
                    progressStep === 1
                      ? handleStep(event)
                      : submitNewProduct(event);
                  }}
                >
                  {progressStep <= 1 ? (
                    <>
                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({ id: "request.payment.title" })}
                        </h5>

                        <Input
                          maxLength={100}
                          mode="named"
                          name="title"
                          autoComplete={"" + Math.random()}
                          value={product.title}
                          placeholder={intl.formatMessage({
                            id: "request.payment.title",
                          })}
                          handler={handleProductData}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="productTitle"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.link.title",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.description",
                          })}
                        </h5>

                        <TextArea
                          mode="named"
                          name="description"
                          value={product.description}
                          placeholder={""}
                          handler={handleProductData}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="productDescription"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.product.description",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "create.product.media.label",
                          })}
                        </h5>

                        <FilePond
                          files={productMedia}
                          onupdatefiles={setProductMedia}
                          allowMultiple={true}
                          acceptedFileTypes={[
                            "image/png",
                            "image/jpeg",
                            "image/svg",
                            "image/jpg",
                            "image/svg+xml",
                            "image/gif",
                          ]}
                          maxFiles={5}
                          name="topBanner"
                        ></FilePond>
                      </div>

                      <h5>
                        {intl.formatMessage({
                          id: "create.product.price.label",
                        })}
                      </h5>
                      <div className="form-item select-and-input-container">
                        <div className="m-20-width">
                          <Select
                            mode="clean-normal-child"
                            disabled={
                              product.amount === "NaN" ||
                              product.amount === "" ||
                              product.amount === "0"
                            }
                            name="currency"
                            placeholder=""
                            value={customerCurrency}
                            handler={setCustomerCurrency}
                          >
                            {(isForeigner && foreignerStoreCurrencySelect
                              ? foreignerStoreCurrencySelect
                              : currencyAndSymbolUnique
                            ).map((currency) => (
                              <option
                                className="bold-option"
                                key={currency.id}
                                value={currency.currency}
                              >
                                {currency.symbol}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div className="m-half-width">
                          <Input
                            disableCurrencyInput={false}
                            required={true}
                            mode="normal-currency"
                            name="amount"
                            value={product.amount}
                            placeholder={""}
                            handler={handleProductAmount}
                            isInputWithoutBorder={true}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        errors={formErrors}
                        wantedError="productPrice"
                        errorMessage={intl.formatMessage({
                          id: "error.request.payment.product.amount",
                        })}
                      />
                    </>
                  ) : null}

                  {progressStep === 2 && (
                    <>
                      <div className="form-item">
                        <div className="form-item__optional">
                          <h5>
                            {intl.formatMessage({
                              id: "request.payment.create.plan.customize.title",
                            })}
                          </h5>
                          <p>
                            {intl.formatMessage({
                              id: "request.payment.optional.label",
                            })}
                          </p>
                        </div>

                        <div className="flex">
                          <Input
                            valueToCheck={useShippable}
                            required={false}
                            mode="checkbox"
                            name="customize"
                            value="customize"
                            placeholder={intl.formatMessage({
                              id: "create.product.shippable.product",
                            })}
                            handler={() => setUseShippable(!useShippable)}
                          />
                        </div>
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="linkPaymentTypes"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.link.payment.types",
                          })}
                        />
                      </div>

                      {useShippable && (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            {shippableItems.map((shippableItem) => (
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: shippableItem.labelIntl,
                                  })}
                                </h5>

                                <Input
                                  required={false}
                                  mode="named"
                                  name={shippableItem.label}
                                  value={
                                    product.shippableProduct![
                                      shippableItem.label
                                    ]
                                  }
                                  placeholder={intl.formatMessage({
                                    id: shippableItem.placeholder,
                                  })}
                                  handler={handleShippableProductData}
                                />
                              </div>
                            ))}
                          </styled.PaymentRequestItems>
                        </div>
                      )}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={useNameUnitLabel}
                            required={false}
                            mode="checkbox"
                            name="redirect-link"
                            value="redirect-link"
                            placeholder={intl.formatMessage({
                              id: "create.product.name.unit.label",
                            })}
                            handler={() =>
                              setUseNameUnitLabel(!useNameUnitLabel)
                            }
                          />
                        </div>
                      </div>

                      {useNameUnitLabel && (
                        <div className="insider-container">
                          <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "create.product.name.unit.label.custom",
                              })}
                            </h5>

                            <Input
                              required={false}
                              mode="named"
                              name="nameUnitLabel"
                              value={product.nameUnitLabel}
                              placeholder={intl.formatMessage({
                                id: "create.product.name.unit.label.custom.i.e",
                              })}
                              handler={handleProductData}
                            />
                            <ErrorMessage
                              errors={formErrors}
                              wantedError="linkUrlRedirect"
                              errorMessage={intl.formatMessage({
                                id: "error.request.payment.url.redirect",
                              })}
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={useSalesPage}
                            required={false}
                            mode="checkbox"
                            name="redirect-link"
                            value="redirect-link"
                            placeholder={intl.formatMessage({
                              id: "create.product.sales.label",
                            })}
                            handler={() => setUseSalesPage(!useSalesPage)}
                          />
                        </div>
                      </div>

                      {useSalesPage && (
                        <div className="insider-container">
                          <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "create.product.sales.description",
                              })}
                            </h5>

                            <Input
                              required={false}
                              mode="named"
                              name="redirectUrl"
                              value={product.redirectUrl}
                              placeholder={intl.formatMessage({
                                id: "create.product.sales.placeholder",
                              })}
                              handler={handleProductData}
                            />
                            <ErrorMessage
                              errors={formErrors}
                              wantedError="linkUrlRedirect"
                              errorMessage={intl.formatMessage({
                                id: "error.request.payment.url.redirect",
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="button-bottom-container">
                    {!processing && (
                      <Buttons
                        mode="filled"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({ id: labelButton })}
                        customClass="full-width"
                        // disable={paymentType === '' || (paymentType === 'recurring' && !newPaymentLink.planId && !creatingPlan)}
                      />
                    )}

                    {processing && (
                      <Buttons
                        mode="filled-processing"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({ id: labelButton })}
                        customClass="full-width"
                        disable={true}
                      />
                    )}
                  </div>
                </form>
              </styled.FormContainer>
            </div>
          </styled.BoxDetails>
        </styled.Left>
      </styled.LeftRightContainer>
    </>
  );
};

export default PaymentLink;
