import React from "react";

import downloadFile from "../../../../helpers/download";

import copyIcon from "../../../../assets/images/global/copy.svg";

import CardManual from "./components/CardForm";
import Pix from "./components/PixForm";
import BoletoManual from "./components/BoletoForm";

import ManualPaymentSucceded from "./components/SuccededPayment";

import SelectPlansButtonModal from "../../Modals/SelectPlansButtonModal";
import TipButtonModal from "../../Modals/TipButtonModal";

import CustomerPersonalData from "../CustomerPersonalData";

import Buttons from "../../../Forms/Buttons";
import Select from "../../../Forms/Select";
import Input from "../../../Forms/InputFloatLabel";

import * as styled from "../payment-request-styles";

import { FormattedMessage, useIntl } from "react-intl";

//Hooks
import { useManualPaymentHook } from "./hooks";

import leftArrow from "../../../../assets/images/global/leaft-arrow.svg";

import ErrorMessage from "../FormValidations/ErrorMessage";

const ManualPayment = () => {
  const intl = useIntl();

  const {
    handlePreviousStep,
    progressStep,
    successManualRequest,
    handlePaymentType,
    paymentType,
    handlePaymentMethod,
    paymentMethod,
    creatingPlan,
    setCreatingPlan,
    setNewPaymentLink,
    newPaymentLink,
    setNewPlan,
    newPlan,
    setManualOrder,
    handleSubmit,
    handleNewPlanData,
    formErrors,
    handleNewPlanAmount,
    customer,
    card,
    setCustomer,
    isForeigner,
    processing,
    manualOrder,
    creditCard,
    setCreditCard,
    colorsTheme,
    tax,
    handleTax,
    selected,
    setSelected,
    conversionRates,
    hasExchangeRates,
    applySplitsAfterOrder,
    errorMessage,
    customerCurrency,
    boleto,
    setProcessing,
    discount,
    handleDiscount,
    lateFee,
    handleFee,
    interest,
    handleTicket,
    handleDiscountFeeTicket,
    maxSubscriptionCharges,
    setMaxSubscriptionCharges,
    daysBeforeGenerateBoleto,
    setDaysBeforeGenerateBoleto,
    manualDuedateError,
    customizeBoleto,
    handleCustomizeBoleto,
    handleSetGenerateAllBoleto,
    handleChargeDaysBefore,
    isPtbr,
    handleManualOrderData,
    preFillCustomersData,
    storeHasENotasKEY,
    informInvoice,
    setInformInvoice,
    eNotasInputRef,
    handleInvoiceData,
    informTax,
    handleTaxAmount,
    includeFeeTransfer,
    setIncludeFeeTransfer,
    informCycle,
    setInformCycle,
    smartRetrial,
    setSmartRetrial,
    handleSmartRetrials,
    labelButton,
    storeNameFinished,
    refQRCode,
    pixQrCode,
    pixQrCodeCopyPaste,
    createPlanFirst,
    limitBoleto
  } = useManualPaymentHook();

  return (
    <>
      <styled.LeftRightContainer>
        <styled.Left className="border-right only-left">
          <styled.BoxDetails className="border-bottom">
            <div className="centered-container">
              <styled.Header>
                <div className="column-header">
                  {progressStep > 1 && !successManualRequest ? (
                    <div
                      className="return-container"
                      onClick={handlePreviousStep}
                    >
                      <img src={leftArrow} alt="left arrow" />
                      <p>
                        {intl.formatMessage({
                          id: "request.payment.header.return",
                        })}
                      </p>
                    </div>
                  ) : null}

                  <FormattedMessage id="request.payment.manual.payment.title" />
                </div>
              </styled.Header>

              <styled.ProgressBar3StepsContainer step={progressStep}>
                <div className="background">
                  <div className="progress-1"></div>
                </div>
                <div className="background">
                  <div className="progress-2"></div>
                </div>
                <div className="background">
                  <div className="progress-3"></div>
                </div>
              </styled.ProgressBar3StepsContainer>

              <styled.FormContainer>
                {!successManualRequest && progressStep <= 1 && (
                  <>
                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({
                          id: "request.payment.payment.type",
                        })}
                      </h5>
                      <Buttons
                        mode="double-checkbox"
                        radio2ButtonsName="payment-type"
                        handler={handlePaymentType}
                        radio2ButtonsValue1="single"
                        radio2ButtonsValue2="recurring"
                        label={intl.formatMessage({
                          id: "request.payment.plan.single",
                        })}
                        labelRight={intl.formatMessage({
                          id: "request.payment.plan.recurring",
                        })}
                        valueToCheck={paymentType}
                      />
                    </div>

                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({
                          id: "request.payment.payment.method.label",
                        })}
                      </h5>
                      <Buttons
                        mode="triple-checkbox"
                        radio2ButtonsName="payment-method"
                        handler={handlePaymentMethod}
                        radio2ButtonsValue1="credit-card"
                        radio2ButtonsValue2="pix"
                        radio3ButtonsValue3="boleto"
                        label={intl.formatMessage({
                          id: "request.payment.payment.method.cc.label",
                        })}
                        labelCenter="Pix"
                        labelRight={intl.formatMessage({
                          id: "request.payment.payment.method.boleto.label",
                        })}
                        valueToCheck={paymentMethod}
                        paymentTypeToDisable={paymentType}
                      />
                    </div>
                  </>
                )}

                {paymentType === "recurring" &&
                  progressStep === 1 &&
                  !creatingPlan && (
                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({ id: "request.payment.plan" })}
                      </h5>

                      <SelectPlansButtonModal
                        handleCreatingPlan={setCreatingPlan}
                        setNewPaymentLink={setNewPaymentLink}
                        newPaymentLink={newPaymentLink}
                        setNewPlan={setNewPlan}
                        newPlan={newPlan}
                        setManualOrder={setManualOrder}
                        isFromPaymentLink={false}
                      />
                    </div>
                  )}

                <form onSubmit={handleSubmit}>
                  {creatingPlan && (
                    <>
                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.recurring.name.plan",
                          })}
                        </h5>
                        <Input
                          required={false}
                          mode="named"
                          name="name"
                          value={newPlan.name}
                          placeholder={intl.formatMessage({
                            id: "request.payment.recurring.name.plan.placeholder",
                          })}
                          handler={handleNewPlanData}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="planName"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.create.plan.name",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({ id: "request.payment.amount" })}
                        </h5>
                        <Input
                          required={true}
                          mode="normal-fixed-symbol"
                          isCurrency={true}
                          fixedSymbol="R$"
                          name="amount"
                          value={newPlan.amount}
                          placeholder=""
                          handler={handleNewPlanAmount}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="planAmount"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.create.plan.amount",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.recurring",
                          })}
                        </h5>
                        <Select
                          mode="named-child"
                          required={true}
                          name="frequency"
                          value={newPlan.frequency}
                          placeholder={""}
                          handler={handleNewPlanData}
                        >
                          <option value={0}>
                            {intl.formatMessage({
                              id: "request.payment.plan.daily",
                            })}
                          </option>
                          <option value={1}>
                            {intl.formatMessage({
                              id: "request.payment.plan.weekly",
                            })}
                          </option>
                          <option value={2}>
                            {intl.formatMessage({
                              id: "request.payment.plan.monthly",
                            })}
                          </option>
                          <option value={3}>
                            {intl.formatMessage({
                              id: "request.payment.plan.quarterly",
                            })}
                          </option>
                          <option value={4}>
                            {intl.formatMessage({
                              id: "request.payment.plan.halfYearly",
                            })}
                          </option>
                          <option value={5}>
                            {intl.formatMessage({
                              id: "request.payment.plan.yearly",
                            })}
                          </option>
                        </Select>
                      </div>
                    </>
                  )}

                  {!creatingPlan && progressStep === 2 && (
                    <>
                      {paymentMethod === "credit-card" && (
                        <CardManual
                          customer={customer}
                          card={card}
                          handleCustomer={setCustomer}
                          isForeigner={isForeigner}
                          manualOrder={manualOrder}
                          handleManualOrder={setManualOrder}
                          creditCard={creditCard}
                          handleCreditCard={setCreditCard}
                          colorsTheme={colorsTheme}
                          tax={tax}
                          handleTax={handleTax}
                          selected={selected}
                          setSelected={setSelected}
                          conversionRates={conversionRates}
                          hasExchangeRates={hasExchangeRates}
                          applySplitsAfterOrder={applySplitsAfterOrder}
                          formErrors={formErrors}
                          createPlanFirst={createPlanFirst}
                        />
                      )}

                      {paymentMethod === "pix" && (
                        <Pix
                          customer={customer}
                          card={card}
                          handleCustomer={setCustomer}
                          submit={() => {}}
                          isForeigner={isForeigner}
                          processing={processing}
                          manualOrder={manualOrder}
                          handleManualOrder={setManualOrder}
                          creditCard={creditCard}
                          handleCreditCard={setCreditCard}
                          colorsTheme={colorsTheme}
                          tax={tax}
                          handleTax={handleTax}
                          selected={selected}
                          setSelected={setSelected}
                          formErrors={formErrors}
                        />
                      )}

                      {paymentMethod === "boleto" && (
                        <>
                          <BoletoManual
                            customerCurrency={customerCurrency}
                            isRecurring={paymentType === "recurring"}
                            customer={customer}
                            boleto={boleto}
                            handleCustomer={setCustomer}
                            submit={() => {}}
                            isForeigner={isForeigner}
                            handleProcessing={setProcessing}
                            manualOrder={manualOrder}
                            handleManualOrder={setManualOrder}
                            applySplitsAfterOrder={applySplitsAfterOrder}
                            formErrors={formErrors}
                            limitBoleto={limitBoleto}
                          />
                          {errorMessage !== "" && (
                            <styled.Error>{errorMessage}</styled.Error>
                          )}
                          {manualDuedateError !== "" && (
                            <styled.Error>{manualDuedateError}</styled.Error>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {progressStep === 3 &&
                    paymentMethod !== "pix" &&
                    !successManualRequest && (
                      <>
                        <div className="form-item">
                          <div className="form-item__optional">
                            <h5>
                              {intl.formatMessage({
                                id: "request.payment.create.plan.customize.title",
                              })}
                            </h5>
                            <p>
                              {intl.formatMessage({
                                id: "request.payment.optional.label",
                              })}
                            </p>
                          </div>
                        </div>

                        {paymentMethod === "boleto" && (
                          <div className="form-item">
                            <div className="flex">
                              <Input
                                valueToCheck={customizeBoleto}
                                required={false}
                                mode="checkbox"
                                name="customize-boleto"
                                value="customize-boleto"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.customize.boleto.btn",
                                })}
                                handler={handleCustomizeBoleto}
                              />
                              <TipButtonModal
                                text={intl.formatMessage({
                                  id: "request.payment.customize.boleto.btn.tip",
                                })}
                              />
                            </div>
                          </div>
                        )}

                        {customizeBoleto && (
                          <>
                            <div className="insider-container">
                              {/* {paymentType !== "recurring" && ( */}
                              {/* <> */}
                              <div className="form-item">
                                <div className="select-and-input-container">
                                  <div className="m-half-width">
                                    <Select
                                      mode="clean-named-child"
                                      required={false}
                                      name="discount"
                                      value={discount.mode}
                                      placeholder={""}
                                      handler={handleDiscountFeeTicket}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount",
                                        })}
                                      </option>
                                      <option value="Percentage">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.percentual",
                                        })}
                                      </option>
                                      <option value="Fixed">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.amount",
                                        })}
                                      </option>
                                    </Select>
                                  </div>

                                  <div className="m-half-width">
                                    {discount.mode === "Fixed" && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-currency"
                                        name="discount-order"
                                        value={discount.amount}
                                        placeholder=""
                                        handler={handleDiscount}
                                      />
                                    )}
                                    {discount.mode === "Percentage" && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-percentage"
                                        name="discount-order"
                                        value={discount.amount}
                                        placeholder=""
                                        handler={handleDiscount}
                                      />
                                    )}
                                    {discount.mode === "" && (
                                      <input
                                        className="input__element"
                                        name="discount-order"
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>

                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="discountAmount"
                                  errorMessage={intl.formatMessage({
                                    id: "request.payment.discount.error",
                                  })}
                                />
                              </div>

                              <div className="form-item ">
                                <div className="select-and-input-container">
                                  <div className="m-half-width">
                                    <Select
                                      mode="clean-named-child"
                                      required={false}
                                      name="lateFee"
                                      value={lateFee.mode}
                                      placeholder={""}
                                      handler={handleDiscountFeeTicket}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.fine",
                                        })}
                                      </option>
                                      <option value="Percentage">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.percentual",
                                        })}
                                      </option>
                                      <option value="Fixed">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.amount",
                                        })}
                                      </option>
                                    </Select>
                                  </div>

                                  <div className="m-half-width">
                                    {lateFee.mode === "Fixed" && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-currency"
                                        name="discount-order"
                                        value={lateFee.amount}
                                        placeholder=""
                                        handler={handleFee}
                                      />
                                    )}
                                    {lateFee.mode === "Percentage" && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-percentage"
                                        name="discount-order"
                                        value={lateFee.amount}
                                        placeholder=""
                                        handler={handleFee}
                                      />
                                    )}
                                    {lateFee.mode === "" && (
                                      <input
                                        className="input__element"
                                        name="discount-order"
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>

                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="lateFeeAmount"
                                  errorMessage={intl.formatMessage({
                                    id: "request.payment.late.fee.error",
                                  })}
                                />
                              </div>

                              <div className="form-item">
                                <div className="select-and-input-container">
                                  <div className="m-half-width">
                                    <Select
                                      mode="clean-named-child"
                                      required={false}
                                      name="interest"
                                      value={interest.mode}
                                      placeholder={""}
                                      handler={handleDiscountFeeTicket}
                                    >
                                      <option value="">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.fees.rp",
                                        })}
                                      </option>
                                      <option value="Daily_Percentage">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.percentual.daily",
                                        })}
                                      </option>
                                      <option value="Monthly_Percentage">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.percentual.monthly",
                                        })}
                                      </option>
                                      <option value="Daily_Amount">
                                        {intl.formatMessage({
                                          id: "request.payment.boleto.discount.amount",
                                        })}
                                      </option>
                                    </Select>
                                  </div>

                                  <div className="m-half-width">
                                    {interest.mode === "Daily_Amount" && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-currency"
                                        name="fee-order"
                                        value={interest.amount}
                                        placeholder=""
                                        handler={handleTicket}
                                      />
                                    )}
                                    {(interest.mode === "Daily_Percentage" ||
                                      interest.mode ===
                                        "Monthly_Percentage") && (
                                      <Input
                                        required={false}
                                        isInputWithoutBorder={true}
                                        mode="normal-percentage"
                                        name="fee-order"
                                        value={interest.amount}
                                        placeholder=""
                                        handler={handleTicket}
                                      />
                                    )}
                                    {interest.mode === "" && (
                                      <input
                                        className="input__element"
                                        name="ticket-order"
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>

                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="interestAmountDailyP"
                                  errorMessage={intl.formatMessage({
                                    id: "request.payment.interest.error.daily.percentage",
                                  })}
                                />
                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="interestAmountMontlyP"
                                  errorMessage={intl.formatMessage({
                                    id: "request.payment.interest.error.monthly",
                                  })}
                                />
                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="interestAmountDailyA"
                                  errorMessage={intl.formatMessage({
                                    id: "request.payment.interest.error.daily.percentage",
                                  })}
                                />
                              </div>
                              {/* </> */}
                              {/* // )} */}

                              {manualOrder.installments > 1 ||
                              (manualOrder.maxCharges &&
                                manualOrder.maxCharges > 1) ? (
                                <div className="payment-request-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.generate.all.boleto",
                                    })}
                                  </h5>

                                  <span className="select-and-input-container">
                                    <Select
                                      mode="clean-named-child"
                                      required={false}
                                      name="generateAllBoletos"
                                      placeholder={""}
                                      handler={handleSetGenerateAllBoleto}
                                    >
                                      <option value=""></option>
                                      <option value="true">
                                        {intl.formatMessage({
                                          id: "select.yes",
                                        })}
                                      </option>
                                      <option value="false">
                                        {intl.formatMessage({
                                          id: "select.no",
                                        })}
                                      </option>
                                    </Select>
                                  </span>
                                </div>
                              ) : null}

                              {paymentType === "recurring" &&
                                newPlan.frequency.toString() !== "0" && (
                                  <div className="form-item">
                                    <h5>
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.days.before.generate.boleto",
                                      })}
                                    </h5>

                                    <Input
                                      required={false}
                                      mode="normal"
                                      name="chargeDaysBefore"
                                      value={manualOrder.chargeDaysBefore}
                                      placeholder=""
                                      handler={handleChargeDaysBefore}
                                    />
                                  </div>
                                )}

                              {/* {discount.mode !== '' && <>
                        <div className="form-item">
                          <h5>{intl.formatMessage({ id: "request.payment.boleto.discount.limit" })}</h5>

                          <Input mode="named-mask" name="discountLimit" mask="99/99/9999" value={manualOrder.discountLimit} placeholder={isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"} handler={setNewPaymentLink} />
                        </div>
                        {manualDuedateError !== '' && <styled.Error>{manualDuedateError}</styled.Error>}
                      </>} */}

                              {paymentType !== "recurring" ? (
                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.duedate",
                                    })}
                                  </h5>

                                  <Input
                                    required={false}
                                    mode="named-mask"
                                    name="duedate"
                                    mask="99/99/9999"
                                    value={manualOrder.duedate}
                                    placeholder={
                                      isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"
                                    }
                                    handler={handleManualOrderData}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}

                        {/* {paymentMethod === 'credit-card' && <div className="form-item">
                      <Input required={false} mode="checkbox" name="prefill" value="prefill" placeholder={intl.formatMessage({ id: "request.payment.pre.fill" })} handler={() => { setPreFillCustomersData(!preFillCustomersData) }} />
                    </div>} */}

                        {preFillCustomersData && (
                          <div className="insider-container">
                            <CustomerPersonalData
                              customer={customer}
                              handleCustomer={setCustomer}
                              isForeigner={isForeigner}
                              showCustomer={true}
                              showAddress={true}
                              boleto={paymentMethod === "boleto"}
                              isPaymentLink={true}
                              customerCurrency={"BRL"}
                            />
                          </div>
                        )}

                        {storeHasENotasKEY && (
                          <>
                            <div className="form-item">
                              <Input
                                valueToCheck={informInvoice}
                                required={false}
                                mode="checkbox"
                                name="inform-invoice"
                                inputRef={eNotasInputRef}
                                value="inform-invoice"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.enota",
                                })}
                                handler={() => setInformInvoice(!informInvoice)}
                              />
                            </div>

                            {informInvoice && (
                              <div className="insider-container">
                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.enota.when.generate",
                                    })}
                                  </h5>

                                  <Select
                                    mode="named-child"
                                    required={false}
                                    name="whenSend"
                                    value={manualOrder.whenSend}
                                    placeholder=""
                                    handler={handleInvoiceData}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.enota.select",
                                      })}
                                    </option>
                                    <option value="0">
                                      {intl.formatMessage({
                                        id: "request.payment.enota.select.on.sell",
                                      })}
                                    </option>
                                    <option value="1">
                                      {intl.formatMessage({
                                        id: "request.payment.enota.select.after.warranty",
                                      })}
                                    </option>
                                    {/* <option value="2">{intl.formatMessage({ id: "request.payment.enota.select.not.generate" })}</option> */}
                                  </Select>
                                </div>

                                {manualOrder.whenSend === "1" && (
                                  <div className="form-item">
                                    <h5>
                                      {intl.formatMessage({
                                        id: "request.payment.enota.select.warranty.days",
                                      })}
                                    </h5>

                                    <Input
                                      required={false}
                                      mode="named"
                                      type="number"
                                      name="warrantyDays"
                                      value={manualOrder.warrantyDays}
                                      placeholder=""
                                      handler={handleInvoiceData}
                                    />
                                  </div>
                                )}

                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select.send",
                                    })}
                                  </h5>

                                  <Select
                                    mode="named-child"
                                    required={false}
                                    name="sendToCustomer"
                                    value={manualOrder.sendToCustomer}
                                    placeholder=""
                                    handler={handleInvoiceData}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.enota.select.send.select",
                                      })}
                                    </option>
                                    <option value="true">
                                      {intl.formatMessage({ id: "select.yes" })}
                                    </option>
                                    <option value="false">
                                      {intl.formatMessage({ id: "select.no" })}
                                    </option>
                                  </Select>
                                </div>

                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.enota.description",
                                    })}
                                  </h5>

                                  <Input
                                    required={false}
                                    mode="named"
                                    name="description"
                                    value={manualOrder.description}
                                    placeholder=""
                                    handler={handleManualOrderData}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* { paymentType !== 'recurring' && <div className="form-item">
                      <Input required={false} mode="checkbox" name="tax-rates" value="tax-rates" placeholder={intl.formatMessage({ id: "request.payment.inform.tax" })} handler={() => { setInformTax(!informTax) }} />
                    </div> } */}

                        {informTax && (
                          <div className="insider-container">
                            <styled.PaymentRequestItems>
                              <div className="form-item select-and-input-container">
                                <div className="m-half-width">
                                  <Select
                                    mode="clean-named-child"
                                    required={false}
                                    name="mode"
                                    value={tax.mode}
                                    placeholder={""}
                                    handler={handleTax}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.tax",
                                      })}
                                    </option>
                                    <option value="Percentage">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.percentual",
                                      })}
                                    </option>
                                    <option value="Fixed">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.amount",
                                      })}
                                    </option>
                                  </Select>
                                </div>

                                <div className="m-half-width">
                                  {tax.mode === "Fixed" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-currency"
                                      name="tax-amount"
                                      value={tax.amount}
                                      placeholder=""
                                      handler={handleTaxAmount}
                                    />
                                  )}
                                  {tax.mode === "Percentage" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-percentage"
                                      name="tax-amount"
                                      value={tax.amount}
                                      placeholder=""
                                      handler={handleTaxAmount}
                                    />
                                  )}
                                  {tax.mode === "" && (
                                    <input
                                      className="input__element"
                                      name="tax-amount"
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                            </styled.PaymentRequestItems>
                          </div>
                        )}

                        <div className="form-item">
                          <div className="flex">
                            <Input
                              valueToCheck={includeFeeTransfer}
                              required={false}
                              mode="checkbox"
                              name="include-fee-transfer"
                              value="include-fee-transfer"
                              placeholder={intl.formatMessage({
                                id: "manager.links.details.include.fee.transfer",
                              })}
                              handler={() => {
                                setIncludeFeeTransfer(!includeFeeTransfer);
                              }}
                            />
                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "manager.links.details.include.fee.transfer.tip",
                              })}
                            />
                          </div>
                        </div>

                        {paymentType === "recurring" && (
                          <>
                            <div className="form-item">
                              <div className="flex">
                                <Input
                                  required={false}
                                  valueToCheck={informCycle}
                                  mode="checkbox"
                                  name="inform-cycle"
                                  value="inform-cycle"
                                  placeholder={intl.formatMessage({
                                    id: "request.payment.inform.cycle",
                                  })}
                                  handler={() => {
                                    setInformCycle(!informCycle);
                                  }}
                                />
                                <TipButtonModal
                                  text={intl.formatMessage({
                                    id: "request.payment.inform.cycle.tip",
                                  })}
                                />
                              </div>
                            </div>

                            <div className="form-item">
                              <div className="flex">
                                <Input
                                  required={false}
                                  valueToCheck={smartRetrial.informRetries}
                                  mode="checkbox"
                                  name="inform-informRetries"
                                  value="inform-informRetries"
                                  placeholder={intl.formatMessage({
                                    id: "request.payment.smart.retrial.label",
                                  })}
                                  handler={() => {
                                    setSmartRetrial({
                                      ...smartRetrial,
                                      informRetries:
                                        !smartRetrial.informRetries,
                                    });
                                  }}
                                />
                                <TipButtonModal
                                  text={intl.formatMessage({
                                    id: "request.payment.smart.retrial.tip",
                                  })}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {informCycle && (
                          <div className="insider-container">
                            <styled.PaymentRequestItems>
                              <div className="payment-request-item">
                                <Input
                                  required={true}
                                  mode="normal"
                                  type="number"
                                  name="maxSubscriptionCharges"
                                  value={maxSubscriptionCharges}
                                  placeholder={intl.formatMessage({
                                    id: "request.payment.max.subcriptions.charge.label",
                                  })}
                                  handler={setMaxSubscriptionCharges}
                                />
                              </div>
                            </styled.PaymentRequestItems>
                          </div>
                        )}

                        {smartRetrial.informRetries && (
                          <>
                            <div className="insider-container">
                              <styled.PaymentRequestItems>
                                <div className="form-item">
                                  <div className="form-item__optional">
                                    <h5>
                                      {intl.formatMessage({
                                        id: "request.payment.smart.retrial.days",
                                      })}
                                    </h5>
                                    <p>
                                      {intl.formatMessage({
                                        id: "request.payment.optional.label",
                                      })}
                                    </p>
                                  </div>

                                  <Input
                                    required={true}
                                    mode="named"
                                    type="number"
                                    name="daysBetweenRetries"
                                    value={smartRetrial.daysBetweenRetries}
                                    placeholder={""}
                                    handler={handleSmartRetrials}
                                  />

                                  <styled.Error>
                                    {smartRetrial.errors.some(
                                      (x: any) => x === "daysBetweenRetries"
                                    )
                                      ? `${intl.formatMessage({
                                          id: "request.payment.smart.retrial.days.error",
                                        })}`
                                      : ``}
                                  </styled.Error>
                                </div>
                              </styled.PaymentRequestItems>

                              <styled.PaymentRequestItems>
                                <div className="form-item">
                                  <div className="form-item__optional">
                                    <h5>
                                      {intl.formatMessage({
                                        id: "request.payment.smart.retrial.limit",
                                      })}
                                    </h5>
                                    <p>
                                      {intl.formatMessage({
                                        id: "request.payment.optional.label",
                                      })}
                                    </p>
                                  </div>

                                  <Input
                                    required={true}
                                    mode="named"
                                    type="number"
                                    name="maxRetries"
                                    value={smartRetrial.maxRetries}
                                    placeholder={""}
                                    handler={handleSmartRetrials}
                                  />

                                  <styled.Error>
                                    {smartRetrial.errors.some(
                                      (x: any) => x === "maxRetries"
                                    )
                                      ? `${intl.formatMessage({
                                          id: "request.payment.smart.retrial.days.error",
                                        })}`
                                      : ``}
                                  </styled.Error>
                                </div>
                              </styled.PaymentRequestItems>
                            </div>
                          </>
                        )}
                      </>
                    )}

                  {((!successManualRequest && paymentMethod !== "pix") ||
                    (paymentMethod === "pix" && progressStep < 3)) && (
                    <div className="button-bottom-container">
                      {!processing && (
                        <Buttons
                          mode="filled"
                          handler={() => {}}
                          type="submit"
                          label={`${intl.formatMessage({ id: labelButton })} 
                      ${
                        progressStep === 3 &&
                        manualOrder.installments > 1 &&
                        paymentMethod === "credit-card" &&
                        !applySplitsAfterOrder
                          ? `${manualOrder.installments}x ${intl.formatMessage({
                              id: "request.payment.of",
                            })}`
                          : ""
                      }
                      ${
                        progressStep === 3 && !applySplitsAfterOrder
                          ? intl.formatNumber(
                              parseFloat(
                                isNaN(parseFloat(manualOrder.amount))
                                  ? newPlan.amount
                                  : manualOrder.amount
                              ) / manualOrder.installments,
                              { style: "currency", currency: "BRL" }
                            )
                          : ""
                      }`}
                          customClass="full-width"
                          disable={
                            paymentType === "" ||
                            paymentMethod === "" ||
                            (paymentType === "recurring" &&
                              !manualOrder.planId &&
                              !creatingPlan &&
                              newPlan.name === "")
                          }
                        />
                      )}

                      {processing && (
                        <Buttons
                          mode="filled-processing"
                          handler={() => {}}
                          type="submit"
                          label={intl.formatMessage({ id: labelButton })}
                          customClass="full-width"
                          disable={paymentType === "" || processing}
                        />
                      )}
                    </div>
                  )}

                  {progressStep === 3 && successManualRequest && (
                    <div>
                      <ManualPaymentSucceded
                        storeName={storeNameFinished}
                        amount={parseFloat(manualOrder.amount)}
                      />
                    </div>
                  )}
                </form>

                {progressStep === 3 && paymentMethod === "pix" && (
                  <div className="column-container">
                    <div className="qr-code-container" ref={refQRCode}>
                      <img src={pixQrCode} alt="qr code" />
                    </div>

                    <div className="column-buttons-container">
                      <div>
                        <Buttons
                          mode="minimal-with-icon"
                          label={intl.formatMessage({
                            id: "request.payment.pix.copy.qrcode",
                          })}
                          icon={copyIcon}
                          handler={() =>
                            navigator.clipboard.writeText(pixQrCodeCopyPaste)
                          }
                        />
                      </div>

                      <div>
                        <Buttons
                          mode="unfilled"
                          label={intl.formatMessage({
                            id: "request.payment.pix.download.qrcode",
                          })}
                          handler={() =>
                            downloadFile(refQRCode, "qrCode", pixQrCode)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </styled.FormContainer>
            </div>
          </styled.BoxDetails>
        </styled.Left>
      </styled.LeftRightContainer>
    </>
  );
};

export default ManualPayment;
