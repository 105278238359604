import LangResource from "./LangResource";

const cn = new LangResource();

cn.langKeys = ["cn"];
cn.messages = {
  "home.recent-orders": "Recent orders",
  "home.status": "Status",
  "home.order": "Order",
  "home.name": "Name",
  "home.method": "Method",
  "home.brl.amount": "BRL Amount",
  "home.new.currency.amount": "Amount",
  "home.gbp.amount": "GBP Amount",
  "home.live.map": "Live Map",
  "home.faq": "FAQ",
  "home.help": "Help",
  "home.complains.help":
    "Need help in any dispute? Get support with our experts by chat.",
  "home.chat.wait.time": "Average wait 4 minutes",
  "home.email.wait.time": "Average wait 3 hours",
  "home.phone.wait.time": "Average wait 10 minutes",
  "home.overview": "Overview",
  "home.report": "Report",
  "home.about": "about",

  "financial.title": "Finance",
  "financial.gross.volume": "Gross volume",
  "financial.net.volume": "Net volume",
  "financial.average.ticket.price": "Average ticket price",
  "financial.orders.quantity": "Orders quantity",
  "financial.dispute.count": "Dispute count",
  "financial.amount.available.settlement": "Available to Settlement",
  "financial.amount.to.receive": "Amount to receive",
  "financial.amount.dispute": "Amount in dispute",
  "financial.withdrawal.country": "Country",
  "button.withdrawal": "Withdrawal",
  "button.sidebar.home": "Home",
  "button.sidebar.transactions": "Transactions",
  "button.sidebar.signout": "Sign out",
  "button.sidebar.developers": "Developers",
  "button.sidebar.settings": "Settings",

  "home.performance": "Performance",
  "home.performance.hint":
    "This is how much you have grown if we compare the same period before and after your company joins Preme",
  "payment.confirmed": "Confirmed",
  "payment.pending": "Pending",
  "payment.awaiting": "Awaiting",
  "payment.refused": "Refused",
  "payment.canceled": "Canceled",
  "payment.PendingChallenge": "Pending",
  "payment.failed": "Failed",
  "payment.blocked": "Blocked",
  "payment.credit.card": "Credit Card",
  "payment.debit.card": "Debit Card",
  "payment.boleto.bancario": "Boleto Bancário",
  "request.payment": "Request Payment",
  "request.payment.customer.name": "Customer name:",
  "request.payment.customer.email": "Customer email:",
  "request.payment.currency": "Currency:",
  "request.payment.currency.value": "BRL (R$)",
  "request.payment.amount": "Amount:",
  "request.payment.boleto.days.before.generate.boleto": "Send Boleto to the customer how many days before expiration?",
  "request.payment.max.subcriptions.charge.label": "Max number of charge",
  "request.payment.recurring.tax": "Tax:",
  "request.payment.title": "Title:",
  "request.payment.order.number": "Order number:",
  "request.payment.due.date": "Due payment date:",
  "request.payment.processing": "Processing payment",
  "request.payment.confirmed": "Payment confirmed",
  "request.payment.description": "Description:",
  "request.payment.expiration.link": "Expiration (optional):",
  "request.payment.expiration.link.placeholder": "dd/mm/yyyy, hh:mm",
  "request.payment.inform.tax": "Inform tax",
  "request.payment.inform.tax.explanation": "Enter the amount that will be withheld for payment of taxes and duties for a better view of the value",
  "request.payment.tax": "Tax mode",

  "request.payment.boleto.cpf.cnpj": "CPF/CNPJ:",
  "request.payment.boleto.business.name": "Company name:",

  "request.payment.plan.daily": "Daily",
  "request.payment.plan.weekly": "Weekly",
  "request.payment.plan.monthly": "Monthly",
  "request.payment.plan.quarterly": "Quarterly",
  "request.payment.plan.halfYearly": "HalfYearly",
  "request.payment.plan.yearly": "Yearly",

  "credit.card.number": "Card number",
  "credit.card.expiration": "MM/YY",
  "credit.card.cvv": "CVV",

  "request.payment.recurring.description": "Description:",

  "transactions.title": "Transactions",
  "transactions.order.details": "Order Details",
  "transactions.customer.details": "Customer Details",
  "transactions.payment.details": "Payment Details",
  "transactions.order.events": "Events",
  "transactions.cost": "Transaction Cost",
  "transactions.refund.control": "Refund Control",
  "transactions.refund.control.reference": "Reference:",
  "transactions.refund.control.percentage": "Percentage:",
  "login.welcome": "Welcome",
  "login.button.login": "Login",
  "login.invalid.username.or.password": "Incorrect Username or Password",
  "error.unknown.error": "Sorry, an unexpected error happened",
  "error.failed": "An unexpected error happened",
  "home.current.year": "2020",

  "user.profile.title": "Personal data",
  "user.profile.email": "Email: ",
  "user.profile.name": "Name: ",
  "user.profile.password": "Password: ",
  "user.profile.new.password": "New password: ",
  "user.profile.old.password": "Old password: ",

  "user.profile.team.title": "Team",
  "user.profile.team.name": "Name: ",
  "user.profile.team.email": "Email: ",
  "user.profile.team.password": "Password: ",
  "user.profile.team.access": "Access: ",
  "user.profile.team.entity.type": "Type: ",
  "user.profile.team.store": "Store: ",
  "user.profile.team.select.default": "Select",
  "user.profile.team.add.user.button.label": "Add user",
  "user.profile.team.cancel.button.label": "Cancel",
  "user.profile.team.delete.user.button.label": "Delete user",
  "user.profile.team.save.changes.button.label": "Save changes",
  "user.profile.team.create.account.button.label": "Create account",

  "user.profile.right.title": "Company data",
  "user.profile.right.company.name": "Company name: ",
  "user.profile.right.trading.name": "Trading name: ",
  "user.profile.right.cnpj": "CNPJ: ",
  "user.profile.right.phone": "Phone: ",
  "user.profile.right.zipcode": "Zipcode: ",
  "user.profile.right.address": "Address: ",
  "user.profile.right.reference": "Complement: ",
  "user.profile.right.district": "Neighborhood: ",
  "user.profile.right.city": "city: ",
  "user.profile.right.state": "State: ",
  "user.profile.right.logo": "Logo: ",
  "user.profile.right.logo.suggest": "We suggest an SVG logo, with a transparent background to be applied to pages with a white background.",
  "user.profile.right.logo.placeholder": "Drag and Drop your files or <span class='filepond--label-action'>Browse</span>",

  "detail.order.number": "Order number:",
  "detail.order.status": "Status:",
  "detail.order.risk.fraud.alert": "Order blocked due to its high risk",
  "detail.order.risk.fraud.button.allow": "Allow this order",
  "detail.order.risk.fraud.score": "Fraud score:",
  "detail.order.risk.fraud.score.label.low": "Low",
  "detail.order.risk.fraud.score.label.medium": "Medium",
  "detail.order.risk.fraud.score.label.high": "High",

  "detail.order.cancel.subscription.day": "Recurring payment in progress every day",
  "detail.order.cancel.subscription.week": "Recurring payment in progress every week",
  "detail.order.cancel.subscription.month": "Recurring payment in progress every month",
  "detail.order.cancel.subscription.quarterly": "Recurring payment in progress every quarter",
  "detail.order.cancel.subscription.halfYearly": "Recurring payment in progress every halfyear",
  "detail.order.cancel.subscription.yearly": "Recurring payment in progress every year",

  // "detail.order.cancel.subscription": "Recurring payment in progress every ",
  // "detail.order.cancel.subscription.days": " days ",
  "detail.order.cancel.subscription.button": "Cancel subscription",
  "detail.order.cancel.subscription.canceled": "Recurring payment canceled",
  "detail.order.date.created": "Created on:",
  "detail.order.date.received": "Received on:",
  "detail.order.description": "Description:",
  "detail.order.name.first": "Name:",
  "detail.order.name.surname": "Surname:",
  "detail.order.cpf": "CPF:",
  "detail.order.dob": "Date of birth:",
  "detail.order.email": "Email:",
  "detail.order.phone": "Phone:",
  "detail.order.zipcode": "Zipcode:",
  "detail.order.street": "Street:",
  "detail.order.house.number": "Number:",
  "detail.order.house.reference": "Reference:",
  "detail.order.district": "District:",
  "detail.order.city": "City:",
  "detail.order.state": "State:",
  "detail.order.country": "Country:",
  "detail.order.events.refunded": "Refunded",
  "detail.order.events.received": "Received",
  "detail.order.events.failed": "Failed",
  "detail.order.events.created": "Created",
  "detail.order.events.button.view.boleto": "View Boleto",
  "detail.order.events.request.sent": "Payment request sent",
  "detail.order.transaction.code": "Transaction code:",
  "detail.order.payment.method": "Payment method:",
  "detail.order.installments.quantity": "Installments:",
  "detail.order.card.type": "Card type:",
  "detail.order.card.holder": "Holder name:",
  "detail.order.card.number": "Card number:",
  "detail.order.expiry.date": "Expiry date:",
  "detail.order.received.amount": "Order total amount:",
  "detail.order.tax": "Pre-informed tax:",
  "detail.order.preme.fee": "Preme fee:",
  "detail.order.additional.fee": "Additional fees:",
  "detail.order.net.amount": "BRL Amount:",
  "detail.order.net.amount.local.currency": "Amount",
  "detail.order.payment.link": "Payment link:",

  "detail.order.ip.address": "Device IP Address:",
  "detail.order.os": "Operating system:",
  "detail.order.browser": "Browser:",
  "detail.order.device": "Device :",
  "detail.order.location": "Location:",

  "ui.cta.processing": "Processing...",
  "ui.cta.create.plan": "Create plan",

  "payment.error.credit.expiration": "Invalid expiration date",

  "request.payment.zoop.error.58" : "Unauthorized transaction. Please contact the card issuer provided.",
  "request.payment.zoop.error.69" : "Unauthorized transaction. The card provided is not allowed to carry out this type of transaction. Debit cards are not accepted for online purchases.",
  "request.payment.zoop.error.101" : "Unauthorized transaction. Problems with the card provided, contact the card issuer.",
  "request.payment.zoop.error.102" : "Unauthorized transaction. Problems with the card provided, contact the card issuer or your bank.",
  "request.payment.zoop.error.103" : "Unauthorized transaction. The transaction may have been denied due to incorrect data or by your bank's order, please try again.",
  "request.payment.zoop.error.104" : "Unauthorized transaction. The transaction may have been denied due to incorrect data or by your bank's order, please try again.",
  "request.payment.zoop.error.105" : "Unauthorized transaction. Card restricted due to lack of credit limit or suspected fraud, contact your issuing bank.",
  "request.payment.zoop.error.106" : "Sorry, there was an error processing with the card issuer, please try again.",
  "request.payment.zoop.error.107" : "Unauthorized transaction. The transaction may have been denied due to incorrect data or by your bank's order, please try again.",
  "request.payment.zoop.error.108" : "Unauthorized transaction. The purchase price is not allowed for the type of card provided.",
  "request.payment.zoop.error.109" : "Unauthorized transaction. The card provided does not exist or has incorrect data, please use data from an existing and valid card.",
  "request.payment.zoop.error.110" : "Unauthorized transaction. The supplied card is not allowed to carry out this type of transaction.",
  "request.payment.zoop.error.111" : "Unauthorized transaction. Card restricted due to lack of credit limit, contact your issuing bank.",
  "request.payment.zoop.error.112" : "Unauthorized transaction. Expiry date of the provided card has expired, please insert a valid card.",
  "request.payment.zoop.error.113" : "Unauthorized transaction. Purchase with moderate suspicion of fraud, contact your issuing bank.",
  "request.payment.zoop.error.114" : "Unauthorized transaction. The purchase was blocked by the card issuer.",
  "request.payment.zoop.error.115" : "Unauthorized transaction. You have exceeded the number of allowed transactions in a period of time.",
  "request.payment.zoop.error.116" : "Unauthorized transaction. Please contact the card issuer provided.",
  "request.payment.zoop.error.117" : "Sorry, we couldn't find any records for this transaction.",
  "request.payment.zoop.error.118" : "Unauthorized transaction. The card provided is blocked, please insert a valid card.",
  "request.payment.zoop.error.119" : "Unauthorized transaction. The security code provided is invalid, please enter a valid security code.",
  "request.payment.zoop.error.121" : "Sorry, there was an error processing, please try again.",
  "request.payment.zoop.error.122" : "Unauthorized transaction. A similar transaction was recently submitted.",
  "request.payment.zoop.error.123" : "Unauthorized transaction. The establishment canceled recurring subscriptions from the issuer.",
  "request.payment.zoop.error.124" : "Unauthorized transaction. Please contact the issuer.",
  "request.payment.zoop.error.170" : "The supplied card is not allowed to perform verification transactions.",
  "request.payment.zoop.error.175" : "Verification transaction denied.",
  
  "default.error.state.message": "default",
};

export default cn;