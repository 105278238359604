interface ISmartRetrialsValidations {
  smartRetrial: any;
  setSmartRetrial: (value: any) => void;
}

export function smartRetrialsValidations({
  smartRetrial,
  setSmartRetrial,
}: ISmartRetrialsValidations) {
  let errors = [];

  if (
    parseInt(smartRetrial.daysBetweenRetries) > 30 ||
    parseInt(smartRetrial.daysBetweenRetries) < 0
  )
    errors.push("daysBetweenRetries");
  if (
    parseInt(smartRetrial.maxRetries) > 30 ||
    parseInt(smartRetrial.maxRetries) < 0
  )
    errors.push("maxRetries");

  setSmartRetrial({ ...smartRetrial, errors: errors });

  return errors.length === 0;
}
