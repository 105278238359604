import axios from "axios";

import { useAuth } from "../store/auth";

export const MemberkitServices = () => {
  const { session } = useAuth((state) => state);

  const registerConnector = async (data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/connectors`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const importCourses = async (connectorId: number) => {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/connectors/${connectorId}/courses`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const integrate = async (data: any, connectorId: number) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/connectors/${connectorId}/courses`,

      data,

      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  const getClassRooms = async (connectorId: number) => {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/connectors/${connectorId}/classrooms`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  };

  return {
    registerConnector,
    importCourses,
    integrate,
    getClassRooms,
  };
};
