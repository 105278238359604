import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 38px;
  flex-direction: column;
  max-width: 343px;
  min-width: 343px;

  .radios {
    max-height: 350px;
    width: 343px;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 16px;
    .extern-label {
      color: ${(props) => props.theme.textColors.primary};
      font-size: 14px;
    }
    .scroll {
      overflow-y: scroll;
      scrollbar-base-color: #c0c0c0;
      scrollbar-base-color: #c0c0c0;
      scrollbar-3dlight-color: #c0c0c0;
      scrollbar-highlight-color: #c0c0c0;
      scrollbar-track-color: #ebebeb;
      scrollbar-arrow-color: black;
      scrollbar-shadow-color: #c0c0c0;
      scrollbar-dark-shadow-color: #c0c0c0;
      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }
      ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
      }
      ::-webkit-scrollbar-thumb {
        background: #dad7d7;
      }
    }
    .search-bank {
      background: ${(props) => props.theme.secondaryBackgroundColors.secondary};
      min-height: 36px;
      border-radius: 3px;
      padding: 8px;
      margin-bottom: 16px;
      color: ${(props) => props.theme.textColors.primary};
      font-size: 12px;
    }
    label {
      cursor: pointer;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${(props) => props.theme.textColors.primary};
      &:hover {
        transition: ease 0.3s;
        background: ${(props) =>
          props.theme.secondaryBackgroundColors.secondary};
      }
      img {
        margin-right: 8px;
      }
    }
    input[type="radio"] {
      display: none;
    }
    span {
      width: 100%;
    }
    input[type="radio"]:checked + span {
      background: ${(props) => props.theme.secondaryBackgroundColors.secondary};
    }
    display: flex;
    flex-direction: column;
  }

  form {
    .form-item {
      margin-top: 24px;

      label {
        font-size: 14px;
        color: #5e5e5e;

        span {
          font-size: 10px;
        }
    }

    .filepond--panel-root {
      background-color: transparent;
      border: 1px dashed #e1e3e6;
    }

    .filepond--drop-label {
      min-height: 40px !important;
      cursor: pointer !important;
    }
  }

  @media screen and (max-width: 768px) {
      margin-bottom: 100px;
    }
`;

export const CreateStoreContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonFooterContainer = styled.div`
  max-width: 343px;
  min-width: 343px;
  margin-top: 24px;

  button {
    width: 100%;
  }
`;

export const SearchBank = styled.div`
  max-height: 350px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;

  .selected-category {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .div-search {
    outline: none;
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    height: 40px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .bank__info {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: rgba(62, 61, 70, 0.75);
      font-size: 14px;
      font-family: "Rubik", sans-serif !important;

      p {
        font-family: "Brandon Text Regular";
        font-size: 16px;
        margin: 0px;
        color: #000;
      }

      img {
        width: 38px;
        height: 31px;
        margin-right: 8px;
      }
    }

    .arrow {
      cursor: pointer;

      img {
        right: 8px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .c-bank-component {
    width: 100%;
    max-width: 343px;
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 1000;
    opacity: 0;
    animation: fadeBankList 0.1s ease-in-out forwards;

    @keyframes fadeBankList {
      to {
        opacity: 1;
        transition: all 0.1s ease-in-out;
      }
    }

    .scroll {
      overflow-y: scroll;
      scrollbar-base-color: #c0c0c0;
      scrollbar-base-color: #c0c0c0;
      scrollbar-3dlight-color: #c0c0c0;
      scrollbar-highlight-color: #c0c0c0;
      scrollbar-track-color: #ebebeb;
      scrollbar-arrow-color: black;
      scrollbar-shadow-color: #c0c0c0;
      scrollbar-dark-shadow-color: #c0c0c0;
      max-height: 150px;

      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }
      ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
      }
      ::-webkit-scrollbar-thumb {
        background: #dad7d7;
      }
    }

    .search-bank {
      outline: none;
      width: 100%;
      background: #fff;
      padding: 13px 8px 8px 8px;
      border-radius: 3px;
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      height: 40px;
      margin-bottom: 4px;
      font-size: 14px;
      font-family: "Rubik", sans-serif !important;
    }

    label {
      .bank__name {
        font-size: 12px;
      }

      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(62, 61, 70, 0.75) !important;

      &:hover {
        transition: ease 0.3s;
        background: #fff;
      }

      img {
        margin-right: 8px;
      }
    }

    input[type="radio"] {
      display: none;
    }

    span {
      width: 100%;
      cursor: pointer;
    }

    input[type="radio"]:checked + span {
      background: #eaeaea;
      transition: all 0.3s ease;
      color: #000;
    }

    input[type="radio"]:hover + span {
      background: #eaeaea;
      transition: all 0.3s ease;
      color: #000;
    }

    display: flex;
    flex-direction: column;
  }
`;

export const SearchCategory = styled(SearchBank)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .search-category {
    outline: none;
    font-size: 16px;
    width: 100%;
    background: #fff
    padding: 13px 8px 8px 8px;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    height: 40px;
    margin-bottom: 4px;

    /* &::placeholder{
      color:transparent;
    } */
  }

  .c-bank-component {
    margin-top: 55px;

    .select-item {
      display: flex;
      flex-direction: column;
    }

    .scroll {
      max-height: 250px;

      .popular-categories {
        border-bottom: 1px solid ${(props) =>
          props.theme.backgroundColors.eighth};
        margin-bottom: 16px;

        strong { margin-bottom: 4px;}
      }
    }

    strong {
      font-size: 13px !important;
    }

    span {
      font-size: 12px !important;
      width: 100%;
      cursor: pointer;
    }

    @media screen and (max-width: 491px) {
      max-width: 446px;
    }

    @media screen and (max-width: 426px) {
      max-width: 385px;
    }

    @media screen and (max-width: 376px) {
      max-width: 335px;
    }
  }
`;
