import React, { useContext } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import {
  RequestPaymentContext,
  ContextAllTypes,
} from "../../../../providers/requestPaymentContext";
import usePaymentLinkHook from "./hooks";

import SelectPlansButtonModal from "../../Modals/SelectPlansButtonModal";
import SelectProductsButtonModal from "../../Modals/SelectProductsButtonModal";

import { SelectCustomerModal } from "../components/SelectCustomerModal";

import TipButtonModal from "../../Modals/TipButtonModal";
import currencyAndSymbolUnique from "../../../../helpers/currencyAndSymbolUnique";

import CusomerPersonalData from "../CustomerPersonalData";

import Buttons from "../../../Forms/Buttons";
import Select from "../../../Forms/Select";
import Input from "../../../Forms/InputFloatLabel";
import TextArea from "../../../Forms/TextArea";

import ErrorMessage from "../FormValidations/ErrorMessage";

import SimpleCheckboxItem from "../components/SimpleCheckboxItem";
import ProgressStepBar from "../components/ProgressStepBar";
import OrderBumpUpCrossSell from "./OrderBumpUpCrossSell";
import Splits from "./Splits";

import SessionService from "../../../../services/Session";

import * as styled from "../payment-request-styles";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType
);

const PaymentLink = () => {
  const {
    newPaymentLink,
    setNewPaymentLink,
    newPlan,
    setNewPlan,
    customer,
    setCustomer,
    tax,
    setTax,
    orderBump,
    setOrderBump,
    upSell,
    setUpSell,
    crossSell,
    setCrossSell,
  }: ContextAllTypes = useContext(RequestPaymentContext);

  const {
    progressStep,
    handlePreviousStep,
    creatingPlan,
    handlePaymentType,
    paymentType,
    setCreatingPlan,
    handleSubmit,
    formErrors,
    setSelectedProducts,
    selectedCustomerID,
    setSelectedCustomerID,
    createCustomerForPaymentLink,
    setCreateCustomerForPaymentLink,
    handleNewPlanData,
    handleNewPlanAndLinkAmount,
    productPreSelected,
    setProductPreSelected,
    selectedProducts,
    handleProductQuantity,
    handleNewPaymentLinkData,
    handleNewPlanAmount,
    handleNewPaymentLinkDataAmount,
    isForeigner,
    foreignerStoreCurrencySelect,
    openAmount,
    setOpenAmount,
    customerCurrency,
    setCustomerCurrency,
    customizePaymentMethods,
    card,
    setCard,
    pix,
    setPix,
    boleto,
    setBoleto,
    handleCustomizePaymentMethods,
    limitInstallments,
    handleLimitInstallments,
    handleBoletoInstallments,
    boletoInstallmentsAmount,
    handleInstallments,
    cardInstallmentsAmount,
    includeFeeTransfer,
    applySplitsAfterOrder,
    setFixedInstallment,
    fixedInstallment,
    applySmartInstallments,
    setApplySmartInstallments,
    payLater,
    setPayLater,
    customizeBoleto,
    handleCustomizeBoleto,
    discount,
    handleDiscountFeeTicket,
    lateFee,
    handleFee,
    handleDiscount,
    manualDuedateError,
    interest,
    isPtbr,
    handleTicket,
    handleSetGenerateAllBoleto,
    maxSubscriptionCharges,
    collectUserDataInCheckout,
    preFillCustomersData,
    storeHasENotasKEY,
    informInvoice,
    hasExpiration,
    handleInvoiceData,
    eNotasInputRef,
    informTrialPeriod,
    setCollectUserDataInCheckout,
    setPreFillCustomersData,
    setInformInvoice,
    informTax,
    handleTax,
    handleTaxAmount,
    informCycle,
    setInformCycle,
    setMaxSubscriptionCharges,
    setIncludeFeeTransfer,
    expirationLink,
    handleExpiration,
    setInformTrialPeriod,
    handleNewGracePeriod,
    setHasExpiration,
    informRedirectLink,
    setInformRedirectLink,
    informBanners,
    setInformBanners,
    topBanner,
    setTopBanner,
    rightBanner,
    setRightBanner,
    labelButton,
    processing,
    store,
    bexsAmount,
    conversionRates,
    applyBumpUpCrossSell,
    setApplyBumpUpCrossSell,
    applyOrderBump,
    setApplyOrderBump,
    applyUpSell,
    setApplyUpSell,
    applyCrossSell,
    setApplyCrossSell,
    showLimitDescError,
    handleOnBlurDescription,
    handleSmartRetrials,
    smartRetrial,
    setSmartRetrial,

    storeFeesAfterOrder,

    selectedCourses,
    setSelectedCourses,
    handleCourseClassRoom,
    classRooms,
    setClassRooms,
    selectedUnlimitedAccess,
    setSelectedUnlimitedAccess,
    setUnlimitedAccessCourse,
    defineSplit,
    setDefineSplit,
    splits,
    handleSplit,
    setSplits,
    selectedSplits,
    setSelectedSplits,
    isCreatingSplit,
    setIsCreatingSplit,
    selectedRecipient,
    setSelectedRecipient,
    selectedRecipientIds,
    setSelectedRecipientIds,

    informPaymentLinkDueDatePlan,
    setInformPaymentLinkDueDatePlan,
    
    shouldCreateDefaultlLink,
    limitBoleto
  } = usePaymentLinkHook({
    newPaymentLink,
    setNewPaymentLink,
    customer,
    setCustomer,
    tax,
    setTax,
    newPlan,
    setNewPlan,
    orderBump,
    upSell,
    crossSell,
  });

  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const intl = useIntl();

  return (
    <>
      <styled.LeftRightContainer>
        <styled.Left className="border-right">
          <styled.BoxDetails className="border-bottom">
            <div className="centered-container">
              {!shouldCreateDefaultlLink ? <ProgressStepBar
                hidePrevious={false}
                progressStep={progressStep}
                handlePreviousStep={handlePreviousStep}
                creatingPlan={creatingPlan}
                title={intl.formatMessage({
                  id:
                    creatingPlan && progressStep === 2
                      ? "request.payment.create.plan.label.button"
                      : "request.payment.payment.link.title",
                })}
              /> : null}

              <styled.FormContainer>
                {progressStep <= 1 && (
                  <div className="form-item">
                    <h5>
                      {intl.formatMessage({
                        id: "request.payment.payment.type",
                      })}
                    </h5>
                    <Buttons
                      mode="double-checkbox"
                      radio2ButtonsName="payment-type"
                      handler={handlePaymentType}
                      radio2ButtonsValue1="single"
                      radio2ButtonsValue2="recurring"
                      label={intl.formatMessage({
                        id: "request.payment.plan.single",
                      })}
                      labelRight={intl.formatMessage({
                        id: "request.payment.plan.recurring",
                      })}
                      valueToCheck={paymentType}
                    />
                  </div>
                )}

                {paymentType === "recurring" &&
                  progressStep === 1 &&
                  !creatingPlan && (
                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({ id: "request.payment.plan" })}
                      </h5>

                      <SelectPlansButtonModal
                        handleCreatingPlan={setCreatingPlan}
                        setNewPaymentLink={setNewPaymentLink}
                        newPaymentLink={newPaymentLink}
                        setNewPlan={setNewPlan}
                        newPlan={newPlan}
                        isFromPaymentLink={true}
                      />
                    </div>
                  )}

                <form onSubmit={handleSubmit}>
                  {creatingPlan && progressStep === 2 && (
                    <>
                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.recurring.name.plan",
                          })}
                        </h5>
                        <Input
                          required={false}
                          mode="named"
                          name="name"
                          value={newPlan.name}
                          placeholder={intl.formatMessage({
                            id: "request.payment.recurring.name.plan.placeholder",
                          })}
                          handler={handleNewPlanData}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="planName"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.create.plan.name",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <div className="form-item__optional">
                          <h5>
                            {intl.formatMessage({
                              id: "choose.a.product.label",
                            })}
                          </h5>
                          <p>
                            {intl.formatMessage({
                              id: "request.payment.optional.label",
                            })}
                          </p>

                          <div className="tip-container">
                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "products.tip.text",
                              })}
                            />
                          </div>
                        </div>

                        <SelectProductsButtonModal
                          handler={setSelectedProducts}
                          handlerCourse={setSelectedCourses}
                          selectedProducts={selectedProducts}
                          selectedCourses={selectedCourses}
                          clear={() => {}}
                          eventsToEdit={[]}
                          handleNewPaymentLinkDataAmount={
                            handleNewPlanAndLinkAmount
                          }
                          productPreSelected={productPreSelected}
                          setProductPreSelected={setProductPreSelected}
                          classRooms={classRooms}
                          setClassRooms={setClassRooms}
                          selectedUnlimitedAccess={selectedUnlimitedAccess}
                          setSelectedUnlimitedAccess={
                            setSelectedUnlimitedAccess
                          }
                          setUnlimitedAccessCourse={setUnlimitedAccessCourse}
                        />
                      </div>

                      {selectedCourses.length > 0 &&
                      !selectedUnlimitedAccess ? (
                        <>
                          <span className="flex-header-form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "create.payment.link.courses.selected.title",
                              })}
                            </h5>
                            <h5>
                              {intl.formatMessage({
                                id: "create.payment.link.courses.selected.classroom",
                              })}
                            </h5>
                          </span>
                          <div className="inputs-table-container">
                            {selectedCourses.map((course) => (
                              <div
                                className="form-item inputs-container form-item--without-margin"
                                key={course.productId}
                              >
                                <Input
                                  disableCurrencyInput={true}
                                  required={false}
                                  mode="normal"
                                  name="productName"
                                  value={course.productName}
                                  placeholder={""}
                                  handler={() => {}}
                                  borderSide="right"
                                  bottomSeparator={true}
                                />

                                <div className="m-40-width">
                                  <Select
                                    mode="normal-child"
                                    disabled={selectedUnlimitedAccess}
                                    name="classRoom"
                                    placeholder=""
                                    handler={(e: any) =>
                                      handleCourseClassRoom(course.productId, e)
                                    }
                                    bottomSeparator={true}
                                  >
                                    <option className="bold-option" value="">
                                      {intl.formatMessage({
                                        id: "create.payment.link.courses.selected.select.classroom",
                                      })}
                                    </option>
                                    {classRooms.map((classRoom) => (
                                      <option
                                        key={classRoom.id}
                                        value={`${classRoom.name}-${classRoom.id}`}
                                      >
                                        {classRoom.name}
                                      </option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}

                      {selectedProducts.length > 0 ? (
                        <>
                          <span className="flex-header-form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "create.payment.link.products.selected.title",
                              })}
                            </h5>
                            <h5>
                              {intl.formatMessage({ id: "product.quantity" })}
                            </h5>
                          </span>
                          <div className="inputs-table-container">
                            {selectedProducts.map((product) => (
                              <div
                                className="form-item inputs-container form-item--without-margin"
                                key={product.productId}
                              >
                                <Input
                                  disableCurrencyInput={true}
                                  required={false}
                                  mode="normal"
                                  name="productName"
                                  value={product.productName}
                                  placeholder={""}
                                  handler={() => {}}
                                  borderSide="right"
                                  bottomSeparator={true}
                                />

                                <div className="m-20-width">
                                  <Input
                                    disableCurrencyInput={false}
                                    required={true}
                                    mode="normal"
                                    name="quantity"
                                    value={product.quantity}
                                    placeholder={""}
                                    handler={(e: any) =>
                                      handleProductQuantity(
                                        product.productId,
                                        e
                                      )
                                    }
                                    bottomSeparator={true}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({ id: "request.payment.amount" })}
                        </h5>
                        <Input
                          required={true}
                          mode="normal-fixed-symbol"
                          isCurrency={true}
                          fixedSymbol="R$"
                          name="amount"
                          value={newPlan.amount}
                          placeholder=""
                          handler={handleNewPlanAmount}
                        />
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="planAmount"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.create.plan.amount",
                          })}
                        />
                      </div>

                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.recurring",
                          })}
                        </h5>
                        <Select
                          mode="named-child"
                          required={true}
                          name="frequency"
                          value={newPlan.frequency}
                          placeholder={""}
                          handler={handleNewPlanData}
                        >
                          <option value={0}>
                            {intl.formatMessage({
                              id: "request.payment.plan.daily",
                            })}
                          </option>
                          <option value={1}>
                            {intl.formatMessage({
                              id: "request.payment.plan.weekly",
                            })}
                          </option>
                          <option value={2}>
                            {intl.formatMessage({
                              id: "request.payment.plan.monthly",
                            })}
                          </option>
                          <option value={3}>
                            {intl.formatMessage({
                              id: "request.payment.plan.quarterly",
                            })}
                          </option>
                          <option value={4}>
                            {intl.formatMessage({
                              id: "request.payment.plan.halfYearly",
                            })}
                          </option>
                          <option value={5}>
                            {intl.formatMessage({
                              id: "request.payment.plan.yearly",
                            })}
                          </option>
                        </Select>
                      </div>
                    </>
                  )}

                  {!creatingPlan && progressStep === 2 && (
                    <>
                      <>
                        <div className="form-item">
                          <h5>
                            {intl.formatMessage({
                              id: "request.payment.title",
                            })}
                          </h5>

                          <Input
                            maxLength={100}
                            mode="named"
                            name="title"
                            autoComplete={"" + Math.random()}
                            value={newPaymentLink.title}
                            placeholder={intl.formatMessage({
                              id: "request.payment.title",
                            })}
                            handler={handleNewPaymentLinkData}
                          />
                          <ErrorMessage
                            errors={formErrors}
                            wantedError="linkTitle"
                            errorMessage={intl.formatMessage({
                              id: "error.request.payment.link.title",
                            })}
                          />
                        </div>

                        <div className="form-item">
                          <h5>
                            {intl.formatMessage({
                              id: "request.payment.description",
                            })}
                          </h5>

                          <TextArea
                            mode="named"
                            name="description"
                            value={newPaymentLink.description}
                            placeholder={""}
                            handler={handleNewPaymentLinkData}
                            onBlur={handleOnBlurDescription}
                          />
                          <ErrorMessage
                            errors={formErrors}
                            wantedError="linkDescription"
                            errorMessage={intl.formatMessage({
                              id: "error.request.payment.link.description",
                            })}
                          />
                          {showLimitDescError ? (
                            <ErrorMessage
                              errors={["linkDescriptionChar"]}
                              wantedError="linkDescriptionChar"
                              errorMessage={intl.formatMessage({
                                id: "error.request.payment.link.description.onblur",
                              })}
                            />
                          ) : null}
                        </div>

                        {!shouldCreateDefaultlLink ? <div className="form-item">
                          <div className="form-item__optional">
                            <h5>
                              {intl.formatMessage({
                                id: "choose.a.product.label",
                              })}
                            </h5>
                            <p>
                              {intl.formatMessage({
                                id: "request.payment.optional.label",
                              })}
                            </p>

                            <div className="tip-container">
                              <TipButtonModal
                                text={intl.formatMessage({
                                  id: "products.tip.text",
                                })}
                              />
                            </div>
                          </div>

                          <SelectProductsButtonModal
                            handler={setSelectedProducts}
                            handlerCourse={setSelectedCourses}
                            selectedProducts={selectedProducts}
                            selectedCourses={selectedCourses}
                            clear={() => {}}
                            eventsToEdit={[]}
                            handleNewPaymentLinkDataAmount={
                              handleNewPaymentLinkDataAmount
                            }
                            productPreSelected={productPreSelected}
                            setProductPreSelected={setProductPreSelected}
                            classRooms={classRooms}
                            setClassRooms={setClassRooms}
                            selectedUnlimitedAccess={selectedUnlimitedAccess}
                            setSelectedUnlimitedAccess={
                              setSelectedUnlimitedAccess
                            }
                            setUnlimitedAccessCourse={setUnlimitedAccessCourse}
                          />
                        </div> : null}

                        {selectedCourses.length > 0 &&
                        !selectedUnlimitedAccess ? (
                          <>
                            <span className="flex-header-form-item">
                              <h5>
                                {intl.formatMessage({
                                  id: "create.payment.link.courses.selected.title",
                                })}
                              </h5>
                              <h5>
                                {intl.formatMessage({
                                  id: "create.payment.link.courses.selected.classroom",
                                })}
                              </h5>
                            </span>
                            <div className="inputs-table-container">
                              {selectedCourses.map((course) => {
                                return (
                                  <div
                                    className="form-item inputs-container form-item--without-margin"
                                    key={course.productId}
                                  >
                                    <Input
                                      disableCurrencyInput={true}
                                      required={false}
                                      mode="normal"
                                      name="productName"
                                      value={course.productName}
                                      placeholder={""}
                                      handler={() => {}}
                                      borderSide="right"
                                      bottomSeparator={true}
                                    />

                                    <div className="m-40-width">
                                      <Select
                                        mode="normal-child"
                                        disabled={selectedUnlimitedAccess}
                                        name="classRoom"
                                        placeholder=""
                                        // value={course.selectedRoom}
                                        handler={(e: any) =>
                                          handleCourseClassRoom(
                                            course.productId,
                                            e
                                          )
                                        }
                                        bottomSeparator={true}
                                      >
                                        <option
                                          className="bold-option"
                                          value=""
                                        >
                                          {intl.formatMessage({
                                            id: "create.payment.link.courses.selected.select.classroom",
                                          })}
                                        </option>
                                        {classRooms.map((classRoom) => (
                                          <option
                                            key={classRoom.id}
                                            value={`${classRoom.name}-${classRoom.id}`}
                                          >
                                            {classRoom.name}
                                          </option>
                                        ))}
                                      </Select>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        ) : null}

                        {selectedProducts.length > 0 ? (
                          <>
                            <span className="flex-header-form-item">
                              <h5>
                                {intl.formatMessage({
                                  id: "create.payment.link.products.selected.title",
                                })}
                              </h5>
                              <h5>
                                {intl.formatMessage({ id: "product.quantity" })}
                              </h5>
                            </span>

                            <div className="inputs-table-container">
                              {selectedProducts.map((product) => (
                                <div
                                  className="form-item inputs-container form-item--without-margin"
                                  key={product.productId}
                                >
                                  <Input
                                    disableCurrencyInput={true}
                                    required={false}
                                    mode="normal"
                                    name="productName"
                                    value={product.productName}
                                    placeholder={""}
                                    handler={() => {}}
                                    disabled={true}
                                    borderSide="right"
                                    bottomSeparator={true}
                                  />

                                  <div className="m-20-width">
                                    <Input
                                      disableCurrencyInput={false}
                                      required={true}
                                      mode="normal"
                                      name="quantity"
                                      value={product.quantity}
                                      placeholder={""}
                                      handler={(e: any) =>
                                        handleProductQuantity(
                                          product.productId,
                                          e
                                        )
                                      }
                                      bottomSeparator={true}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : null}

                        <h5>
                          {intl.formatMessage({
                            id: "request.payment.customer.currency",
                          })}
                        </h5>
                        <div className="form-item select-and-input-container margin-bottom-0">
                          <div className="m-20-width">
                            <Select
                              mode="clean-normal-child"
                              disabled={
                                newPaymentLink.amount === "NaN" ||
                                newPaymentLink.amount === "" ||
                                newPaymentLink.amount === "0" ||
                                selectedProducts.length > 0
                              }
                              name="currency"
                              placeholder=""
                              value={customerCurrency}
                              handler={setCustomerCurrency}
                            >
                              {(isForeigner && foreignerStoreCurrencySelect
                                ? foreignerStoreCurrencySelect
                                : currencyAndSymbolUnique
                              ).map((currency: any) => (
                                <option
                                  className="bold-option"
                                  key={currency.id}
                                  value={currency.currency}
                                >
                                  {currency.symbol}
                                </option>
                              ))}
                            </Select>
                          </div>

                          <div className="m-half-width">
                            <Input
                              disableCurrencyInput={openAmount}
                              required={true}
                              mode="normal-currency"
                              name="amount"
                              value={newPaymentLink.amount}
                              placeholder={""}
                              handler={handleNewPaymentLinkDataAmount}
                              isInputWithoutBorder={true}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="linkAmount"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.link.amount",
                          })}
                        />
                        
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="linkAmountLess3"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.link.amount.less.3",
                          })}
                        />

                        {!shouldCreateDefaultlLink ? <div className="form-item margin-top-16">
                          <div className="flex">
                            <Input
                              valueToCheck={openAmount}
                              required={false}
                              mode="checkbox"
                              name="open-amount"
                              value="open-amount"
                              placeholder={intl.formatMessage({
                                id: "open.purchase.payment.link.label",
                              })}
                              disabled={
                                selectedProducts.length > 0 ||
                                selectedCourses.length > 0
                              }
                              handler={() => {
                                setOpenAmount(!openAmount);
                              }}
                            />
                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "open.purchase.payment.link",
                              })}
                            />
                          </div>
                        </div> : null}
                      </>
                    </>
                  )}

                  {progressStep === 3 && (
                    <>
                      <div className="form-item">
                        <div className="form-item__optional">
                          <h5>
                            {intl.formatMessage({
                              id: "request.payment.create.plan.customize.title",
                            })}
                          </h5>
                          <p>
                            {intl.formatMessage({
                              id: "request.payment.optional.label",
                            })}
                          </p>
                        </div>

                        <Splits
                          valueToCheck={defineSplit}
                          toggle={() => setDefineSplit(!defineSplit)}
                          splits={splits}
                          setSplits={setSplits}
                          handleSplit={handleSplit}
                          selectedSplits={selectedSplits}
                          setSelectedSplits={setSelectedSplits}
                          isCreatingSplit={isCreatingSplit}
                          setIsCreatingSplit={setIsCreatingSplit}
                          selectedRecipient={selectedRecipient}
                          setSelectedRecipient={setSelectedRecipient}
                          selectedRecipientIds={selectedRecipientIds}
                          setSelectedRecipientIds={setSelectedRecipientIds}
                        />
                      </div>

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={customizePaymentMethods}
                            required={false}
                            mode="checkbox"
                            name="customize"
                            value="customize"
                            placeholder={intl.formatMessage({
                              id: "request.payment.create.plan.limit.payment.methods",
                            })}
                            handler={handleCustomizePaymentMethods}
                          />
                          <TipButtonModal
                            text={intl.formatMessage({
                              id: "request.payment.create.plan.limit.payment.methods.tip",
                            })}
                          />
                        </div>
                        <ErrorMessage
                          errors={formErrors}
                          wantedError="linkPaymentTypes"
                          errorMessage={intl.formatMessage({
                            id: "error.request.payment.link.payment.types",
                          })}
                        />
                      </div>

                      {customizePaymentMethods && (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            <div className="form-item">
                              <Input
                                valueToCheck={card}
                                required={false}
                                mode="checkbox"
                                checked={card}
                                name="customize"
                                value="customize"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.payment.method.cc.label",
                                })}
                                handler={() => setCard(!card)}
                              />
                            </div>
                            <div className="form-item">
                              <Input
                                valueToCheck={pix}
                                required={false}
                                mode="checkbox"
                                checked={pix}
                                name="customize"
                                value="customize"
                                placeholder="Pix"
                                handler={() => setPix(!pix)}
                              />
                            </div>
                            <div className="form-item">
                              <Input
                                valueToCheck={boleto}
                                required={false}
                                mode="checkbox"
                                checked={boleto}
                                name="customize"
                                value="customize"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.payment.method.boleto.label",
                                })}
                                handler={() => setBoleto(!boleto)}
                              />
                            </div>
                          </styled.PaymentRequestItems>
                        </div>
                      )}

                      {paymentType !== "recurring" && (boleto || card) && (
                        <div className="form-item">
                          <div className="flex">
                            <Input
                              valueToCheck={limitInstallments}
                              required={false}
                              mode="checkbox"
                              name="limit-installments"
                              value="limit-installments"
                              placeholder={intl.formatMessage({
                                id: "limit.installments",
                              })}
                              handler={handleLimitInstallments}
                            />
                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "limit.installments.tip",
                              })}
                            />
                          </div>
                        </div>
                      )}

                      {limitInstallments && (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            {card && (
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.credit.card.installments",
                                  })}
                                </h5>
                                <Select
                                  mode="normal-child"
                                  name="installments"
                                  value={newPaymentLink.installments.toString()}
                                  placeholder={""}
                                  handler={handleInstallments}
                                  disabled={
                                    (customerCurrency !== "BRL" &&
                                      !isForeigner) ||
                                    paymentType === "recurring"
                                  }
                                >
                                  {cardInstallmentsAmount.map(
                                    (installment: number, index: number) => (
                                      <option key={index + 1} value={index + 1}>
                                        {`${index + 1}x ${
                                          openAmount
                                            ? ""
                                            : `${intl.formatMessage({
                                                id: "request.payment.of",
                                              })} ${intl.formatNumber(
                                                cardInstallmentsAmount[index],
                                                {
                                                  style: "currency",
                                                  currency: customerCurrency,
                                                  minimumSignificantDigits: 2,
                                                }
                                              )}`
                                        } ${intl.formatMessage({
                                          id:
                                            includeFeeTransfer ||
                                            (applySplitsAfterOrder &&
                                              index + 1 > 1)
                                              ? "request.payment.with.fees"
                                              : "request.payment.without.fees",
                                        })}`}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </div>
                            )}

                            {boleto && (
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.boleto.installment.2",
                                  })}
                                </h5>

                                <Select
                                  mode="normal-child"
                                  name="installments"
                                  value={newPaymentLink.boletoInstallments.toString()}
                                  placeholder={""}
                                  handler={handleBoletoInstallments}
                                  disabled={
                                    (customerCurrency !== "BRL" &&
                                      !isForeigner) ||
                                    paymentType === "recurring"
                                  }
                                >
                                  {boletoInstallmentsAmount.map(
                                    (installment: number, index: number) => (
                                      <option key={index} value={index + 1}>
                                        {`${index + 1}x ${
                                          openAmount
                                            ? ""
                                            : `${intl.formatMessage({
                                                id: "request.payment.of",
                                              })} ${intl.formatNumber(
                                                boletoInstallmentsAmount[index],
                                                {
                                                  style: "currency",
                                                  currency: "BRL",
                                                }
                                              )}`
                                        } ${intl.formatMessage({
                                          id:
                                            includeFeeTransfer ||
                                            (applySplitsAfterOrder &&
                                              index + 1 > 1)
                                              ? "request.payment.with.fees"
                                              : "request.payment.without.fees",
                                        })}`}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </div>
                            )}

                            <div className="form-item">
                              <Input
                                valueToCheck={fixedInstallment}
                                required={false}
                                mode="checkbox"
                                name="fixed-installment"
                                value="fixed-installment"
                                placeholder={intl.formatMessage({
                                  id: "payment.link.fixed.installment",
                                })}
                                handler={() =>
                                  setFixedInstallment(!fixedInstallment)
                                }
                              />
                            </div>
                          </styled.PaymentRequestItems>
                        </div>
                      )}

                      <OrderBumpUpCrossSell
                        valueToCheck={applyBumpUpCrossSell}
                        toggle={setApplyBumpUpCrossSell}
                        setOrderBump={setOrderBump}
                        orderBump={orderBump}
                        setUpSell={setUpSell}
                        upSell={upSell}
                        setCrossSell={setCrossSell}
                        crossSell={crossSell}
                        applyOrderBump={applyOrderBump}
                        setApplyOrderBump={setApplyOrderBump}
                        applyUpSell={applyUpSell}
                        setApplyUpSell={setApplyUpSell}
                        applyCrossSell={applyCrossSell}
                        setApplyCrossSell={setApplyCrossSell}
                      />

                      {card ? (
                        <>
                          <SimpleCheckboxItem
                            valueToCheckt={applySmartInstallments}
                            placeholder="apply.smart.installments.label"
                            tipText="apply.smart.installments.tip"
                            toggle={setApplySmartInstallments}
                            name="apply-smart-installments"
                            value="apply-smart-installments"
                          >
                            <div className="insider-container">
                              <p>
                                {intl.formatMessage({
                                  id: "apply.smart.installments.description",
                                })}
                              </p>
                            </div>
                          </SimpleCheckboxItem>

                          {/* fazer array pra loop desse component */}

                          <SimpleCheckboxItem
                            valueToCheckt={payLater}
                            placeholder="payment.link.pay.later"
                            tipText="payment.link.pay.later.tip"
                            toggle={setPayLater}
                            name="pay-later"
                            value="pay-later"
                          />
                        </>
                      ) : null}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={informPaymentLinkDueDatePlan}
                            required={false}
                            mode="checkbox"
                            name="inform-duedate-plan"
                            value="inform-duedate-plan"
                            placeholder={intl.formatMessage({
                              id: "request.payment.payment.duedate",
                            })}
                            handler={() =>
                              setInformPaymentLinkDueDatePlan(
                                !informPaymentLinkDueDatePlan
                              )
                            }
                          />
                          <TipButtonModal
                            text={intl.formatMessage({
                              id: "request.payment.payment.duedate.tip",
                            })}
                          />
                        </div>
                      </div>

                      {informPaymentLinkDueDatePlan ? (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            <div className="payment-request-item">
                              <Input
                                required={true}
                                mode="named-mask"
                                name="dueDate"
                                mask="99/99/9999"
                                value={newPaymentLink.dueDate}
                                placeholder={
                                  isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"
                                }
                                handler={handleNewPaymentLinkData}
                              />

                              <ErrorMessage
                                errors={formErrors}
                                wantedError="invalidGracePeriod"
                                errorMessage={intl.formatMessage({
                                  id: "error.request.payment.link.payment.boleto.duedate.valid",
                                })}
                              />
                            </div>
                          </styled.PaymentRequestItems>
                        </div>
                      ) : null}

                      {boleto && (
                        <div className="form-item">
                          <div className="flex">
                            <Input
                              valueToCheck={customizeBoleto}
                              required={false}
                              mode="checkbox"
                              name="customize-boleto"
                              value="customize-boleto"
                              placeholder={intl.formatMessage({
                                id: "request.payment.customize.boleto.btn",
                              })}
                              handler={handleCustomizeBoleto}
                            />
                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "request.payment.customize.boleto.btn.tip",
                              })}
                            />
                          </div>
                        </div>
                      )}

                      {customizeBoleto && (
                        <>
                          <div className="insider-container">
                            {/* {paymentType !== "recurring" && (
                              <> */}
                            <div className="form-item ">
                              <div className="select-and-input-container">
                                <div className="m-half-width">
                                  <Select
                                    mode="clean-named-child"
                                    required={false}
                                    name="discount"
                                    value={discount.mode}
                                    placeholder={""}
                                    handler={handleDiscountFeeTicket}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount",
                                      })}
                                    </option>
                                    <option value="Percentage">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.percentual",
                                      })}
                                    </option>
                                    <option value="Fixed">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.amount",
                                      })}
                                    </option>
                                  </Select>
                                </div>

                                <div className="m-half-width">
                                  {discount.mode === "Fixed" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-currency"
                                      name="discount-order"
                                      value={discount.amount}
                                      placeholder=""
                                      handler={handleDiscount}
                                    />
                                  )}
                                  {discount.mode === "Percentage" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-percentage"
                                      name="discount-order"
                                      value={discount.amount}
                                      placeholder=""
                                      handler={handleDiscount}
                                    />
                                  )}
                                  {discount.mode === "" && (
                                    <input
                                      className="input__element"
                                      name="discount-order"
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>

                              <ErrorMessage
                                errors={formErrors}
                                wantedError="discountAmount"
                                errorMessage={intl.formatMessage({
                                  id: "request.payment.discount.error",
                                })}
                              />
                            </div>

                            <div className="form-item">
                              <div className="select-and-input-container">
                                <div className="m-half-width">
                                  <Select
                                    mode="clean-named-child"
                                    required={false}
                                    name="lateFee"
                                    value={lateFee.mode}
                                    placeholder={""}
                                    handler={handleDiscountFeeTicket}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.fees.rp",
                                      })}
                                    </option>
                                    <option value="Percentage">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.percentual",
                                      })}
                                    </option>
                                    <option value="Fixed">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.amount",
                                      })}
                                    </option>
                                  </Select>
                                </div>

                                <div className="m-half-width">
                                  {lateFee.mode === "Fixed" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-currency"
                                      name="discount-order"
                                      value={lateFee.amount}
                                      placeholder=""
                                      handler={handleFee}
                                    />
                                  )}
                                  {lateFee.mode === "Percentage" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-percentage"
                                      name="discount-order"
                                      value={lateFee.amount}
                                      placeholder=""
                                      handler={handleFee}
                                    />
                                  )}
                                  {lateFee.mode === "" && (
                                    <input
                                      className="input__element"
                                      name="discount-order"
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>

                              <ErrorMessage
                                errors={formErrors}
                                wantedError="lateFeeAmount"
                                errorMessage={intl.formatMessage({
                                  id: "request.payment.late.fee.error",
                                })}
                              />
                            </div>
                            {/* </>
                            )} */}

                            {/* {paymentType !== "recurring" && (
                              <> */}
                            <div className="form-item">
                              <div className="select-and-input-container">
                                <div className="m-half-width">
                                  <Select
                                    mode="clean-named-child"
                                    required={false}
                                    name="interest"
                                    value={interest.mode}
                                    placeholder={""}
                                    handler={handleDiscountFeeTicket}
                                  >
                                    <option value="">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.fine",
                                      })}
                                    </option>
                                    <option value="Daily_Percentage">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.percentual.daily",
                                      })}
                                    </option>
                                    <option value="Monthly_Percentage">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.percentual.monthly",
                                      })}
                                    </option>
                                    <option value="Daily_Amount">
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.amount",
                                      })}
                                    </option>
                                  </Select>
                                </div>

                                <div className="m-half-width">
                                  {interest.mode === "Daily_Amount" && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-currency"
                                      name="fee-order"
                                      value={interest.amount}
                                      placeholder=""
                                      handler={handleTicket}
                                    />
                                  )}
                                  {(interest.mode === "Daily_Percentage" ||
                                    interest.mode === "Monthly_Percentage") && (
                                    <Input
                                      required={false}
                                      isInputWithoutBorder={true}
                                      mode="normal-percentage"
                                      name="fee-order"
                                      value={interest.amount}
                                      placeholder=""
                                      handler={handleTicket}
                                    />
                                  )}
                                  {interest.mode === "" && (
                                    <input
                                      className="input__element"
                                      name="ticket-order"
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>

                              <ErrorMessage
                                errors={formErrors}
                                wantedError="interestAmountDailyP"
                                errorMessage={intl.formatMessage({
                                  id: "request.payment.interest.error.daily.percentage",
                                })}
                              />
                              <ErrorMessage
                                errors={formErrors}
                                wantedError="interestAmountMontlyP"
                                errorMessage={intl.formatMessage({
                                  id: "request.payment.interest.error.monthly",
                                })}
                              />
                              <ErrorMessage
                                errors={formErrors}
                                wantedError="interestAmountDailyA"
                                errorMessage={intl.formatMessage({
                                  id: "request.payment.interest.error.daily.percentage",
                                })}
                              />
                            </div>
                            {/* </>
                            )} */}

                            {discount.mode !== "" &&
                              paymentType !== "recurring" && (
                                <>
                                  <div className="form-item">
                                    <h5>
                                      {intl.formatMessage({
                                        id: "request.payment.boleto.discount.limit",
                                      })}
                                    </h5>

                                    <Input
                                      required={true}
                                      mode="named-mask"
                                      name="discountLimit"
                                      mask="99/99/9999"
                                      value={newPaymentLink.discountLimit}
                                      placeholder={
                                        isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"
                                      }
                                      handler={handleNewPaymentLinkData}
                                    />
                                  </div>
                                  {manualDuedateError !== "" && (
                                    <styled.Error>
                                      {manualDuedateError}
                                    </styled.Error>
                                  )}
                                </>
                              )}

                            {paymentType !== "recurring" ? (
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.duedate",
                                  })}
                                </h5>

                                <Input
                                  required={false}
                                  mode="named-mask"
                                  name="dueDate"
                                  mask="99/99/9999"
                                  value={newPaymentLink.dueDate}
                                  placeholder={
                                    isPtbr ? "DD/MM/AAAA" : "DD/MM/YYYY"
                                  }
                                  handler={handleNewPaymentLinkData}
                                />

                                <ErrorMessage
                                  errors={formErrors}
                                  wantedError="linkBoletoDuedateInvalid"
                                  errorMessage={intl.formatMessage({
                                    id: "error.request.payment.link.payment.boleto.duedate.valid",
                                  })}
                                />
                              </div>
                            ) : null}

                            {paymentType === "recurring" &&
                              (newPlan.frequency.toString() !== "0" ||
                                newPaymentLink.frequency > 0) && (
                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.boleto.days.before.generate.boleto",
                                    })}
                                  </h5>

                                  <Input
                                    required={false}
                                    mode="named"
                                    name="chargeDaysBefore"
                                    value={newPaymentLink.chargeDaysBefore}
                                    placeholder=""
                                    handler={handleNewPaymentLinkData}
                                  />
                                </div>
                              )}

                            {(parseInt(maxSubscriptionCharges) > 1 ||
                              newPaymentLink.boletoInstallments > 1) && (
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.generate.all.boleto",
                                  })}
                                </h5>

                                <span className="select-and-input-container">
                                  <Select
                                    mode="clean-named-child"
                                    required={false}
                                    name="generateAllBoletos"
                                    placeholder={""}
                                    handler={handleSetGenerateAllBoleto}
                                  >
                                    <option value=""></option>
                                    <option value="true">
                                      {intl.formatMessage({ id: "select.yes" })}
                                    </option>
                                    <option value="false">
                                      {intl.formatMessage({ id: "select.no" })}
                                    </option>
                                  </Select>
                                </span>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <SimpleCheckboxItem
                        valueToCheckt={collectUserDataInCheckout}
                        placeholder="request.payment.address.data"
                        tipText="request.payment.address.data.tip"
                        toggle={setCollectUserDataInCheckout}
                        name="collect-address"
                        value="collect-address"
                      />

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={preFillCustomersData}
                            required={false}
                            mode="checkbox"
                            name="prefill"
                            value="prefill"
                            placeholder={intl.formatMessage({
                              id: "request.payment.pre.fill",
                            })}
                            handler={() => {
                              setPreFillCustomersData(!preFillCustomersData);
                            }}
                          />
                          <TipButtonModal
                            text={intl.formatMessage({
                              id: "request.payment.pre.fill.tip",
                            })}
                          />
                        </div>
                      </div>

                      {preFillCustomersData && (
                        <div className="insider-container">
                          {!createCustomerForPaymentLink ? <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "home.name",
                              })}
                            </h5>
                            <SelectCustomerModal
                              handler={setSelectedCustomerID}
                              createCustomerForPaymentLink={createCustomerForPaymentLink}
                              setCreateCustomerForPaymentLink={setCreateCustomerForPaymentLink}
                            />
                          </div> : null}

                          {createCustomerForPaymentLink ? (
                            <CusomerPersonalData
                              customer={customer}
                              handleCustomer={setCustomer}
                              isForeigner={isForeigner}
                              showCustomer={true}
                              showAddress={true}
                              boleto={boleto}
                              isPaymentLink={true}
                              customerCurrency={"BRL"}
                              formErrors={formErrors}
                            />
                          ) : null}
                        </div>
                      )}

                      {storeHasENotasKEY && (
                        <>
                          <div className="form-item">
                            <Input
                              valueToCheck={informInvoice}
                              required={false}
                              mode="checkbox"
                              name="inform-invoice"
                              value="inform-invoice"
                              inputRef={eNotasInputRef}
                              placeholder={intl.formatMessage({
                                id: "request.payment.enota",
                              })}
                              handler={() => setInformInvoice(!informInvoice)}
                            />
                          </div>

                          {informInvoice && (
                            <div className="insider-container">
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.enota.when.generate",
                                  })}
                                </h5>

                                <Select
                                  mode="named-child"
                                  required={false}
                                  name="whenSend"
                                  value={newPaymentLink.whenSend}
                                  placeholder=""
                                  handler={handleInvoiceData}
                                >
                                  <option value="">
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select",
                                    })}
                                  </option>
                                  <option value="0">
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select.on.sell",
                                    })}
                                  </option>
                                  <option value="1">
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select.after.warranty",
                                    })}
                                  </option>
                                  {/* <option value="2">{intl.formatMessage({ id: "request.payment.enota.select.not.generate" })}</option> */}
                                </Select>
                              </div>

                              {newPaymentLink.whenSend === "1" && (
                                <div className="form-item">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select.warranty.days",
                                    })}
                                  </h5>

                                  <Input
                                    required={false}
                                    mode="named"
                                    type="number"
                                    name="warrantyDays"
                                    value={newPaymentLink.warrantyDays}
                                    placeholder=""
                                    handler={handleInvoiceData}
                                  />
                                </div>
                              )}

                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "request.payment.enota.select.send",
                                  })}
                                </h5>

                                <Select
                                  mode="named-child"
                                  required={false}
                                  name="sendToCustomer"
                                  value={newPaymentLink.sendToCustomer}
                                  placeholder=""
                                  handler={handleInvoiceData}
                                >
                                  <option value="">
                                    {intl.formatMessage({
                                      id: "request.payment.enota.select.send.select",
                                    })}
                                  </option>
                                  <option value="true">
                                    {intl.formatMessage({ id: "select.yes" })}
                                  </option>
                                  <option value="false">
                                    {intl.formatMessage({ id: "select.no" })}
                                  </option>
                                </Select>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {/* <div className="form-item">
                      <Input required={false} mode="checkbox" name="tax-rates" value="tax-rates" placeholder={intl.formatMessage({ id: "request.payment.inform.tax" })} handler={() => { setInformTax(!informTax) }} />
                    </div> */}

                      {informTax && (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            <div className="form-item select-and-input-container">
                              <div className="m-half-width">
                                <Select
                                  mode="clean-named-child"
                                  required={false}
                                  name="mode"
                                  value={tax.mode}
                                  placeholder={""}
                                  handler={handleTax}
                                >
                                  <option value="">
                                    {intl.formatMessage({
                                      id: "request.payment.tax",
                                    })}
                                  </option>
                                  <option value="Percentage">
                                    {intl.formatMessage({
                                      id: "request.payment.boleto.discount.percentual",
                                    })}
                                  </option>
                                  <option value="Fixed">
                                    {intl.formatMessage({
                                      id: "request.payment.boleto.discount.amount",
                                    })}
                                  </option>
                                </Select>
                              </div>

                              <div className="m-half-width">
                                {tax.mode === "Fixed" && (
                                  <Input
                                    required={false}
                                    isInputWithoutBorder={true}
                                    mode="normal-currency"
                                    name="tax-amount"
                                    value={tax.amount}
                                    placeholder=""
                                    handler={handleTaxAmount}
                                  />
                                )}
                                {tax.mode === "Percentage" && (
                                  <Input
                                    required={false}
                                    isInputWithoutBorder={true}
                                    mode="normal-percentage"
                                    name="tax-amount"
                                    value={tax.amount}
                                    placeholder=""
                                    handler={handleTaxAmount}
                                  />
                                )}
                                {tax.mode === "" && (
                                  <input
                                    className="input__element"
                                    name="tax-amount"
                                    disabled
                                  />
                                )}
                              </div>
                            </div>
                            {/* <styled.Error>{tax.errors.some((x: any) => x == "amount") ? `${intl.formatMessage({ id: "request.payment.late.fee.error" })}` : ``}</styled.Error> */}
                          </styled.PaymentRequestItems>
                        </div>
                      )}

                      <SimpleCheckboxItem
                        valueToCheckt={includeFeeTransfer}
                        placeholder="manager.links.details.include.fee.transfer"
                        tipText="manager.links.details.include.fee.transfer.tip"
                        toggle={setIncludeFeeTransfer}
                        disabled={storeFeesAfterOrder.length > 0}
                        name="include-fee-transfer"
                        value="include-fee-transfer"
                      />

                      {paymentType === "recurring" && (
                        <>
                          <div className="form-item">
                            <div className="flex">
                              <Input
                                valueToCheck={informCycle}
                                required={false}
                                mode="checkbox"
                                name="inform-cycle"
                                value="inform-cycle"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.inform.cycle",
                                })}
                                handler={() => {
                                  setInformCycle(!informCycle);
                                }}
                              />
                              <TipButtonModal
                                text={intl.formatMessage({
                                  id: "request.payment.inform.cycle.tip",
                                })}
                              />
                            </div>
                          </div>

                          {informCycle && (
                            <div className="insider-container">
                              <styled.PaymentRequestItems>
                                <div className="payment-request-item">
                                  <Input
                                    required={true}
                                    mode="normal"
                                    type="number"
                                    name="maxSubscriptionCharges"
                                    value={maxSubscriptionCharges}
                                    placeholder={intl.formatMessage({
                                      id: "request.payment.max.subcriptions.charge.label",
                                    })}
                                    handler={setMaxSubscriptionCharges}
                                  />
                                </div>
                                {/* <styled.Error>{tax.errors.some((x: any) => x == "amount") ? `${intl.formatMessage({ id: "request.payment.late.fee.error" })}` : ``}</styled.Error> */}
                              </styled.PaymentRequestItems>
                            </div>
                          )}

                          <div className="form-item">
                            <div className="flex">
                              <Input
                                required={false}
                                valueToCheck={smartRetrial.informRetries}
                                mode="checkbox"
                                name="inform-informRetries"
                                value="inform-informRetries"
                                placeholder={intl.formatMessage({
                                  id: "request.payment.smart.retrial.label",
                                })}
                                handler={() => {
                                  setSmartRetrial({
                                    ...smartRetrial,
                                    informRetries: !smartRetrial.informRetries,
                                  });
                                }}
                              />
                              <TipButtonModal
                                text={intl.formatMessage({
                                  id: "request.payment.smart.retrial.tip",
                                })}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {smartRetrial.informRetries && (
                        <>
                          <div className="insider-container">
                            <styled.PaymentRequestItems>
                              <div className="form-item">
                                <div className="form-item__optional">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.smart.retrial.days",
                                    })}
                                  </h5>
                                  <p>
                                    {intl.formatMessage({
                                      id: "request.payment.optional.label",
                                    })}
                                  </p>
                                </div>

                                <Input
                                  required={true}
                                  mode="named"
                                  type="number"
                                  name="daysBetweenRetries"
                                  value={smartRetrial.daysBetweenRetries}
                                  placeholder={""}
                                  handler={handleSmartRetrials}
                                />

                                <styled.Error>
                                  {smartRetrial.errors.some(
                                    (x: any) => x === "daysBetweenRetries"
                                  )
                                    ? `${intl.formatMessage({
                                        id: "request.payment.smart.retrial.days.error",
                                      })}`
                                    : ``}
                                </styled.Error>
                              </div>
                            </styled.PaymentRequestItems>

                            <styled.PaymentRequestItems>
                              <div className="form-item">
                                <div className="form-item__optional">
                                  <h5>
                                    {intl.formatMessage({
                                      id: "request.payment.smart.retrial.limit",
                                    })}
                                  </h5>
                                  <p>
                                    {intl.formatMessage({
                                      id: "request.payment.optional.label",
                                    })}
                                  </p>
                                </div>

                                <Input
                                  required={true}
                                  mode="named"
                                  type="number"
                                  name="maxRetries"
                                  value={smartRetrial.maxRetries}
                                  placeholder={""}
                                  handler={handleSmartRetrials}
                                />

                                <styled.Error>
                                  {smartRetrial.errors.some(
                                    (x: any) => x === "maxRetries"
                                  )
                                    ? `${intl.formatMessage({
                                        id: "request.payment.smart.retrial.days.error",
                                      })}`
                                    : ``}
                                </styled.Error>
                              </div>
                            </styled.PaymentRequestItems>
                          </div>
                        </>
                      )}

                      {paymentType === "recurring" && (
                        <div className="form-item">
                          <div className="flex">
                            <Input
                              valueToCheck={informTrialPeriod}
                              required={false}
                              mode="checkbox"
                              name="inform-trial"
                              value="inform-trial"
                              placeholder={`${intl.formatMessage({
                                id: "request.payment.recurring.trial",
                              })}`}
                              handler={() => {
                                setInformTrialPeriod(!informTrialPeriod);
                              }}
                              disabled={
                                informPaymentLinkDueDatePlan &&
                                newPaymentLink.dueDate !== ""
                              }
                            />

                            <TipButtonModal
                              text={intl.formatMessage({
                                id: "request.payment.recurring.trial.tip",
                              })}
                            />
                          </div>
                        </div>
                      )}

                      {informTrialPeriod && (
                        <div className="insider-container">
                          <styled.PaymentRequestItems>
                            <div className="payment-request-item">
                              <Input
                                required={true}
                                mode="normal"
                                type="number"
                                name="grace"
                                value={newPlan.grace}
                                placeholder={""}
                                handler={handleNewGracePeriod}
                              />

                              <ErrorMessage
                                errors={formErrors}
                                wantedError="invalidGracePeriod"
                                errorMessage={intl.formatMessage({
                                  id: "error.request.payment.link.payment.boleto.duedate.valid",
                                })}
                              />
                            </div>
                          </styled.PaymentRequestItems>
                        </div>
                      )}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={hasExpiration}
                            required={false}
                            mode="checkbox"
                            name="expiration"
                            value="expiration"
                            placeholder={intl.formatMessage({
                              id: "request.payment.expiration.link",
                            })}
                            handler={() => {
                              setHasExpiration(!hasExpiration);
                            }}
                          />
                          <TipButtonModal
                            text={intl.formatMessage({
                              id: "request.payment.expiration.link.tip",
                            })}
                          />
                        </div>
                      </div>

                      {hasExpiration && (
                        <div className="insider-container">
                          <div className="form-item select-and-input-container">
                            <div className="m-half-width--flex">
                              <Input
                                type="date"
                                name="date"
                                mode="named"
                                placeholder=""
                                isInputWithoutBorder={true}
                                minDate={format(
                                  new Date(),
                                  "yyyy-MM-dd'T'HH:mm"
                                )}
                                value={expirationLink.date}
                                handler={handleExpiration}
                              />
                            </div>

                            <div className="m-half-width--flex">
                              <Input
                                type="time"
                                name="time"
                                mode="named"
                                placeholder=""
                                isInputWithoutBorder={true}
                                value={expirationLink.time}
                                handler={handleExpiration}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={informRedirectLink}
                            required={false}
                            mode="checkbox"
                            name="redirect-link"
                            value="redirect-link"
                            placeholder={intl.formatMessage({
                              id: "request.payment.redirect.payment",
                            })}
                            handler={() =>
                              setInformRedirectLink(!informRedirectLink)
                            }
                          />
                          <TipButtonModal
                            text={intl.formatMessage({
                              id: "request.payment.redirect.payment.tip",
                            })}
                          />
                        </div>
                      </div>

                      {informRedirectLink && (
                        <div className="insider-container">
                          <p>
                            {intl.formatMessage({
                              id: "request.payment.redirect.payment.instead",
                            })}
                          </p>

                          <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "request.payment.redirect.payment.page",
                              })}
                            </h5>

                            <Input
                              required={false}
                              mode="named"
                              name="redirectUrl"
                              value={newPaymentLink.redirectUrl}
                              placeholder={intl.formatMessage({
                                id: "request.payment.redirect.payment.page.placeholder",
                              })}
                              handler={handleNewPaymentLinkData}
                            />
                            <ErrorMessage
                              errors={formErrors}
                              wantedError="linkUrlRedirect"
                              errorMessage={intl.formatMessage({
                                id: "error.request.payment.url.redirect",
                              })}
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-item">
                        <div className="flex">
                          <Input
                            valueToCheck={informBanners}
                            required={false}
                            mode="checkbox"
                            name="banners"
                            value="banners"
                            placeholder={intl.formatMessage({
                              id: "request.payment.banner.label",
                            })}
                            handler={() => setInformBanners(!informBanners)}
                          />
                          {/* <TipButtonModal text={intl.formatMessage({ id: "request.payment.redirect.payment.tip" })}/> */}
                        </div>
                      </div>

                      {informBanners ? (
                        <div className="insider-container">
                          <p>
                            {intl.formatMessage({
                              id: "request.payment.banner",
                            })}
                          </p>

                          <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "request.payment.top.banner",
                              })}
                            </h5>
                            <p>
                              {intl.formatMessage({
                                id: "request.payment.top.banner.dimensions",
                              })}
                            </p>

                            <FilePond
                              files={topBanner}
                              onupdatefiles={setTopBanner}
                              allowMultiple={false}
                              acceptedFileTypes={[
                                "image/png",
                                "image/jpeg",
                                "image/svg",
                                "image/jpg",
                                "image/svg+xml",
                                "image/gif",
                              ]}
                              maxFiles={1}
                              name="topBanner"
                            ></FilePond>

                            {/* <Input required={false} mode="named" name="topBanner" value={newPaymentLink.topBanner} placeholder="" handler={handleNewPaymentLinkData} /> */}
                            {/* <ErrorMessage errors={formErrors} wantedError="linkUrlRedirect" errorMessage={intl.formatMessage({ id: "error.request.payment.url.redirect" })} /> */}
                          </div>

                          <div className="form-item">
                            <h5>
                              {intl.formatMessage({
                                id: "request.payment.right.banner",
                              })}
                            </h5>
                            <p>
                              {intl.formatMessage({
                                id: "request.payment.right.banner.dimensions",
                              })}
                            </p>

                            <FilePond
                              files={rightBanner}
                              onupdatefiles={setRightBanner}
                              allowMultiple={false}
                              acceptedFileTypes={[
                                "image/png",
                                "image/jpeg",
                                "image/svg",
                                "image/jpg",
                                "image/svg+xml",
                                "image/gif",
                              ]}
                              maxFiles={1}
                              name="rightBanner"
                            ></FilePond>

                            {/* <Input required={false} mode="named" name="rightBanner" value={newPaymentLink.rightBanner} placeholder="" handler={handleNewPaymentLinkData} /> */}
                            {/* <ErrorMessage errors={formErrors} wantedError="linkUrlRedirect" errorMessage={intl.formatMessage({ id: "error.request.payment.url.redirect" })} /> */}
                          </div>
                        </div>
                      ) : null}
                    
                    <ErrorMessage
                      errors={formErrors}
                      wantedError="amountMoreThanLimit"
                      errorMessage={`${intl.formatMessage({
                        id: "error.request.payment.amount.more.than.10k",
                      })}${intl.formatNumber(limitBoleto ? limitBoleto : 0, { style: "currency", currency: "BRL" })}.
                      
                      ${" "}
                      ${intl.formatMessage({ id: "error.request.payment.amount.more.than.limit.2" })}
                      `}
                    />
                    </>
                  )}

                  <div className="button-bottom-container margin-bottom-100">
                    {!processing && (
                      <Buttons
                        mode="filled"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({ id: labelButton })}
                        customClass="full-width"
                        disable={
                          // isCreatingSplit ||
                          (paymentType === "" && !shouldCreateDefaultlLink) ||
                          (paymentType === "recurring" &&
                            !newPaymentLink.planId &&
                            !creatingPlan)
                        }
                      />
                    )}

                    {processing && (
                      <Buttons
                        mode="filled-processing"
                        handler={() => {}}
                        type="submit"
                        label={intl.formatMessage({ id: labelButton })}
                        customClass="full-width"
                        disable={paymentType === ""}
                      />
                    )}
                  </div>
                </form>
              </styled.FormContainer>
            </div>
          </styled.BoxDetails>
        </styled.Left>
      </styled.LeftRightContainer>
    </>
  );
};

export default PaymentLink;
