import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { SessionProvider } from "./providers/sessionContext";

import AppRoutes from "./router/Index";

import * as serviceWorker from "./serviceWorker";

import resources from "./resources/resources";

import "./assets/css/index.scss";
import ToggleTemaProvider from "./providers/toggleTheme";

const messages = resources.getMessages(navigator.language.split("-")[0]);

Bugsnag.start({
  apiKey: "79471e957e9bb16f30a564bf2a774524",
  plugins: [new BugsnagPluginReact()],
});

ReactDOM.render(
  <SessionProvider>
    <ToggleTemaProvider>
      <IntlProvider locale={navigator.language} messages={messages}>
        <AppRoutes />
      </IntlProvider>
    </ToggleTemaProvider>
  </SessionProvider>,
  document.getElementById("root")
);

serviceWorker.register();
