import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

//Services
import SessionService from "services/Store";

import useClickOutside from "../../../../hooks/useClickOutside";

//Helpers
import formatIdentificationNumber from "helpers/formatIdentificationNumber";

//Components
import Input from "components/Forms/InputFloatLabel";
import Buttons from "components/Forms/Buttons";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";

import * as styled from "./styles";

type TProps = {
  handler?: any;
  setID?: any;
  labelWithStoreNameAndId?: boolean;
  storeId?: number;
};

type TStore = {
  id: number;
  businessId: number;
  socialName: string;
  identificationNumber: string;
  status: string;
};

const SelectStoresButtonModal = ({
  handler,
  setID,
  labelWithStoreNameAndId,
  storeId,
}: TProps) => {
  const [stores, setStores] = useState<TStore[]>([]);
  const [backupStores, setBackupStores] = useState<TStore[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [showStoresModal, setShowStoresModal] = useState(false);
  const [isSelectedAStore, setIsSelectedAStore] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [wantedStore, setWantedStore] = useState("");

  const storesModalRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    getSessionInfos();
  }, []);

  useEffect(() => {
    if (stores.length > 0 && storeId && storeId > 0) {
      setIsSelectedAStore(true);

      let labelEditting = stores.filter((store) => store.id === storeId);

      setSelectedLabel(
        formatIdentificationNumber(labelEditting[0].identificationNumber)
      );
    }
  }, [stores]);

  useEffect(() => {
    if (wantedStore !== "") {
      let filteredStores = backupStores.filter((store: TStore) =>
        store.socialName.toLowerCase().includes(wantedStore.toLowerCase())
      );

      setStores(filteredStores);
    }

    if (wantedStore === "") {
      setStores(backupStores);
    }
  }, [wantedStore]);

  function getSessionInfos() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    // if(session.entityType !== "Business") {
    //   SessionService.getStoreByID().then((response) => {
    //     if (/admin/.test(location.pathname)) {
    //       const responseStores = response.data.filter((store: any) => {
    //         if (store.connectors && store.connectors.length > 0) {
    //           const hasActiveNuvemshopConnector = store.connectors.some(
    //             (connector: any) =>
    //             connector.tag === "nuvemshop" && connector.isActive
    //             );
                
    //             if (hasActiveNuvemshopConnector) return store;
    //           }
    //           return store;
    //         });
    //         setBackupStores(
    //           responseStores.sort((a: TStore, b: TStore) =>
    //           a.socialName.localeCompare(b.socialName)
    //           )
    //           );
              
    //           return setStores(
    //             responseStores.sort((a: TStore, b: TStore) =>
    //           a.socialName.localeCompare(b.socialName)
    //           )
    //           );
    //         } else {
    //           console.log(response.data, "e aí")
    //           const responseStores = response.data.filter((store: any) => {
    //             if (store.connectors && store.connectors.length > 0) {
    //               const hasActiveNuvemshopConnector = store.connectors.some(
    //                 (connector: any) =>
    //                 connector.tag === "nuvemshop" && !connector.isActive
    //                 );
                    
    //                 if (hasActiveNuvemshopConnector) return store;
    //               }
    //               return store;
    //             });
    //           setBackupStores(
    //             responseStores.sort((a: TStore, b: TStore) =>
    //             a.socialName.localeCompare(b.socialName)
    //             )
    //             );
                
    //             return setStores(
    //               responseStores.sort((a: TStore, b: TStore) =>
    //               a.socialName.localeCompare(b.socialName)
    //               )
    //               );
    //             }
    //     });
    // }
    SessionService.getStores().then((response) => {
      if (/admin/.test(location.pathname)) {
        const responseStores = response.data.filter((store: any) => {
          if (store.connectors && store.connectors.length > 0) {
            const hasActiveNuvemshopConnector = store.connectors.some(
              (connector: any) =>
              connector.tag === "nuvemshop" && connector.isActive
              );
              
              if (hasActiveNuvemshopConnector) return store;
            }
            return store;
          });
          setBackupStores(
            responseStores.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
            )
            );
            
            return setStores(
              responseStores.sort((a: TStore, b: TStore) =>
            a.socialName.localeCompare(b.socialName)
            )
            );
          } else {
            const responseStores = response.data.filter((store: any) => {
              if (store.connectors && store.connectors.length > 0) {
                const hasActiveNuvemshopConnector = store.connectors.some(
                  (connector: any) =>
                  connector.tag === "nuvemshop" && !connector.isActive
                  );
                  
                  if (hasActiveNuvemshopConnector) return store;
                }
                return store;
              });
              console.log(responseStores, "e aí", response.data, "como?")
            setBackupStores(
              responseStores.sort((a: TStore, b: TStore) =>
              a.socialName.localeCompare(b.socialName)
              )
              );
              
              return setStores(
                responseStores.sort((a: TStore, b: TStore) =>
                a.socialName.localeCompare(b.socialName)
                )
                );
              }
      });
          }
          
  function handleShowModal() {
    if (storesModalRef.current) {
      setIsMounted(false);
      setShowStoresModal(true);
    } else {
      setIsMounted(true);
      setShowStoresModal(true);
    }
  }

  useClickOutside(storesModalRef, isMounted, setIsMounted);
  function handleSelectAStore(store: TStore) {
    setIsSelectedAStore(true);
    if (labelWithStoreNameAndId) {
      setSelectedLabel(
        `${store.socialName} / ${formatIdentificationNumber(
          store.identificationNumber
        )}`
      );
    } else {
      setSelectedLabel(formatIdentificationNumber(store.identificationNumber));
    }

    handler(store.identificationNumber);
    setID(store.id);
    
    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowStoresModal(false);
    }, 300);
  }

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>
          {isSelectedAStore
            ? selectedLabel
            : intl.formatMessage({ id: "settings.select.store.placeholder" })}
        </p>

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showStoresModal && (
        <styled.SelectStoreContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={storesModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowStoresModal(false);
          }}
        >
          <div className="header-container">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={wantedStore}
                placeholder={intl.formatMessage({
                  id: "view.as.search.establishment",
                })}
                handler={setWantedStore}
              />
            </div>
          </div>

          <div className="buttons-container">
            {stores &&
              stores.map((store) => {
                return (
                  <div
                    className="button__item"
                    onClick={() => handleSelectAStore(store)}
                  >
                    <Buttons
                      key={store.id}
                      mode="simple-limited"
                      label={
                        labelWithStoreNameAndId
                          ? store.socialName
                          : formatIdentificationNumber(
                              store.identificationNumber
                            )
                      }
                      handler={() => {}}
                    />
                    {labelWithStoreNameAndId ? (
                      <div className="span-id-number">
                        {formatIdentificationNumber(store.identificationNumber)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </styled.SelectStoreContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectStoresButtonModal;
