import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

//Styles
import * as styled from "./styles";

export const FinishRegisterTopBanner = () => {
  const intl = useIntl();
  const history = useHistory();

  const pushToSettingsPage = () => {
    history.push("/store-register");
  };

  return (
    <styled.TopBannerContainer onClick={pushToSettingsPage}>
      <p>{intl.formatMessage({ id: "finish.register.top.banner" })}</p>
    </styled.TopBannerContainer>
  );
};
