const axios = require("axios");

type Tax = {
  mode: string;
  amount: number;
};

interface UserTracking {
  browser: string;
  device: string;
  ip: string;
  operatingSystem: string;
}

interface RequestPaymentLinkPost {
  productCategory: string | undefined;
  boletoInstallments: number;
  includeFeeTransfer: boolean;
  amount: number;
  orderNumber?: string | null;
  planId?: number;
  type: number;
  installments?: number;
  paymentTypes: number[];
  title?: string;
  description?: string;
  discount?: any;
  lateFee?: any;
  interestOrFine?: any;
  isUserDataRequired?: boolean;
  expiresOn: string | null;
  customer?: any;
  tax?: Tax | undefined;
  daysBeforeGenerateBoleto?: number | null;
  generateAllBoletos?: boolean | undefined;
  dueDate?: string | null;
  currency?: string;
  redirectUrl?: string;
  metadata?: any;
}

interface Address {
  street: string;
  number: string;
  zipcode: string;
  reference: string;
  district: string;
  city: string;
  state: number;
  country: number;
}

interface RequestPaymentPost {
  productCategory: string | undefined;
  isTokenized: boolean;
  includeFeeTransfer: boolean;
  amount: number;
  planId?: number;
  description: string;
  referencedOrderNumber?: string;
  customerId: number;
  payment: {
    type: string;
    installments?: number;
    cardId?: number;
    dueDate?: string;
    discount?: any;
    lateFee?: any;
    interestOrFine?: any;
  };
  userTracking?: UserTracking;
  tax?: {
    mode: string;
    amount: number;
  };
  currency?: string;
  chargeDaysBefore?: number;
  generateAllBoletos?: boolean;
}

interface RequestBoletoPaymentPost {
  productCategory: string | undefined;
  includeFeeTransfer: boolean;
  amount: number;
  description: string;
  referencedOrderNumber: string | null;
  customer: {
    firstName: string;
    surname: string | null;
    identificationNumber: string;
    birthdate: string | null;
    email: string;
    phone: string | null;
    address: Address | undefined;
  };
  payment: {
    type: string;
    installments: number;
    dueDate: string;
    discount?: any;
    lateFee?: any;
    interestOrFine?: any;
  };
  userTracking?: UserTracking;
  chargeDaysBefore?: number;
  tax?:
    | {
        mode: string;
        amount: number;
      }
    | undefined;
  generateAllBoletos?: boolean;
}

interface RecurringRequestPayment {
  productCategory?: string;
  planId?: number;
  customerId: number;
  payment: {
    type: string;
    cardId?: number;
    dueDate?: string | null;
    discount?: any;
    lateFee?: any;
    interestOrFine?: any;
  };
  includeFeeTransfer: boolean;
  userTracking?: UserTracking;
  generateAllBoletos?: boolean;
  fxAmount?: number;
  currency?: string;
  daysBetweenRetries?: string;
  maxRetries?: string;
}

interface RequestRecurringLink {
  productCategory?: string;
  includeFeeTransfer: boolean;
  type: number;
  orderNumber: null;
  planId: number;
  amount: number;
  paymentTypes: number[];
  title: string;
  description?: string;
  isUserDataRequired?: boolean;
  expiresOn: string | null;
  customer?: any;
  generateAllBoleto?: boolean;
  dueDate?: string | null;
}

type Customer = {
  firstName: string;
  surname: string;
  identificationNumber?: string;
  email: string;
  birthdate?: string;
  phone?: string;
  address?: Address;
};

type Pix = {
  customerId: number;
  includeFeeTransfer: boolean;
  amount: number;
  description: string;
  installments: number;
};

type CreditCardProps = {
  holderName: string;
  expirationMonth: number;
  expirationYear: number;
  cardNumber: string;
  securityCode: string;
  customerId: number;
};
class RequestPaymentLink {
  async requestPaymentLinkPost({
    amount,
    type,
    orderNumber,
    paymentTypes,
    planId,
    installments,
    title,
    description,
    includeFeeTransfer,
    discount,
    lateFee,
    interestOrFine,
    productCategory,
    isUserDataRequired,
    expiresOn,
    customer,
    boletoInstallments,
    tax,
    daysBeforeGenerateBoleto,
    generateAllBoletos,
    dueDate,
    currency,
    redirectUrl,
    metadata,
  }: RequestPaymentLinkPost) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment_links`,
      {
        productCategory: productCategory,
        includeFeeTransfer: includeFeeTransfer,
        boletoInstallments: boletoInstallments,
        type: type,
        orderNumber: orderNumber,
        planId: planId,
        amount: amount,
        installments: installments,
        paymentTypes: paymentTypes,
        title: title,
        description: description,
        discount: discount,
        lateFee: lateFee,
        interestOrFine: interestOrFine,
        isUserDataRequired: isUserDataRequired,
        expiresOn: expiresOn,
        customer: customer,
        tax: tax,
        chargeDaysBefore: daysBeforeGenerateBoleto,
        generateAllBoletos: generateAllBoletos,
        dueDate: dueDate,
        currency: currency,
        redirectUrl: redirectUrl,
        metadata: metadata,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async requestRecurringPaymentLinkPost({
    type,
    orderNumber,
    planId,
    amount,
    paymentTypes,
    title,
    description,
    includeFeeTransfer,
    productCategory,
    isUserDataRequired,
    expiresOn,
    customer,
    generateAllBoleto,
    dueDate,
  }: RequestRecurringLink) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment_links`,
      {
        productCategory: productCategory,
        includeFeeTransfer: includeFeeTransfer,
        type: 1,
        orderNumber: orderNumber,
        planId: planId,
        amount: amount,
        paymentTypes: paymentTypes,
        title: title !== "" ? title : "Recorrente",
        description: description,
        isUserDataRequired: isUserDataRequired,
        expiresOn: expiresOn,
        customer: customer,
        generateAllBoletos: generateAllBoleto,
        dueDate: dueDate,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async postQRCode(id: string, svg: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment_link/${id}`,
      {
        QrCode: svg,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async postCreditCard(creditCard: CreditCardProps) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers/${creditCard.customerId}/cards`,
      creditCard,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async addCustomer(customer: Customer) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/customers`,
      customer,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async createPix(pix: Pix) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders`,
      {
        customerId: pix.customerId,
        includeFeeTransfer: pix.includeFeeTransfer,
        amount: pix.amount,
        description: pix.description,
        payment: {
          type: "pix",
          installments: pix.installments,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async requestPlans() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/plans`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async requestPlan(planId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/plans/${planId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async postPlans(
    name: string,
    description: string,
    frequency: number,
    amount: number,
    gracePeriod: number,
    productCategory: string | undefined,
    paymentTypes: number[],
    maxSubscriptionCharges: number | undefined,
    daysBeforeGenerateBoleto: number,
    taxAmount: number,
    taxMode: string,
    isVisible: boolean | undefined,
    products: any
  ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/plans`,
      {
        productCategory: productCategory,
        name: name,
        description: description,
        frequency: frequency,
        paymentTypes: paymentTypes,
        amount: amount,
        currency: 0,
        gracePeriod: gracePeriod,
        maxCharges: maxSubscriptionCharges,
        chargeDaysBefore: daysBeforeGenerateBoleto,
        isVisible: isVisible,
        tax:
          taxMode !== "" && taxAmount > 0
            ? {
                amount: taxAmount,
                mode: taxMode,
              }
            : undefined,
        products: products,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updatePlan(
    name: string,
    description: string,
    frequency: number,
    amount: number,
    gracePeriod: number,
    productCategory: string | undefined,
    paymentTypes: number[],
    maxSubscriptionCharges: number | undefined,
    daysBeforeGenerateBoleto: number,
    taxAmount: number,
    taxMode: string,
    isVisible: boolean | undefined,
    products: any,
    planId: number
  ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/plans/${planId}`,
      {
        productCategory: productCategory,
        name: name,
        description: description,
        frequency: frequency,
        paymentTypes: paymentTypes,
        amount: amount,
        currency: "BRL",
        gracePeriod: gracePeriod,
        maxCharges: maxSubscriptionCharges,
        chargeDaysBefore: daysBeforeGenerateBoleto,
        isVisible: isVisible,
        tax:
          taxMode !== "" && taxAmount > 0
            ? {
                amount: taxAmount,
                mode: taxMode,
              }
            : undefined,
        products: products,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async customerFees(
    cardBrand: string | undefined,
    amount: number,
    paymentType: string,
    installments?: number
  ) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fees?cardBrand=0&amount=${amount}&paymentType=${paymentType}&numberOfInstallments=${installments}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
  
  async requestPaymentPost(order: RequestPaymentPost) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders`,
      {
        productCategory: order.productCategory,
        isTokenized: order.isTokenized,
        includeFeeTransfer: order.includeFeeTransfer,
        // fingerprint: (<HTMLInputElement>document.getElementById("premepaysessionid")).value,
        amount: order.amount,
        planId: order.planId,
        description: order.description,
        referencedOrderNumber: order.referencedOrderNumber,
        customerId: order.customerId,
        payment: {
          type: order.payment.type,
          installments: order.payment.installments,
          cardId: order?.payment.cardId,
          dueDate: order.payment.dueDate,
          discount: order.payment.discount,
          lateFee: order.payment.lateFee,
          interestOrFine: order.payment.interestOrFine,
        },
        userTracking: order.userTracking
          ? {
              browser: order.userTracking.browser,
              device: order.userTracking.device,
              ip: order.userTracking.ip,
              operatingSystem: order.userTracking.operatingSystem,
            }
          : undefined,
        tax: order.tax,
        currency: order.currency,
        chargeDaysBefore: order.chargeDaysBefore,
        generateAllBoletos: order.generateAllBoletos,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async requestRecurringPaymentPost(subscription: RecurringRequestPayment) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/subscriptions`,
      {
        productCategory: subscription.productCategory,
        planId: subscription.planId,
        customerId: subscription.customerId,
        payment: {
          type: subscription.payment.type,
          cardId: subscription?.payment.cardId,
          dueDate: subscription.payment.dueDate,
          discount: subscription.payment.discount,
          lateFee: subscription.payment.lateFee,
          interestOrFine: subscription.payment.interestOrFine,
        },
        includeFeeTransfer: subscription.includeFeeTransfer,
        userTracking: subscription.userTracking,
        generateAllBoletos: subscription.generateAllBoletos,
        fxAmount: subscription.fxAmount,
        currency: subscription.currency,
        daysBetweenRetries: subscription.daysBetweenRetries,
        maxRetries: subscription.maxRetries,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async requestBoletoPaymentPost(order: RequestBoletoPaymentPost) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL_V2}/stores/${session.storeId}/orders`,
      {
        productCategory: order.productCategory,
        includeFeeTransfer: order.includeFeeTransfer,
        // fingerprint: (<HTMLInputElement>document.getElementById("premepaysessionid")).value,
        amount: order.amount,
        description: order.description,
        referencedOrderNumber: order.referencedOrderNumber,
        customer: {
          firstName: order.customer.firstName,
          surname: order.customer.surname,
          identificationNumber: order.customer.identificationNumber,
          birthdate: order.customer.birthdate,
          email: order.customer.email,
          phone: order.customer.phone,
          address: order.customer.address,
        },
        payment: {
          type: "Boleto",
          installments: order.payment.installments,
          dueDate: order.payment.dueDate,
          discount: order.payment.discount,
          lateFee: order.payment.lateFee,
          interestOrFine: order.payment.interestOrFine,
        },
        userTracking: order.userTracking
          ? {
              browser: order.userTracking.browser,
              device: order.userTracking.device,
              ip: order.userTracking.ip,
              operatingSystem: order.userTracking.operatingSystem,
            }
          : undefined,
        chargeDaysBefore: order.chargeDaysBefore,
        tax: order.tax,
        generateAllBoletos: order.generateAllBoletos,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new RequestPaymentLink();
