import styled from "styled-components";

export const TopBannerContainer = styled.div`
  height: 48px;
  background: #fbf4f5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: #fb505a;
    font-family: "Rubik", sans-serif !important;
    font-weight: 600;
    font-size: 16px;

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }

    @media screen and (max-width: 390px) {
      font-size: 12px;
      margin: 0 8px;
      text-align: center;
    }
  }

  @media screen and (max-width: 320px) {
    height: 52px;
  }
`;
