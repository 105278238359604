import React, { useState } from "react";
import { useIntl } from "react-intl";

//Components
import Buttons from "../Forms/Buttons";
import MemberkitButtonModal from "components/Layout/Modals/MemberkitButtonModal";

//Styles
import * as styled from "./styles";

type IExternalServiceConnection = {
  icon: string;
  description: string;
  isConnected?: boolean;
  withSeparator?: boolean;
  setFinishedTheEdition?: any;
  isConnectWithModal?: boolean;
};

const ExternalServiceConnectionWithModal = ({
  icon,
  description,
  isConnected,
  withSeparator,
  setFinishedTheEdition,
  isConnectWithModal = false,
}: IExternalServiceConnection) => {
  const intl = useIntl();

  const [openForm, setOpenForm] = useState<boolean>(false);

  function toggleShow() {
    setFinishedTheEdition(false);
    setOpenForm(!openForm);
  }

  return (
    <>
      <styled.ServiceContainer>
        <div className="service-header">
          <div>
            <img src={icon} alt="logo" />
          </div>

          <div>
            {!openForm && <MemberkitButtonModal />}
            {openForm && (
              <Buttons
                mode="unfilled"
                label={intl.formatMessage({ id: "integrations.cancel" })}
                handler={toggleShow}
              />
            )}
          </div>
        </div>

        <p>{intl.formatMessage({ id: description })}</p>
      </styled.ServiceContainer>

      {withSeparator ? <styled.Separator /> : null}
    </>
  );
};

export default ExternalServiceConnectionWithModal;
