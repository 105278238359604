// @ts-nocheck

import React, { FC, InputHTMLAttributes, useState } from "react";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";

//Styles
import * as styled from "./input-float-label-styles";

//Components
import SelectTypeSearchButtonModal from "components/Layout/Modals/SelectTypeSearchButtonModal";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  value?: any;
  placeholder?: string;
  handler?: any;
  icon?: string;
  iconHandler?: any;
  autoComplete?: any;
  mask?: string;
  mode:
    | "named"
    | "normal"
    | "normal-mask"
    | "named-mask"
    | "normal-fixed-symbol"
    | "normal-fixed-label"
    | "normal-currency"
    | "normal-percentage"
    | "search"
    | "expand-search"
    | "checkbox"
    | "radio"
    | "normal-mask-for-rhf";
  minDate?: string;
  bgColors?: string;
  inputRef?: any;
  currencySymbol?: string;
  disableModal?: boolean;
  isInputWithoutBorder?: boolean;
  fixedSymbol?: string;
  isCurrency?: boolean;
  valueToCheck?: boolean;
  disableCurrencyInput?: boolean;
  searchFunction?: any;
  borderSide?: "" | "left" | "right" | "bottom";
  bottomSeparator?: boolean;
  searchOptions?: string[];
  ref?: any;
}

const Input: FC<IInputProps> = ({
  autoComplete,
  mode,
  value,
  placeholder = "",
  handler,
  icon,
  iconHandler,
  type = "text",
  mask,
  minDate,
  bgColors,
  inputRef,
  currencySymbol = "R$",
  isInputWithoutBorder,
  fixedSymbol,
  disableModal,
  isCurrency,
  valueToCheck,
  disableCurrencyInput,
  searchFunction,
  borderSide,
  bottomSeparator,
  searchOptions,
  ref,
  ...rest
}) => {
  const [searchSelectToggle, setSearchSelectToggle] = useState(false);

  function handlerNamed(name: string, value: string) {
    handler(name, value);
  }

  return (
    <>
      {(() => {
        switch (mode) {
          case "normal":
            return (
              <styled.Input
                bgColors={bgColors}
                borderSide={borderSide}
                bottomSeparator={bottomSeparator}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                <input
                  ref={inputRef}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={(e: any) => handler(e.target.value)}
                  autoComplete={"" + Math.random()}
                  {...rest}
                />
                {icon && (
                  <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
                )}
              </styled.Input>
            );
          case "expand-search":
            return (
              <styled.ExpandSearch value={value}>
                <styled.LeftIcon
                  onClick={() => searchFunction(value)}
                  icon={icon ? icon : ""}
                ></styled.LeftIcon>
                <div className="wrapper">
                  <input
                    type={type}
                    ref={inputRef}
                    placeholder=""
                    {...rest}
                    value={value}
                    onChange={(e: any) => handler("value", e.target.value)}
                    autoComplete={"" + Math.random()}
                  />
                  <span className="placeholder">{placeholder}</span>
                </div>
                {disableModal ? <SelectTypeSearchButtonModal
                  handler={handler}
                  searchOptions={searchOptions}
                /> : null}
              </styled.ExpandSearch>
            );
          case "search":
            return (
              <styled.SearchInput bgColors={bgColors}>
                <input
                  ref={inputRef}
                  placeholder={placeholder}
                  {...rest}
                  type={type}
                  value={value}
                  onChange={(e: any) => handler(e.target.value)}
                  autoComplete={"" + Math.random()}
                />
              </styled.SearchInput>
            );
          case "normal-currency":
            return (
              <styled.Input
                bgColors={bgColors}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                {/* <span>R$</span> */}
                <NumberFormat
                  onValueChange={(values) => {
                    handler(values.value);
                  }}
                  value={value}
                  placeholder={placeholder}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  isNumericString={true}
                  disabled={disableCurrencyInput}
                  ref={inputRef}
                  // prefix={currencySymbol}
                  {...rest}
                />
                {icon && (
                  <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
                )}
              </styled.Input>
            );
          case "normal-fixed-label":
            return (
              <styled.InputWithFixedLabel
                bgColors={bgColors}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                <span>{fixedSymbol}</span>

                {!isCurrency ? (
                  <input
                    ref={inputRef}
                    placeholder={placeholder}
                    {...rest}
                    type={type}
                    value={value}
                    onChange={handler}
                    autoComplete={"" + Math.random()}
                  />
                ) : null}

                {isCurrency ? (
                  <NumberFormat
                    onValueChange={handler}
                    value={value}
                    placeholder={placeholder}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    isNumericString={true}
                    // prefix={currencySymbol}
                  />
                ) : null}
              </styled.InputWithFixedLabel>
            );
          case "normal-fixed-symbol":
            return (
              <styled.Input
                bgColors={bgColors}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                <span>{fixedSymbol}</span>

                {!isCurrency ? (
                  <input
                    ref={inputRef}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={(e: any) => handler(e.target.value)}
                    autoComplete={"" + Math.random()}
                    {...rest}
                  />
                ) : null}

                {isCurrency ? (
                  <NumberFormat
                    onValueChange={(values) => {
                      handler(values.value);
                    }}
                    value={value}
                    placeholder={placeholder}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    isNumericString={true}
                    {...rest}
                    // prefix={currencySymbol}
                  />
                ) : null}
                {icon && (
                  <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
                )}
              </styled.Input>
            );
          case "normal-percentage":
            return (
              <styled.Input
                bgColors={bgColors}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                {/* <span>%</span> */}
                <NumberFormat
                  onValueChange={(values) => {
                    handler(values.value);
                  }}
                  placeholder={placeholder}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  isNumericString={true}
                  // suffix="%"
                />
                {icon && (
                  <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
                )}
              </styled.Input>
            );
          case "named":
            return (
              <styled.Input
                bgColors={bgColors}
                isInputWithoutBorder={isInputWithoutBorder}
              >
                <input
                  ref={inputRef}
                  min={minDate}
                  placeholder={placeholder}
                  {...rest}
                  type={type}
                  value={value}
                  onChange={(e: any) =>
                    handlerNamed(e.target.name, e.target.value)
                  }
                  autoComplete={"" + Math.random()}
                />
                {icon && (
                  <styled.Icon onClick={iconHandler} icon={icon}></styled.Icon>
                )}
              </styled.Input>
            );
          case "normal-mask":
            return (
              <InputMask
                mask={`${mask}`}
                maskChar={null}
                maskPlaceholder=""
                alwaysShowMask={false}
                value={value}
                onChange={(e: any) => handler(e.target.value)}
              >
                <styled.Input bgColors={bgColors}>
                  <input
                    ref={inputRef}
                    placeholder={placeholder}
                    {...rest}
                    type={type}
                    autoComplete={"" + Math.random()}
                  />
                  {icon && (
                    <styled.Icon
                      onClick={iconHandler}
                      icon={icon}
                    ></styled.Icon>
                  )}
                </styled.Input>
              </InputMask>
            );
          case "normal-mask-for-rhf":
            return (
              <InputMask
                mask={`${mask}`}
                maskChar={null}
                maskPlaceholder=""
                alwaysShowMask={false}
                value={value}
                onChange={(e: any) => handler(e.target.value)}
                {...rest}
              >
                <styled.Input bgColors={bgColors}>
                  <input
                    ref={inputRef}
                    placeholder={placeholder}
                    type={type}
                    autoComplete={"" + Math.random()}
                  />
                  {icon && (
                    <styled.Icon
                      onClick={iconHandler}
                      icon={icon}
                    ></styled.Icon>
                  )}
                </styled.Input>
              </InputMask>
            );
          case "named-mask":
            return (
              <InputMask
                mask={`${mask}`}
                maskChar={null}
                maskPlaceholder=""
                alwaysShowMask={false}
                value={value}
                onChange={(e: any) =>
                  handlerNamed(e.target.name, e.target.value)
                }
              >
                <styled.Input bgColors={bgColors}>
                  <input
                    ref={inputRef}
                    placeholder={placeholder}
                    {...rest}
                    type={type}
                    autoComplete={"" + Math.random()}
                  />
                  {icon && (
                    <styled.Icon
                      onClick={iconHandler}
                      icon={icon}
                    ></styled.Icon>
                  )}
                </styled.Input>
              </InputMask>
            );
          case "checkbox":
            return (
              <styled.CheckboxLabel>
                <input
                  type="checkbox"
                  checked={valueToCheck}
                  {...rest}
                  ref={inputRef}
                  value={value}
                  onClick={handler}
                />{" "}
                <span className="checkbox-span">{placeholder}</span>
              </styled.CheckboxLabel>
            );
          case "radio":
            return (
              <styled.RadioLabel>
                <input
                  type="radio"
                  {...rest}
                  ref={inputRef}
                  checked={valueToCheck}
                  value={value}
                  onClick={handler}
                />{" "}
                <span className="radio-span">{placeholder}</span>
              </styled.RadioLabel>
            );
          default:
            return <div></div>;
        }
      })()}
    </>
  );
};

export default Input;
