import styled from 'styled-components';

export const LeftRightContainer = styled.section``;


export const FormContainer = styled.div`
  
  h5 {
    margin-top: 32px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
    color: ${props => props.theme.contentColors.primary};
  }

  .insider-container {
    margin-left: 32px;
    margin-bottom: 24px;
  }

  .select-and-input-container {
    display: flex;
    border: 1px solid ${props => props.theme.backgroundColors.eighth};
    border-radius: 4px;
    align-items: center;
    height: 40px;

    .m-half-width {
      min-width: 50%;
      max-width: 50%;
    }

    .m-half-width--flex {
      display: flex;
    }

    div {
      &:last-child {
        height: 100%;
        border-left: 1px solid ${props => props.theme.backgroundColors.eighth};
      }
    }

    input {
      height: 24px;
      padding: 0 8px;
      width: 100%;
      background: transparent;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .limited-width {
    max-width: 420px;
  }

  .form-item {
    transition: all .2s ease-in-out;

    .error {
      margin-top: 4px;
      font-size: 12px !important;
    }

    .form-item__optional {
      display: flex;

      p {
        margin-top: 32px;
        margin-bottom: 8px;
        font-size: 10px;
        margin-left: 16px;
        color: ${props => props.theme.contentColors.primary};
      }
    }
  }

  .button-bottom-container {
    margin-top: 32px;
  }

  .radios {
      max-height: 300px;
      width: 100%;
      flex-direction: column;
      margin: 16px auto;
      margin-bottom: 16px;

      .extern-label {
        color: ${props => props.theme.textColors.primary};
        font-size: 14px;
      }

      .scroll {
        overflow-y: scroll;

        scrollbar-base-color: #C0C0C0;
        scrollbar-base-color: #C0C0C0;
        scrollbar-3dlight-color: #C0C0C0;
        scrollbar-highlight-color: #C0C0C0;
        scrollbar-track-color: #EBEBEB;
        scrollbar-arrow-color: black;
        scrollbar-shadow-color: #C0C0C0;
        scrollbar-dark-shadow-color: #C0C0C0;

        ::-webkit-scrollbar-track {
          background-color: #F4F4F4;
        }
        ::-webkit-scrollbar {
          width: 6px;
          background: #F4F4F4;
        }
        ::-webkit-scrollbar-thumb {
          background: #dad7d7;
        }
      }

      .search-bank {
        background: ${({ theme }) => theme.backgroundColors.primary};
        border: 1px solid ${props => props.theme.backgroundColors.eighth};
        min-height: 36px;
        border-radius: 3px;
        padding: 8px;
        margin-bottom: 16px;
        color: ${props => props.theme.contentColors.secondary};
        font-size: 12px;
      }

      label {
        cursor: pointer;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${props => props.theme.contentColors.primary};

        &:hover {
          transition: ease .3s;
          background: ${props => props.theme.backgroundColors.fourth};
        }

        img {
          margin-right: 8px;
        }
      }

      input[type=radio]{
        display: none;
      }

      span {
        width: 100%;
      }

      input[type=radio]:checked + span{
        background: ${props => props.theme.backgroundColors.sixth};
      }

      display: flex;
      flex-direction: column;
    }
`;

export const MessageError = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.7;
  color: #f00;
`;

export const MessageSuccess = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.7;
  color: #008c00;
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionDayItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .date {
    font-size: 14px;
    margin-bottom: 16px;
    color: ${props => props.theme.contentColors.primary};
  }

  .transaction-type-container {
    margin-bottom: 8px;

    .transaction__type__simple {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      p {
        color: #1B1B1B;
      }
    }

    .transaction__type__detailed {
      margin: 16px 0;
    }

    .transaction__item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin: 10px 0;

      div {
        display: flex;
        align-items: center;

        span {
          margin-right: 8px;
        }
      }

      p {
        color: #5E5E5E;
        margin-right: 8px;
      }

      ul {
        li {
          display: flex;
          /* white-space: nowrap; */
          align-items: center;
        }
      }
    }

    .payin__item {
      color: #5E5E5E;
      font-size: 14px;
    }

    .transaction__type {
      p {
        transition: all .2s ease-in-out;
        font-size: 14px;
        color: ${props => props.theme.contentColors.secondary};
      }

      img {
        transform: rotate(-90deg);
        transition: transform .5s ease-in-out;
      }

      &:hover {
        p {
          transition: all .2s ease-in-out;
          color: ${props => props.theme.contentColors.fourth};
        }
      }

      .rotateArrow {
        transform: rotate(90deg);
        transition: transform .5s ease-in-out;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.backgroundColors.secondary};
  }
`; 

export const UpcomingContainer = styled.div`
  background: ${props => props.theme.primaryColors.fifth};
  border-radius: 3px;
  display: flex;
  padding: 10px 20px;
  width: 100%;

  div {
    align-items: center;
    color: ${props => props.theme.contentColors.secondary};
    font-size: 14px;

    img {
      margin-right: 8px;
    }
  }
`;

export const ReturnButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: all .3s ease-in-out;

  img {
    transition: all .3s ease-in-out;
  }

  &:hover {
    img {
      transform: translateX(-5px);
      transition: all .3s ease-in-out;
    }
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  .create-product-cta {
    margin-bottom: 16px;
  }
  
`;

export const SearchFilterExportActions = styled.div`
  display: flex;
  flex-direction: row;
`; 
