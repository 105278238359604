// @ts-nocheck

import React, { Component, useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

//Components
import TipButtonModal from "../Layout/Modals/TipButtonModal";
import { TopBarMobile } from "./TopBarMobile/Index";

//Assets
import premeLogo from "assets/images/global/main-logo.svg";
import financialInfoIcon from "assets/images/bottom/financial-info.svg";
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Services
import OrderService from "../../services/Order";

// Store
import { useSettings } from "../../store/settings";

//Styles
import "./layout.scss";
import * as styled from "./layout-styles";

interface Button {
  label: string;
  icon: string;
  handler: any;
  css?: string;
  disabled?: boolean;
}

// const [valueStatus, setValueStatus] = useState('');

export const Button = ({
  label,
  icon,
  handler,
  css = "",
  disabled = false,
}: Button) => {
  return (
    <div className="button-container">
      <button
        className={`btn ${css}`}
        onClick={() => handler()}
        disabled={disabled}
      >
        <span>
          <img src={icon} alt={`${label.toLocaleLowerCase()}_icon`} />
          {label}
        </span>
      </button>
    </div>
  );
};

export const UnfilledButton = ({
  label,
  icon,
  handler,
  css = "",
  disabled = false,
}: Button) => {
  return (
    <styled.UnfilledButton onClick={() => handler()} disabled={disabled}>
      {label}
      <span>
        <img src={icon} alt={`${label.toLocaleLowerCase()}_icon`} />
      </span>
    </styled.UnfilledButton>
  );
};

export const UnfilledButtonWithoutIcon = ({
  label,
  handler,
  css = "",
  disabled = false,
}: Button) => {
  return (
    <styled.UnfilledButtonIconMask
      onClick={() => handler()}
      disabled={disabled}
    >
      {label}
    </styled.UnfilledButtonIconMask>
  );
};

export const UnfilledButtonIconMask = ({
  label,
  icon,
  handler,
  css = "",
  disabled = false,
}: Button) => {
  return (
    <styled.UnfilledButtonIconMask
      onClick={() => handler()}
      disabled={disabled}
    >
      <styled.IconDiv icon={icon}></styled.IconDiv>
      {label}
    </styled.UnfilledButtonIconMask>
  );
};

export const LayoutWrapper = (props: any) => {
  const { status } = useSettings((state) => state.settings);
  
  return (
    <styled.ContentContainer
      className={`${
        status !== "enabled" && status !== "denied" ? "double-top-margin" : ""
      }`}
    >
      {" "}
      {props.children}{" "}
    </styled.ContentContainer>
  );
};

export const LayoutWrapperMobile = (props: any) => {
  return (
    <div ref={props.refs} className="content-container-mobile">
      <MetaTags>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </MetaTags>
      <TopBarMobile />
      {props.children}{" "}
    </div>
  );
};

export const LogoLogin = () => {
  return (
    <styled.LogoContainerLogin>
      <a href="/">
        <img src={premeLogo} alt="Preme Dashboard" />
      </a>
    </styled.LogoContainerLogin>
  );
};

export const ContentBox = (props: any) => {
  let { cssClass, color, width } = props;

  let styles = {
    color: color ? color : "#ffffff",
    width: width ? width : "100%",
  };
  return (
    <div
      ref={props.refs}
      className={`content-box ${cssClass ? cssClass : ""}`}
      style={styles}
    >
      {props.children}
    </div>
  );
};

export const ContentBoxv2 = (props: any) => {
  let { cssClass, color, width } = props;

  let styles = {
    color: color ? color : "#ffffff",
    width: width ? width : "100%",
  };
  return (
    <div
      className={`content-box--v2 ${cssClass ? cssClass : ""}`}
      style={styles}
    >
      {props.children}
    </div>
  );
};

type SelectItem = {
  value: any;
  label: string;
};

type SelectData = {
  mainLabel: string;
  name: string;
  options: Array<SelectItem>;
  action: any;
};

export const Select = ({ mainLabel, name, options, action }: SelectData) => (
  <styled.SelectDefault>
    <div className="select-label"> {mainLabel} </div>
    <select
      name={name}
      onChange={(e) => action(e.target.value)}
      className="container-options-select"
    >
      {options.map((item, key) => (
        <option key={key} value={item.value} className="option-select">
          {" "}
          {item.label}{" "}
        </option>
      ))}
    </select>
  </styled.SelectDefault>
);

type InfoLabelData = {
  label: string;
  value: any;
  brlAmount?: any;
  isForeign?: any;
  tipText?: string;
};

export let InfoLabel = ({ label, value }: InfoLabelData) => {
  const intl = useIntl();

  return (
    <div className="info-label">
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
};

export let SmallInfoLabel = ({
  label,
  value,
  brlAmount,
  isForeign,
  tipText,
}: InfoLabelData) => (
  <div className="info-label">
    <div className="label label--with-icon">
      {label} <TipButtonModal text={tipText} />
    </div>
    <div className="value">{value}</div>
    {isForeign && <div className="brl-amount">{brlAmount}</div>}
  </div>
);

type DataInput = {
  label?: string;
  name: string;
  placeholder?: string;
  handler: any;
  css?: string;
  type?: string;
  id?: string;
  mask?: string;
  value?: any;
  required?: boolean;
  pattern?: string;
  formatChars?: any;
  disable?: boolean;
  refInput?: any;
  alwaysShowMask?: boolean;
  icon?: string;
  iconHandler?: any;
  labelFloat?: string;
  onBlur?: any;
  minDate?: any;
};

type DataInputIcon = {
  label?: string;
  name: string;
  placeholder?: string;
  handler: any;
  css?: string;
  type?: string;
  id?: string;
  mask?: string;
  value?: any;
  required?: boolean;
  pattern?: string;
  formatChars?: any;
  disable?: boolean;
  refInput?: any;
  alwaysShowMask?: boolean;
  icon: string;
  iconHandler?: any;
  labelFloat?: any;
};

export const Input = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  refInput,
  minDate,
}: DataInput) => {
  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}
      <input
        ref={refInput}
        min={minDate}
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={(e) => handler(e.target.value)}
        id={id}
      />
    </styled.InputWrapper>
  );
};

export const InputValue = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
}: DataInput) => {
  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}
      <input
        ref={refInput}
        disabled={disable}
        placeholder={placeholder}
        type={type}
        value={value}
        pattern={pattern}
        name={name}
        onChange={(e) => handler(e.target.value)}
        required={required}
        id={id}
      />
    </styled.InputWrapper>
  );
};

export const InputNamed = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
}: DataInput) => {
  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}
      <input
        ref={refInput}
        disabled={disable}
        placeholder={placeholder}
        type={type}
        value={value}
        pattern={pattern}
        name={name}
        onChange={(e) => handler(e.target.value, e.target.name)}
        required={required}
        id={id}
      />
    </styled.InputWrapper>
  );
};

export const InputIconRight = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
  icon,
  iconHandler,
}: DataInputIcon) => {
  return (
    <styled.WrapperInputWithLinearBorder className={`${css}`}>
      <div className="div-input">
        <input
          ref={refInput}
          disabled={disable}
          placeholder={placeholder}
          type={type}
          value={value}
          pattern={pattern}
          name={name}
          onChange={(e) => handler(e.target.value, e.target.name)}
          required={required}
          id={id}
        />
      </div>
      <styled.IconItem
        className="hide-show"
        onClick={iconHandler}
        icon={icon}
      ></styled.IconItem>
    </styled.WrapperInputWithLinearBorder>
  );
};

export const InputMaskNewStyle = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
  icon,
  iconHandler,
  labelFloat,
}: DataInputIcon) => {
  return (
    <InputMask
      mask={`${mask}`}
      maskChar={null}
      alwaysShowMask={false}
      value={value}
      onChange={(event) => handler(event.target.value, event.target.name)}
    >
      <styled.WrapperInput className={`${css}`}>
        <div className="div-input">
          <input
            autoComplete="off"
            ref={refInput}
            disabled={disable}
            placeholder={placeholder}
            type={type}
            pattern={pattern}
            name={name}
            required={required}
            id={id}
          />
          <label>{labelFloat}</label>
          <styled.IconItem onClick={iconHandler} icon={icon}></styled.IconItem>
        </div>
      </styled.WrapperInput>
    </InputMask>
  );
};

export const InputNewStyle = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
  icon,
  iconHandler,
  labelFloat,
}: DataInputIcon) => {
  return (
    <styled.WrapperInput className={`${css}`}>
      <div className="div-input">
        <input
          autoComplete="off"
          ref={refInput}
          disabled={disable}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handler(e.target.value, e.target.name)}
          type={type}
          pattern={pattern}
          name={name}
          required={required}
          id={id}
        />
        <label>{labelFloat}</label>
        {/* <styled.IconItem className="hide-show" onClick={iconHandler} icon={icon}></styled.IconItem> */}
      </div>
    </styled.WrapperInput>
  );
};

export const InputMaskElement = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  formatChars,
  disable,
  refInput,
  alwaysShowMask = false,
}: DataInput) => {
  return (
    <InputMask
      mask={`${mask}`}
      maskChar={null}
      alwaysShowMask={alwaysShowMask}
      // formatChars={formatChars}
      value={value}
      onChange={(event) => handler(event.target.value)}
    >
      <styled.InputWrapper className={`${css}`}>
        {label !== "" && <label htmlFor={name}> {label} </label>}
        <input
          ref={refInput}
          disabled={disable}
          placeholder={placeholder}
          type={type}
          name={name}
          id={id}
          required={required}
        />
      </styled.InputWrapper>
    </InputMask>
  );
};

export const InputMaskHandleObj = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  formatChars,
  disable,
  refInput,
  alwaysShowMask = false,
}: DataInput) => {
  return (
    <InputMask
      mask={`${mask}`}
      maskChar={null}
      alwaysShowMask={alwaysShowMask}
      // formatChars={formatChars}
      value={value}
      onChange={(event) => handler(event.target.value, event.target.name)}
    >
      <styled.InputWrapper className={`${css}`}>
        {label !== "" && <label htmlFor={name}> {label} </label>}
        <input
          ref={refInput}
          disabled={disable}
          placeholder={placeholder}
          type={type}
          name={name}
          id={id}
          required={required}
        />
      </styled.InputWrapper>
    </InputMask>
  );
};

export const InputValueCurrency = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
}: DataInput) => {
  const [values, setValues] = useState({
    numberformat: "1320",
  });

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}

      <NumberFormat
        onValueChange={(values) => {
          handler(values.value);
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        isNumericString={true}
        prefix="R$"
      />
    </styled.InputWrapper>
  );
};

export const InputValuePercentage = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
}: DataInput) => {
  const [values, setValues] = useState({
    numberformat: "1320",
  });

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}

      <NumberFormat
        onValueChange={(values) => {
          handler(values.value);
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        isNumericString={true}
        suffix="%"
      />
    </styled.InputWrapper>
  );
};

export const InputValueCurrencyUseValue = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
}: DataInput) => {
  const [values, setValues] = useState({
    numberformat: "1320",
  });

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}

      <NumberFormat
        onValueChange={(values) => {
          handler(values.value);
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        isNumericString={true}
        prefix="R$"
        value={value}
      />
    </styled.InputWrapper>
  );
};

export const InputCurrency = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  labelFloat,
  onBlur,
}: DataInput) => {
  return (
    <styled.InputContainer className={` ${css}`}>
      <div className="float-placeholder">
        <NumberFormat
          onValueChange={(values) => {
            handler(parseFloat(values.value));
          }}
          onBlur={onBlur}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          isNumericString={true}
          prefix=""
          placeholder={placeholder}
        />
        <label>{labelFloat}</label>
      </div>
    </styled.InputContainer>
  );
};

export const TextArea = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
}: DataInput) => {
  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}
      <textarea
        ref={refInput}
        disabled={disable}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => handler(e.target.value)}
        required={required}
        id={id}
      />
    </styled.InputWrapper>
  );
};

export const TextAreaHandleObj = ({
  id,
  label,
  placeholder,
  handler,
  name,
  mask,
  value,
  required,
  css = "",
  type = "text",
  pattern,
  disable,
  refInput,
}: DataInput) => {
  return (
    <styled.InputWrapper className={`${css}`}>
      {label !== "" && <label htmlFor={name}> {label} </label>}
      <textarea
        ref={refInput}
        disabled={disable}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => handler(e.target.value, e.target.name)}
        required={required}
        id={id}
      />
    </styled.InputWrapper>
  );
};

interface IPaginationProps {
  pageIndex: number;
  simplePage?: true;
  setPageIndex(value: number): any;
  disableNextButton: boolean;
  pageIndexProcessing: boolean;
  totalOrder: number;
  customClass?: string;
  maxNumberOrderPerRequest: number;
}

export const Pagination = ({
  pageIndex,
  setPageIndex,
  disableNextButton,
  pageIndexProcessing,
  totalOrder,
  simplePage,
  customClass,
  maxNumberOrderPerRequest,
}: IPaginationProps) => {
  const [label, setLabel] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let lastPageNumber = totalOrder / maxNumberOrderPerRequest;

    setLastPage(Math.ceil(lastPageNumber));
  }, [totalOrder]);

  useEffect(() => {
    if (!pageIndexProcessing) {
      setLabel(pageIndex);
    }
  }, [pageIndexProcessing]);

  const next = () => {
    if (pageIndex < lastPage) {
      setPageIndex(pageIndex + 1);
    }
  };

  const prev = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  };

  return (
    <div className={`pagination ${customClass}`}>
      {!simplePage ? (
        <div className="current-item">
          {pageIndex}{" "}
          <FormattedMessage id="detail.order.events.boleto.of.the" /> {lastPage}
        </div>
      ) : null}
      {simplePage ? (
        <div className="current-item">
          <FormattedMessage id="pagination.page" /> {pageIndex}
        </div>
      ) : null}

      <div
        className={`arrow left ${
          pageIndex === 1 || pageIndexProcessing ? "disabled" : ""
        } ${pageIndexProcessing ? "processing" : ""}`}
        onClick={pageIndex === 1 || pageIndexProcessing ? () => {} : prev}
      >
        <img src={arrowIcon} alt="arrow left" />
      </div>

      <div
        className={`arrow right ${
          disableNextButton || pageIndexProcessing || lastPage === pageIndex
            ? "disabled"
            : ""
        } ${pageIndexProcessing ? "processing" : ""}`}
        onClick={disableNextButton || pageIndexProcessing ? () => {} : next}
      >
        <img src={arrowIcon} alt="arrow right" />
      </div>
    </div>
  );
};
