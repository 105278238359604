import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import moment from "moment";

//Services
import StoreService from "services/Store";
import SessionService from "services/Session";
import BankAccountService from "services/BankAccount";
import FinancialsServices from "services/FinancialsServices";

//Components
import Input from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";
import SelectAndButtonModal from "components/Layout/Modals/SelectAndButtonModal";
import TipButtonModal from "components/Layout/Modals/TipButtonModal";
import SelectBankAccountButtonModal from "components/Layout/Modals/SelectBankAccountButtonModal";
import SelectStoresButtonModal from "components/Layout/Modals/SelectStoresButtonModal";
import NationsSelectButtonModal from "components/Layout/Modals/NationsSelectButtonModal";
import {
  LayoutWrapper,
  SmallInfoLabel,
  Pagination,
} from "components/Layout/Index";
import Buttons from "components/Forms/Buttons";

//Helpers
import currencyAndSymbol from "helpers/currencyAndSymbol";
import bexsAllowCurrency from "helpers/bexsAllowCurrency";
import banksAndCodes from "helpers/banksAndCodes";

//Assets
import bancoCitiLogo from "assets/images/icon-banco-citi.svg";
import noLogoBank from "assets/images/icon-banco-nologo.svg";
import bancoInterIcon from "assets/images/icon-banco-inter.svg";
import bancoCaixaIcon from "assets/images/icon-banco-caixa.svg";
import bancoBrasilIcon from "assets/images/icon-banco-do-brasil.svg";
import bancoBradescoIcon from "assets/images/icon-banco-bradesco.svg";
import bancoSantanderIcon from "assets/images/icon-banco-santander.svg";
import bancoItauIcon from "assets/images/icon-banco-itau.svg";
import tipIcon from "assets/images/bottom/financial-info.svg";
import arrowIcon from "assets/images/global/icon-arrow.svg";
import arrowDownIcon from "assets/images/signup/arrow-down-select.svg";

//Hooks
import { useFinancials } from "./hooks";

//Styles
import * as detailsStyled from "components/Details/details-styles";
import * as homeStyled from "../Home/styles";
import * as styled from "./financials-styles";
import * as indexStyled from "../../styles/index-styles";

//Types
import { BalanceProps, Bank, BankAccount, Nation, Withdrawal } from "./types";

export default function Balance() {
  const intl = useIntl();
  const history = useHistory();

  const {
    withdrawalTransactions,
    incomingTransactions,
    incomingAmount,
    openWithdrawalTransaction,
    pageIndex,
    pageIndexProcessing,
    numberTotalOfOrders,

    redirectToOrderDetails,

    setPageIndex,
    setOpenWithdrawalTransaction,

    toggleWithdrawalTransaction,
  } = useFinancials();

  const [banksAndCodesState, setBanksAndCodesState] = useState<Bank[]>([]);

  const [withdrawalErrors, setWithdrawalErrors] = useState("");

  const [bank, setBank] = useState({
    bankName: "",
    ag: "",
    account: "",
    type: "Checking",
    titular: "",
    identificationNumber: "",
    errors: [""],
  });

  const [nationFlag, setNationFlag] = useState<Nation>({
    nation: "",
    flag: "",
  });

  const transferPlataformTypeArray = ["", "SWIFT", "BIC", "IBAN"];

  const [bankAccountId, setBankAccountId] = useState(0);
  const [withdrawalArray, setWithdrawalArray] = useState([]);
  const [currencySymbolStore, setCurrencySymbolStore] = useState("");
  const [currencyStore, setCurrencyStore] = useState("BRL");

  const [moneyDestiny, setMoneyDestiny] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);
  const [isForeigner, setIsForeigner] = useState(false);
  const [showListOfBanks, setShowListOfBanks] = useState(false);
  const [bankIconSelected, setBankIconSelected] = useState("");
  const [showBankList, setShowBankList] = useState(false);
  const [bankNameSelected, setBankNameSelected] = useState("");

  const [selectedStoreId, setSelectedStoreId] = useState(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session && session.entityType === "Store") {
      return session.storeId;
    }

    return 0;
  });

  const handleOpenBankList = () => {
    showBankList ? setShowBankList(false) : setShowBankList(true);
  };

  const [isJustStore, setIsJustStore] = useState(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session && session.entityType === "Store") {
      return true;
    }

    return false;
  });

  const [viewAsStore, setViewAsStore] = useState(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session && session.entityType.includes("Business-")) {
      setSelectedStoreId(session.storeId);

      return true;
    }

    return false;
  });

  const [selectedStoreIdRecipient, setSelectedStoreIdRecipient] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [cpfCnpjLabel, setCpfCnpjLabel] = useState("CPF/CNPJ");

  const [balances, setBalances] = useState<BalanceProps>({
    currentBalance: 0,
    nationalCurrentBalance: 0,
    currentBlockedBalance: 0,
    nationalCurrentBlockedBalance: 0,
    accountBalance: 0,
    nationalAccountBalance: 0,
    loaded: false,
  });

  const [withdrawal, setWithdrawal] = useState<Withdrawal>({
    bankAccountId: 0,
    bankAccountIdToActivate: "",
    bankName: "",
    branchCode: "",
    bankCode: "",
    accountNumber: "",
    amount: 0,
    description: "",
    isActive: false,
  });

  const [withdrawalAccounts, setWithdrawalAccounts] = useState<BankAccount[]>(
    []
  );

  const [selectedFinances, setSelectedFinances] = useState("brazil-finances");
  const [showFinanceAccounts, setShowFinanceAccounts] = useState(false);
  const [withdrawalStatusMessage, setWithdrawalStatusMessage] = useState(
    "home.withdrawall.status.message.default"
  );
  const [bankIcon, setBankIcon] = useState("");
  const financeAccountsRef = useRef<HTMLDivElement>(null);

  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleDeveloperId, setVisibleDeveloperId] = useState("");

  const [progressStep, setProgressStep] = useState(2);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [openUpcomingPayin, setOpenUpcomingPayin] = useState(false);
  const [identificationNumberForNewBank, setIdentificationNumberForNewBank] =
    useState("");

  useEffect(() => {
    setAllValues();
  }, [selectedStoreId]);

  useEffect(() => {
    setSelectedStoreId(0);
    setSelectedStoreIdRecipient(0);
  }, [moneyDestiny, isAddingAccount]);

  useEffect(() => {
    if (bank.identificationNumber.length >= 14) {
      setCpfCnpjLabel("CNPJ:");
    } else if (bank.identificationNumber.length === 0) {
      setCpfCnpjLabel("CPF/CNPJ");
    } else {
      setCpfCnpjLabel("CPF");
    }
  }, [bank.identificationNumber]);

  function handleShowFinancesAccounts(value: string) {
    setSelectedFinances(value);

    setShowFinanceAccounts(false);
  }

  function setAllValues() {
    StoreService.get().then((response: any) => {
      setIdentificationNumberForNewBank(response.data.identificationNumber);

      if (response.data.isForeigner) {
        StoreService.getBalancesWithForeignAmount()
          .then((response) => {
            setBalances({
              currentBalance: response.data.current.ForeignCurrency,
              nationalCurrentBalance: response.data.current.NationalCurrency,
              currentBlockedBalance: 0,
              nationalCurrentBlockedBalance: 0,
              accountBalance: response.data.pending.ForeignCurrency,
              nationalAccountBalance: response.data.pending.NationalCurrency,
              loaded: true,
            });
          })
          .catch((err) => console.log(err));
      } else {
        StoreService.getBalances(selectedStoreId)
          .then((response) => {
            setBalances({
              currentBalance: 0,
              nationalCurrentBalance: response.data.available,
              currentBlockedBalance: 0,
              nationalCurrentBlockedBalance: 0,
              accountBalance: 0,
              nationalAccountBalance: response.data.incoming,
              loaded: true,
            });
          })
          .catch((err) => console.log(err));
      }

      setIsForeigner(response.data.isForeigner);

      let currencyFromAPI = { id: 0, currency: "BRL", symbol: "R$" };

      if (response.data.isForeigner) {
        let findCurrency = bexsAllowCurrency.find((value) => {
          return value.currency === response.data.fxMerchant.account.currency;
        });

        if (findCurrency) currencyFromAPI = findCurrency;
      }

      setCurrencyStore(currencyFromAPI.currency);

      localStorage.setItem(
        "@PrimefyStore",
        JSON.stringify({
          isForeigner: response.data.isForeigner,
          currency: currencyFromAPI.currency,
          currencySymbol: currencyFromAPI.symbol,
        })
      );

      setCurrencySymbolStore(currencyFromAPI.symbol);

      if (response.data.address) {
        setNationFlag({
          nation: currencyAndSymbol[response.data.address.country].nation,
          flag: currencyAndSymbol[response.data.address.country].flag,
        });
      } else {
        setNationFlag({
          nation: currencyAndSymbol[0].nation,
          flag: currencyAndSymbol[0].flag,
        });
      }
      
      if (response.data.store.bankAccounts) {
        let mapAccounts: BankAccount[] = [];

        response.data.store.bankAccounts.map((account: any) => {
          if (account.isActive) {
            setWithdrawal((prevState) => {
              return {
                ...prevState,
                bankAccountId: account.id,
                bankName: account.bankName,
                branchCode: account.routingNumber,
                accountNumber: account.accountNumber,
                bankAccountIdToActivate: account.bankAccountIdToActivate,
                isActive: account.isActive,
              };
            });
          }

          const filteredBankIconAndName = banksAndCodes.filter((bank) => {
            if (bank.bankCode) {
              if (account.bankCode === bank.bankCode) {
                return bank;
              }
            }
          });
          
          console.log(account.bankName, 'funcionaaaa')
          mapAccounts.push({
            bankAccountId: account.id,
            bankName: account.bankName
              ? account.bankName
              : filteredBankIconAndName[0].bankName
              ? filteredBankIconAndName[0].bankName
              : "",
            bankIcon: filteredBankIconAndName[0]?.bankLogo
              ? filteredBankIconAndName[0].bankLogo
              : noLogoBank,
            bankAccountIdToActivate: account.bankAccountId,
            branchCode: account.routingNumber,
            accountNumber: account.accountNumber,
            bankCode: account.bankCode ? account.bankCode : "",
            isActive: account.isActive,
          });
        });
        setWithdrawalAccounts(mapAccounts);
        setBankAccountId(response.data.bankAccounts[0].id);
      } else if (response.data.fxMerchant) {
        setBankAccountId(response.data.fxMerchant.account.id);

        setWithdrawal((prevState) => {
          return {
            ...prevState,
            bankAccountId: response.data.fxMerchant.account.id,
            bankName: response.data.fxMerchant.account.bank,
            branchCode: "-",
            accountNumber: response.data.fxMerchant.account.accountNumber,
            transferPlatformType:
              transferPlataformTypeArray[
                response.data.fxMerchant.account.transferPlatformType
              ],
            transferPlatformCode:
              response.data.fxMerchant.account.transferPlatformCode,
          };
        });
      }

      switch (
        response.data.isForeigner
          ? response.data.fxMerchant.account.bank.toString()
          : response.data.store.bankAccounts.lenght > 0
          ? response.data.store.bankAccounts[response.data.store.bankAccounts.length - 1]
              .bankCode
          : ""
      ) {
        case "077":
          setBankIcon(bancoInterIcon);
          break;
        case "745":
          setBankIcon(bancoCitiLogo);
          break;
        case "001":
          setBankIcon(bancoBrasilIcon);
          break;
        case "341":
          setBankIcon(bancoItauIcon);
          break;
        case "104":
          setBankIcon(bancoCaixaIcon);
          break;
        case "033":
          setBankIcon(bancoSantanderIcon);
          break;
        case "237":
          setBankIcon(bancoBradescoIcon);
          break;
        default:
          setBankIcon("");
          break;
      }
    });
  }

  function handleSearchBank(value: string) {
    let searchedBank: Bank[] = banksAndCodes.filter((bank: any) => {
      return bank.bankName.toLowerCase().includes(value.toLowerCase());
    });

    setBanksAndCodesState(searchedBank);
  }

  function handleNewBankAccount(name: string, value: any) {
    setBank((prevState) => {
      return {
        ...prevState,
        [name]: value.replace("-", ""),
      };
    });
  }

  function handleNewBankAccountIdentificationNumber(
    identificationNumber: string
  ) {
    setBank((prevState) => {
      return {
        ...prevState,
        identificationNumber: identificationNumber,
      };
    });
  }

  const handleSelectABank = (
    name: string,
    iconBank: string,
    codeBank: string
  ) => {
    if (codeBank === "237N") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("N", ""),
        };
      });
    } else if (codeBank === "121N") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("N", ""),
        };
      });
    } else if (codeBank === "394F") {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank.replace("F", ""),
        };
      });
    } else {
      setBank((prevState) => {
        return {
          ...prevState,
          bankName: codeBank,
        };
      });
    }

    setBanksAndCodesState([]);
    setShowBankList(false);
    setBankNameSelected(name);
    setBankIconSelected(iconBank);
  };

  function withdrawalAnimation() {
    let start = balances.nationalCurrentBalance;
    let end = balances.nationalCurrentBalance - withdrawal.amount;
    let duration = 1500;

    let steps = 10;
    let stepsize = (end - start) / steps;
    let current = start;
    var stepTime = 3;
    let stepspassed = 0;
    let stepsneeded = (end - start) / stepsize;

    let x = setInterval(() => {
      current += stepsize;
      stepspassed++;

      // setTesteState(Math.round(current * 1000) / 1000);

      setBalances((prevState) => {
        return {
          ...prevState,
          nationalCurrentBalance: Math.round(current * 1000) / 1000,
        };
      });

      if (stepspassed >= stepsneeded) {
        clearInterval(x);
      }
    }, stepTime);
  }

  const submitWithdrawal = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setIsProcessing(true);
    let amount = 0;
    if (withdrawal.amount === undefined) {
      setWithdrawalErrors("zeroAmountError");
      setIsProcessing(false);
      return;
    } else if (withdrawal.amount <= 0) {
      setWithdrawalErrors("zeroAmountError");
      setIsProcessing(false);
      return;
    } else if (
      withdrawal.amount >
      (!isForeigner ? balances.nationalCurrentBalance : balances.currentBalance)
    ) {
      setWithdrawalErrors("amountError");
      setIsProcessing(false);
      return;
    } else {
      amount = withdrawal.amount;

      if (moneyDestiny === "merchantAccount") {
        BankAccountService.transferToAnotherStore(
          selectedStoreId,
          selectedStoreIdRecipient,
          amount,
          withdrawal.description
        )
          .then((response) => {
            withdrawalAnimation();
            setWithdrawalStatusMessage(
              "home.withdrawall.status.message.success"
            );
            setIsProcessing(false);
          })
          .catch((err) => {
            setIsProcessing(false);
            setWithdrawalStatusMessage(
              "home.withdrawall.status.message.failed"
            );
            console.log(err);
          });
      } else {
        if (isForeigner) {
          BankAccountService.withdrawalForeign(withdrawal.amount)
            .then(() => {
              setWithdrawalStatusMessage(
                "home.withdrawall.status.message.success"
              );
              setIsProcessing(false);
            })
            .catch(() => {
              setIsProcessing(false);
              setWithdrawalStatusMessage(
                "home.withdrawall.status.message.failed"
              );
            });
        } else {
          BankAccountService.withdrawal({
            bankAccountId: withdrawal.bankAccountId,
            amount: amount,
            description: withdrawal.description,
          })
            .then(() => {
              withdrawalAnimation();
              setWithdrawalStatusMessage(
                "home.withdrawall.status.message.success"
              );
              setIsProcessing(false);
            })
            .catch(() => {
              setIsProcessing(false);
              setWithdrawalStatusMessage(
                "home.withdrawall.status.message.failed"
              );
            });
        }
      }
    }
  };

  function submitNewBankAccount() {
    setIsProcessing(true);

    const session = JSON.parse(localStorage.getItem("session") || "{}");
    let storeIdToUse = selectedStoreId;
    let identificationNumberToUse = bank.identificationNumber;

    if (session && viewAsStore) {
      storeIdToUse = session.storeId;
      identificationNumberToUse = identificationNumberForNewBank;
    }

    BankAccountService.changeBankAccount(
      {
        holderName: bank.titular,
        bankCode: bank.bankName,
        routingNumber: bank.ag,
        accountNumber: bank.account.replace("-", ""),
        identificationNumber: identificationNumberToUse
          .replaceAll(".", "")
          .replace("-", "")
          .replace("/", ""),
        type: bank.type,
      },
      storeIdToUse
    )
      .then((response) => {
        setAllValues();
        setIsProcessing(false);
        setIsAddingAccount(false);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  }

  function handleSelectWithdrawalAccount(bankAccountId: number) {
    let selectedAccount = withdrawalAccounts.filter((account) => {
      if (account.bankAccountId === bankAccountId) {
        return account;
      }
    });

    setSelectedLabel(
      `${selectedAccount[0].bankName ? selectedAccount[0].bankName : ""}, ${
        selectedAccount[0].accountNumber
      }`
    );

    setWithdrawal((prevState) => {
      return {
        ...prevState,
        bankAccountIdToActivate: selectedAccount[0].bankAccountIdToActivate
          ? selectedAccount[0].bankAccountIdToActivate
          : null,
        bankAccountId: selectedAccount[0].bankAccountId,
        bankName: selectedAccount[0].bankName,
        branchCode: selectedAccount[0].branchCode,
        bankCode: selectedAccount[0].bankCode,
        accountNumber: selectedAccount[0].accountNumber,
        isActive: selectedAccount[0].isActive,
      };
    });
  }

  function handleWithdrawalAmount(value: string) {
    setWithdrawal((prevState) => {
      return {
        ...prevState,
        amount: parseFloat(value),
      };
    });
  }

  return (
    <LayoutWrapper>
      <detailsStyled.LeftRightContainer>
        <detailsStyled.LeftBalance className="border-right">
          <detailsStyled.BoxDetails className="border-bottom">
            <detailsStyled.Header>
              <FormattedMessage id="financial.title" />
            </detailsStyled.Header>
            <homeStyled.FinanceBottom>
              <NationsSelectButtonModal
                label1="financial.withdrawal.national"
                label2="financial.withdrawal.international"
                value1="brazil-finances"
                value2="global-finances"
                handler={() => {}}
              />

              <homeStyled.FinanceBox>
                <SmallInfoLabel
                  isForeign={isForeigner}
                  label={intl.formatMessage({
                    id: "financial.amount.available.settlement",
                  })}
                  value={
                    balances.loaded ? (
                      intl.formatNumber(
                        !isForeigner
                          ? balances.nationalCurrentBalance
                          : balances.currentBalance,
                        {
                          style: "currency",
                          currency: !isForeigner ? "BRL" : currencyStore,
                        }
                      )
                    ) : (
                      <Skeleton width={100} className="skeleton" />
                    )
                  }
                  brlAmount={
                    balances.loaded ? (
                      intl.formatNumber(balances.nationalCurrentBalance, {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) : (
                      <Skeleton width={100} className="skeleton" />
                    )
                  }
                />

                <SmallInfoLabel
                  isForeign={isForeigner}
                  label={intl.formatMessage({
                    id: "financial.amount.to.receive",
                  })}
                  value={
                    balances.loaded ? (
                      intl.formatNumber(
                        !isForeigner
                          ? balances.nationalAccountBalance
                          : balances.accountBalance,
                        {
                          style: "currency",
                          currency: !isForeigner ? "BRL" : currencyStore,
                        }
                      )
                    ) : (
                      <Skeleton width={100} className="skeleton" />
                    )
                  }
                  brlAmount={
                    balances.loaded ? (
                      intl.formatNumber(balances.nationalAccountBalance, {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) : (
                      <Skeleton width={100} className="skeleton" />
                    )
                  }
                />
              </homeStyled.FinanceBox>
            </homeStyled.FinanceBottom>
          </detailsStyled.BoxDetails>

          <detailsStyled.BoxDetails className="border-bottom complete-height">
            <detailsStyled.BalanceHeader>
              <FormattedMessage id="financial.page.withdrawal.title" />

              <p className="subtitle">
                {intl.formatMessage({
                  id: "financial.page.withdrawal.subtitle",
                })}
              </p>

              <styled.FormContainer>
                {!isAddingAccount && (
                  <>
                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({ id: "withdrawal.transfer.type" })}
                      </h5>

                      <Buttons
                        mode={
                          isJustStore ? "single-checkbox" : "double-checkbox"
                        }
                        radio2ButtonsName="transfer-type"
                        handler={setMoneyDestiny}
                        radio2ButtonsValue1="ownAccount"
                        radio2ButtonsValue2="merchantAccount"
                        label={intl.formatMessage({
                          id: "withdrawal.your.accout",
                        })}
                        labelRight={intl.formatMessage({
                          id: "withdrawal.merchant.accout",
                        })}
                        valueToCheck={moneyDestiny}
                      />
                    </div>

                    <div className="limited-width">
                      {moneyDestiny !== "" && !isJustStore ? (
                        <div className="form-item">
                          <h5>
                            {intl.formatMessage({
                              id: "settings.business.business.number",
                            })}
                          </h5>

                          <SelectStoresButtonModal
                            handler={() => {}}
                            setID={setSelectedStoreId}
                            labelWithStoreNameAndId={true}
                          />
                        </div>
                      ) : null}

                      {selectedStoreId > 0 || isJustStore ? (
                        <>
                          {moneyDestiny === "ownAccount" && (
                            <div className="form-item">
                              <h5>
                                {intl.formatMessage({
                                  id: "financial.type.account.select.account",
                                })}
                              </h5>

                              <SelectBankAccountButtonModal
                                withdrawalAccounts={withdrawalAccounts}
                                setIsAddingAccount={setIsAddingAccount}
                                selectedLabel={selectedLabel}
                                handler={handleSelectWithdrawalAccount}
                              />
                            </div>
                          )}

                          {moneyDestiny === "merchantAccount" && (
                            <>
                              <div className="form-item">
                                <h5>
                                  {intl.formatMessage({
                                    id: "settings.balance.recipient",
                                  })}
                                </h5>

                                <SelectStoresButtonModal
                                  handler={() => {}}
                                  setID={setSelectedStoreIdRecipient}
                                  labelWithStoreNameAndId={true}
                                />
                              </div>
                            </>
                          )}

                          {moneyDestiny !== "" && (
                            <div className="form-item">
                              <h5>
                                {intl.formatMessage({
                                  id: "request.payment.amount",
                                })}
                              </h5>
                              <Input
                                required={true}
                                mode="normal-fixed-symbol"
                                isCurrency={true}
                                fixedSymbol="R$"
                                name="withdrawal-amount"
                                value={withdrawal.amount}
                                placeholder=""
                                handler={handleWithdrawalAmount}
                              />

                              {withdrawalErrors === "amountError" ? (
                                <div className="error">
                                  <FormattedMessage id="home.withdrawall.error.available.amount.zero" />{" "}
                                </div>
                              ) : null}
                              {["zeroAmountError"].includes(
                                withdrawalErrors
                              ) ? (
                                <div className="error">
                                  <FormattedMessage id="home.withdrawall.error.amount.equal.zero" />{" "}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </>
                )}

                {isAddingAccount && (
                  <div className="limited-width">
                    <styled.SearchBank>
                      <div className="div-search" onClick={handleOpenBankList}>
                        <div className="bank__info">
                          {!bankIconSelected &&
                            !bankNameSelected &&
                            intl.formatMessage({
                              id: "home.withdrawall.select.bank.account.placeholder.register",
                            })}

                          {bankIconSelected && bankNameSelected && (
                            <>
                              <img src={bankIconSelected} alt="arrow down" />

                              <p>{bankNameSelected}</p>
                            </>
                          )}
                        </div>

                        <div className="arrow">
                          <img src={arrowDownIcon} alt="arrow down" />
                        </div>
                      </div>

                      {showBankList && (
                        <div className="c-bank-component">
                          <input
                            type="search"
                            placeholder="Pesquise o banco"
                            className="search-bank"
                            onChange={(e) => handleSearchBank(e.target.value)}
                          />

                          <div className="scroll">
                            {(banksAndCodesState.length > 0
                              ? banksAndCodesState
                              : banksAndCodes
                            ).map((bank) => (
                              <label
                                key={bank.bankCode}
                                onClick={() =>
                                  handleSelectABank(
                                    bank.bankName,
                                    bank.bankLogo ? bank.bankLogo : noLogoBank,
                                    bank.bankCode
                                  )
                                }
                              >
                                <input
                                  type="radio"
                                  name="bank"
                                  value={bank.bankCode}
                                />

                                <span className="bank__name">
                                  <img
                                    src={
                                      bank.bankLogo ? bank.bankLogo : noLogoBank
                                    }
                                    alt="bank logo"
                                  />
                                  {bank.bankName}
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* <ErrorMessage
                        errors={errors}
                        name="bank"
                        render={({ message }) => (
                          <p className="error">{message}</p>
                        )}
                      /> */}
                    </styled.SearchBank>

                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({ id: "home.withdrawall.branch" })}
                      </h5>

                      <Input
                        required={true}
                        mode="named"
                        name="ag"
                        value={bank.ag}
                        placeholder=""
                        handler={handleNewBankAccount}
                        type="number"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                    </div>

                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({ id: "home.withdrawall.account" })}
                      </h5>

                      <Input
                        required={true}
                        mode="named"
                        name="account"
                        value={bank.account}
                        placeholder=""
                        handler={handleNewBankAccount}
                        type="number"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) &&
                          evt.preventDefault()
                        }
                      />
                    </div>

                    <div className="payment-request-item">
                      <h5>
                        {intl.formatMessage({
                          id: "home.withdrawall.type.account",
                        })}
                      </h5>
                      <Select
                        required={false}
                        mode="named-child"
                        name="type"
                        placeholder=""
                        handler={handleNewBankAccount}
                      >
                        <option value="Checking">
                          {intl.formatMessage({
                            id: "financial.type.account.checking",
                          })}
                        </option>
                        <option value="Savings">
                          {intl.formatMessage({
                            id: "financial.type.account.savings",
                          })}
                        </option>
                      </Select>
                    </div>

                    <div className="form-item">
                      <h5>
                        {intl.formatMessage({
                          id: "home.withdrawall.bank.holder.name",
                        })}
                      </h5>
                      <Input
                        required={true}
                        mode="named"
                        name="titular"
                        value={bank.titular}
                        placeholder=""
                        handler={handleNewBankAccount}
                      />
                    </div>

                    {!viewAsStore ? (
                      <div className="form-item">
                        <h5>
                          {intl.formatMessage({
                            id: "user.profile.right.title",
                          })}
                        </h5>

                        <SelectStoresButtonModal
                          handler={handleNewBankAccountIdentificationNumber}
                          setID={setSelectedStoreId}
                          labelWithStoreNameAndId={true}
                        />
                      </div>
                    ) : null}

                    {!viewAsStore ? (
                      <div className="form-item">
                        <h5>{cpfCnpjLabel}</h5>
                        <Input
                          required={true}
                          mode="named-mask"
                          mask={
                            bank.identificationNumber.length >= 14
                              ? "99.999.999/9999-99"
                              : "999.999.999-999"
                          }
                          name="identificationNumber"
                          value={bank.identificationNumber}
                          placeholder=""
                          handler={handleNewBankAccount}
                          disabled
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </styled.FormContainer>
            </detailsStyled.BalanceHeader>

            {(selectedStoreId > 0 || isJustStore) && !isProcessing ? (
              <>
                {!isAddingAccount && moneyDestiny === "ownAccount" && (
                  <div className="flex-buttons-container">
                    <Buttons
                      mode="filled-with-event"
                      label={intl.formatMessage({ id: "button.withdrawal" })}
                      handler={(e: any) => submitWithdrawal(e)}
                    />
                  </div>
                )}

                {!isAddingAccount && moneyDestiny === "merchantAccount" && (
                  <div className="flex-buttons-container">
                    <Buttons
                      mode="filled-with-event"
                      label={intl.formatMessage({
                        id: "withdrawall.confirm.transfer",
                      })}
                      handler={(e: any) => submitWithdrawal(e)}
                    />
                  </div>
                )}
              </>
            ) : null}

            {(selectedStoreId > 0 || isJustStore) &&
            isProcessing &&
            !isAddingAccount ? (
              <>
                <div>
                  <Buttons
                    mode="filled-processing"
                    label=""
                    handler={() => {}}
                  />
                </div>
              </>
            ) : null}

            {isAddingAccount && (
              <div className="flex-buttons-container">
                {isProcessing ? (
                  <Buttons
                    mode="filled-processing"
                    label=""
                    handler={() => {}}
                  />
                ) : (
                  <Buttons
                    mode="filled"
                    label={intl.formatMessage({
                      id: "home.withdrawall.confirm.new.bank.details",
                    })}
                    handler={submitNewBankAccount}
                  />
                )}
                <Buttons
                  mode="unfilled"
                  label={intl.formatMessage({ id: "home.withdrawall.cancel" })}
                  disable={isProcessing}
                  handler={() => setIsAddingAccount(false)}
                />
              </div>
            )}

            {withdrawalStatusMessage.includes("success") && (
              <styled.MessageSuccess>
                {intl.formatMessage({ id: withdrawalStatusMessage })}
              </styled.MessageSuccess>
            )}
            {withdrawalStatusMessage.includes("failed") && (
              <styled.MessageError>
                {intl.formatMessage({ id: withdrawalStatusMessage })}
              </styled.MessageError>
            )}
          </detailsStyled.BoxDetails>
        </detailsStyled.LeftBalance>

        <detailsStyled.RightBalance className="border-right">
          <detailsStyled.BoxDetails className="border-bottom">
            <div className="limitted-right-container">
              <detailsStyled.Header className="column-header">
                {openUpcomingPayin && (
                  <styled.ReturnButton
                    onClick={() => setOpenUpcomingPayin(false)}
                  >
                    <img src={arrowIcon} alt="arrow left" />{" "}
                    {intl.formatMessage({ id: "financials.sales.upcoming" })}
                  </styled.ReturnButton>
                )}

                <FormattedMessage id="financial.page.transactions.title" />

                <div className="flex-container">
                  <p className="subtitle">
                    {intl.formatMessage({
                      id: !openUpcomingPayin
                        ? "financial.page.transactions.subtitle"
                        : "financial.page.transactions.upcoming.subtitle",
                    })}
                  </p>

                  {/* <SelectAndButtonModal rightHandler={() => { }} /> */}
                </div>
              </detailsStyled.Header>

              {!openUpcomingPayin && (
                <styled.UpcomingContainer>
                  <div>
                    <img src={tipIcon} alt="tip" />
                    {intl.formatMessage({
                      id: "financial.page.transactions.upcoming.amount",
                    })}
                    {intl.formatNumber(balances.nationalAccountBalance, {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </div>

                  <span
                    className="view-all__element"
                    onClick={() =>
                      setOpenUpcomingPayin((prevState) => !prevState)
                    }
                  >
                    {intl.formatMessage({
                      id: "financial.page.transactions.view.all",
                    })}
                  </span>
                </styled.UpcomingContainer>
              )}

              <styled.TransactionContainer>
                {!openUpcomingPayin ? (
                  <>
                    {withdrawalTransactions.map((withdrawalTransaction) => (
                      <styled.TransactionDayItem key={Math.random()}>
                        <p className="date">{withdrawalTransaction.date}</p>
                        {withdrawalTransaction.payinAmount > 0 ? (
                          <div className="transaction-type-container">
                            <div className="transaction__type__simple">
                              <p
                                className="transaction__type"
                                onClick={() =>
                                  toggleWithdrawalTransaction(
                                    withdrawalTransaction.date,
                                    "payin"
                                  )
                                }
                              >
                                {intl.formatMessage({
                                  id: "financials.sales.payin",
                                })}
                                <img
                                  className={`${
                                    openWithdrawalTransaction.type ===
                                      "payin" &&
                                    openWithdrawalTransaction.visibleTransaction &&
                                    openWithdrawalTransaction.visibleDate ===
                                      withdrawalTransaction.date
                                      ? "rotateArrow"
                                      : ""
                                  }`}
                                  src={arrowIcon}
                                  alt="drop down"
                                />
                              </p>
                              <p>
                                {intl.formatNumber(
                                  withdrawalTransaction.payinAmount,
                                  { style: "currency", currency: "BRL" }
                                )}
                              </p>
                            </div>

                            {openWithdrawalTransaction.type === "payin" &&
                            openWithdrawalTransaction.visibleTransaction &&
                            openWithdrawalTransaction.visibleDate ===
                              withdrawalTransaction.date ? (
                              <div className="transaction__type__detailed">
                                {withdrawalTransaction.payin.map((payin) => (
                                  <div
                                    className="transaction__item payin__item"
                                    key={payin.transaction}
                                    onClick={() =>
                                      redirectToOrderDetails(
                                        payin.number,
                                        payin.store.id
                                      )
                                    }
                                  >
                                    {/* <div>
                                      <span>
                                        {isJustStore
                                          ? ""
                                          : `${payin.store.name} - `}
                                        {payin.number}
                                      </span> */}
                                    {/* <span>{payin.number ? payin.number : payin.id}</span> */}
                                    {/* </div> */}

                                    <p>
                                      {intl.formatNumber(payin.amount, {
                                        style: "currency",
                                        currency: "BRL",
                                      })}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {withdrawalTransaction.payoutAmount > 0 ? (
                          <div className="transaction-type-container">
                            <div className="transaction__type__simple">
                              <p
                                className="transaction__type"
                                onClick={() =>
                                  toggleWithdrawalTransaction(
                                    withdrawalTransaction.date,
                                    "payout"
                                  )
                                }
                              >
                                {intl.formatMessage({
                                  id: "financials.sales.payout",
                                })}
                                <img
                                  className={`${
                                    openWithdrawalTransaction.type ===
                                      "payout" &&
                                    openWithdrawalTransaction.visibleTransaction &&
                                    openWithdrawalTransaction.visibleDate ===
                                      withdrawalTransaction.date
                                      ? "rotateArrow"
                                      : ""
                                  }`}
                                  src={arrowIcon}
                                  alt="drop down"
                                />
                              </p>
                              <p>
                                {" "}
                                -{" "}
                                {intl.formatNumber(
                                  withdrawalTransaction.payoutAmount,
                                  { style: "currency", currency: "BRL" }
                                )}
                              </p>
                            </div>

                            {openWithdrawalTransaction.type === "payout" &&
                            openWithdrawalTransaction.visibleTransaction &&
                            openWithdrawalTransaction.visibleDate ===
                              withdrawalTransaction.date ? (
                              <div className="transaction__type__detailed">
                                {withdrawalTransaction.payout.map((payout) => {
                                  const bankIcon = banksAndCodes.filter(
                                    (bank) =>
                                      bank.bankCode ===
                                      payout.bankAccount.bankCode
                                  );

                                  let formattedIdentIficationNumber = "";

                                  if (
                                    payout.store.identificationNumber.length >
                                    11
                                  ) {
                                    formattedIdentIficationNumber =
                                      payout.store.identificationNumber
                                        .replace(/\D/g, "")
                                        .replace(
                                          /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                                          "$1.$2.$3/$4-$5"
                                        );
                                  } else {
                                    formattedIdentIficationNumber =
                                      payout.store.identificationNumber
                                        .replace(/\D/g, "")
                                        .replace(
                                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          "$1.$2.$3-$4"
                                        );
                                  }

                                  return (
                                    <div className="transaction__item">
                                      <div>
                                        <span
                                          className={`status-${payout.status}`}
                                        >
                                          {intl.formatMessage({
                                            id: `payment.${
                                              payout.status === "succeeded"
                                                ? "confirmed"
                                                : payout.status
                                            }`,
                                          })}
                                        </span>
                                        <p>{payout.bankAccount.bankName}</p>
                                        <TipButtonModal text="">
                                          <ul>
                                            <li>
                                              {payout.bankAccount.bankName}{" "}
                                              {bankIcon[0].bankLogo ? (
                                                <img
                                                  src={bankIcon[0].bankLogo}
                                                  alt={`${payout.bankAccount.bankName} icon`}
                                                />
                                              ) : null}
                                            </li>
                                            <li>
                                              {intl.formatMessage({
                                                id: "financials.sales.agency",
                                              })}{" "}
                                              {payout.bankAccount.routingNumber}
                                            </li>
                                            <li>
                                              {intl.formatMessage({
                                                id: "financials.sales.bank.account",
                                              })}{" "}
                                              {payout.bankAccount.accountNumber}
                                            </li>
                                            <li>{`CNPJ ${formattedIdentIficationNumber}`}</li>
                                            <li>{payout.store.name}</li>
                                          </ul>
                                        </TipButtonModal>
                                      </div>

                                      <p>
                                        -
                                        {intl.formatNumber(payout.amount, {
                                          style: "currency",
                                          currency: "BRL",
                                        })}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </styled.TransactionDayItem>
                    ))}
                  </>
                ) : (
                  <>
                    {incomingTransactions.map((incomingTransaction) => (
                      <styled.TransactionDayItem key={Math.random()}>
                        <p className="date">{incomingTransaction.date}</p>
                        <div className="transaction-type-container">
                          <div className="transaction__type__simple">
                            <p
                              className="transaction__type"
                              onClick={() =>
                                toggleWithdrawalTransaction(
                                  incomingTransaction.date,
                                  "payin"
                                )
                              }
                            >
                              {intl.formatMessage({
                                id: "financials.sales.payin",
                              })}{" "}
                              <img
                                className={`${
                                  openWithdrawalTransaction.type === "payin" &&
                                  openWithdrawalTransaction.visibleTransaction &&
                                  openWithdrawalTransaction.visibleDate ===
                                    incomingTransaction.date
                                    ? "rotateArrow"
                                    : ""
                                }`}
                                src={arrowIcon}
                                alt="drop down"
                              />
                            </p>
                            <p>
                              {intl.formatNumber(
                                incomingTransaction.payinAmount,
                                { style: "currency", currency: "BRL" }
                              )}
                            </p>
                          </div>

                          {openWithdrawalTransaction.type === "payin" &&
                          openWithdrawalTransaction.visibleTransaction &&
                          openWithdrawalTransaction.visibleDate ===
                            incomingTransaction.date ? (
                            <div className="transaction__type__detailed">
                              {incomingTransaction.payin.map((payin) => (
                                <div
                                  className="transaction__item payin__item"
                                  key={payin.transaction}
                                  onClick={() =>
                                    redirectToOrderDetails(
                                      payin.number,
                                      payin.store.id
                                    )
                                  }
                                >
                                  {/* <div>
                                    <span>
                                      {isJustStore
                                        ? ""
                                        : `${payin.store.name} - `}
                                      {payin.number}
                                    </span> */}
                                  {/* <span>{payin.number ? payin.number : payin.id}</span> */}
                                  {/* </div> */}

                                  <p>
                                    {intl.formatNumber(payin.amount, {
                                      style: "currency",
                                      currency: "BRL",
                                    })}
                                  </p>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </styled.TransactionDayItem>
                    ))}
                  </>
                )}

                <indexStyled.PaginationWrapper>
                  <Pagination
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    disableNextButton={
                      withdrawalTransactions.length < 5 ? true : false
                    }
                    pageIndexProcessing={pageIndexProcessing}
                    totalOrder={numberTotalOfOrders}
                    simplePage={true}
                    maxNumberOrderPerRequest={50}
                  />
                </indexStyled.PaginationWrapper>
              </styled.TransactionContainer>
            </div>
          </detailsStyled.BoxDetails>
        </detailsStyled.RightBalance>
      </detailsStyled.LeftRightContainer>
    </LayoutWrapper>
  );
}
