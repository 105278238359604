import styled from 'styled-components';

export const TopbarMobile = styled.div`
  display: none;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 25px;

  
  .title {
      text-align: left;
      font: Bold 12px/13px Ubuntu;
      letter-spacing: 0;
      color: ${props => props.theme.textColors.sixth};
      opacity: 1;
  }
  img {
      width: 11px;
      height: 13px;
      margin-right: 10px;
      opacity: 1;
  }

  @media screen and (max-width: 916px) {
    padding: 0;
    display: flex;
    align-items: center;
    min-width: 143px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;