import styled from 'styled-components';

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 916px) {
    flex-direction: column;

    .remove-margins-mobile {
      margin-left: 0px !important;
    }
  }
`;