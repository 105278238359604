import React from "react";
import ContentLoader from "react-content-loader";

//Types
import { Loader } from "./types";

const MyLoader = ({ height = 35, speed = 2, width = "100%" } : Loader) => (
  <ContentLoader
    speed={speed}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </ContentLoader>
);

export default MyLoader;
