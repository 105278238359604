import React from "react";
import { useHistory } from "react-router-dom";

//Components
import { LayoutWrapper } from "components/Layout/Index";
import { CustomerDetails } from "./Components/Details";

//Services
import SessionService from "services/Session";

//Styles
import * as indexStyled from "../../styles/index-styles";

function CustomerDetailsPage() {
  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <LayoutWrapper>
      <indexStyled.RowList className=" transactions_details">
        <CustomerDetails />
      </indexStyled.RowList>
    </LayoutWrapper>
  );
}

export default CustomerDetailsPage;
