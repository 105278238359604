import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Services
import ProductServices from "services/Product";
import { MemberkitServices } from "services/Memberkit";

//Components
import Buttons from "../../Forms/Buttons";
import Input from "../../Forms/InputFloatLabel";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Styles
import * as styled from "./modals-styles";

type TClassRoom = {
  course_name: string;
  id: number;
  name: string;
};

type TCourse = {
  id: number;
  tag: string;
  courseName: string;
};

type TProduct = {
  unitPrice: number;
  productName: string;
  productId: number;
  quantity: number;
  createdOn: string;
  connector?: TCourse;
  selectedRoom: string;
  selectedRoomId: number;
};

type TModal = {
  handler: any;
  handlerCourse: any;
  clear: () => void;
  selectedProducts: TProduct[];
  selectedCourses: any[];
  handleNewPaymentLinkDataAmount: (amount: string) => void;
  eventsToEdit?: any;
  productPreSelected?: any;
  setProductPreSelected?: any;
  classRooms: any;
  setClassRooms: any;
  selectedUnlimitedAccess: any;
  setSelectedUnlimitedAccess: any;
  setUnlimitedAccessCourse: any;
  triggerVisualHandler?: boolean;
};

const SelectProductsButtonModal = ({
  handler,
  handlerCourse,
  selectedProducts,
  selectedCourses,
  productPreSelected,
  setProductPreSelected,
  classRooms,
  setClassRooms,
  selectedUnlimitedAccess,
  setSelectedUnlimitedAccess,
  setUnlimitedAccessCourse,
  triggerVisualHandler,
}: TModal) => {
  const { getClassRooms } = MemberkitServices();

  const [isMounted, setIsMounted] = useState(false);
  const [showSelectAndButtonModal, setShowSelectAndButtonModal] =
    useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [searchedEvent, setSearchedEvent] = useState("");

  const [selectedProductsNames, setSelectedProductsNames] = useState<string[]>(
    []
  );
  const [hasCourses, setHasCourses] = useState(false);

  const [selectedCoursesIds, setSelectedCoursesIds] = useState<number[]>([]);
  const [selectedProductsIds, setSelectedProductsIds] = useState<number[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [backupProducts, setBackupProducts] = useState<any[]>([]);
  // const [selectedProducts, setSelectedProducts] = useState<TProduct[]>([]);
  const [amountByProducts, setAmountByProducts] = useState(0);

  const selectProductsModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!triggerVisualHandler) return;

    const currentLabels: string[] = [];
    const currentIds: number[] = [];
    selectedProducts.forEach((selectedProduct) => {
      currentLabels.push(selectedProduct.productName);
      currentIds.push(selectedProduct.productId);
    });
    selectedCourses.forEach((selectedCourse) => {
      currentLabels.push(selectedCourse.productName);
      currentIds.push(selectedCourse.productId);
    });

    setSelectedLabel(currentLabels.join(", "));
    setSelectedProductsIds(currentIds);
  }, [triggerVisualHandler, selectedProducts, selectedCourses]);

  useEffect(() => {
    if (searchedEvent !== "") {
      let filteredStores = backupProducts.filter((product: any) =>
        product.name.toLowerCase().includes(searchedEvent.toLowerCase())
      );

      setProducts(filteredStores);
    }

    if (searchedEvent === "") {
      setProducts(backupProducts);
    }
  }, [searchedEvent]);

  // function getAllProductsCourseClasses(sorttedProductsByCreatedOn: any[]) {
  //   const allProducts = [];

  //   for (let i = 0; i <= sorttedProductsByCreatedOn.length - 1; i++) {
  //     const product = sorttedProductsByCreatedOn[i];
  //     if (product.connector) {
  //       getClassRooms(product.connector.id)
  //         .then((responseClass: any) => {
  //           const newProduct = {
  //             ...product,
  //             connector: {
  //               ...product.connector,
  //               classRooms: responseClass.data,
  //             },
  //             selectedRoom: responseClass.data[0].name,
  //           };

  //           allProducts.push(newProduct);
  //         })
  //         .catch((err: any) => {
  //           console.log(err);
  //         });
  //     } else {
  //       allProducts.push(product);
  //     }
  //   }

  //   setTimeout(() => {}, 500);
  //   return allProducts;
  // }

  function getallProductsData() {
    ProductServices.getAllProducts()
      .then((response) => {
        const activeProducts = response.data.filter((product: any) => {
          return product.status === "Active";
        });

        if (response.data.some((product2: any) => product2.isUnlimited)) {
          const course = response.data.filter(
            (course: any) => course.isUnlimited
          );
          setHasCourses(true);

          getClassRooms(course[0].connector.id)
            .then((responseClass: any) => {
              setClassRooms(responseClass.data);
            })
            .catch((err: any) => {
              console.log(err);
            });
        }

        // const allProductsCoursesWithClasses =
        //   getAllProductsCourseClasses(activeProducts);

        const sorttedProductsByCreatedOn = activeProducts.sort(
          (a: TProduct, b: TProduct) => {
            return (
              new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
            );
          }
        );

        setProducts(sorttedProductsByCreatedOn);
        setBackupProducts(sorttedProductsByCreatedOn);

        if (productPreSelected) {
          handleFilter({
            name: productPreSelected.productName,
            id: parseInt(productPreSelected.id),
            unitPrice: productPreSelected.unitPrice,
          });

          setProductPreSelected(undefined);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getallProductsData();
  }, [productPreSelected]);

  function handleShowModal() {
    if (selectProductsModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  function createNewProduct() {
    history.push("/request-payment/create-product");
  }

  function handleFilter(property: any) {
    if (selectedProducts || selectedCourses) {
      let currentLabels: string[] = [...selectedProductsNames];
      let currentIds: number[] = [...selectedProductsIds];
      const currentProducts: TProduct[] = [...selectedProducts];
      const currentCourses: any[] = [...selectedCourses];

      if (currentIds.includes(property.id)) {
        if (property.connector) {
          const newSelectedCourses: any[] = selectedCourses.filter(
            (product) => product.productId !== property.id
          );

          const newSelectedCoursesIds: number[] = currentIds.filter(
            (product) => product !== property.id
          );

          const newSelectedProductsLabel = newSelectedCourses.map(
            (product) => product.productName
          );

          setSelectedProductsNames(newSelectedProductsLabel);
          currentLabels = newSelectedProductsLabel;
          currentIds = newSelectedCoursesIds;

          handlerCourse(newSelectedCourses);
        } else {
          const newSelectedProducts: any[] = selectedProducts.filter(
            (product) => product.productId !== property.id
          );
          const newSelectedProductsIds: number[] = selectedProductsIds.filter(
            (product) => product !== property.id
          );

          const newSelectedProductsLabel = newSelectedProducts.map(
            (product) => product.productName
          );

          setSelectedProductsNames(newSelectedProductsLabel);
          currentLabels = newSelectedProductsLabel;
          currentIds = newSelectedProductsIds;

          handler(newSelectedProducts);
        }
      } else {
        currentLabels.push(property.name);
        setSelectedProductsNames(currentLabels);

        if (property.connector) {
          handlerCourse([
            ...currentCourses,
            {
              unitPrice: property.unitPrice,
              productName: property.name,
              productId: property.id,
              quantity: 1,
              productImage:
                property.images.length > 0 ? property.images[0].filePath : "",
              connector: {
                ...property.connector,
                classRooms: property.classRooms,
              },
              selectedRoom: property.selectedRoom,
              selectedRoomId: property.selectedRoomId,
            },
          ]);
        } else {
          handler([
            ...currentProducts,
            {
              unitPrice: property.unitPrice,
              productName: property.name,
              productId: property.id,
              quantity: 1,
              productImage:
                property.images.length > 0 ? property.images[0].filePath : "",
              selectedRoom: "",
            },
          ]);
        }
        currentIds.push(property.id);
      }

      setSelectedLabel(currentLabels.join(", "));
      setSelectedProductsIds(currentIds);
    }
  }

  useClickOutside(selectProductsModalRef, isMounted, setIsMounted);

  const handleSelectAllCourses = () => {
    setSelectedUnlimitedAccess(!selectedUnlimitedAccess);

    const unlimitedAccessCourse = products.filter(
      (course) => course.isUnlimited
    );

    setUnlimitedAccessCourse(unlimitedAccessCourse[0]);
  };

  useEffect(() => {
    if (selectedUnlimitedAccess) {
      handler([]);
      handlerCourse([]);
      setSelectedProductsNames([]);
      setSelectedProductsIds([]);

      let currentLabels: string[] = [];
      let finalCourses: any[] = [];
      let currentIds: number[] = [];

      const courseProducts = products.filter(
        (product) => product.connector && !product.isUnlimited
      );

      courseProducts.forEach((course) => {
        currentLabels.push(course.name);
        currentIds.push(course.id);

        finalCourses.push({
          unitPrice: course.unitPrice,
          productName: course.name,
          productId: course.id,
          quantity: 1,
          productImage:
            course.images.length > 0 ? course.images[0].filePath : "",
          connector: {
            ...course.connector,
            classRooms: course.classRooms,
          },
          selectedRoom: course.selectedRoom,
          selectedRoomId: course.selectedRoomId,
        });
      });

      setSelectedProductsNames(currentLabels);
      handlerCourse(finalCourses);
      setSelectedLabel(currentLabels.join(", "));
      setSelectedCoursesIds(currentIds);
      setSelectedProductsIds(currentIds);
    }
    // else {
    //   handler([]);
    //   handlerCourse([]);
    //   setSelectedProductsNames([]);
    //   setSelectedProductsIds([]);
    //   setSelectedCoursesIds([]);
    //   setSelectedLabel("");
    // }
  }, [selectedUnlimitedAccess]);

  useEffect(() => {
    if (selectedUnlimitedAccess) {
      let existentIds: number[] = [];

      selectedCoursesIds.forEach((id) => {
        const indexId = selectedProductsIds.indexOf(id);

        existentIds.push(indexId);
      });

      if (existentIds.includes(-1)) {
        setSelectedUnlimitedAccess(false);
      }
    }
  }, [selectedProductsIds]);

  const productSourceBadge = <span className="status-blue">Memberkit</span>;

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>
          {selectedLabel !== ""
            ? selectedLabel
            : intl.formatMessage({ id: "choose.a.product" })}
        </p>

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showSelectAndButtonModal && (
        <styled.SelectProductsContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectProductsModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowSelectAndButtonModal(false);
          }}
        >
          <div className="header-container--flex">
            <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
          </div>

          <div className="header-container--search">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={searchedEvent}
                placeholder={intl.formatMessage({
                  id: "header.search.country",
                })}
                handler={setSearchedEvent}
              />
            </div>

            {hasCourses ? (
              <div className="button__item--large">
                <div className="flex">
                  <Input
                    required={false}
                    mode="checkbox"
                    valueToCheck={selectedUnlimitedAccess}
                    name="events"
                    value="allCourses"
                    placeholder="Acesso ilimitado"
                    readOnly={true}
                    handler={handleSelectAllCourses}
                  />
                  {productSourceBadge}
                </div>
              </div>
            ) : null}
          </div>

          <div className="buttons-container">
            {products.map((product, index) => {
              if (!product.isUnlimited || product.isUnlimited === undefined) {
                return (
                  <div className="button__item--large" key={product.id}>
                    <div className="flex">
                      <Input
                        required={false}
                        mode="checkbox"
                        valueToCheck={selectedProductsIds.includes(product.id)}
                        name="events"
                        value={product.id}
                        placeholder={product.name}
                        readOnly={true}
                        handler={() => handleFilter(product)}
                      />
                      {product.connector ? productSourceBadge : null}
                    </div>
                    <div className="span-id-number">
                      {intl.formatNumber(product.unitPrice, {
                        style: "currency",
                        currency: product.currency,
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="create-product-container">
            <Buttons
              mode="unfilled"
              handler={createNewProduct}
              type="submit"
              label={intl.formatMessage({ id: "create.new.product" })}
              disable={false}
            />
          </div>
        </styled.SelectProductsContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectProductsButtonModal;
