import moment from 'moment';

function formatFinancialTransactions(responseWithdrawals: any, responseReceivables: any) {

  const receivables = responseReceivables.reduce((accumulator: any, currentReceivable: any) => {
    const newCurrentReceivable = {...currentReceivable};
    let receivableResult = {};
    let newAccumulator = [...accumulator];
    let finalResult: any[] = [];

    const newPaidAt = moment(newCurrentReceivable.paid_at).format('DD/MM/YYYY');
    newCurrentReceivable.paid_at = newPaidAt;

    newAccumulator.forEach((receivable: any) => {
      if(receivable.date === newPaidAt) {
        let newPayIn: any[] = []

        newPayIn = [...receivable.payin, newCurrentReceivable];
        receivable.payin = newPayIn;
        receivable.payinAmount += newCurrentReceivable.amount;

        finalResult = [...newAccumulator]

      }else {
        receivableResult = {
          date: newPaidAt,
          payinAmount: newCurrentReceivable.amount, 
          payoutAmount: 0,
          payin: [newCurrentReceivable], 
          payout: [] 
        }

        finalResult = [...newAccumulator, receivableResult]
      }
    });

    return finalResult;

  }, [{date: '', payinAmount: 0, payoutAmount: 0, payin: [], payout: [] }]);

  const withdrawals = responseWithdrawals.reduce((accumulator: any, currentWithdrawal: any) => {
    const newCurrentWithdrawal = {...currentWithdrawal};
    let withdrawalResult = {};
    let newAccumulator = [...accumulator];
    let finalResult: any[] = [];

    const newCreatedOn = moment(newCurrentWithdrawal.createdOn).format('DD/MM/YYYY');
    newCurrentWithdrawal.createdOn = newCreatedOn;

    newAccumulator.forEach((withdrawal: any) => {
      if(withdrawal.date === newCreatedOn) {
        let newPayout: any[] = []

        newPayout = [...withdrawal.payout, newCurrentWithdrawal];
        withdrawal.payout = newPayout;
        withdrawal.payoutAmount += newCurrentWithdrawal.amount;

        finalResult = [...newAccumulator]

      }else {
        withdrawalResult = {
          date: newCreatedOn,
          payinAmount: 0, 
          payoutAmount: newCurrentWithdrawal.amount,
          payin: [], 
          payout: [newCurrentWithdrawal] 
        }

        finalResult = [...newAccumulator, withdrawalResult]
      }
    });

    return finalResult;

  }, [{date: '', payinAmount: 0, payoutAmount: 0, payin: [], payout: [] }]);

  const receivablesAndWithdrawals = [...withdrawals, ...receivables];
  const removedEmpty = receivablesAndWithdrawals.filter(item => item.date !== '')
  const sorttedResult = removedEmpty.sort((a, b) => {
    const newA = moment(a.date).format('YYYY-MM-DD');
    const newB = moment(b.date).format('YYYY-MM-DD');
    return +new Date(newA) - +new Date(newB)
  } );

  return sorttedResult;

}

export default formatFinancialTransactions;