import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import QRCode from "qrcode.react";
import isBefore from "date-fns/isBefore";
import { parseISO } from "date-fns";
import moment from "moment";

//Services
import ManagerLinksService from "services/ManagerLinks";
import RequesPaymentService from "services/RequestPayment";
import SessionService from "services/Session";

//Components
import Buttons from "components/Forms/Buttons";
import {
  ContentBox,
  LayoutWrapper,
  Pagination,
} from "components/Layout/Index";

//Assets
import copyIcon from "assets/images/global/copy.svg";

import * as indexStyled from "../styles/index-styles";

type TLink = {
  id: number;
  title: string;
  url: string;
  amount: number;
  installments: number;
  maxCharges: number;
  paymentMethods: string;
  description: string;
  qrCode: string | null | undefined;
  orderNumber: string | null;
  dateTime: string;
  isExpiredLink?: boolean;
  store?: any;
  isActiveLink?: boolean;
  currency: string;
};

function PaymentLinks() {
  const intl = useIntl();
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleLinkId, setVisibleId] = useState(0);

  const [isLinksLoaded, setIsLinksLoaded] = useState(false);

  const [copyLinkTextButton, setCopyLinkTextButton] = useState(
    "manager.links.details.copy.barcode"
  );

  const [colorsTheme, setColorsTheme] = useState({
    backgroundColor: "",
    color: "",
  });

  const [qrCode, setQrCode] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const refQRCode = useRef<HTMLInputElement>(null);
  const refQRCodeImg = useRef<HTMLImageElement>(null);
  const refQRCodeMobile = useRef<HTMLInputElement>(null);
  const refQRCodeImgMobile = useRef<HTMLImageElement>(null);

  const [links, setLinks] = useState<TLink[]>([]);

  const [uniqueCode, setUniqueCode] = useState("");

  const paymentLinkToCopyManage = useRef<HTMLInputElement>(null);
  const paymentLinkToCopyManageMobile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsLinksLoaded(false);

    const themeStorage = localStorage.getItem("premepay-dashboard-theme");
    const { premepayDashboardTheme } = JSON.parse(
      themeStorage ? themeStorage : "{}"
    );

    if (premepayDashboardTheme === "light") {
      setColorsTheme({
        backgroundColor: "#f0f0f0",
        color: "#16161B",
      });
    } else {
      setColorsTheme({
        backgroundColor: "#3E3D46",
        color: "#fff",
      });
    }

    let linksGET: TLink[] = [];

    ManagerLinksService.getLinks().then((response: any) => {
      response.data.map((link: any) => {
        let paymentType = "";

        if (link.paymentTypes.includes(0) && link.paymentTypes.includes(1)) {
          paymentType = "manager.links.details.payment.methods.01";
        } else if (link.paymentTypes.includes(1)) {
          paymentType = "manager.links.details.payment.methods.1";
        } else {
          paymentType = "manager.links.details.payment.methods.0";
        }

        let isBoletoWithInstallments =
          (link.boletoInstallments && link.boletoInstallments) > 1
            ? true
            : false;
        let showInstallments = isBoletoWithInstallments
          ? link.boletoInstallments
          : link.installments === 0
          ? 1
          : link.installments;

        let isExpiredLink;

        if (link.expiresOn && link.expiresOn !== "") {
          const formattedDate = parseISO(link.expiresOn);

          // if (link.store.isForeigner && (link.expiresOn.split('T')[1] !== '23:59:59')) {
          //   const timeZoneOffset = new Date(link.expiresOn).getTimezoneOffset();
          //   const timeZoneOffsetPositive = new Date(link.expiresOn).getTimezoneOffset() * (-1);

          //   const formatDate = timeZoneOffset > 0 ? new Date(new Date(link.expiresOn).getTime() - (timeZoneOffset * 60 * 1000)) : new Date(new Date(link.expiresOn).getTime() + (timeZoneOffsetPositive * 60 * 1000));
          //   const dateFormattedTimeZone = moment(formatDate).format();

          //   const parsedDate = parseISO(dateFormattedTimeZone.substring(0, 19));

          //   isExpiredLink = isBefore(parsedDate, new Date());
          // } else {
          isExpiredLink = isBefore(formattedDate, new Date());
          // }
        }

        let isActiveLink;

        if (link.isActive) {
          isActiveLink = true;
        } else {
          isActiveLink = false;
        }

        linksGET.push({
          id: link.id,
          title: link.title,
          url: link.url,
          amount: link.amount,
          installments: showInstallments,
          maxCharges: link.plan ? link.plan.maxCharges : 0,
          paymentMethods: paymentType,
          description: link.description,
          qrCode: link.qrCode ? link.qrCode : null,
          orderNumber: link.orderNumber ? link.orderNumber : null,
          dateTime: format(new Date(link.createdOn), "P, HH:mm", {
            locale: navigator.language === "pt-BR" ? ptBR : undefined,
          }),
          isExpiredLink: isExpiredLink,
          isActiveLink: isActiveLink,
          currency: link.currency ? link.currency : "BRL",
        });
      });

      setLinks(linksGET.reverse());
      setIsLinksLoaded(true);
    });
  }, []);

  function generateQRCode(id: number) {
    setQrCode(true);

    setTimeout(() => {
      if (refQRCode.current) {
        const svgTag = refQRCode.current.childNodes[0];

        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svgTag);

        if (
          !source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)
        ) {
          source = source.replace(
            /^<svg/,
            '<svg xmlns="http://www.w3.org/2000/svg"'
          );
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
          source = source.replace(
            /^<svg/,
            '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
          );
        }

        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        const encodedData = window.btoa(source);
        const base = `data:image/svg+xml;base64,${encodedData}`;

        RequesPaymentService.postQRCode(id.toString(), base)
          .then((response) => {
            let linksBackup = links;

            let pos = linksBackup
              .map((link) => {
                return link.id;
              })
              .indexOf(id);

            linksBackup[pos].qrCode = response.data.qrCode;

            setLinks(linksBackup);

            setQrCode(true);
            setQrCodeUrl(response.data.qrCode);
          })
          .catch((err) => {
            console.log(err);
            setQrCode(false);
          });
      }
    }, 500);
  }

  function downloadQRCode(qrCodeUrl: string) {
    window.open(qrCodeUrl, "_blank");
  }

  function deleteLink(id: number) {
    const themeStorage = localStorage.getItem("premepay-dashboard-theme");
    const { premepayDashboardTheme } = JSON.parse(
      themeStorage ? themeStorage : "{}"
    );

    if (premepayDashboardTheme === "light") {
      setColorsTheme({
        backgroundColor: "#f0f0f0",
        color: "#16161B",
      });
    } else {
      setColorsTheme({
        backgroundColor: "#3E3D46",
        color: "#fff",
      });
    }

    let linksGET: TLink[] = [];

    ManagerLinksService.deleteLink(id).then((response: any) => {
      setVisibleDetails(false);
      setVisibleId(0);

      ManagerLinksService.getLinks().then((response: any) => {
        response.data.map((link: any) => {
          let paymentType = "";

          if (link.paymentTypes.includes(0) && link.paymentTypes.includes(1)) {
            paymentType = "manager.links.details.payment.methods.01";
          } else if (link.paymentTypes.includes(1)) {
            paymentType = "manager.links.details.payment.methods.1";
          } else {
            paymentType = "manager.links.details.payment.methods.0";
          }

          let isBoletoWithInstallments =
            (link.boletoInstallments && link.boletoInstallments) > 1
              ? true
              : false;
          let showInstallments = isBoletoWithInstallments
            ? link.boletoInstallments
            : link.installments === 0
            ? 1
            : link.installments;

          linksGET.push({
            id: link.id,
            title: link.title,
            url: link.url,
            amount: link.amount,
            installments: showInstallments,
            maxCharges: link.plan ? link.plan.maxCharges : 0,
            paymentMethods: paymentType,
            description: link.description,
            qrCode: link.qrCode ? link.qrCode : null,
            orderNumber: link.orderNumber ? link.orderNumber : null,
            dateTime: format(new Date(link.createdOn), "P, HH:mm", {
              locale: navigator.language === "pt-BR" ? ptBR : undefined,
            }),
            currency: link.currency ? link.currency : "BRL",
          });
        });

        setLinks(linksGET);
        setIsLinksLoaded(true);
      });
    });
  }

  const [callCopy, setCallCopy] = useState(false);

  function callCopyPaymentLink() {
    setCallCopy(true);
  }

  function copyPaymentLink(paymentLink: string) {
    navigator.clipboard.writeText(paymentLink);
  }

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowListWithMarginBottom>
          <ContentBox cssClass="m-top-25 list-transactions">
            <indexStyled.TableList>
              <indexStyled.ContextBoxHeader>
                <div className="title">
                  <FormattedMessage id="manager.links.title" />
                </div>
              </indexStyled.ContextBoxHeader>

              {links.length === 0 && isLinksLoaded && (
                <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
              )}

              <indexStyled.FullTableLinks>
                <thead>
                  <tr className="remove--border-bottom">
                    <th>
                      <FormattedMessage id="manager.links.header.table.status" />
                    </th>
                    <th>
                      <FormattedMessage id="manager.links.header.table.title" />
                    </th>
                    <th>
                      <FormattedMessage id="manager.links.header.table.url" />
                    </th>
                    <th>
                      <FormattedMessage id="manager.links.header.table.price" />
                    </th>
                    {/* <th><FormattedMessage id="manager.links.header.table.store" /></th> */}
                    <th>
                      <FormattedMessage id="manager.links.header.table.created" />
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {isLinksLoaded ? (
                    links.map((link: TLink) => {
                      let status;

                      if (link.isExpiredLink) {
                        status = (
                          <span className="status-failed">
                            <FormattedMessage id="payment.expired" />
                          </span>
                        );
                      } else if (link.isActiveLink) {
                        status = (
                          <span className="status-succeeded">
                            <FormattedMessage id="status.active" />
                          </span>
                        );
                      } else {
                        status = (
                          <span className="status-deactivated">
                            <FormattedMessage id="status.deactivated" />
                          </span>
                        );
                      }

                      return (
                        <tr key={link.url}>
                          <td>{status}</td>
                          <td>{link.title}</td>
                          <td className="space-between">
                            <span>https://pay.primefy.com/{link.url}</span>{" "}
                            <Buttons
                              customClass="copy-button--none-mobile"
                              mode="minimal-with-icon"
                              label={intl.formatMessage({
                                id: "ui.label.copy",
                              })}
                              icon={copyIcon}
                              handler={() =>
                                copyPaymentLink(
                                  `https://pay.primefy.com/${link.url}`
                                )
                              }
                            />
                          </td>
                          <td>
                            {intl.formatNumber(link.amount, {
                              style: "currency",
                              currency: link.currency,
                            })}
                          </td>
                          {/* <td>loja</td> */}
                          <td>{`${link.dateTime.split(",")[0].substr(0, 5)}, ${
                            link.dateTime.split(",")[1]
                          }`}</td>
                          <td>
                            <Buttons
                              mode="unfilled"
                              label={intl.formatMessage({ id: "view.label" })}
                              handler={() => {
                                history.push(`/payment-links/${link.url}`);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Skeleton count={1} height={25} className="skeleton" />
                  )}
                </tbody>
              </indexStyled.FullTableLinks>
            </indexStyled.TableList>
            {/* <indexStyled.PaginationWrapper>
              <Pagination  
                pageIndex={pageIndex} 
                setPageIndex={setPageIndex} 
                disableNextButton={Orders.length < 50 ? true : false}
                pageIndexProcessing={pageIndexProcessing}
                totalOrder={numberTotalOfOrders}
              />
            </indexStyled.PaginationWrapper> */}
          </ContentBox>
        </indexStyled.RowListWithMarginBottom>
      </LayoutWrapper>
    </>
  );
}

export default PaymentLinks;
