import React, { FC, SelectHTMLAttributes } from "react";
import { useIntl } from "react-intl";

//Styles
import * as styled from "./select-styles";

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  value?: any;
  placeholder: string;
  handler: any;
  icon?: string;
  data?: any[];
  children?: any;
  mode:
    | "named"
    | "normal"
    | "named-child"
    | "normal-intl"
    | "normal-child"
    | "clean-named-child"
    | "clean-normal-child";
  bgColors?: string;
  selectRef?: any;
  bottomSeparator?: boolean;
  ref?: any;
}

const Select: FC<ISelectProps> = ({
  data,
  mode,
  value,
  placeholder,
  handler,
  icon,
  children,
  bgColors,
  selectRef,
  bottomSeparator,
  ...rest
}) => {
  const intl = useIntl();

  function handlerNamed(name: string, value: string) {
    handler(name, value);
  }

  return (
    <>
      {(() => {
        switch (mode) {
          case "normal":
            return (
              <styled.Select
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  ref={selectRef}
                  value={value}
                  onChange={(e: any) => handler(e.target.value)}
                  {...rest}
                >
                  <option value="">{placeholder}</option>
                  {data!.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </styled.Select>
            );
          case "normal-intl":
            return (
              <styled.Select
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  {...rest}
                  ref={selectRef}
                  value={value!.toString()}
                  onChange={(e: any) => handler(e.target.value)}
                >
                  <option value="">{placeholder}</option>
                  {data!.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {intl.formatMessage({ id: option.label })}
                    </option>
                  ))}
                </select>
              </styled.Select>
            );
          case "named":
            return (
              <styled.Select
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  placeholder=" "
                  ref={selectRef}
                  {...rest}
                  value={value}
                  onChange={(e: any) =>
                    handlerNamed(e.target.name, e.target.value)
                  }
                >
                  <option value="" selected={true}></option>
                  {data!.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </styled.Select>
            );
          case "named-child":
            return (
              <styled.Select
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  placeholder=" "
                  ref={selectRef}
                  {...rest}
                  value={value}
                  onChange={(e: any) =>
                    handlerNamed(e.target.name, e.target.value)
                  }
                >
                  {children}
                </select>
              </styled.Select>
            );
          case "clean-normal-child":
            return (
              <styled.CleanSelect
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  placeholder=" "
                  ref={selectRef}
                  value={value}
                  onChange={(e: any) => handler(e.target.value)}
                  {...rest}
                >
                  {children}
                </select>
              </styled.CleanSelect>
            );
          case "clean-named-child":
            return (
              <styled.CleanSelect
                isValid={value === "" ? false : true}
                bgColors={bgColors}
              >
                <select
                  placeholder=" "
                  ref={selectRef}
                  {...rest}
                  value={value}
                  onChange={(e: any) =>
                    handlerNamed(e.target.name, e.target.value)
                  }
                >
                  {children}
                </select>
              </styled.CleanSelect>
            );
          case "normal-child":
            return (
              <styled.Select
                isValid={value === "" ? false : true}
                bgColors={bgColors}
                bottomSeparator={bottomSeparator}
              >
                <select
                  placeholder=" "
                  ref={selectRef}
                  value={value}
                  onChange={(e: any) => handler(e.target.value)}
                  {...rest}
                >
                  {children}
                </select>
              </styled.Select>
            );
          default:
            return <div></div>;
        }
      })()}
    </>
  );
};

export default Select;
