import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .title {
    position: absolute;
    z-index: 1;
    left: 60px;
    top: 34px;
    font-size: 18px;
    font-family: "Brandon Text Medium" !important;
    color: ${props => props.theme.contentColors.secondary};
  }
`;

export const LiveMapContainer= styled.div`
  &.liveMapContainer {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0 ;
    width: 100% !important;
    min-height: 500px;
    max-height: 1000px;

    @media screen and (max-width: 860px) {
      min-height: 300px;
    }
  }

  .mapboxgl-canvas {
    border-radius: 0 0 3px 3px !important;
  }

  .confirmed, .pending, .canceled, .deactivated {
    background: #33C344;
    border: 5px solid #C1E5C4;
    border-radius: 50%;
    height: 25px;
    width: 25px;       
    animation: pulse 1s ease-out;
    animation-iteration-count: infinite; 
  }

  .pending {
    background: #FFC400;
    border: 5px solid #F4E5B3;
  }

  .canceled {
    background: #FF0000;
    border: 5px solid #F5B5B4;
  }

  .deactivated {
    background: #44667d;
    border: 5px solid #E2E8EB;
  }

  @keyframes pulse {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
  }
`;

export const pulseDiv = styled.div`
  
  .canceled {
    background: #FF0000;
    border: 5px solid #F5B5B4;
    border-radius: 50%;
    height: 15px;
    width: 15px;       
    animation: pulse 1s ease-out;
    animation-iteration-count: infinite; 
  }

  @keyframes pulse {
    0% {transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {transform: scale(1.2, 1.2); opacity: 0.0;}
  }
`;