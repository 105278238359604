import styled from "styled-components";

export const LeftRightContainer = styled.section``;

export const FormContainer = styled.div`
  h5 {
    margin-top: 32px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
    color: ${(props) => props.theme.contentColors.primary};
  }

  .insider-container {
    margin-left: 32px;
    margin-bottom: 24px;
  }

  .select-and-input-container {
    display: flex;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    border-radius: 4px;
    align-items: center;
    height: 40px;

    .m-half-width {
      min-width: 50%;
      max-width: 50%;
    }

    .m-half-width--flex {
      display: flex;
    }

    div {
      &:last-child {
        height: 100%;
        border-left: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      }
    }

    input {
      height: 24px;
      padding: 0 8px;
      width: 100%;
      background: transparent;
    }

    input[type="time"],
    input[type="date"] {
      height: 24px;
      padding: 0px;
      width: 100%;
      background: transparent;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .limited-width {
    max-width: 420px;
  }

  .form-item {
    transition: all 0.2s ease-in-out;

    .error {
      margin-top: 4px;
      font-size: 12px !important;
    }

    .form-item__optional {
      display: flex;

      p {
        margin-top: 32px;
        margin-bottom: 8px;
        font-size: 10px;
        margin-left: 16px;
        color: ${(props) => props.theme.contentColors.primary};
      }
    }
  }

  .button-bottom-container {
    margin-top: 32px;
  }

  .radios {
    max-height: 300px;
    width: 100%;
    flex-direction: column;
    margin: 16px auto;
    margin-bottom: 16px;

    .extern-label {
      color: ${(props) => props.theme.textColors.primary};
      font-size: 14px;
    }

    .scroll {
      overflow-y: scroll;

      scrollbar-base-color: #c0c0c0;
      scrollbar-base-color: #c0c0c0;
      scrollbar-3dlight-color: #c0c0c0;
      scrollbar-highlight-color: #c0c0c0;
      scrollbar-track-color: #ebebeb;
      scrollbar-arrow-color: black;
      scrollbar-shadow-color: #c0c0c0;
      scrollbar-dark-shadow-color: #c0c0c0;

      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }
      ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
      }
      ::-webkit-scrollbar-thumb {
        background: #dad7d7;
      }
    }

    .search-bank {
      background: ${({ theme }) => theme.backgroundColors.primary};
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      min-height: 36px;
      border-radius: 3px;
      padding: 8px;
      margin-bottom: 16px;
      color: ${(props) => props.theme.contentColors.secondary};
      font-size: 12px;
    }

    label {
      cursor: pointer;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${(props) => props.theme.contentColors.primary};

      &:hover {
        transition: ease 0.3s;
        background: ${(props) => props.theme.backgroundColors.fourth};
      }

      img {
        margin-right: 8px;
      }
    }

    input[type="radio"] {
      display: none;
    }

    span {
      width: 100%;
    }

    input[type="radio"]:checked + span {
      background: ${(props) => props.theme.backgroundColors.sixth};
    }

    display: flex;
    flex-direction: column;
  }
`;

export const MessageError = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.7;
  color: #f00;
`;

export const MessageSuccess = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.7;
  color: #008c00;
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionDayItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .date {
    font-size: 14px;
    margin-bottom: 16px;
    color: ${(props) => props.theme.contentColors.primary};
  }

  .transaction-type-container {
    margin-bottom: 8px;

    .transaction__type__simple {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      p {
        color: #1b1b1b;
      }
    }

    .transaction__type__detailed {
      margin: 16px 0;
    }

    .transaction__item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin: 10px 0;

      div {
        display: flex;
        align-items: center;

        span {
          margin-right: 8px;
        }
      }

      p {
        color: #5e5e5e;
        margin-right: 8px;
      }

      ul {
        li {
          display: flex;
          /* white-space: nowrap; */
          align-items: center;
        }
      }
    }

    .payin__item {
      color: #5e5e5e;
      font-size: 14px;
    }

    .transaction__type {
      p {
        transition: all 0.2s ease-in-out;
        font-size: 14px;
        color: ${(props) => props.theme.contentColors.secondary};
      }

      img {
        transform: rotate(-90deg);
        transition: transform 0.5s ease-in-out;
      }

      &:hover {
        p {
          transition: all 0.2s ease-in-out;
          color: ${(props) => props.theme.contentColors.fourth};
        }
      }

      .rotateArrow {
        transform: rotate(90deg);
        transition: transform 0.5s ease-in-out;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid
      ${(props) => props.theme.backgroundColors.secondary};
  }
`;

export const UpcomingContainer = styled.div`
  background: ${(props) => props.theme.primaryColors.fifth};
  border-radius: 3px;
  display: flex;
  padding: 10px 20px;
  width: 100%;

  div {
    align-items: center;
    color: ${(props) => props.theme.contentColors.secondary};
    font-size: 14px;

    img {
      margin-right: 8px;
    }
  }
`;

export const ReturnButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease-in-out;

  img {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    img {
      transform: translateX(-5px);
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const SearchBank = styled.div`
  max-height: 350px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;

  .selected-category {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .div-search {
    outline: none;
    font-size: 14px;
    width: 100%;
    background: #fff !important;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
    height: 40px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .bank__info {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: rgba(62, 61, 70, 0.75);
      font-size: 14px;
      font-family: "Rubik", sans-serif !important;

      p {
        font-family: "Brandon Text Regular";
        font-size: 16px;
        margin: 0px;
        color: #000;
      }

      img {
        width: 38px;
        height: 31px;
        margin-right: 8px;
      }
    }

    .arrow {
      cursor: pointer;

      img {
        right: 8px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .c-bank-component {
    width: 100%;
    max-width: 420px;
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 1000;
    opacity: 0;
    animation: fadeBankList 0.1s ease-in-out forwards;

    @keyframes fadeBankList {
      to {
        opacity: 1;
        transition: all 0.1s ease-in-out;
      }
    }

    .scroll {
      overflow-y: scroll;
      scrollbar-base-color: #c0c0c0;
      scrollbar-base-color: #c0c0c0;
      scrollbar-3dlight-color: #c0c0c0;
      scrollbar-highlight-color: #c0c0c0;
      scrollbar-track-color: #ebebeb;
      scrollbar-arrow-color: black;
      scrollbar-shadow-color: #c0c0c0;
      scrollbar-dark-shadow-color: #c0c0c0;
      max-height: 150px;

      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }
      ::-webkit-scrollbar {
        width: 6px;
        background: #f4f4f4;
      }
      ::-webkit-scrollbar-thumb {
        background: #dad7d7;
      }
    }

    .search-bank {
      outline: none;
      width: 100%;
      background: #fff;
      padding: 13px 8px 8px 8px;
      border-radius: 3px;
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      height: 40px;
      margin-bottom: 4px;
      font-size: 14px;
      font-family: "Rubik", sans-serif !important;
    }

    label {
      .bank__name {
        font-size: 12px;
      }

      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(62, 61, 70, 0.75) !important;

      &:hover {
        transition: ease 0.3s;
        background: #fff;
      }

      img {
        margin-right: 8px;
      }
    }

    input[type="radio"] {
      display: none;
    }

    span {
      width: 100%;
      cursor: pointer;
    }

    input[type="radio"]:checked + span {
      background: #eaeaea;
      transition: all 0.3s ease;
      color: #000;
    }

    input[type="radio"]:hover + span {
      background: #eaeaea;
      transition: all 0.3s ease;
      color: #000;
    }

    display: flex;
    flex-direction: column;
  }
`;
