import styled from "styled-components";

interface ImgProps {
  path: string;
}

export const LeftRightContainer = styled.section`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 990px) {
    display: flex;
    flex-direction: column !important;
  }
`;

export const Left = styled.div`
  width: calc(54% - (25px / 2));

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 100%;

  @media screen and (max-width: 1800px) {
    width: 70%;
  }

  @media screen and (max-width: 1240px) {
    width: 70%;
  }

  @media screen and (max-width: 1080px) {
    width: 50%;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    padding-bottom: 8em;
  }
`;

export const LeftSettings = styled.div`
  width: calc(60% - (25px / 2));

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const LeftPaymentLinkDetails = styled.div`
  width: calc(60% - (25px / 2));

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const LeftBalance = styled(Left)`
  min-width: 40%;
`;

export const RightSettings = styled.div`
  width: 100%;

  @media screen and (max-width: 1240px) {
    width: 70%;
  }

  @media screen and (max-width: 1080px) {
    width: 50%;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const RightBalance = styled(Right)`
  @media screen and (max-width: 1800px) {
    width: 70%;
  }

  @media screen and (max-width: 1500px) {
    width: 55%;
  }

  @media screen and (max-width: 1330px) {
    width: 50%;
  }

  @media screen and (max-width: 1240px) {
    width: 40%;
  }

  @media screen and (max-width: 1080px) {
    width: 40%;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    padding-bottom: 8em;
  }
`;

export const BoxDetails = styled.div`
  background: ${(props) => props.theme.backgroundColors.primary};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 38px 39px 38px 60px;

  .details-button {
    width: 100%;
    display: flex;
    justify-content: center;

    .btn {
      text-align: center;
      width: 170px;
      outline: none;
    }
  }

  .details-items-input-100 {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 20px 20px 0 20px;
  }

  .details-items-edit-form {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    margin: 0px 0px 10px 0px !important;
    animation: topDown 0.3s ease-in-out forwards;
    /* opacity: 0; */

    @keyframes topDown {
      from {
        transform: translateY(-10px);
      }
      to {
        transform: translateY(0px);
        /* opacity: 1 */
      }
    }
  }

  .blue-title {
    color: ${(props) => props.theme.primaryColors.sixth};

    div {
      display: flex;

      p {
        margin-left: 8px;
      }
    }
  }

  .containar--50-max {
    max-width: 50px;
  }

  .containar--250-max {
    max-width: 250px;
  }

  .receivables {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.backgroundColors.fifth} !important;

    .value-line {
      margin-top: 10px;
      display: flex;
      max-width: 350px;
      justify-content: space-between;

      p {
        color: ${(props) => props.theme.contentColors.secondary};

        &:first-child {
          min-width: 30%;

          @media screen and (max-width: 916px) {
            min-width: 30%;
          }
        }
      }
    }

    .value-line.withAnticipation {
      max-width: 550px;
    }

    .withAnticipation {
      p {
        &:last-child {
          min-width: 30%;

          @media screen and (max-width: 916px) {
            min-width: 30%;
          }
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .receivable--last {
    border-bottom: 0px !important;
  }

  .limitted-right-container {
    max-width: 700px;
  }

  @media screen and (max-width: 425px) {
    padding: 8px 16px;
  }
`;

export const MobileBoxDetails = styled(BoxDetails)`
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

export const Header = styled.header`
  margin-bottom: 34px;
  display: flex;
  font-size: 18px;
  font-family: "Brandon Text Medium" !important;
  color: ${(props) => props.theme.contentColors.secondary};
  flex-direction: column;

  .edit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edit-buttons-container {
      display: flex;

      button {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  .subtitle {
    margin-top: 8px;
    color: #000;
  }

  .header-right {
    display: flex;
    align-items: center;
    widows: 100%;
    justify-content: space-between;

    .btn {
      font-size: 0.8em;
      width: auto !important;
    }
  }

  .edit-version {
    font-size: 0.66em !important;

    .btn {
      font-size: 1.2em;
      width: auto !important;
    }
  }

  a {
    color: ${(props) => props.theme.contentColors.fourth};

    &:hover {
      color: ${(props) => props.theme.contentColors.fourth};
    }
  }

  .tip {
    margin: 8px 0;
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
    }
  }

  .desactivate-link-container {
    margin: 16px 0;
  }

  @media screen and (max-width: 1500px) {
    width: 69%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BalanceHeader = styled(Header)`
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
`;

export const FlexHeader = styled(Header)`
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CustomerFlexHeader = styled(FlexHeader)`
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 1.5em;
  justify-content: flex-start;

  button {
    &:first-child {
      margin-right: 16px;
    }
  }

  .delete-user-button {
    margin-left: 8px;
  }

  .header-right {
    margin-left: 8px;

    .btn {
      font-size: 0.8em;
      width: auto !important;
    }
  }
`;

export const BottomMobile = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 1.5em;
  justify-content: flex-end;

  .img-none {
    span {
      img {
        display: none;
      }
    }
  }

  .delete-user-button {
    margin-left: 8px;
  }

  .header-right {
    margin-left: 8px;

    .btn {
      font-size: 0.8em;
      width: auto !important;

      img {
        display: none !important;
      }
    }
  }
`;

export const ProductSourceValue = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
  }
`;

export const DetailsItems = styled.div`
  .detail-item--margin-bottom {
    margin-bottom: 16px;

    h5 {
      font-size: 14px;
      margin-bottom: 8px;
      font-family: "Brandon Text Medium" !important;
      color: ${(props) => props.theme.contentColors.primary};
    }

    .margin-left {
      margin-left: 16px;
    }

    .store-logo-container {
      position: relative;
      width: auto;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;

      label {
        cursor: pointer;
        margin-bottom: 0px;
        width: auto;
      }

      #upload-logo {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-flex;
        background-color: ${(props) => props.theme.backgroundColors.third};
        align-items: center;
      }

      &:hover {
        border: 2px solid ${(props) => props.theme.primaryColors.primary};
        transition: all 0.1s ease-in-out;
      }
    }
  }

  .large-width {
    min-width: 300px;
  }

  .status {
    background: #f4a000;
    color: #fff;
    padding: 0 8px;
    border-radius: 20px;
    margin-right: 16px;
  }

  hr {
    background: ${(props) => props.theme.backgroundColors.fifth};
  }

  .desactivate-link-container {
    margin-top: 25px;

    div {
      display: flex;

      img {
        margin-right: 4px;
      }

      p {
        font-size: 13px;
        color: ${(props) => props.theme.contentColors.secondary};
        font-family: "Brandon Text Medium" !important;
      }
    }

    .margin-top-16 {
      margin-top: 16px;
    }

    p {
      font-size: 13px;
      color: ${(props) => props.theme.contentColors.primary};
    }
  }

  .skeleton {
    background-color: ${(props) => props.theme.backgroundColors.fourth};
  }

  .line-height-27 {
    .label,
    .value {
      min-height: 27px !important;
    }
  }

  .detail-item--flex {
    display: flex;
  }

  .success {
    color: #33c344;
    font-size: 1.2em;
  }

  .error {
    color: #ff0000;
    font-size: 1.2em;
  }

  .details-items-input-100 {
    background-color: ${(props) => props.theme.backgroundColors.fifth};
    border-radius: 3px;
    padding: 20px;
    margin: 20px;

    .form-create-user {
      padding: 0px 0px !important;
    }
  }

  .enotas-container {
    .enotas-header {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
    }
  }

  .info-detail-item {
    margin: 12px 0;

    div {
      display: flex;

      img {
        margin-left: 8px;
      }
    }

    h5 {
      font-family: "Brandon Text Medium" !important;
      font-size: 14px;
      color: ${(props) => props.theme.contentColors.primary};
    }

    .input-container-settings {
      margin-top: 8px;
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 8px;
      }
    }

    p {
      font-size: 14px;
      color: ${(props) => props.theme.contentColors.secondary};

      img {
        width: 32px;
        border-radius: 50%;
      }
    }
  }
`;

export const BlockedOrder = styled.div`
  background: ${(props) => props.theme.primaryColors.fifth};
  border-radius: 6px;
  padding: 8px;
  margin: 20px 0;
  display: flex;
  min-width: auto;
  width: 430px;
  align-items: center;
  justify-content: center;

  p {
    color: ${(props) => props.theme.contentColors.primary};
    margin-left: 8px;
    margin-right: 16px;
    font-size: 12px;

    @media screen and (max-width: 916px) {
      margin-right: 0px;
    }
  }
`;

export const CancelSubscription = styled(BlockedOrder)`
  width: 100%;

  p {
    font-size: 14px;
  }

  button {
    font-size: 16px !important;
  }

  img {
    width: 15px !important;
    height: 15px;
  }

  .div-header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 916px) {
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: 916px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CheckPixPaymentStatus = styled(CancelSubscription)`
  width: 250px;

  button {
    width: auto !important;
  }
`;

export const CanceledSubscription = styled(CancelSubscription)`
  height: 52px;
  min-width: 170px;
  max-width: 25%;
  justify-content: center;
  align-items: center;

  .div-header-canceled {
    display: flex;
    align-items: center;
  }

  p {
    margin-right: 0px;
  }

  @media screen and (max-width: 916px) {
    max-width: 100%;
  }
`;

export const ChargeNow = styled(BlockedOrder)`
  .label {
    color: #5e5e5e;
    width: 25%;
    font-size: 14px;
    font-family: "Brandon Text Medium" !important;
  }

  justify-content: left;
  border-bottom: 1px solid ${(props) => props.theme.backgroundColors.secondary};
  background: transparent;
  width: auto;
  padding: 24px 0;
  margin: 0px;
`;

export const HighScore = styled.span`
  color: #fff;
  background-color: #ff0000;
  padding: 0.2em 0.5em;
  border-radius: 20px;
  margin-left: 10px;
`;

export const MediumScore = styled(HighScore)`
  background-color: #f4a000;
`;

export const LowScore = styled(HighScore)`
  background-color: #33c344;
`;

export const DetailItem = styled.div`
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid ${(props) => props.theme.backgroundColors.fifth};

  .width-select-state {
    width: 100%;
    padding: 1px;
    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.backgroundColors.seventh} 0%,
      ${(props) => props.theme.backgroundColors.eighth} 100%
    );
    border-radius: 3px;
    outline: none;
    height: 36px;
  }

  .label {
    color: ${(props) => props.theme.contentColors.primary};
    width: 30%;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: "Brandon Text Medium" !important;

    @media screen and (max-width: 916px) {
      min-width: 30%;
      margin-right: 5px;
    }
  }

  .value {
    .input-hidden {
      height: 0px !important;
    }

    .map-container {
      min-width: 253px;
      max-width: 253px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.backgroundColors.secondary};

      @media screen and (max-width: 990px) {
        min-width: 350px;
        max-width: 500px;
      }

      @media screen and (max-width: 583px) {
        min-width: 250px;
        max-width: 250px;
      }
    }

    .media-container {
      display: flex;
      max-width: 370px;

      img {
        margin: 8px;
        object-fit: cover;
      }

      .first-media {
        min-width: 158px;
        max-width: 158px;
        min-height: 158px;
        max-height: 158px;
        border-radius: 9px;
      }

      div {
        display: flex;
        flex-wrap: wrap;

        img {
          min-width: 73px;
          max-width: 73px;
          min-height: 73px;
          max-height: 73px;
          border-radius: 9px;
        }
      }
    }

    .form-item {
      transition: all 0.2s ease-in-out;
      margin-bottom: 24px;

      .form-item__optional {
        display: flex;

        p {
          margin-top: 16px;
          margin-bottom: 8px;
          font-size: 10px;
          margin-left: 16px;
          color: ${(props) => props.theme.contentColors.primary};
        }
      }

      .flex {
        display: flex;

        label {
          margin-right: 8px;
        }
      }
    }

    .select-and-input-container {
      display: flex;
      border: 1px solid ${(props) => props.theme.backgroundColors.eighth};
      border-radius: 4px;
      align-items: center;
      height: 40px;
      max-width: 368px;

      .m-half-width {
        min-width: 50%;
        max-width: 50%;
      }

      .m-20-width {
        min-width: 20%;
        max-width: 20%;

        select {
          padding: 0px;
        }
      }

      .m-half-width--flex {
        display: flex;
      }

      div {
        &:last-child {
          height: 100%;
          border-left: 1px solid
            ${(props) => props.theme.backgroundColors.eighth};
        }
      }

      input {
        height: 24px;
        padding: 0 8px;
        width: 100%;
        background: transparent;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .value {
    .card-network {
      width: 30px;
    }

    .load-images-container {
      width: 100%;
    }
  }

  .value,
  .value-column {
    width: 100%;
    color: ${(props) => props.theme.contentColors.secondary};
    font-size: 14px;
    display: flex;
    align-items: center;
    word-break: break-all;

    .copy-button-container {
      margin-left: 8px;
    }

    .filepond-div {
      .filepond--item {
        max-width: 100%;
      }

      img {
        width: 250px;
        height: auto;

        @media screen and (max-width: 916px) {
          width: 150px;
        }
      }
    }

    @media screen and (max-width: 1441px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 916px) {
      word-break: break-all;
      min-width: 60%;
    }
  }

  .value-column {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .value-column__item {
      margin-bottom: 8px;
    }

    .qr-code {
      margin-bottom: 8px;
    }
  }

  .value-100 {
    min-width: 80% !important;

    input,
    select {
      width: 100% !important;
      max-width: 750px !important;
      height: 36px;
    }

    @media screen and (max-width: 916px) {
      min-width: 70% !important;
    }
  }

  div {
    input {
      background: ${(props) => props.theme.backgroundColors.fourth};
      width: 350px;
      height: 34px;
      border: none;
      border-radius: 3px;
      color: ${(props) => props.theme.textColors.primary};
      outline: none;
      padding: 0 15px;

      @media screen and (max-width: 916px) {
        width: auto;
      }
    }
  }

  select {
    background: ${(props) => props.theme.backgroundColors.fourth};
    width: 350px;
    height: 34px;
    border: none;
    border-radius: 3px;
    color: ${(props) => props.theme.textColors.primary};
    outline: none;
    padding: 0 15px;

    @media screen and (max-width: 916px) {
      width: 188px;
    }
  }

  &:last-child {
    border: 0px;
  }
`;

export const FilePondDetailItem = styled(DetailItem)`
  flex-direction: column;
  margin-left: 16px;
  padding: 0px 0px;

  .value {
    color: ${(props) => props.theme.textColors.primary};

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: inline-flex;
      background-color: ${(props) => props.theme.backgroundColors.third};
      align-items: center;
    }
  }
`;

export const ImgBg = styled.div<ImgProps>`
  background-image: url(${(props) => props.path});
  width: 250px;
  height: 250px;
  background-size: cover;
`;

export const EventItems = styled.ol`
  list-style-type: none;

  .card-title {
    margin-bottom: 8px;

    strong {
      color: ${(props) => props.theme.textColors.primary};
      font-size: 14px;
    }
  }

  hr {
    background: ${(props) => props.theme.backgroundColors.fifth};
  }

  .e-last--without-bar {
    padding-bottom: 0rem !important;

    &:after {
      width: 0px !important;
    }
  }
`;

export const EventItem = styled.li`
  position: relative;
  margin: 0;
  padding-bottom: 6em;
  padding-left: 20px;
  font-size: 1.2em;

  &:after {
    content: "";
    background-color: ${(props) => props.theme.contentColors.primary};
    position: absolute;
    bottom: 0;
    top: 13px;
    left: 6.5px;
    width: 2px;
  }

  &:before {
    content: "";
    background-color: transparent;
    position: absolute;
    border: 2px solid ${(props) => props.theme.contentColors.primary};
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .flex-container--cancel-boleto {
    display: flex;
  }

  @keyframes fade {
    to {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }

  &.last {
    .c-event-item--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        margin-left: 16px;

        button {
          &:last-child {
            margin-left: 4px;
          }
        }
      }
    }
  }
`;

export const Content = styled.ol`
  li {
    color: ${(props) => props.theme.textColors.third};
  }

  li.description {
    color: ${(props) => props.theme.textColors.primary};
  }

  ol.ghost-content li {
    padding-top: 0.5rem;
  }
`;

export const FeeItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeeItem = styled.div`
  cursor: pointer;
  margin: 16px 0;

  .header {
    display: none;
    margin-top: 16px;

    /* justify-content: space-between; */

    .item {
      width: 70px;
      font-family: "Brandon Text Medium" !important;
      font-size: 14px;
      color: ${(props) => props.theme.contentColors.primary};
    }

    /* @media screen and (max-width: 990px) {
      width: 40%;
    } */
  }

  .header-open {
    display: flex;
  }

  .installments {
    display: flex;
    margin-left: 16px;

    span {
      font-family: "Brandon Text Medium" !important;
      font-size: 14px;
      color: ${(props) => props.theme.contentColors.secondary};
    }

    .fees-info {
      margin: 0 0 0 13px;
    }

    img {
      margin-left: 9px;
    }
  }

  .installments__content {
    padding-left: 40px;
    max-width: 250px;
  }

  .fees {
    display: flex;
    align-items: center;
    height: 56px;
    display: none;
    border-bottom: 1px solid ${(props) => props.theme.backgroundColors.fifth};
    justify-content: flex-start;

    p {
      color: ${(props) => props.theme.contentColors.secondary};
    }

    div {
      width: 70px;

      img {
        max-width: 32px;
      }
    }

    &:last-child {
      border-bottom: 0px;
    }

    @media screen and (max-width: 990px) {
      max-width: 50%;
    }
  }

  .fees--open {
    display: flex;
  }
`;

export const ProdutctsTable = styled.table`
  width: 100%;

  tr {
    display: flex;
    font-size: 14px;
    color: #5e5e5e;
    border-bottom: 1px solid
      ${(props) => props.theme.backgroundColors.secondary};
    padding: 24px 0;

    &:first-child {
      margin-bottom: 24px;
      padding: 0px 0;
      border: 0px;
    }

    &:last-child {
      border: 0px;
    }

    th {
      width: 33%;
    }

    td {
      width: 33%;
    }
  }
`;

export const UnfilledButtoncustom = styled.button`
  border-radius: 3px;
  padding: 7px 16px;
  border: 1px solid transparent;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: all 0.2s ease-in-out;
  background: ${(props) => props.theme.primaryColors.fifth};
  color: ${(props) => props.theme.contentColors.fourth};

  .arrow__button {
    transition: all 0.2s ease-in-out;
  }

  .isOpen {
    transform: rotate(-180deg);
    transition: all 0.2s ease-in-out;
  }
`;
