import React, { useState, useRef, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "react-calendar";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Buttons from "../../Forms/Buttons";
import Input from "../../Forms/InputFloatLabel";

//Assets
import arrowIcon from "assets/images/topbar/select-arrow.svg";
import exportIcon from "assets/images/global/export.svg";

//Styles
import * as styled from "./modals-styles";

type TFilter = {
  [key: string]: boolean;
};

type TModal = {
  leftHandler?: any;
  rightHandler?: any;
  action: (
    statusFilter: string,
    periodFilter: string,
    methodFilter: string,
    typeFilter: string,
    periodType: string
  ) => void;
  clear: () => void;
  rangeDateFilter?: any;
  setRangeDateFilter?: any;
};

const FilterExportButtonModal = ({
  leftHandler,
  rightHandler,
  action,
  clear,
  rangeDateFilter,
  setRangeDateFilter,
}: TModal) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSelectAndButtonModal, setShowSelectAndButtonModal] =
    useState(false);
  const [periodType, setPeriodType] = useState("creation");

  const [filterOptions, setFilterOptions] = useState({
    status: "",
    period: "",
    method: "",
    type: "",
  });

  const filterAndExportModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();
  let location = useLocation();

  const [filterChecked, setFilterChecked] = useState<TFilter>({
    date: false,
    status: false,
    paymentMethod: false,
    type: false,
  });

  const statusArray = [
    "all",
    "Succeded",
    "Canceled",
    "Failed",
    "Pending",
    "Disputed",
    "ChargedBack",
    "Blocked",
    "DisputeSucceeded",
  ];
  const paymentMethodArray = ["all", "Boleto", "Credit", "Pix"];
  const typeArray = ["all", "single", "recurring"];

  function handleShowModal() {
    if (filterAndExportModalRef.current) {
      setIsMounted(false);
      setShowSelectAndButtonModal(true);
    } else {
      setIsMounted(true);
      setShowSelectAndButtonModal(true);
    }
  }

  function handleFilter(property: string) {
    const itemCurrentValue = filterChecked[property];

    setFilterChecked((prevState) => {
      return {
        ...prevState,
        [property]: !itemCurrentValue,
      };
    });
  }

  function handleFilterOptions(name: string, value: string) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  useEffect(() => {
    localStorage.setItem(
      "applyFilter",
      JSON.stringify({ statusFilter: false })
    );
  }, []);

  useEffect(() => {
    const filterStorageString = localStorage.getItem("filter");
    const { status, method, type, periodType } = JSON.parse(
      filterStorageString ? filterStorageString : "{}"
    );

    if (status) {
      const radioStatusStorage = document.querySelectorAll(
        `input[value=${status}]`
      );

      radioStatusStorage.forEach((radio: any) => {
        if (radio.value === status) {
          radio.checked = true;
        }
      });
    }

    if (method) {
      const radioMethodStorage = document.querySelectorAll(
        `input[value=${method}]`
      );

      radioMethodStorage.forEach((radio: any) => {
        if (radio.value === method) {
          radio.checked = true;
        }
      });
    }

    if (type) {
      const radioTypeStorage = document.querySelectorAll(
        `input[value=${type}]`
      );

      radioTypeStorage.forEach((radio: any) => {
        if (radio.value === type) {
          radio.checked = true;
        }
      });
    }

    if (periodType && filterChecked.date) {
      const radioTypeStorage = document.querySelectorAll(
        `input[value=${periodType}]`
      );
      const radioTypeStorageView = document.querySelectorAll(
        `input[value=period-view]`
      );

      radioTypeStorage.forEach((radio: any) => {
        if (radio.value === periodType) {
          radio.checked = true;
        }
      });

      radioTypeStorageView.forEach((radio: any) => {
        if (radio.value === "period-view") {
          radio.checked = true;
        }
      });
    }
  }, [isMounted, filterChecked]);

  useEffect(() => {
    handleFilterStorage();

    action(
      filterOptions.status,
      filterOptions.period,
      filterOptions.method,
      filterOptions.type,
      periodType
    );
  }, [filterOptions, rangeDateFilter, periodType]);

  function handleFilterStorage() {
    if (periodType === "payment") {
      const radioStatusStorage = document.querySelectorAll(
        `input[value=Succeded]`
      );

      radioStatusStorage.forEach((radio: any) => {
        if (radio.value === "Succeded") {
          radio.checked = true;
        }
      });
    }

    localStorage.setItem(
      "filter",
      JSON.stringify({
        status: periodType === "payment" ? "Succeded" : filterOptions.status,
        method: filterOptions.method,
        type: filterOptions.type,
        period: rangeDateFilter,
        periodType: periodType,
      })
    );
  }

  function changeRadioButton(e: React.FormEvent<HTMLInputElement>) {
    handleFilterOptions(e.currentTarget.name, e.currentTarget.value);
  }

  function changePeriodType(e: React.FormEvent<HTMLInputElement>) {
    setPeriodType(e.currentTarget.value);
  }

  function clearSelections() {
    const radioButtons = document.querySelectorAll("input");
    radioButtons.forEach((radio: any) => {
      radio.checked = false;
    });

    setFilterOptions({
      status: "",
      period: "",
      method: "",
      type: "",
    });

    localStorage.removeItem("filter");
    localStorage.setItem(
      "applyFilter",
      JSON.stringify({ statusFilter: false })
    );
  }

  useClickOutside(filterAndExportModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <Buttons
        mode="selec-and-button-transactions"
        label={intl.formatMessage({ id: "filter.label.btn" })}
        labelRight={intl.formatMessage({ id: "filter.label.btn.export" })}
        icon={arrowIcon}
        iconRight={exportIcon}
        handler={handleShowModal}
        handlerRight={rightHandler}
      />

      {showSelectAndButtonModal && (
        <styled.FilterContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={filterAndExportModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowSelectAndButtonModal(false);
          }}
        >
          <div className="header-container--flex">
            <p>{intl.formatMessage({ id: "filter.label.title" })}</p>
            <Buttons
              mode="unfilled"
              label={intl.formatMessage({ id: "filter.cleaner" })}
              handler={() => {
                clear();
                clearSelections();
              }}
            />
          </div>

          <div className="buttons-container--padding">
            <Input
              required={false}
              mode="checkbox"
              name="period"
              value="period-view"
              placeholder={intl.formatMessage({ id: "filter.label.period" })}
              handler={() => handleFilter("date")}
            />

            {filterChecked.date && (
              <div className="intern-container">
                <div className="calendar-container">
                  <Calendar
                    onChange={setRangeDateFilter}
                    value={rangeDateFilter}
                    selectRange={true}
                  />
                </div>

                <label>
                  <Input
                    required={false}
                    mode="radio"
                    name="period-type"
                    placeholder={intl.formatMessage({
                      id: "filter.label.period.creation",
                    })}
                    value="creation"
                    handler={changePeriodType}
                  />
                </label>

                <label>
                  <Input
                    required={false}
                    mode="radio"
                    name="period-type"
                    placeholder={intl.formatMessage({
                      id: "filter.label.period.payment",
                    })}
                    value="payment"
                    handler={changePeriodType}
                  />
                </label>
              </div>
            )}

            <Input
              required={false}
              mode="checkbox"
              name="status"
              value="expiration"
              placeholder={intl.formatMessage({ id: "filter.label.status" })}
              handler={() => handleFilter("status")}
            />

            {filterChecked.status && (
              <div className="intern-container">
                {statusArray.map((status) => {
                  return (
                    <label key={status}>
                      <Input
                        required={false}
                        mode="radio"
                        name="status"
                        value={status}
                        placeholder={intl.formatMessage({
                          id: `filter.label.status.${status}`,
                        })}
                        handler={(e: any) => changeRadioButton(e)}
                      />
                    </label>
                  );
                })}
              </div>
            )}

            <Input
              required={false}
              mode="checkbox"
              name="method"
              value="expiration"
              placeholder={intl.formatMessage({
                id: "filter.label.payment.methods",
              })}
              handler={() => handleFilter("paymentMethod")}
            />

            {filterChecked.paymentMethod && (
              <div className="intern-container">
                {paymentMethodArray.map((method) => {
                  return (
                    <label key={method}>
                      <Input
                        required={false}
                        mode="radio"
                        name="method"
                        value={method}
                        placeholder={intl.formatMessage({
                          id: `filter.label.payment.methods.${method}`,
                        })}
                        handler={(e: any) => changeRadioButton(e)}
                      />
                    </label>
                  );
                })}
              </div>
            )}

            <Input
              required={false}
              mode="checkbox"
              name="type"
              value="expiration"
              placeholder={intl.formatMessage({ id: "filter.label.type" })}
              handler={() => handleFilter("type")}
            />

            {filterChecked.type && (
              <div className="intern-container">
                {typeArray.map((type) => {
                  return (
                    <label key={type}>
                      <Input
                        required={false}
                        mode="radio"
                        name="type"
                        value={type}
                        placeholder={intl.formatMessage({
                          id: `filter.label.type.${type}`,
                        })}
                        handler={(e: any) => changeRadioButton(e)}
                      />
                    </label>
                  );
                })}
              </div>
            )}
          </div>
        </styled.FilterContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default FilterExportButtonModal;
