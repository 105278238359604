import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { FilePond, registerPlugin } from "react-filepond";
import { ErrorMessage } from "@hookform/error-message";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import Buttons from "../../../../components/Forms/Buttons";
import InputFloat from "../../../../components/Forms/InputFloatLabel";

import arrowDownIcon from "../../../../assets/images/signup/arrow-down.svg";

import * as styled from "../../styles";

import { useBusinessInformation } from "./hooks";

// Types
type TCategory = {
  id: string;
  code: string;
  category: string;
  description: string;
};

interface ICategories {
  category: string;
  items: TCategory[];
}

export const BusinessInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  // Register the plugins
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType
  );

  const intl = useIntl();

  const {
    Controller,
    control,
    handleSubmit,
    submitBusinessData,
    showCategories,
    handleOpenCategoriesList,
    selectedCategoryDescription,
    searchedCategoriesState,
    handleSearchCategory,
    categories,
    handleInput,
    logo,
    setLogo,
    isProcessing,
    errors,
  } = useBusinessInformation({ setProgressStep, isStoreWithCNPJ, store });

  let refShowCategories = useRef(null);

  return (
    <>
      <form onSubmit={handleSubmit(submitBusinessData)}>
        {isStoreWithCNPJ ? (
          <div className="form-item">
            <label>
              {intl.formatMessage({
                id: "settings.store.list.id",
              })}
            </label>

            <Controller
              name="identificationNumber"
              control={control}
              render={({ field }) => (
                <InputFloat
                  mode="normal-mask"
                  mask={"99.999.999/9999-99"}
                  handler={() => {}}
                  // disabled={true}
                  {...field}
                />
              )}
            />
          </div>
        ) : null}

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "settings.business.information.category",
            })}
          </label>

          <div className="input-container">
            <div>
              <styled.SearchCategory>
                <div className="div-search" onClick={handleOpenCategoriesList}>
                  <div className="bank__info selected-category">
                    <p>{selectedCategoryDescription}</p>
                  </div>

                  <div className="arrow">
                    <img src={arrowDownIcon} alt="arrow down" />
                  </div>
                </div>

                {showCategories && (
                  <div ref={refShowCategories} className="c-bank-component">
                    <input
                      type="search"
                      placeholder={intl.formatMessage({
                        id: "finish.register.search.category",
                      })}
                      className="search-category"
                      onChange={(e) => handleSearchCategory(e.target.value)}
                    />

                    <div className="scroll">
                      <div className="popular-categories">
                        <strong>
                          {intl.formatMessage({
                            id: "create.account.popular.categories.title",
                          })}
                        </strong>

                        <div>
                          <label
                            onClick={() =>
                              handleInput(
                                "category",
                                "40",
                                intl.formatMessage({
                                  id: "create.account.popular.categories.education",
                                })
                              )
                            }
                          >
                            <input type="radio" name="category" value="40" />

                            <span>
                              {intl.formatMessage({
                                id: "create.account.popular.categories.education",
                              })}
                            </span>
                          </label>
                        </div>

                        <div>
                          <label
                            onClick={() =>
                              handleInput(
                                "category",
                                "216",
                                intl.formatMessage({
                                  id: "create.account.popular.categories.entertainment",
                                })
                              )
                            }
                          >
                            <input type="radio" name="category" value="40" />

                            <span>
                              {intl.formatMessage({
                                id: "create.account.popular.categories.entertainment",
                              })}
                            </span>
                          </label>
                        </div>

                        <div>
                          <label
                            onClick={() =>
                              handleInput(
                                "category",
                                "216",
                                intl.formatMessage({
                                  id: "create.account.popular.categories.technology",
                                })
                              )
                            }
                          >
                            <input type="radio" name="category" value="40" />

                            <span>
                              {intl.formatMessage({
                                id: "create.account.popular.categories.technology",
                              })}
                            </span>
                          </label>
                        </div>
                        <div>
                          <label
                            onClick={() =>
                              handleInput(
                                "category",
                                "221",
                                intl.formatMessage({
                                  id: "create.account.popular.categories.clothing",
                                })
                              )
                            }
                          >
                            <input type="radio" name="category" value="40" />

                            <span>
                              {intl.formatMessage({
                                id: "create.account.popular.categories.clothing",
                              })}
                            </span>
                          </label>
                        </div>
                      </div>

                      {searchedCategoriesState &&
                        searchedCategoriesState.length > 0 &&
                        searchedCategoriesState.map((category: ICategories) => (
                          <div key={category.category} className="select-item">
                            <strong>{category.category}</strong>

                            <div>
                              {category.items?.map((item) => (
                                <label
                                  key={item.id}
                                  onClick={() =>
                                    handleInput(
                                      "category",
                                      item.id,
                                      item.description
                                    )
                                  }
                                >
                                  <input
                                    type="radio"
                                    name="category"
                                    value={item.id}
                                  />

                                  <span>{item.description}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}

                      {searchedCategoriesState &&
                        searchedCategoriesState.length === 0 &&
                        categories.map((category) => (
                          <div key={category.category} className="select-item">
                            <strong>{category.category}</strong>

                            <div>
                              {category.items?.map((item) => (
                                <label
                                  key={item.id}
                                  onClick={() =>
                                    handleInput(
                                      "category",
                                      item.id,
                                      item.description
                                    )
                                  }
                                >
                                  <input
                                    type="radio"
                                    name="category"
                                    value={item.id}
                                  />

                                  <span>{item.description}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <ErrorMessage
                  errors={errors}
                  name="category"
                  render={({ message }) => <p className="error">{message}</p>}
                />
              </styled.SearchCategory>
            </div>
          </div>
        </div>

        {isStoreWithCNPJ ? (
          <>
            <div className="form-item">
              <label>
                {intl.formatMessage({
                  id: "finish.register.legal.company.name",
                })}
              </label>

              <Controller
                name="legalCompanyName"
                control={control}
                render={({ field }) => (
                  <InputFloat mode="normal" handler={() => {}} {...field} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="legalCompanyName"
                render={({ message }) => <p className="error">{message}</p>}
              />
            </div>
          </>
        ) : null}

        <div className="form-item">
          <label>
            {intl.formatMessage({
              id: "finish.register.business.name",
            })}
          </label>

          <Controller
            name="businessName"
            control={control}
            render={({ field }) => (
              <InputFloat mode="normal" handler={() => {}} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="businessName"
            render={({ message }) => <p className="error">{message}</p>}
          />
        </div>

        <div className="form-item">
          <label>
            Logo{" "}
            <span>
              {intl.formatMessage({
                id: "request.payment.optional.label",
              })}
            </span>
          </label>

          <FilePond
            labelIdle={intl.formatMessage({
              id: "finish.register.logo.label",
            })}
            files={logo}
            onupdatefiles={setLogo}
            allowMultiple={false}
            acceptedFileTypes={[
              "image/png",
              "image/jpeg",
              "image/svg",
              "image/jpg",
            ]}
            maxFiles={1}
            name="logo"
          ></FilePond>
        </div>

        <styled.ButtonFooterContainer>
          <Buttons
            mode={!isProcessing ? "filled" : "filled-processing"}
            label={intl.formatMessage({
              id: "login.button.login.continue",
            })}
            type="submit"
            handler={() => {}}
          />
        </styled.ButtonFooterContainer>
      </form>
    </>
  );
};
