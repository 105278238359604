import React, { ButtonHTMLAttributes, FC } from "react";
import { useIntl } from "react-intl";

//Components
import Input from "./InputFloatLabel";

//Styles
import * as styled from "./buttons-styles";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  labelCenter?: string;
  labelRight?: string;
  handler: any;
  handlerRight?: any;
  icon?: string;
  iconLeft?: string;
  iconRight?: string;
  iconClick?: any;
  iconClickRight?: any;
  mode:
    | "filled-with-icon"
    | "filled"
    | "filled-with-event"
    | "simple"
    | "simple-limited"
    | "unfilled-with-icon"
    | "unfilled"
    | "unfilled-with-event"
    | "filled-processing"
    | "unfilled-processing"
    | "selec-and-button"
    | "selec-and-button-transactions"
    | "minimal-with-icon"
    | "single-checkbox"
    | "double-checkbox"
    | "triple-checkbox";
  buttonRef?: any;
  disable?: boolean;
  radio2ButtonsName?: string;
  radio2ButtonsValue1?: string;
  radio2ButtonsValue2?: string;
  radio3ButtonsValue3?: string;
  customClass?: string;
  valueToCheck?: string;
  paymentTypeToDisable?: string;
}

const Buttons: FC<IButtonProps> = ({
  label,
  labelCenter,
  labelRight,
  handler,
  handlerRight,
  icon,
  iconRight,
  iconClick,
  iconClickRight,
  iconLeft,
  mode,
  buttonRef,
  disable,
  radio2ButtonsName,
  radio2ButtonsValue1,
  radio2ButtonsValue2,
  radio3ButtonsValue3,
  customClass,
  inputMode,
  valueToCheck,
  paymentTypeToDisable,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <>
      {(() => {
        switch (mode) {
          case "filled":
            return (
              <styled.Button
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                disabled={disable}
                onClick={(e: any) => handler()}
              >
                {label}
              </styled.Button>
            );

          case "filled-with-event":
            return (
              <styled.Button
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                disabled={disable}
                onClick={(e: any) => handler(e)}
              >
                {label}
              </styled.Button>
            );

          case "filled-with-icon":
            return (
              <styled.Button
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                disabled={disable}
                onClick={(e: any) => handler()}
              >
                {icon && (
                  <styled.WhiteIcon
                    onClick={iconClick}
                    icon={icon}
                  ></styled.WhiteIcon>
                )}
                {label}
              </styled.Button>
            );

          case "unfilled-with-icon":
            return (
              <styled.UnfilledButton
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                disabled={disable}
                onClick={(e: any) => handler()}
              >
                {icon && (
                  <styled.WhiteIcon
                    onClick={iconClick}
                    icon={icon}
                  ></styled.WhiteIcon>
                )}
                {label}
              </styled.UnfilledButton>
            );

          case "unfilled":
            return (
              <styled.UnfilledButton
                ref={buttonRef}
                className={`${customClass}`}
                disabled={disable}
                onClick={(e: any) => handler()}
                {...rest}
              >
                {label}
              </styled.UnfilledButton>
            );
          case "unfilled-with-event":
            return (
              <styled.UnfilledButton
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                disabled={disable}
                onClick={(e: any) => handler(e)}
              >
                {label}
              </styled.UnfilledButton>
            );

          case "filled-processing":
            return (
              <styled.FilledProcessingButton
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
              >
                <div className="spinner"></div>
                {intl.formatMessage({ id: "ui.cta.processing" })}
              </styled.FilledProcessingButton>
            );

          case "unfilled-processing":
            return (
              <styled.UnfilledProcessingButton
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
              >
                <div className="spinner"></div>
                {intl.formatMessage({ id: "ui.cta.processing" })}
              </styled.UnfilledProcessingButton>
            );

          case "simple":
            return (
              <styled.SimpleButton
                ref={buttonRef}
                {...rest}
                onClick={(e: any) => handler()}
              >
                {label}
              </styled.SimpleButton>
            );

          case "simple-limited":
            return (
              <styled.SimpleLimitedButton
                ref={buttonRef}
                {...rest}
                onClick={(e: any) => handler()}
              >
                {label}
              </styled.SimpleLimitedButton>
            );

          case "selec-and-button":
            return (
              <styled.DoubleButtonsContainer
                ref={buttonRef}
                className={`${customClass}`}
              >
                {/* <styled.LeftButton 
              {...rest} 
              disabled={disable}
              onClick={(e: any) => handler()}>
              {label}
              {icon && <styled.RightIcon onClick={iconClick} icon={icon}></styled.RightIcon>}
            </styled.LeftButton> */}

                <styled.RightButton
                  {...rest}
                  disabled={disable}
                  onClick={(e: any) => handlerRight()}
                >
                  {iconRight && (
                    <styled.LeftIcon
                      onClick={iconClickRight}
                      icon={iconRight}
                    ></styled.LeftIcon>
                  )}
                  {labelRight}
                </styled.RightButton>
              </styled.DoubleButtonsContainer>
            );
          case "selec-and-button-transactions":
            return (
              <styled.DoubleButtonsContainer
                ref={buttonRef}
                className={`${customClass}`}
              >
                <styled.LeftButton
                  {...rest}
                  disabled={disable}
                  onClick={(e: any) => handler()}
                >
                  {iconLeft ? <styled.LeftIcon
                      icon={iconLeft}
                    ></styled.LeftIcon> : null}
                  {label}
                  {icon ? (
                    <styled.RightIcon
                      onClick={iconClick}
                      icon={icon}
                    ></styled.RightIcon>
                  ) : null}
                </styled.LeftButton>

                <styled.RightButton
                  {...rest}
                  disabled={disable}
                  onClick={(e: any) => handlerRight()}
                >
                  {iconRight && (
                    <styled.LeftIcon
                      onClick={iconClickRight}
                      icon={iconRight}
                    ></styled.LeftIcon>
                  )}
                  {labelRight}
                </styled.RightButton>
              </styled.DoubleButtonsContainer>
            );
          case "double-checkbox":
            return (
              <styled.DoubleButtonsContainer>
                <styled.LeftRadioButton>
                  <input
                    type="radio"
                    name={radio2ButtonsName}
                    checked={valueToCheck === radio2ButtonsValue1}
                    value={radio2ButtonsValue1}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{label}</span>
                </styled.LeftRadioButton>

                <styled.RightRadioButton>
                  <input
                    type="radio"
                    name={radio2ButtonsName}
                    checked={valueToCheck === radio2ButtonsValue2}
                    value={radio2ButtonsValue2}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{labelRight}</span>
                </styled.RightRadioButton>
              </styled.DoubleButtonsContainer>
            );
          case "single-checkbox":
            return (
              <styled.DoubleButtonsContainer>
                <styled.SingleRadioButton>
                  <input
                    type="radio"
                    name={radio2ButtonsName}
                    value={radio2ButtonsValue1}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{label}</span>
                </styled.SingleRadioButton>
              </styled.DoubleButtonsContainer>
            );
          case "triple-checkbox":
            return (
              <styled.TripleButtonsContainer>
                <styled.LeftRadioButton>
                  <input
                    type="radio"
                    name={radio2ButtonsName}
                    checked={valueToCheck === radio2ButtonsValue1}
                    value={radio2ButtonsValue1}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{label}</span>
                </styled.LeftRadioButton>

                <styled.CenterRadioButton>
                  <input
                    type="radio"
                    disabled={paymentTypeToDisable === "recurring"}
                    name={radio2ButtonsName}
                    checked={valueToCheck === radio2ButtonsValue2}
                    value={radio2ButtonsValue2}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{labelCenter}</span>
                </styled.CenterRadioButton>

                <styled.RightRadioButton>
                  <input
                    type="radio"
                    name={radio2ButtonsName}
                    checked={valueToCheck === radio3ButtonsValue3}
                    value={radio3ButtonsValue3}
                    onChange={(e) => handler(e.target.value)}
                  />
                  <span>{labelRight}</span>
                </styled.RightRadioButton>
              </styled.TripleButtonsContainer>
            );

          case "minimal-with-icon":
            return (
              <styled.MinimalButton
                ref={buttonRef}
                className={`${customClass}`}
                {...rest}
                onClick={(e: any) => handler()}
              >
                {icon && (
                  <styled.LeftIcon
                    onClick={iconClick}
                    icon={icon}
                  ></styled.LeftIcon>
                )}
                {label}
              </styled.MinimalButton>
            );
          default:
            return <div></div>;
        }
      })()}
    </>
  );
};

export default Buttons;
