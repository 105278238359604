import styled from "styled-components";

export const FormItemsContainer = styled.div`
  flex-direction: column;
  margin-top: 20px;

  .form-item {
    h5 {
      font-size: 14px;
      margin-bottom: 8px;
      font-family: "Rubik", sans-serif !important;
      font-weight: 400;
      color: ${(props) => props.theme.contentColors.eighth};
    }
  }
`;
