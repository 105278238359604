import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useClickOutside from '../../../hooks/useClickOutside';

//Components
import Buttons from '../../Forms/Buttons';

//Assets
import brazilFinancesIcon from 'assets/images/finances/brazil-finances.svg';
import globalFinancesIcon from 'assets/images/finances/global-finances.svg';
import arrowIcon from 'assets/images/topbar/select-arrow.svg';

//Styles
import * as styled from './modals-styles';

type TNationSelectModal = {
  label1: string;
  label2: string;
  value1: string;
  value2: string;
  handler: any;
}

const NationsSelectButtonModal = ({ label1, label2, value1, value2, handler }: TNationSelectModal) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedFinances, setSelectedFinances] = useState('brazil-finances');

  const nationSelectModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();

  function handleShowModal() {
    if(nationSelectModalRef.current) {
      setIsMounted(false);
      setShowModal(true);
    } else {
      setIsMounted(true);
      setShowModal(true);
    }
  }

  function handleItems(value: string) {
    setSelectedFinances(value);

    handler(value);
  }

  useClickOutside(nationSelectModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelNation onClick={handleShowModal}>
        <img src={selectedFinances === value1 ? brazilFinancesIcon : globalFinancesIcon} alt="account" />
        <p>{intl.formatMessage({ id: selectedFinances === value1 ? label1 : label2 })}</p>
        
        <img src={arrowIcon} alt="arrow" />
      </styled.LabelNation>

      {showModal && <styled.NationSelectContainer 
        style={isMounted ? { animation: 'scaleModal .2s ease forwards' } : { animation: 'reveserScameModal .1s ease-out forwards' }} 
        ref={nationSelectModalRef}
        onAnimationEnd={() => { if (!isMounted) setShowModal(false) }}
      >
        <div className="buttons-container">
          <div className="button__item">
            <img src={brazilFinancesIcon} alt={value1} />
            <Buttons mode='simple' label={intl.formatMessage({ id: label1 })} handler={() => handleItems(value1)} />
          </div>
          <div className="button__item">
            <img src={globalFinancesIcon} alt={value2} />
            <Buttons mode='simple' label={intl.formatMessage({ id: label2 })} handler={() => handleItems(value2)} />
          </div>
        </div>
      </styled.NationSelectContainer>}
    </styled.RelativeContainer>
  )
} 

export default NationsSelectButtonModal;
