const axios = require('axios');

interface IPagination {
    pageSize: number,
    pageIndex?: number
}

class SaleCommissionService {

    async get({pageSize, pageIndex = 1}: IPagination) {
        const session = JSON.parse(localStorage.getItem("session") || "{}");
        if(session.entityType === 'Administrator') {
            return axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/comission/store/80`, {}, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });  
        }else {
            return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/sale_commissions?pageSize=${pageSize}&pageIndex=${pageIndex}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });  
        }
        
    }

    async getByOrderNumber(number: string | undefined) {
        const session = JSON.parse(localStorage.getItem("session") || "{}");

        if(session.entityType === 'Administrator') {
            return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/sale_commissions/${number}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });
        }else {
            return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/sale_commissions/${number}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });
        }
        
    }
}

export default new SaleCommissionService()