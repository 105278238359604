import { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import SessionService from '../../../../services/Session';
import ProductServices from '../../../../services/Product';

function useProduct({ product, setProduct }: any) {
  const history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    // if (/test/.test(window.location.href)) {
    //   window.location.href = `https://dashboard.premepay.com/login`;
    // } else {
    //   history.push("/login");
    // }
    history.push("/login");
  }

  const [incomingAmount, setIncomingAmount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageIndexProcessing, setPageIndexProcessing] = useState(false);
  const [numberTotalOfOrders, setNumberTotalOfOrders] = useState(1);

  const [useShippable, setUseShippable] = useState(false);
  const [useNameUnitLabel, setUseNameUnitLabel] = useState(false);
  const [useSalesPage, setUseSalesPage] = useState(false);
  const [productMedia, setProductMedia] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    let medias: any = []
    if(productMedia.length >= 1 && productMedia.length <= 5) {
      productMedia.map((media: any) => {
        const mediaBase64 = `data:${media.fileType};base64,${media.getFileEncodeBase64String()}`;

        medias.push({
          description: "",
          encodedFile: mediaBase64
        });
      });

      setImages(medias)
    }
    
  }, [productMedia])

  function redirectToOrderDetails(orderNumber: string, storeId: number) { 
    history.push(`/transactions/${orderNumber}/${storeId}/details`);
  }

  function handleProductData(name: string, value: string) {
    setProduct((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      }
    });
    
  }

  function handleShippableProductData(name: string, value: string) {
    setProduct((prevState: any) => {
      return {
        ...prevState,
        shippableProduct: {
          ...prevState.shippableProduct,
          [name]: value
        }
      }
    });
    
  }

  function handleProductAmount(value: string) {
    setProduct((prevState: any) => {
      return {
        ...prevState,
        amount: value
      }
    });
    
  }

  function submitNewProduct(e: any) {
    e.preventDefault();
    setProcessing(true);

    const productToCreate = {
      ...product,
      images: images
    }

    ProductServices.createProduct(productToCreate).then(response => {
      setProcessing(false);

      history.push(`/product/${response.data.id}/new`);

    }).catch(err => { 
      console.log(err);
      setProcessing(false);
    });
  }

  const shippableItems = [ 
    { label: 'weight', labelIntl: 'create.product.weight', placeholder: 'create.product.weight.placeholder' },
    { label: 'width', labelIntl: 'create.product.width', placeholder: 'create.product.width.placeholder' },
    { label: 'height', labelIntl: 'create.product.height', placeholder: 'create.product.height.placeholder' },
    { label: 'length', labelIntl: 'create.product.length', placeholder: 'create.product.length.placeholder' },
  ];

  return {
    
    shippableItems,

    useShippable, 
    setUseShippable,
    useNameUnitLabel, 
    useSalesPage, 
    productMedia,
    processing,
    
    setImages,
    setProductMedia,
    setUseNameUnitLabel,
    setUseSalesPage,
    handleProductData,
    handleShippableProductData,
    handleProductAmount,
    redirectToOrderDetails,

    submitNewProduct
  }
}

export default useProduct;