import { useState, useEffect } from "react";
import { Controller, useForm, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl } from "react-intl";
import { object, string } from "yup";
import axios from "axios";

import states from "../../../../../helpers/states-format";

import { StoreRegisterServices } from "../../../../../services/StoreRegister";

export const useAddressInformation = ({
  setProgressStep,
  isStoreWithCNPJ,
  store,
}: any) => {
  const intl = useIntl();

  const stringRequired = string().required(
    intl.formatMessage({ id: "webhook.error.message.default" })
  );

  const addressSchema = object({
    postalCode: stringRequired,
    address: stringRequired,
    houseNumber: stringRequired,
    reference: string(),
    district: stringRequired,
    city: stringRequired,
    state: stringRequired,
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addressSchema),
  });
  const { addressData } = StoreRegisterServices();

  const [isProcessing, setIsProcessing] = useState(false);

  const submitAddressInformation = (data: FieldValues) => {
    setIsProcessing(true);

    const {
      address,
      district,
      houseNumber,
      postalCode,
      reference,
      city,
      state,
    } = data;

    const cleanPostalCode = postalCode.replace("-", "");

    const payload = {
      street: address,
      number: parseInt(houseNumber),
      reference: reference,
      district: district,
      zipCode: parseInt(cleanPostalCode),
      city: city,
      state: state,
      country: "BR",
    };

    addressData(payload)
      .then((response) => {
        setIsProcessing(false);
        setProgressStep(isStoreWithCNPJ ? 3 : 4);
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
      });
  };

  const postalCodeWatch = watch("postalCode");

  useEffect(() => {
    if (postalCodeWatch && !store.address) {
      let zipcodeClean = postalCodeWatch.replace(".", "").replace("-", "");
      if (zipcodeClean.length === 8) {
        axios
          .get(`https://viacep.com.br/ws/${zipcodeClean}/json/`)
          .then((response) => {
            reset(
              (formValues) => ({
                ...formValues,
                address: response.data.logradouro,
                district: response.data.bairro,
                reference: response.data.complemento,
                city: response.data.localidade,
                state: response.data.uf,
              }),
              { keepErrors: true }
            );
          });
      }
    }
  }, [postalCodeWatch, store]);

  useEffect(() => {
    if (store) {
      if (store.address) {
        reset(
          () => ({
            address: store.address.street,
            district: store.address.district,
            reference: store.address.reference,
            city: store.address.city,
            state: states[store.address.state].value,
            houseNumber: store.address.number,
            postalCode: store.address.zipCode,
          }),
          { keepErrors: true }
        );
      }
    }
  }, [store]);

  return {
    Controller,
    control,
    handleSubmit,
    submitAddressInformation,
    isProcessing,
    errors,
  };
};
