import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

//Components
import GrossSalesChart from "./GrossSalesChart";

//Services
import ExportCSVService from 'services/ReportsCSV';

import SelectAndButtonModal from '../Layout/Modals/SelectAndButtonModal';

//Styles
import * as styled from './charts-styles';

type ReportChartData = {
  title: string;
  data: any;
  id: string;
  isFull?: boolean;
};

const GrossSalesComponent = ({ title, data, id, isFull }: ReportChartData) => {
  const [year, setYear] = useState('');
  const location = useLocation();
  const [isInHomepage, setIsInHomePage] = useState(() => {
    if(location.pathname === '/') {
      return true;
    }

    return false;
  });

  useEffect(() => {
    const date = new Date();

    setYear(date.getFullYear().toString());
  }, [])

  function exportToCSV(){
    ExportCSVService.grossSalesExport(year).then(response => {
      let downloadLink = document.createElement("a");
      let blob = new Blob([response.data], {type: 'text/csv'});
      let url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "export-gross-sales-premepay.csv";  //Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  return (
    <styled.Chart className={isFull ? 'full' : ''}>
      <div className="box-chart">
        <div className="report-chart_top">
          <div className="left">
            <div className="title">{title}</div>
          </div>
          
          { !isInHomepage && <div className="right">
            <SelectAndButtonModal rightHandler={exportToCSV}/>
          </div> }
        </div>
        <div className="chart-wrapper">
          <GrossSalesChart />
        </div>
      </div>
    </styled.Chart>
  );
};

export default GrossSalesComponent;
