import styled from 'styled-components';


interface FormProps {
  background: string;
}

type TItemIcon = {
  icon: string;
}

type TMaskBG = {
  bg: string;
}

/*------------------------------------------------------------------
[ Finance div ]*/

export const FinanceBox = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 916px) {
    margin-top: 20px;
  }
`;

export const FinanceBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 100%; */
  max-width: 600px;

  .title {
    margin-bottom: 34px;
    display: flex;
    font-size: 18px;
    font-family: "Brandon Text Medium" !important;
    color: ${props => props.theme.contentColors.secondary};
  }

  .view-all__element {
    margin-left: auto;
    font-size: 14px;
    color: ${props => props.theme.primaryColors.primary};
    cursor: pointer;
  }

  .label {
    color: ${props => props.theme.textColors.primary};
    
  }

  .value {
    color: ${props => props.theme.textColors.primary};
  }
`;

export const Separator = styled.div`
  width: calc(100% + 40px);
  height: 2px;
  background: ${props => props.theme.backgroundColors.fifth};
  margin: 20px 0;
`;

export const BoxFinanceBottom = styled.div`
  display: flex;
  flex-direction: row;

  .content {

    p {
      color: ${props => props.theme.textColors.primary};
    }

    @media screen and (max-width: 429px) {
      max-width: 285px;
    }
  }
`;

/*---------------------------------------------*/


/*------------------------------------------------------------------
[ Faq and Help ]*/

export const Faq = styled.div`
  
  .title {
    margin-bottom: 34px;
    font-size: 18px;
    font-family: "Brandon Text Medium" !important;
    color: ${props => props.theme.contentColors.secondary};
  }

  @media screen and (max-width: 916px) {
    width: 100%;
    border-right: 0px;
    padding-bottom: 1.5em;
    margin-bottom: 1em;
  }
`;


export const Help = styled.div`

  .title {
    margin-bottom: 34px;
    font-size: 18px;
    font-family: "Brandon Text Medium" !important;
    color: ${props => props.theme.contentColors.secondary};
  }

  @media screen and (max-width: 768px) {
    padding-left: 0px;
    padding-bottom: 4em;
  }

`;


export const ListWithIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 200px;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
`;

export const Item = styled.div`
  color: ${props => props.theme.contentColors.primary};
  height: 80px;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;

  img {
    width: 40px;
    margin-bottom: 10px;
  }

  .label {
    display: flex;
    flex-direction: column;
    transition: all .3s ease;

    p {
      font-size: 18px;
      font-family: "Brandon Text Medium" !important;
      color: ${props => props.theme.contentColors.secondary};
      margin: 6px 0 11px 0;
    }

    span {
      font-size: 14px;
      color: ${props => props.theme.contentColors.secondary};
    }
  }

  &:hover {

    div {
      transition: all .3s ease;
      background-color: ${props => props.theme.contentColors.fourth};

      &:last-child {
        background-color: transparent;
      }
    }
    
    .label {
      p {
        transition: all .3s ease;
        color: ${props => props.theme.contentColors.fourth};
      }

      span {
        color: ${props => props.theme.contentColors.secondary};
      }
    }
  }
`;

export const ItemIcon = styled.div<TItemIcon>`
  background-color: ${props => props.theme.contentColors.secondary};
  -webkit-mask: url(${props => props.icon}) no-repeat center;
  mask: url(${props => props.icon}) no-repeat center !important;
  min-width: 40px;
  min-height: 40px !important;

  @media screen and (max-width: 376px) {
    width: 40px;
    min-height: 40px !important;
  }
`;

// export const IconItemFaq = styled.div<FormProps>`
  
// `;

/*---------------------------------------------*/


export const ReportsTotal = styled.div`
  width: 100%;

  .report-totals{
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.textColors.primary};

    .label-top{
      font-size: 1.2em;
      span{
        color: ${props => props.theme.textColors.fourth};
      }
    }

    .c-label-top--flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .list{
      display: flex;
      flex-direction: column;
      margin-top: 2em;
      width: 100%;
      /* flex-wrap: wrap; */

      .list-item{
        /* flex-basis: 50%; */
        margin-bottom: 2em;
        width: 25%;

        .list-item-label{
          font-size: 1.2em;
        }

        .list-item-value{
          font-size: 2em;
          font-weight: 700;
        }

        @media screen and (max-width: 916px) {
          width: 50%;
        }
      }

      .c-list--flex-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 916px) {
          flex-wrap: wrap;
        }
      }
    }

    .perfomance{
      margin-bottom: 2em;
      display: flex;
      flex-direction: row;
      align-items: center;

      .right{
        width: 65%;
        color: #f00;
      }

      .left{
        flex: 1;
      }

      .label{
        font-size: 1.2em;
      }

      .value{
        font-size: 2em;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 916px) {
      padding: 0px 0px;
    }
  }

`;

export const SelectContainer = styled.div`
  /* margin-right: 8px; */
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
    font-size: 12px;
  }

  select {
    background-color: transparent;
    color: ${props => props.theme.backgroundColors.primary};
    border-radius: 3px;
    padding: 8px 16px;
    width: auto;
    border: 1px solid ${props => props.theme.backgroundColors.primary};
    font-size: 12px;
    height: 36px;
    cursor: pointer;
    font-weight: 500;
    /* box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3); */

    &:disabled {
      opacity: 0.5;
      background: ${props => props.theme.backgroundColors.primary};
      cursor: not-allowed;
    }
  }
`;

export const AccountSelectContainer = styled.div`
  margin: 10px 0 27px 0;
  position: relative;
  

  .selected {
    width: 170px;
    display: flex;
    cursor: pointer;
    align-items: center;

    p {
      color: ${props => props.theme.contentColors.secondary};
      margin: 0 7px 0 8px;
      font-size: 16px;
      min-width: 85px;
    }
  }

  .select {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 35px;
    padding: 4px;
    border-radius: 3px;
    background: linear-gradient(${props => props.theme.backgroundColors.fourth}, ${props => props.theme.backgroundColors.fourth}) padding-box,
    linear-gradient(to bottom, ${props => props.theme.backgroundColors.seventh}, ${props => props.theme.backgroundColors.eighth}) border-box;
    transition: all .3s ease-in-out;
    opacity: 0;
    animation: showSelect .2s ease-in-out forwards;

    input {
      opacity: 0;
      width: 1px;
    }

    label {
      margin: 2px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all .3s ease-in-out;
      padding: 6px;
      border-radius: 3px;

      &:hover {
        background: ${props => props.theme.backgroundColors.secondary};
        transition: all .3s ease-in-out;
      }
    }

    span {
      font-size: 16px;
      color: ${props => props.theme.contentColors.secondary};

      img {
        margin: 0 8px 0 0;
      }
    }

    @keyframes showSelect {
      to {
        opacity: 1;
        transition: all .2s ease-in-out;
      }
    }
  }
`;


export const RowWith3GridColumns = styled.div`
  display: grid;
  grid-template-columns: 3fr 2.2fr 2.2fr;

  @media screen and (max-width: 1136px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 916px) {
    grid-template-columns: 1fr;
  }
`;

export const RowWith2GridColumns = styled.div`
  display: grid;
  grid-template-columns: 5.2fr 2.2fr;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
