type TNewProduct = {
  title: string;
  amount: string;
  description: string;
  currency: string;
  redirectUrl?: string;
  nameUnitLabel?: string;
  shippableProduct?: {
    [key: string]: string;
  };
};

type ProductProps = {
  product: TNewProduct;
  progressStep: number;
  handleErrors: any;
};

export function productValidations({
  product,
  progressStep,
  handleErrors,
}: ProductProps) {
  let errors = [];

  if (progressStep === 1) {
    if (product.title.length === 0) errors.push("productTitle");
    if (product.description.length === 0) errors.push("productDescription");
    if (parseFloat(product.amount) === 0 || product.amount === "")
      errors.push("productPrice");
  }

  if (progressStep === 2) {
  }

  console.log(errors);
  handleErrors(errors);

  return errors.length === 0;
}
